.RadioButton {
  all: unset;
  position: relative;
  box-sizing: border-box;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 4px);
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  padding: 1rem;
  transition: all var(--transition-fast-in-out);
  caret-color: transparent;
  cursor: pointer;
  isolation: isolate;
}
.RadioButton .Label {
  cursor: pointer;
}
.RadioButton:hover {
  border-color: var(--color-border-secondary-subtle, #6888b5);
  box-shadow: var(--box-shadow-pretoken-container-interaction);
}
.RadioButton:not(.RadioButton--isDisabled)[tabindex]:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  outline-offset: 0.125rem;
}
.RadioButton--isChecked,
.RadioButton--isChecked:hover {
  border-color: var(--color-border-secondary-medium, #254b86);
}
.RadioButton-content {
  display: flex;
}

/* Overlay */
.RadioButton-overlay {
  position: absolute;
  z-index: var(--z-index-container-overlay);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-radius: var(--border-radius-sm, 4px);
  background: transparent;
  opacity: 0;
  transition: all var(--transition-fast-in-out);
}
.RadioButton-overlay:active,
.RadioButton-content:active + .RadioButton-overlay,
.RadioButton:active .RadioButton-overlay {
  opacity: 1;
  background: rgba(37, 75, 134, 0.12);
}

/* Radio input */
.RadioButton-input-contatiner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RadioButton input[type='radio'] {
  display: none;
}

.RadioButton-button {
  pointer-events: none;
}

/* Text */
.RadioButton-text {
  padding: 0 1rem 0 0.5rem;
  width: 100%;
}
.RadioButton-title {
  font: var(--typography-heading-3);
  color: var(--color-foreground-secondary-bold, #00245b);
}
.RadioButton-title--isTruncated {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: 3rem;
}
.RadioButton-description {
  color: var(--color-foreground-neutral-subtle, #717385);
  margin: 0;
}
.RadioButton-description--isTruncated {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: 2.5rem;
}

/* isDisabled */
.RadioButton--isDisabled {
  border: 1px solid transparent;
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
}
.RadioButton--isDisabled,
.RadioButton--isDisabled .Label {
  cursor: not-allowed;
}
.RadioButton--isDisabled:hover {
  border: 1px solid transparent;
  box-shadow: unset;
}
.RadioButton--isDisabled .RadioButton-title {
  color: var(--color-foreground-neutral-subtle, #717385);
}
.RadioButton-button--isDisabled {
  outline-color: var(--color-foreground-neutral-disabled, #9ea1b1);
  background: transparent;
}
.RadioButton--isDisabled .RadioButton-overlay,
.RadioButton--isDisabled:active .RadioButton-overlay {
  pointer-events: none;
  opacity: 0;
}
.RadioButton--isDisabled .Icon svg,
.RadioButton--isDisabled .Icon svg path,
.RadioButton--isDisabled .Icon svg g {
  fill: var(--color-foreground-neutral-subtle, #717385);
}
