.RiskScore--regular {
  padding-bottom: 1.25rem;
  padding-inline: 0.5rem;
}

.RiskScore--regular--noLabel {
  padding-bottom: 0;
}

.RiskScore-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 4rem;
}

.RiskScore-progressStats {
  position: relative;
}

.RiskScore-progressStatsIconBackGround,
.RiskScore-progressStatsIconIndicator {
  will-change: auto;
  /* stroke-width: 1.25rem; */
  stroke-miterlimit: round;
  transition: stroke-dashoffset 850ms ease-in-out;
}

.RiskScore-progressStatsIconIndicator--success {
  stroke: var(--color-success-700, #028329);
}

.RiskScore-progressStatsIconIndicator--warning {
  stroke: var(--color-warning-400, #f6a91c);
}

.RiskScore-progressStatsIconIndicator--critical {
  stroke: var(--color-critical-700, #cc1c10);
}

.RiskScore-progressStatsIconIndicator--info {
  stroke: var(--color-info-800, #422bbc);
}

.RiskScore-progressStatsNumberColor--success,
.RiskScore-progressLabel--success {
  color: var(--color-success-700, #028329);
}

.RiskScore-progressStatsNumberColor--warning,
.RiskScore-progressLabel--warning {
  color: var(--color-warning-400, #f6a91c);
}

.RiskScore-progressStatsNumberColor--critical,
.RiskScore-progressLabel--critical {
  color: var(--color-critical-700, #cc1c10);
}

.RiskScore-progressStatsNumberColor--info,
.RiskScore-progressLabel--info {
  color: var(--color-info-800, #422bbc);
}

.RiskScore-progressStatsNumber {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RiskScore-progressChip--small {
  white-space: nowrap;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.RiskScore-progressChip--regular {
  white-space: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: -1.25rem;
  transform: translateX(-50%);
}
