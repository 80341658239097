.CheckboxGroup {
  display: flex;
  flex-flow: row wrap;
  border: none;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
}

.CheckboxGroup-legend {
  display: block;
  padding: 0;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-heading-4);
}

.CheckboxGroup-container {
  display: flex;
  flex-direction: column;
}

.CheckboxGroup-error {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  color: var(--color-foreground-critical-medium, #cc1c10);
  word-break: break-word;
  gap: 0.25rem;
  font: var(--typography-body-3);
}

.CheckboxGroup-error span svg {
  width: 0.75rem;
  height: 0.75rem;
}
