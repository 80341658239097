.CertsControlsMore-columnsItems.Menu-items {
  padding: 0.75rem 0 0 0;
  width: 22.1875rem;
  max-height: 90vh;
  overflow: hidden;
}

.CertsControlsMore-saviItem .MenuNested-nestedTriggerLabel {
  color: var(--color-foreground-savi-medium, #a800e0);
}
.CertsControlsMore-saviItem .Icon svg path {
  fill: var(--color-foreground-savi-medium, #a800e0);
}

/* Tabs */
.CertsMoreColumnsFilter-tabGroup {
  width: 100%;
}
.CertsMoreColumnsFilter-tabGroup > :first-child {
  margin-left: 0.75rem;
}

.CertsMoreColumnsFilter-dropDownBody {
  padding: 0.75rem;
  height: 100%;
  max-height: calc(100vh - 16rem);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.CertsMoreColumnsFilter-bodyNote {
  width: 100%;
  padding: 0 0.75rem 0.25rem 0.75rem;
  color: var(--color-foreground-neutral-subtle, #717385);
  white-space: break-spaces;
}

.CertsMoreColumnsFilter-subItems {
  height: 2.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0;
}
.CertsMoreColumnsFilter-subItems,
.CertsMoreColumnsFilter-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
}

.CertsMoreColumnsFilter-text {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.CertsMoreColumnsFilter-dragger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;

  /* TODO: @Jacob, find out when this should display */
  visibility: hidden;
}
