/* Time select */
.react-datepicker--time-only {
  width: 5.75rem;
  overflow: hidden;
  border-radius: var(--border-width-xlg, 0.1875rem);
}
.react-datepicker__time {
  width: max-content;
  font: var(--typography-body-2);
  border-radius: var(--border-width-xlg, 0.1875rem);
}
.react-datepicker__header--time--only .react-datepicker__time-container,
.react-datepicker__time-container {
  float: left;
  border-radius: var(--border-width-xlg, 0.1875rem);
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100%;
  background: var(--color-background-neutral-subtlest, #ffffff);
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  display: flex;
  flex-direction: column;
  width: max-content;
  max-height: 19rem;
  height: 100%;
  padding-top: 0.25rem;
}
/* Scrollbar */
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar {
  width: 0.5rem;
  padding: 0.25rem;
  background: var(--color-background-neutral-subtlest, #ffffff);
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border-radius: var(--border-width-xlg, 0.1875rem);
}

/* Remove time header */
.react-datepicker__time-container.react-datepicker__header,
.react-datepicker__time-container.react-datepicker__header:not(
    .react-datepicker__header--has-time-select
  ) {
  display: none;
}
/* Not selected */
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 0.25rem 0.5rem;
  width: calc(100% - 0.5rem);
  margin: 0 0.25rem 0 0.25rem;
  border-radius: var(--border-width-xlg, 0.1875rem);
  transition: all var(--transition-fast-in-out);
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: rgba(55, 81, 255, 0.08);
}
.react-datepicker__time-list-item:active {
  background: rgba(55, 81, 255, 0.1);
}
/* Selected */
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-neutral-bold, #212328);
  background: rgba(55, 81, 255, 0.1);
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__time-list-item:active {
  background: rgba(55, 81, 255, 0.08);
}
/* Disabled */
.react-datepicker__time-list-item--disabled {
  display: none;
}

/* Date and time adjustments */
.react-datepicker__time-container {
  border-left: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  border-radius: 0 var(--border-width-xlg) 0 0;
}
.react-datepicker-time__header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 0.5rem);
  height: 3.5rem;
  background: var(--color-background-neutral-subtlest, #ffffff);
  border-bottom: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  border-radius: 0 var(--border-width-xlg) 0 0;
  font: var(--typography-body-bold-1);
  color: var(--color-neutral-1000, #212328);
}
/* Display none when in time only container */
.react-datepicker__header--time--only .react-datepicker-time__header {
  display: none;
}
