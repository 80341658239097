@import './utilities.css';

.body {
  .Nav_CertificationRow {
    .Nav_CertificationRow-sourceAndPurpose
      .Nav_CertificationRow-sourceAndPurposeHeading {
      color: var(--color-foreground-neutral-bold, #212328);
    }

    .Nav_CertificationRow-sourceAndPurpose
      .Nav_CertificationRow-sourceAndPurposeId {
      color: var(--color-foreground-neutral-subtle, #717385);
    }

    .Nav_CertificationRow-details .Nav_CertificationRow-detailsLabel,
    .Nav_CertificationRow-subject1 .Nav_CertificationRow-subject1Label,
    .Nav_CertificationRow-subject2 .Nav_CertificationRow-subject2Label {
      @extend Nav_RecentAssignmentRow-label;
    }

    .Nav_CertificationRow-details .Nav_CertificationRow-detailsValue,
    .Nav_CertificationRow-subject1 .Nav_CertificationRow-subject1Value,
    .Nav_CertificationRow-subject2 .Nav_CertificationRow-subject2Value {
      @extend Nav_RecentAssignmentRow-value;
    }
  }
}
