.hidescroll {
  overflow-y: hidden;
}
div.MuiDrawer-modal {
  z-index: 1300 !important;
}
.advance-filter-modal {
  height: 100%;
  .advanced-filter-panel .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    min-height: 67px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.12);
    align-items: center;
    padding-right: 40px;
    .reset {
      color: var(--primary-main);
      font-size: 12px;
      font-weight: 500;
      margin-right: 27px;
    }
  }
}
.advanced-filter-panel .scrollbar:hover {
  overflow-y: scroll;
}

.advanced-filter-panel {
  .MuiAutocomplete-tag {
    display: none;
  }
  height: 100%;
  position: relative;
  max-width: 26.125rem;
  .filter-body {
    padding: 5px 36px;
    height: 77% !important;
  }
  .label-div-advfil {
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin-bottom: 0;
  }
  .section-title {
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
    color: black;
  }
  .advfilter-title {
    font-size: 12px;
    font-weight: 500;
    color: black;
  }
  .adv-fil-loader {
    width: 50px;
    height: 50px;
  }
  .panel-det-padd {
    margin-top: -6px;
  }
  .hide-filter-link {
    padding: 6px 6px 0 0;
  }
  .subtitle {
    margin-bottom: 5px;
    font-size: 12px;
    color: var(--grey-font);
    margin-top: 3px;
  }
  .form-group {
    margin-bottom: 3px;
  }

  .scrollbar {
    overflow-y: hidden;
    padding-top: 23px;
    // opacity: 0;
  }

  .form-container {
    margin-bottom: 20px;
    width: 92%;
  }
  .session-timing .datepicker-wrapper {
    margin-left: 0px;
    .calender-icon {
      top: 5px;
      width: 13px;
      height: 13px;
    }
  }

  .filter-check-items {
    padding-bottom: 12px;
  }

  .filter-item {
    margin-top: -10px;
  }

  .session-timing .datepicker-wrapper {
    .session-timing {
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }
}
.advancefilter-heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 5px 36px;
  .title {
    font-size: 18px;
    font-weight: 500;
    color: black;
  }
}
.heading-border {
  border-bottom: solid 1px var(--grey-border);
}
.dateButtons {
  button {
    width: 100px;
  }
}

.dateRingePicker {
  border: solid 1px #dddddd;
  margin-top: 12px;
}

.autoCompleteField {
  div {
    height: 100%;
  }
}

.selectedLabel {
  background-color: #e8ebfd !important;
  svg {
    fill: var(--primary-main);
  }
}
