.filterLoader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.sortingBtn {
  width: 80px;
  padding: 8px 21px;
  border-radius: 3px;
  color: #747474;
  border: solid 1px #dfe0eb;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 21px;
  margin-right: 19px;
  outline: none;
  &:hover {
    background-color: rgba(210, 216, 252, 0.5);
    border-color: rgba(210, 216, 252, 0.5);
    color: #3751ff;
  }
}

.sortingBtn.active {
  background-color: rgba(210, 216, 252, 0.5);
  border-color: rgba(210, 216, 252, 0.5);
  color: #3751ff;
  outline: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.stringCheckBox {
  width: 18px;
  height: 18px;
}
.filterWidth {
  width: 246px;
}
