.Savi-Companion-Action-Popover--DisplayFlex {
  display: flex;
  margin-bottom: 0.5rem;
  gap: 0.25rem;
}

.Savi-Companion-Action-Popover--DisplayFlex.Title {
  margin-top: 0.5rem;
}

.Savi-Companion-Action-Popover--ContentWrapper {
  width: min-content;
  min-width: 20rem;
  max-width: 60rem;
  padding: 1rem;
}

.Savi-Companion-Action-Popover--ContentWrapper .Action-Button-Wrapper {
  padding: 0.5rem 0 0;
}

.Savi-Companion-Action-Popover--ContentWrapper .Action-Button-Wrapper .Cancel {
  width: 8.125rem;
  height: 2rem;
}

.Savi-Companion-Action-Popover--ContentWrapper
  .Action-Button-Wrapper
  .Cancel
  .Text {
  color: var(--color-foreground-primary-medium, #3751ff);
}

.Savi-Companion-Action-Popover--ContentWrapper .Action-Button-Wrapper .GotIt {
  width: 4rem;
  height: 2rem;
  padding: 0.625rem 0.75rem;
}

.Savi-Companion-Action-Popover--ContentWrapper
  .Action-Button-Wrapper
  .GotIt
  .Text {
  color: var(--color-foreground-neutral-inverse, #fff);
}

.Action-Button--MarginLeft-Auto {
  margin-left: auto;
}

.Savi-Companion-Action-Popover--DisplayFlex.Reasons {
  padding-left: 1rem;
}

@media (max-width: 960px) {
  .Savi-Companion-Action-Popover--ContentWrapper {
    padding: 0.5rem;
    width: 100%;
    min-width: inherit;
    max-width: inherit;
  }
  .Savi-Companion-Action-Popover--DisplayFlex.Reasons {
    padding-left: 1.5rem;
  }
  .Savi-Companion-Action-Mobile-Drawer-Header {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }
}
