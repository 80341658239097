.Global404-pageNotFound {
  display: flex;
  justify-content: space-around;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1021;
  height: 100%;
  position: fixed;
  background-color: var(--page-bg);
}

.Global404-pageNotFound > div {
  margin-top: 10%;
}

.Global404-pageNotFound .Global404-logo {
  color: var(--primary-main);
  text-decoration: none;
  font-size: 1.125rem; 
  font-weight: 500;
  padding-left: 1.563rem; 
  padding-top: 1.25rem; 
  margin-top: 0%;
}

.Global404-pageNotFound .Global404-text-header {
  font-weight: 500;
  font-size: 7.75rem; 
  color: var(--color-navy-900, #00245B);
  text-align: center;
}

.Global404-pageNotFound .Global404-error-body {
  font-size: 1.875rem; 
  font-weight: 500;
  margin-bottom: 0.625rem; 
  color: var(--color-navy-900, #00245B);
  text-align: center;
}

.Global404-pageNotFound .Global404-error-details {
  width: 23.75rem; 
  font-size: 1.125rem; 
  text-align: center;
  color: var(--color-neutral-1000, #212328);
  margin-bottom: 3.938rem; 
}

.Global404-pageNotFound .Global404-btn-back {
  border-radius: 0.188rem; 
  border: solid 1px var(--color-indigo-800, #2035CB);
  background-color: var(--color-indigo-800, #2035CB);
  font-family: Rubik;
  font-size: 1.5rem; 
  font-weight: 500;
  color: var(--color-neutral-000, #FFFFFF);
  padding: 0.375rem 0.75rem;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}

.Global404-pageNotFound .Global404-error-illustration {
  right: 7.063rem; 
  display: block;
}

@media (max-width: 62rem) {
  .Global404-error-illustration {
    display: none;
  }
}

.Global404-pageNotFound .Global404-error-content {
  display: flex;
  flex-direction: column;
}