.logout-section {
  color: #150b0b;
  background-color: #fff;
  overflow-y: hidden;
  .logout-logo {
    padding: 15px 0 0 25px;
    color: var(--primary-main);
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 70px;
  }
  .logout-msg {
    margin: 20px 0;
  }
  .btn-logout {
    font-size: 24px;
  }
  .app-logout-logo {
    width: 150px;
  }
}
