.SODItem {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  padding: 0.5rem 1rem;
}

.SODItem:not(:last-child) {
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.SODItem-details {
  display: flex;
  flex-direction: column;
}

.SODItem-detailsTitle {
  color: var(--color-foreground-neutral-bold, #212328);
}

.SODItem-detailsDescription {
  color: var(--color-foreground-neutral-subtle, #717385);
}
