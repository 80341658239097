.dropdown-container {
  position: relative;
}
.input-wrapper {
  width: 100%;
  height: 42px;
  border: 1px solid var(--grey-border-input);
  background-color: var(--white);
  border-radius: 3px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover,
  &.focused {
    border-color: var(--primary-main);
  }

  & input {
    font-size: 12px;
    box-sizing: border-box;
    padding: 4px 14px;
    color: var(--black);
    width: 0;
    min-width: 30;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    &::placeholder {
      color: var(--grey-font-input);
    }
  }

  i {
    padding-top: 6px;
  }
}

.list-box {
  width: 100% !important;
  margin: 2px 0 0;
  padding: 5px !important;
  position: absolute;
  list-style: none;
  background-color: var(--white);
  // overflow: auto;
  overflow-y: scroll !important;  
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  &.short-menu {
    max-height: 125px;
  }

  li {
    span {
      font-size: 12px;
      font-weight: normal;
      color: var(--black) !important;
    }
  }

  & li {
    padding: 5px 12px;
    display: flex;
    & span {
      flex-grow: 1;
    }
    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: var(--primary-bg-li);
    font-weight: 500;
    // & svg {
    // }
  }

  & li[data-focus='true'] {
    background-color: var(--grey-bg);
    cursor: pointer;

    & svg {
      color: var(--black);
    }
  }
}
.disabled {     
  background-color: var(--light-bg);
  border-color: var(--light-bg);
 }
