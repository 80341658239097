.Scheduler {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.Scheduler-block {
  width: 100%;
}

.Scheduler-block--hidden {
  display: none;
}

.Scheduler-blockLabel {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.Scheduler-blockLabel--weekday {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-bold-2);
}

.Scheduler-blockLabel--date {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
}
