.sideBarSpacing {
  margin-left: var(--sidebar-minified-width) !important;
  transition: margin-left 0.3s ease;
}

.menuOpen {
  margin-left: 240px !important;
  transition: margin-left 0.3s ease;
}

.menuClosed {
  margin-left: 0px;
}

@media screen and (max-width: 480px) {
  .sideBarSpacing {
    margin-left: 0 !important;
  }

  .menuOpen {
    margin-left: 0 !important;
  }
}
