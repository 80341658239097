.body {
  .Nav_RecentAssignmentRow {
    padding: 1rem 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
    gap: 0.5rem;
    border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);

    &:last-child {
      border-bottom: none;
    }

    &.Nav_RecentAssignmentRow--error {
      color: var(--color-foreground-critical-medium, #cc1c10);
      justify-content: start;
    }

    &.Nav_RecentAssignmentRow--loading {
      justify-content: center;
    }

    .Nav_RecentAssignmentRow-sourceAndPurpose,
    .Nav_RecentAssignmentRow-details,
    .Nav_RecentAssignmentRow-subject1,
    .Nav_RecentAssignmentRow-subject2,
    .Nav_RecentAssignmentRow-status {
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    .Nav_RecentAssignmentRow-sourceAndPurpose {
      display: flex;
      align-items: start;
      gap: 0.75rem;
    }

    .Nav_RecentAssignmentRow-status {
      display: flex;
      align-items: center;
    }
  }
}
