.TabGroup {
  position: relative;
  display: inline-flex;
  align-items: flex-end;
}

.TabGroup-fullWidthBorder {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}
.TabGroup--isHorizontalScroll .TabGroup-fullWidthBorder {
  bottom: 0.25rem;
}

.TabGroup--isHorizontalScroll {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  /* Required for tab outline */
  padding: 0.25rem;
}
/* For Webkit browsers (Chrome, Safari, newer versions of Edge) */
.TabGroup--isHorizontalScroll::-webkit-scrollbar {
  display: none; /* Hide the scroll bar */
}

/* For Firefox */
.TabGroup--isHorizontalScroll {
  scrollbar-width: none; /* Hide the scroll bar */
}

/* For older versions of Internet Explorer */
.TabGroup--isHorizontalScroll {
  -ms-overflow-style: none; /* Hide scroll bar */
}

.TabGroup-pointerLeft,
.TabGroup-pointerRight {
  position: absolute;
  /* top, right, and left will need to be updated by the parent, depends on the padding and position context */
  top: 1.75rem; /* +4px for the tab focus outline */
  opacity: 0;
  box-shadow: var(--box-shadow-pretoken-container-default);
  pointer-events: none;
  transition: opacity var(--transition-quick-in-out);
  z-index: var(--z-index-tabgroup-left-right-pointers);
}
.TabGroup-pointerLeft--isVisible,
.TabGroup-pointerRight--isVisible {
  opacity: 1;
}
.TabGroup-pointerLeft {
  left: 0.5rem;
}
.TabGroup-pointerRight {
  right: 0.5rem;
}

.TabItem--isLastChild {
  /* top, right, and left will need to be updated by the parent, depends on the padding and position context */
  position: absolute;
  top: 1.25rem; /* +4px for the tab focus outline */
  right: 1rem;
  box-shadow: var(--box-shadow-pretoken-overlay-left);
  background: var(--color-background-neutral-subtlest, #fff);
  z-index: var(--z-index-tabgroup-sticky-last-child);
}
