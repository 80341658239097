.kpiPlusIcon {
  svg {
    path {
      fill: blue;
    }
  }
}

.addKpi {
  font-size: 12px;
  color: var(--home-subtitle);
  font-weight: 500;
  margin-left: 2px;
  margin-right: 2px;
}
.addButtonColor {
  color: var(--home-subtitle);
}
.requestAction-Tiles {
  min-width: 12.25rem;
  height: 5.75rem !important;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 20px;
  justify-content: space-between;
  margin: 0px 7px;
  // border-radius: 8px;
  // box-shadow: 0 2px 6px 0 rgba(206, 214, 234, 0.64);
  // background-color: var(--kpiwidget-bg);
  .editIconsdisplay {
    display: none;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.02);
    transition-duration: 100ms;
    border-radius: 8px;
    background-color: #f4f6fa;
    .editIconsdisplay {
      display: flex;
    }
  }
  &:hover .editIconsDiv {
    opacity: 1;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .editIconsDiv {
    opacity: 0.2;
  }
  .arrowDownColor {
    color: var(--success-green);
  }
  .status-Tile {
    font-size: 12px;
    color: var(--home-main-title);
    font-weight: normal;
    opacity: 0.8;
  }
  .status-Tile-Data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .status-count {
    font-size: 24px;
    color: var(--black);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0px;
  }
  .percentage-count {
    font-size: 12px;
    color: var(--success-green);
    margin-top: 8px;
  }
  .percentage-countRed {
    color: var(--failure-red);
  }
}
