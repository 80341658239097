.SpbTimeLine {
  position: relative;
}

.SpbTimeLine-tail--normal,
.SpbTimeLine-tail--dashed {
  position: absolute;
  z-index: var(--z-index-root-level);
  top: 0;
  bottom: -0.5rem;
  left: 50%;
}

.SpbTimeLine-tail--normal {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.SpbTimeLine-tail--dashed {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  overflow: hidden;
}

.SpbTimeLine-tailDashedBlocks {
  width: 1px;
  height: 1rem;
  border-radius: var(--border-radius-circle, 50%);
  background-color: var(--color-border-secondary-subtle, #6888b5);
}

.SpbTimeLine-icon {
  display: flex;
  border-radius: var(--border-radius-circle, 50%);
  align-items: center;
  justify-content: center;
  position: relative;
}

.SpbTimeLine-icon--small {
  width: 1rem;
  height: 1rem;
}

.SpbTimeLine-icon--large {
  width: 1.5rem;
  height: 1.5rem;
  /* background-image: repeating-linear-gradient(0deg, white, white 20px, black 20px, black 40px); */
}
