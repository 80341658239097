.ARSTimeLine2 {
  position: relative;
}

.ARSTimeLine2-icon {
  height: 0.675rem;
  width: 0.675rem;
  margin-top: 0.45rem;
  border-radius: var(--border-radius-circle, 0.25rem);
  position: relative;
  background: var(--Light-Brand-Secondary-500, #033176);
}

.ARSTimeLine2-icon--active {
  outline: 0.25rem solid var(--color-navy-500, #6888b5);
}

.ARSTimeLine2-icon--critical {
  background: var(--Light-Critical-500, #cc1c10);
}

.ARSTimeLine2-icon--outline {
  background: #fff;
  outline: 1px solid var(--color-neutral-400, #9ea1b1);
}

.ARSTimeLine2-line {
  width: 1px;
  position: absolute;
  top: 1.25rem;
  bottom: -1.25rem;
  left: 50%;
  border-radius: var(--border-radius-sm, 0.25rem);
  transform: translateX(-50%);
}

.ARSTimeLine2-line--none {
  display: none;
}

.ARSTimeLine2-line--fill {
  background: var(--Light-Brand-Secondary-500, #033176);
}

.ARSTimeLine2-line--fill.ARSTimeLine2-line--critical {
  background: var(--Light-Critical-500, #cc1c10);
}

.ARSTimeLine2-line--outline {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
  overflow: hidden;
}

.ARSTimeLine2-lineChild--outline {
  width: 100%;
  min-height: 0.5rem;
  background: var(--color-navy-500, #6888b5);
  border-radius: var(--border-radius-sm, 0.25rem);
}

.ARSTimeLine2-lineChild--outline:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ARSTimeLine2-lineChild--outline:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ARSTimeLine2-lineChild--critical {
  background: var(--Light-Critical-500, #cc1c10);
}

/* var(--Light-Brand-Secondary-500, #033176);
var(--Light-Critical-500, #cc1c10); */
