/* TODO: Reduce specificity if we ever fix the style build order */
/* Everything close button */
.react-datepicker__close-icon.react-datepicker__close-icon {
  padding: 0.25rem;
  height: min-content;
  margin-top: 0.75rem;
  margin-right: 0.5rem;
  border-radius: var(--border-radius-circle, 50%);
  opacity: 0;
  transition: all var(--transition-fast-in-out);
}
.react-datepicker__close-icon.react-datepicker__close-icon:hover {
  background-color: rgba(92, 94, 110, 0.12);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  opacity: 1;
}
/* TODO: Replace with ButtonIcon */
.react-datepicker__close-icon.react-datepicker__close-icon::after {
  content: '';
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  background-image: url('data:image/svg+xml, %3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22%23212328%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20id%3D%22close%22%3E%0A%3Cpath%20id%3D%22Vector%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2.62204%202.62225C2.78476%202.45953%203.04858%202.45953%203.21129%202.62225L10%209.41096L16.7887%202.6223C16.9514%202.45958%2017.2152%202.45958%2017.3779%202.6223C17.5407%202.78502%2017.5407%203.04884%2017.3779%203.21156L10.5893%2010.0002L17.3779%2016.7889C17.5407%2016.9516%2017.5407%2017.2154%2017.3779%2017.3781C17.2152%2017.5408%2016.9514%2017.5408%2016.7887%2017.3781L10%2010.5895L3.21129%2017.3782C3.04858%2017.5409%202.78476%2017.5409%202.62204%2017.3782C2.45932%2017.2155%202.45932%2016.9516%202.62204%2016.7889L9.41076%2010.0002L2.62204%203.21151C2.45932%203.04879%202.45932%202.78497%202.62204%202.62225Z%22%20fill%3D%22%23212328%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
  padding: 0;
  background-size: contain;
}

.DateAndTimeInputField-inputContainer.DateAndTimeInputField-inputContainer:hover
  .react-datepicker__close-icon.react-datepicker__close-icon,
.DateAndTimeInputField-inputContainer.DateAndTimeInputField-inputContainer:focus-within
  .react-datepicker__close-icon.react-datepicker__close-icon {
  opacity: 1;
}
.react-datepicker__close-icon.react-datepicker__close-icon[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
  outline-offset: 0.125rem;
  opacity: 1;
}
