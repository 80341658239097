.AccessRequestHistoryBtn {
  display: block;
  position: relative;
  width: fit-content;
  color: var(--color-foreground-primary-medium, #3751ff);
}

.AccessRequestHistoryBtn:hover {
  text-decoration: underline;
}

.AccessRequestHistoryModal {
  max-width: 56rem;
}

.AccessRequestHistoryModal .ModalIsland-section {
  width: 100%;
}

.AccessRequestHistoryModal .ModalIsland-header {
  height: auto;
}

.AccessRequestHistoryModal .ModalIsland-titleAndHeaderIcon {
  padding: 0.75rem 0 0.75rem 1.5rem;
}

.AccessRequestHistoryModalSubTitle {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.AccessRequestHistoryModalSubTitle-text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.AccessRequestHistoryModalSubTitle-textBoxTerminate:not(:last-child)::after {
  content: '\00B7';
  text-align: center;
  display: inline-block;
  padding-inline: 0.25rem;
  color: var(--color-foreground-neutral-bold, #212328);
}

.AccessRequestHistoryModalSubTitle-textBoxKey,
.AccessRequestHistoryModalSubTitle-textBoxValue {
  font: var(--typography-body-2);
}

.AccessRequestHistoryModalSubTitle-textBoxKey {
  white-space: nowrap;
  color: var(--color-foreground-neutral-medium, #4a4c59);
}

.AccessRequestHistoryModalSubTitle-textBoxValue {
  /* background: aqua; */
  white-space: break-spaces;
  color: var(--color-foreground-neutral-bold, #212328);
}

/* important flag as in design system id is assigned which has higher priority */
.AccessRequestHistoryModal .ModalIsland-body {
  height: calc(100vh - 12rem) !important;
  max-height: 32rem !important;
  padding: 0 !important;
}

.AccessRequestHistoryModal .ModalIsland-body::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.AccessRequestHistoryBody-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 3rem;
  gap: 0.75rem;
  height: 100%;
}

.AccessRequestHistoryBody-loader {
  width: 100%;
  margin: auto;
}

.AccessRequestHistoryBody-NoDataAvailable {
  text-align: center;
  width: 100%;
  margin: auto;
}

.AccessRequestHistoryBody-accordianBox {
  width: 100%;
  border-radius: var(--border-radius-sm, 0.25rem);
  background: var(--color-neutral-000, #fff);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
}

.AccessRequestHistoryBody-accordianBox--subbox {
  width: 100%;
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
}

.AccessRequestHistoryBody-accordianBoxTitle {
  padding: 0.5rem;
  color: var(--color-foreground-secondary-bold, #00245b);
}

.AccessRequestHistoryBody-accordianBoxTitle--subbody {
  color: var(--color-neutral-1000, #212328);
}

.AccessRequestHistoryBody-accordianBoxBody {
  width: 100%;
  padding: 0 1rem 1.5rem 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 0.75rem;
}

.AccessRequestHistoryBody-accordianBoxSubBody {
  width: 100%;
  padding: 0 1rem 1.5rem 1rem;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.75rem;
}

.AccessRequestHistoryBody-accordianBoxSubBody--subbody {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  /* gap: 0.75rem; */
}

.AccessRequestHistoryBody-accordianBoxSubBody--subbodyList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
}

.AccessRequestHistoryBody-accordianBoxSubBody--subbodyList.shifted {
  padding-left: 1rem;
}

.AccessRequestHistoryBody-accordianBoxSubBody--subbodyList.lastItem {
  margin-bottom: 0;
}

.AccessRequestHistoryBody-accordianBoxBodyTile {
  width: 100%;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}

.AccessRequestHistoryBody-accordianBoxBodyHighlighted {
  display: inline-flex;
  flex-wrap: wrap;
  /* margin-top: 0.25rem; */
  color: var(--color-foreground-neutral-bold, #212328);
}

.AccessRequestHistoryBody-accordianBoxBodyHighlighted--withToolTip {
  display: inline-flex;
  flex-wrap: wrap;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
  cursor: pointer;
}

.AccessRequestHistoryBody-accordianBoxBody--thirdTile {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1.4rem 1.5rem 2rem;
  gap: 1rem;
}

.AccessRequestHistoryBody-accordianBoxBodyWrapper--thirdTile {
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.75rem;
}

.AccessRequestHistoryBody-accordianBoxBodyTile--thirdTile {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.redtext {
  color: var(--Light-Critical-500, #cc1c10);
}

.ARSAccordian-subbody {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
