/* Basic Animation */
.CertsControlsGroup {
  grid-row: 2;
  grid-column: 1 / -1;
  width: 100%;
  height: min-content;
  background: var(--page-bg, #f7f8fc);
}
.CertificationDetails--isSidePanelVisible .CertsControlsGroup {
  grid-column: 1 / 9;
}

.CertsControlsGroup .AlertBanner-container {
  position: fixed;
  z-index: var(--z-index-certs-landingpage-dropdown);
  top: 6rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 6rem);
}

.CertsControlsGroup-controls {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.CertsControlsGroup-expandedObserverTarget {
  position: absolute;
  top: 0;
  right: 19.625rem;
  height: 2.5rem;
  content: '';
  pointer-events: none;
}
.CertsControlsGroup-noSearchObserverTarget {
  position: absolute;
  top: 0;
  right: 3rem;
  height: 2.5rem;
  content: '';
  pointer-events: none;
}
.CertsControlsGroup-iconSizedButtonsObserverTarget {
  position: absolute;
  top: 0;
  left: -3.625rem;
  height: 2.5rem;
  width: 6rem;
  content: '';
  pointer-events: none;
}

.CertsControlsGroup-conditionalButtonsArray {
  position: relative;
  display: flex;
  width: min-content;
}

/* Controls Side */
/* Remove All ButtonSelect icons for responsivness */
.CertsControlsGroup-controls .ButtonSelect--rectangle-chevron {
  display: none;
}

.CertsControlsGroup-tabsAndControls {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 3rem 0 3rem;
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}
.CertsControlsGroup-tabsAndControls .TabGroup-fullWidthBorder {
  border-bottom: unset;
}

.CertsControlsGroup-tabsAndControls .TabItem:not(.TabItem--isActive) {
  border: unset;
}

/* Select All */
.CertsControlsGroup-selectAllArea {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 1.5rem;
  padding-inline: 3rem;
}
.CertificationDetails--isSidePanelVisible .CertsControlsGroup-selectAllArea {
  padding-right: 1.25rem;
}

.CertsControlsGroup-selectAll {
  display: none;
  gap: 0.75rem;
  height: 2.75rem;
  padding: 0.75rem 0;
  background: var(--page-bg, #f7f8fc);
}
.CertsControlsGroup-selectAll--isSelectAllVisible {
  display: flex;
}

.CertsControlsGroup-totalResults {
  padding: 0.75rem 0;
}

.CertificationDetails--isSidePanelVisible .CertsControlsGroup-tabsAndControls,
.CertificationDetails--isSidePanelVisible .CertsControlsGroup-selectAll,
.CertificationDetails--isSidePanelVisible .AdvancedFilters-filterBar--isActive {
  padding-right: 1.25rem;
}

.Savi-Companion-Button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  border: 1px solid var(--color-gradient-Savi-Companion, #8000ac);
  background: var(--color-background-savi-subtlest, #f9f5fa);
  cursor: pointer;
}

.Savi-Companion-Button.Savi-Companion-button--isDisabled {
  pointer-events: none;
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  cursor: not-allowed;
}

/* Placholder */
.CertsGroupByButton {
  margin-right: 0.5rem;
}

.CertsControlsGroup-controlButtons {
  display: flex;
  justify-content: flex-end;
  height: 3rem;
  padding-bottom: 0.5rem;
}
.CertsControlsGroup--isSearchBarExpanded .CertsControlsGroup-controlButtons {
  width: -webkit-fill-available;
}

.CertsControlsGroup--isSaviInMore .Savi-Companion-Button,
.CertsControlsGroup--isFilterInMore .CertsAdvancedFilterButton,
.CertsControlsGroup--isGroupByInMore .CertsGroupByButton,
.CertsControlsGroup--isRowsInMore .RowFilterButton-trigger,
.CertsControlsGroup--isColumnsInMore .ColumnFilterButton-trigger,
.CertsControlsGroup--isSortInMore .DynamicSort-trigger {
  display: none;
  margin-right: 0;
}
.CertsControlsGroup--isMoreVisible .CertsControlsMore {
  display: block;
}

/* Responsive */
@media (max-width: 1279px) {
  .CertsControlsGroup-tabsAndControls.CertsControlsGroup-tabsAndControls {
    padding: 0.5rem 1.5rem 0 1.5rem;
  }
  .CertsControlsGroup-selectAllArea {
    padding-inline: 1.5rem;
  }
}

@media (max-width: 1151px) {
  .CertificationDetails--isSidePanelVisible {
    .CertsControlsGroup-tabLength--4 {
      .CertsControlsGroup-tabsAndControls {
        flex-direction: column-reverse;
        justify-content: flex-start;
      }

      .CertsControlsGroup-controls,
      .CertsControlsGroup-controlButtons {
        justify-content: flex-start;
      }

      .CertsControlsGroup-noSearchObserverTarget {
        right: 1.5rem;
      }
      .CertsControlsGroup-expandedObserverTarget {
        right: 16.625rem;
      }
      .CertsControlsGroup-iconSizedButtonsObserverTarget {
        left: unset;
        right: -5.5rem;
      }
    }
    .CertsControlsGroup--isSearchBarExpanded.CertsControlsGroup-tabLength--4
      .CertsControlsGroup-iconSizedButtonsObserverTarget {
      left: unset;
      right: -14rem;
    }
  }
}

@media (max-width: 1023px) {
  .CertificationDetails--isSidePanelVisible {
    .CertsControlsGroup {
      grid-column: 1 / 8;
    }
  }
}

@media (max-width: 1000px) {
  .CertificationDetails--isSidePanelVisible {
    .CertsControlsGroup-tabLength--3 {
      .CertsControlsGroup-tabsAndControls {
        flex-direction: column-reverse;
        justify-content: flex-start;
      }

      .CertsControlsGroup-controls,
      .CertsControlsGroup-controlButtons {
        justify-content: flex-start;
      }

      .CertsControlsGroup-noSearchObserverTarget {
        right: 1.5rem;
      }
      .CertsControlsGroup-expandedObserverTarget {
        right: 16.625rem;
      }
      .CertsControlsGroup-iconSizedButtonsObserverTarget {
        left: unset;
        right: -5.5rem;
      }
    }
    .CertsControlsGroup--isSearchBarExpanded.CertsControlsGroup-tabLength--3
      .CertsControlsGroup-iconSizedButtonsObserverTarget {
      left: unset;
      right: -14rem;
    }
  }
}

@media (max-width: 768px) {
  .CertsControlsGroup-tabLength--4 {
    .CertsControlsGroup-tabsAndControls {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }

    .CertsControlsGroup-controls,
    .CertsControlsGroup-controlButtons {
      justify-content: flex-start;
    }

    .CertsControlsGroup-noSearchObserverTarget {
      right: 1.5rem;
    }
    .CertsControlsGroup-expandedObserverTarget {
      right: 16.625rem;
    }
    .CertsControlsGroup-iconSizedButtonsObserverTarget {
      left: unset;
      right: -5.5rem;
    }
  }
  .CertsControlsGroup--isSearchBarExpanded.CertsControlsGroup-tabLength--4
    .CertsControlsGroup-iconSizedButtonsObserverTarget {
    left: unset;
    right: -14rem;
  }
}

@media (max-width: 617px) {
  .CertsControlsGroup-tabLength--3 {
    .CertsControlsGroup-tabsAndControls {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }

    .CertsControlsGroup-controls,
    .CertsControlsGroup-controlButtons {
      justify-content: flex-start;
    }

    .CertsControlsGroup-noSearchObserverTarget {
      right: 1.5rem;
    }
    .CertsControlsGroup-expandedObserverTarget {
      right: 16.625rem;
    }
    .CertsControlsGroup-iconSizedButtonsObserverTarget {
      left: unset;
      right: -5.5rem;
    }
  }
  .CertsControlsGroup--isSearchBarExpanded.CertsControlsGroup-tabLength--3
    .CertsControlsGroup-iconSizedButtonsObserverTarget {
    left: unset;
    right: -14rem;
  }
}
