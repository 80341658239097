
.roleReq-header{
  display:flex;
  align-items: baseline;
  padding-left: 10px;
}

.roleReq-title {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 6px;
}

.roleReq-subtitle {
  font-size: 12px;
  color: var(--grey-font);
  padding-bottom: 29px;
  display: block;
  font-weight: 500;
  margin-left:20px;
}

.roledetails-content {
  button:focus{
    outline: none;
  }
 
}


