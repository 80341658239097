.alert-banner-section {
  padding: 23px 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .alert-message {
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  .alert-close {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 8px;
  }
}
.alert-critical {
  background: #cc1c10;
}
.alert-information {
  background: #573bdc;
}
