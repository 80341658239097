.get-help-section {
  margin: 32px 33px 30px 48px;
  .get-help-fetch-error {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid rgba(204, 28, 16, 1);
    border-radius: 24px;
    padding: 10px;
    background: rgba(253, 245, 244, 1);
    .link-button {
      color: var(--color-indigo-700, #3751ff);
      font-family: Rubik;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      padding-left: 0.5rem;
    }
    .get-help-alert-icon {
      display: flex;
    }
    .get-help-error-text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
    .get-help-error-description {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
    span.ModalIsland-alertContent-icon {
      min-height: 40px;
      min-width: 40px;
    }
  }
  .get-help-title {
    color: #000;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .help-section {
    display: flex;
    border-radius: 24px;
    background: #fff;
    padding: 38px 34px;
    &:hover {
      cursor: pointer;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    }
  }
  .help-content {
    margin-left: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content-title {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 7px;
    }
    .content-description {
      color: #474747;
      font-size: 15px;
      font-weight: 400;
      line-height: normal;
      line-height: normal;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .get-help-section {
    .help-section {
      flex-direction: column;
      padding: 21px;
    }
    .help-icon {
      text-align: center;
      display: flex;
      justify-content: center;
      margin-bottom: 17px;
    }
    .help-content {
      margin-left: 0px;
      .content-title {
        text-align: center;
        font-size: 16px;
      }
      .content-description {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .get-help-section {
    .help-section {
      flex-direction: row;
      padding: 32px 24px;
    }
    .help-icon {
      margin-right: 8px;
      margin-bottom: 0;
    }
    .help-content {
      margin: auto 0;
      .content-title {
        text-align: left;
        font-size: 20px;
        line-height: 20px;
      }
      .content-description {
        text-align: left;
        margin: 0;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .help-cards-section .help-card {
    margin-bottom: 21px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

@media screen and (max-width: 480px) {
  .get-help-section {
    margin-top: 33px;
    .help-section {
      flex-direction: column;
      padding: 24px;
    }
    .get-help-title {
      color: #000;
      font-size: 19px;
      font-weight: 500;
      text-align: center;
    }
    .help-content {
      margin-top: 8px;
      .content-title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        text-align: center;
      }
      .content-description {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
