.slot-selection {
  background-color: #fafafa;
  border-radius: 3px;
  height: 184px;
  margin-top: 18px;
  margin-bottom: 15px;
  padding: 11px 20px 16px 20px;

  .future-parent {
    padding-bottom: 12px;
    display: flex;
    align-items: center;

    .ant-calendar-picker-input.ant-input {
      width: 100px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 11px;
      color: var(--grey-font);
      font-weight: normal;
    }

    .Dropdown-arrow {
      right: 5px;
      top: 7px;
    }

    .Dropdown-menu {
      font-size: 12px;
    }

    .Dropdown-control {
      border: none;
      border-bottom: solid 1px #e1e1e1;
      background-color: #fafafa;
      padding: 4px 22px 4px 4px;
      font-size: 11px;
      margin-left: 2px;
    }
  }

  .see-future {
    font-size: 11px;
    color: var(--grey-font);
  }

  .slot-date-picker {
    margin: auto;
    margin-right: 20px;
  }

  .slot-picker {
    height: 113px;
    margin-bottom: 10px;
    overflow-x: auto;
  }

  .slot-picker::-webkit-scrollbar {
    height: 6px;
    background-color: #ffffff;
    border-radius: 9px;
  }

  .slot-picker::-webkit-scrollbar-thumb {
    background-color: #9f9d9d;
    border-radius: 9px;
  }

  .slot-time-label {
    padding-top: 3px;
    font-size: 9px;
  }

  .slot-selectddate {
    font-size: 11px;
    color: #000000;
  }

  .slot-parent {
    margin-right: 4px;

    .slot {
      width: 20px;
      height: 59px;
      border-radius: 3px;
      border: solid 1px var(--grey-border);
      background-color: #ffffff;
      outline: none;
    }

    .slot:hover {
      background-color: var(--primary-main);
    }

    .slot-range-select {
      outline: none;
      width: 20px;
      height: 59px;
      border-radius: 3px;
      border: solid 1px var(--grey-border);
      background-color: #d1d7ff;
    }

    .slot-selected {
      outline: none;
      width: 20px;
      height: 59px;
      border-radius: 3px;
      border: solid 1px var(--grey-border);
      background-color: var(--primary-main);
    }

    .final-slot {
      outline: none;
      width: 20px;
      height: 59px;
      border-radius: 3px;
      border: solid 1px var(--grey-border);
      background-color: var(--primary-main);
    }

    .slot-notavailable {
      outline: none;
      width: 20px;
      height: 59px;
      border-radius: 3px;
      border: dashed 1px var(--grey-border);
      background-color: #a9a9a9;
    }
  }
}

.top-navigation {
  .go-back {
    &:hover {
      cursor: pointer;
    }
    span {
      font-size: 13px;
    }
  }
}
.slot-header {
  span {
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }
  .priv-role-access-subtitle {
    font-size: 12px;
  }
}
.timeframe {
  background-color:#f4edf9;
  padding: 14px 0 14px 20px;
  border-radius: 3px;
  .id-assignment-title {
    font-size: 12px;
    color: #686a87;
  }
  .id-assignment-value {
    font-size: 14px;
    font-weight: 500;
    color: #686a87;
    margin-top: 8px;
  }
}

  // .modal-dialog {
  //   margin: auto;
  //   height: 650px;
  //   min-width: 620px;
  // }
  // .modal-content {
  //   height: 98%;
  //   border-radius: 0px;
  //   background-color: #ffffff;
  // }
  // .modal-subheader {
  //   display: flex;
  //   flex-direction: column;
  //   padding: 10px;
  // }
  .ant-calendar-picker-input.ant-input {
    padding: 5px;
    border: none;
  }
  .modal-header {
    padding: 10px 26px 5px 26px;
    border-bottom: 1px solid #dee2e6;
    .close {
      padding: 0;
      padding-top: 5px;
      margin: 0;
    }
  }
  // .modal-title {
  //   font-size: 18px;
  //   line-height: normal;
  // }
  .sap-slot-title {
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    padding-bottom: 4px;
  }
  .sap-slot-subtitle {
    font-size: 11px;
  }

  .slot-selection {
    background-color: #fafafa;
    border-radius: 3px;
    height: 184px;
    margin-top: 18px;
    margin-bottom: 15px;
    padding: 11px 20px 16px 20px;
    .future-parent {
      padding-bottom: 12px;
      display: flex;
      align-items: center;
      .ant-calendar-picker-input.ant-input {
        width: 100px;
        border-bottom: 1px solid #e6e6e6;
        font-size: 11px;
        color: var(--grey-font);
        font-weight: normal;
      }
      .Dropdown-arrow {
        right: 5px;
        top: 7px;
      }
      .Dropdown-menu {
        font-size: 12px;
      }
      .Dropdown-control {
        border: none;
        border-bottom: solid 1px #e1e1e1;
        background-color: #fafafa;
        padding: 4px 22px 4px 4px;
        font-size: 11px;
        margin-left: 2px;
      }
    }
    .see-future {
      font-size: 11px;
      color: var(--grey-font);
    }
    .slot-date-picker {
      margin: auto;
      margin-right: 20px;
    }
    .slot-picker {
      height: 113px;
      margin-bottom: 10px;
      overflow-x: auto;
    }
    .slot-picker::-webkit-scrollbar {
      height: 6px;
      background-color: #ffffff;
      border-radius: 9px;
    }
    .slot-picker::-webkit-scrollbar-thumb {
      background-color: #9f9d9d;
      border-radius: 9px;
    }
    .slot-time-label {
      padding-top: 3px;
      font-size: 9px;
    }
    .slot-selectddate {
      font-size: 11px;
      color: #000000;
    }
    .slot-parent {
      margin-right: 4px;
      .slot {
        width: 20px;
        height: 59px;
        border-radius: 3px;
        border: solid 1px var(--grey-border);
        background-color: #ffffff;
        outline: none;
      }
      .slot:hover {
        background-color: var(--primary-main);
      }
      .slot-range-select {
        outline: none;
        width: 20px;
        height: 59px;
        border-radius: 3px;
        border: solid 1px var(--grey-border);
        background-color: #d1d7ff;
      }
      .slot-selected {
        outline: none;
        width: 20px;
        height: 59px;
        border-radius: 3px;
        border: solid 1px var(--grey-border);
        background-color: var(--primary-main);
      }
      .final-slot {
        outline: none;
        width: 20px;
        height: 59px;
        border-radius: 3px;
        border: solid 1px var(--grey-border);
        background-color: var(--primary-main);
      }
      .slot-notavailable {
        outline: none;
        width: 20px;
        height: 59px;
        border-radius: 3px;
        border: dashed 1px var(--grey-border);
        background-color: #a9a9a9;
      }
    }
  }
  .sap-slot-busjusti {
    font-size: 14px;
    font-weight: 500;
    color: #1e1e1e;
    padding-bottom: 5px;
  }

  .sap-slot-textarea {
    min-height: 80px;
    margin-top: 14px;
  }
  .slot-footer {
    display: flex;
    /* justify-content: right; */
    flex-direction: row-reverse;
    padding: 0 26px 10px 0;
    .btn-not-selected {
      opacity: 0.5;
      background-color: #ffffff;
      color: #000000;
    }
    .btn-ingrid {
      font-size: 12px;
      font-weight: 500;
      border-radius: 3px;
      border: solid 1px #b4b4b4;
      min-height: 32px;
    }
    .cancel-margin {
      margin-right: 9px;
    }
    .btn-ingrid:hover {
      // color: #000000;
    }
  }
