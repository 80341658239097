.ReviewAndSubmitModal-bodyStep1Wrapper {
  width: 100%;
  height: 100%;
  /* height: 32rem; */
  background: var(--color-page-bold, #fff);
  padding: 1rem 3rem;
  overflow: auto;
}

.ReviewAndSubmitModal-bodyStep1Wrapper::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.ReviewAndSubmitModal-bodyStep1WrapperShorts {
  padding-block: 0.5rem;
  width: 100%;
}

.ReviewAndSubmitModal-bodyStep1WrapperStats {
  padding-block: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsTitle {
  color: var(--color-foreground-neutral-bold, #212328);
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsDescription {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
  position: relative;
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionIncomplete {
  display: flex;
  gap: 0.25rem;
  padding-block: 0.25rem;
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionIncompleteText {
  color: var(--color-foreground-secondary-bold, #00245b);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.ReviewAndSubmitModal-bodyStep1WrapperHLine {
  width: 100%;
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionList {
  padding: 0.25rem 0 0.25rem 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionListCategoryMessage {
  margin-top: 4rem;
  text-align: center;
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionDecisionList--visible,
.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionDecisionList--hidden,
.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionTypeList--visible,
.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionTypeList--hidden {
  min-width: 100%;
  transition: var(--transition-quick-in-out);
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionDecisionList--visible,
.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionDecisionList--hidden {
  padding-left: 1.5rem;
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionTypeList--visible,
.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionTypeList--hidden {
  padding-left: 0;
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionDecisionList--hidden {
  transform: translateX(-100%);
}

.ReviewAndSubmitModal-bodyStep1WrapperStatsDescriptionTypeList--visible {
  transform: translateX(-100%);
}
