.CertsChangeEndDateModal-wrapper .ModalIsland-section .ModalIsland-body {
  max-height: 32rem !important;
  overflow-y: auto !important;
}

.CertsChangeEndDateModal-wrapper
  .ModalIsland-section
  .ModalIsland-body::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.CertsChangeEndDateModal-TitleWrapper,
.CertsChangeEndDateModal-wrapper .CertsChangeEndDate-buttonWrapper {
  display: flex;
  align-items: center;
}

.CertsChangeEndDateModal-wrapper .DateAndTimeInputField-container {
  padding: 0.5rem 0px;
}

.CertsChangeEndDateModal-wrapper .CertsModalCommentBox-wrapper {
  margin-top: 1rem;
}

.CertsChangeEndDate-Title,
.CertsChangeEndDateModal-wrapper .DateAndTimeInputField-inputContainer,
.CertsChangeEndDateModal-wrapper .DateAndTimeInputField-labelContainer {
  margin-top: 0.3rem;
}

.CertsChangeEndDateModal-wrapper .CertsChangeEndDate-CommonMargin-Container {
  margin: 0.5rem 0rem;
}

.CertsChangeEndDateModal-wrapper .CertsChangeEndDate-saveButton {
  margin-left: 0.5rem;
}

.CertsChangeEndDateModal-wrapper
  .AlertBanner-container.AlertBanner-container--isVisible {
  max-width: 31rem;
}

.CertsChangeEndDateModal-wrapper .AlertBanner-title,
.CertsChangeEndDateModal-wrapper .AlertBanner-heading,
.CertsChangeEndDateModal-wrapper .AlertBanner-section {
  align-items: flex-start;
}

.CertsChangeEndDateModal-wrapper .CertsChangeEndDate-Title-empty {
  margin-left: 0.3rem;
}
