.Link {
  display: inline-block;
  font-weight: 500;
  font-style: normal;
  cursor: pointer;
}

.Link .Link-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  text-decoration: none;
  cursor: pointer;
}

.Link-textWrapper {
  white-space: nowrap;
}
.Link:not(.Link--isDisabled):hover .Link-textWrapper {
  text-decoration: underline;
}
.Link.Link--isDisabled {
  cursor: not-allowed;
}
.Link.Link--isDisabled .Link-wrapper {
  cursor: not-allowed;
  text-decoration: none;
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
  pointer-events: none;
}

/* set svg width/heights */
.Link.Link--large .Link-icon,
.Link.Link--large .Link-icon svg,
.Link.Link--medium .Link-icon,
.Link.Link--medium .Link-icon svg,
.ButtonLink--large .Link-icon,
.ButtonLink--large .Link-icon svg,
.ButtonLink--medium .Link-icon,
.ButtonLink--medium .Link-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}
.Link.Link--small .Link-icon,
.Link.Link--small .Link-icon svg,
.ButtonLink--small .Link-icon,
.ButtonLink--small .Link-icon svg {
  width: 1rem;
  height: 1rem;
}

.Link:not(.Link--isDisabled)[tabindex]:focus,
.ButtonLink[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
}

.Link--brand > .Link-wrapper {
  color: var(--color-foreground-primary-medium, #3751ff);
}
.Link--neutral > .Link-wrapper {
  color: var(--color-foreground-neutral-bold, #212328);
}

/* color variations */
.Link--brand {
  color: var(--color-foreground-primary-medium, #3751ff);
}
.Link--brand .Link-icon svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}

.Link--neutral {
  color: var(--color-foreground-neutral-bold, #212328);
}
.Link--neutral .Link-icon svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
}

.Link--neutralInverse .Link-textWrapper {
  color: var(--color-foreground-neutral-inverse, #ffffff);
}
.Link--neutralInverse .Link-icon svg path {
  fill: var(--color-foreground-neutral-inverse, #ffffff);
}

/* Size variations */
.Link.Link--large {
  height: 1.5rem;
  font: var(--typography-body-bold-1);
}
.Link.Link--medium {
  height: 1.25rem;
  font: var(--typography-body-bold-2);
}
.Link.Link--small {
  height: 1rem;
  font: var(--typography-body-bold-3);
}

.ButtonLink.ButtonLink--large {
  height: 1.5rem;
  font: var(--typography-body-1);
}
.ButtonLink.ButtonLink--medium {
  height: 1.25rem;
  font: var(--typography-body-2);
}
.ButtonLink.ButtonLink--small {
  height: 1rem;
  font: var(--typography-body-3);
}

/* Button - Link styled */
.ButtonLink {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  background: none;
  color: var(--color-foreground-primary-medium, #3751ff);
  border: none;
  padding: 0;
  cursor: pointer;
}
.ButtonLink--large {
  height: 1.5rem;
}
.ButtonLink--medium {
  height: 1.25rem;
}
.ButtonLink--small {
  height: 1rem;
}
.ButtonLink,
.ButtonLink .Link-textWrapper,
.ButtonLink .Link-icon svg path {
  transition: all var(--transition-fast-in-out);
}

.ButtonLink .Link-icon svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}
/* Alt color */
.ButtonLink--neutralInverse .Link-textWrapper {
  color: var(--color-foreground-neutral-inverse, #ffffff);
}
.ButtonLink--neutralInverse .Link-icon svg path {
  fill: var(--color-foreground-neutral-inverse, #ffffff);
}

/* Hover */
.ButtonLink:not(.ButtonLink--isDisabled):hover {
  color: var(--color-foreground-primary-bold, #032185);
  text-decoration: none;
}
.ButtonLink:hover {
  color: var(--color-foreground-primary-bold, #032185);
}
.ButtonLink:hover .Link-icon svg path,
.ButtonLink:hover .Link-icon svg {
  fill: var(--color-foreground-primary-bold, #032185);
}

/* Disabled */
.ButtonLink--isDisabled,
.ButtonLink--isDisabled:hover,
.ButtonLink--isDisabled .Link-icon,
.ButtonLink--isDisabled .Link-icon svg path,
.ButtonLink--isDisabled:hover .Link-icon svg path {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
  cursor: not-allowed;
}
.Link--isDisabled .Link-icon svg path {
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
}
