.CertsTerminateModal-alertIcon {
  min-width: 2rem;
  min-height: 2rem;
}

.CertsTerminateModal-withComments header {
  height: 100%;
}

.CertsTerminateModal-withComments .ModalIsland-titleAndHeaderIcon {
  padding: 0.75rem 0;
}

.CertsTerminateModal-withComments .InputField-Container {
  padding: 0.5rem 1.375rem;
}

.CertsTerminateModal-wrapper.ModalIsland--isOpen {
  max-width: 35rem;
}

.CertsTerminateModal-wrapper .CertsTerminateModal-isCrossIconDisabled {
  pointer-events: none;
}

.CertsTerminateModal-wrapper .AlertBanner-container {
  margin-bottom: 1.2rem;
}

.CertsTerminateModal-wrapper .CertsTerminateModal-NoteWrapper {
  margin-top: 0.5rem;
}

.CertsTerminateModal-wrapper .CertsTerminateModal-NoteText {
  color: var(--color-critical-700, #cc1c10);
}

.CertsTerminateModal-wrapper
  .AlertBanner-container.AlertBanner-container--isVisible {
  max-width: 30rem;
  margin-left: 1rem;
}

.CertsTerminateModal-wrapper .FooterActionBar {
  justify-content: flex-end;
}
