.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.5rem;
}

.lastRow {
    margin-bottom: 2rem;
}

.noResults {
    margin: 1rem;
}
