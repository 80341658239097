.PaginationCondensed {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  min-width: min-content;
  white-space: nowrap;
}
.PaginationCondensed-body {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-medium, #4a4c59);
  margin: 0;
}

/* Left */
.PaginationCondensed-rangePicker {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.PaginationCondensed-rangePicker .Button {
  padding: 0.125rem;
  padding-right: 0.25rem;
}
.PaginationCondensed-rangePicker .Button .Icon svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
}
.PaginationCondensed-selector {
  display: flex;
  align-items: center;
  gap: 0.125rem;
}

/* Center */
.PaginationCondensed-divider {
  content: '';
  height: 1.5rem;
  width: 0.0625rem;
  background: var(--color-border-neutral-subtlest, #cecfd9);
}

/* Right */
.PaginationCondensed-rangeOutline {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
