.welcome-section {
  container: welcome-section / inline-size;
}

.welcome-container {
  border-radius: 0px 0px 24px 24px;
  background: #f7f9fc;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  padding: 33px 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.welcome-text {
  color: #212328;
  font-size: 40px;
  font-weight: 500;
  .TooltipComponent-container {
    width: 100%;
    .welcome-message-tooltip-section .TooltipComponent-arrow--top {
      .Icon {
        position: absolute;
      }
    }
  }
}
.welcome-message {
  --marginLeft: 13px;
  margin-left: var(--marginLeft);
  width: calc(40% - var(--marginLeft));
  flex-shrink: 0;
}
.welcome-message-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.welcome-message-tooltip-section {
  word-break: break-all;
  max-width: 100%;
  top: 0.2px;
}

.welcome-shortcuts {
  width: 60%;

  --is-desktop: true;
  --is-tablet: false;
  --is-mobile: false;
}

@container welcome-section (width <= 768px) {
  .welcome-message {
    width: 100%;
    margin-left: 0;
  }
  .welcome-text {
    margin-bottom: 24px;
    text-align: left;
  }
  .welcome-shortcuts {
    width: 100%;
    --is-desktop: false;
    --is-tablet: true;
  }
}

@media screen and (max-width: 480px) {
  .welcome-container {
    padding: 30px;
    position: relative;
    border-radius: 0;
  }
  .welcome-message {
    padding-left: 30px;
  }

  .welcome-text {
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }

  .welcome-shortcuts {
    width: 100%;
    --is-mobile: true;
    --is-tablet: false;
  }
}
