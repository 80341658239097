.toolTipMenuItem{
  font-size: 16px;
  line-height: 24px;


  .menuItemText{
    font-size: 12px;

  }
}


.toolTipTitle{
  color: var(--grey-font-input);
  padding-bottom: 8px;
  padding-top: 5px;
  margin-bottom: 10px;
  font-size: 11px;
  font-weight: normal;
  border-bottom: solid 1px #DFE0EB;

}

.moreAssignee{
  color: blueviolet;
  &:hover{
    cursor: pointer;
  }
}

.scrollInside{
  max-height: 150px!important;
    overflow-y: auto;
    line-height: 24px;
    font-size: 12px;
}
.scrollInside::-webkit-scrollbar-thumb{
  width: 5px !important;
  border-radius: 10px;
}
