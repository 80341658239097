.modal-data span.ant-calendar-range-picker-separator:after {
  content: '';
  font-weight: bold;
  visibility: visible;
  display: block;
  position: absolute;
}
.multiSelect {
  display: flex;
}
.modal-data .ant-calendar-range-picker-separator {
  margin-left: 10px;
  color: rgba(0, 0, 0, 1);
}
.modal-data .ant-calendar-picker {
  padding: 5px;
  width: 20%;
}
.modal-data .ant-calendar-picker .ant-input {
  border-bottom: none !important;
  font-size: 12px;
  height: 18px;
}
.modal-data .modal-slimselect {
  width: 12%;
  padding-top: 10px;
  margin-left: 10px;
}

.modal-data .modal-separator {
  padding-top: 7px;
}
.selectdatemodal-label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 11px;
  font-weight: 500;
}
.modal-data .ant-calendar-picker .ant-input:focus {
  outline: none;
  box-shadow: none;
}
.modal-data .ant-calendar-picker-icon {
  display: inline-block !important;
  left: 0;
  color: rgba(0, 0, 0, 1);
}
.modal-data .ant-calendar-range-picker-input {
  text-align: left;
  padding-left: 20px;
  font-weight: bold;
  color: #1e1e1e;
  width: 45%;
}
.modal-data .ant-calendar-range-picker-input::placeholder {
  font-weight: normal;
  color: #1e1e1e;
}
div.react-autosuggest__suggestions-container {
  position: absolute;
  top: 22px;
  border-radius: 3px;
  box-shadow: 3px 3px 8px 0 #cdced9, -3px -3px 8px 0 #cdced9;
}
.modal-data input.react-autosuggest__input {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  padding: 0;
  height: 16px;
  font-size: 12px;
}
.modal-data input.react-autosuggest__input:focus {
  border-bottom: 1px solid var(--primary-main);
}
li.react-autosuggest__suggestion,
ul.react-autosuggest__suggestions-list li.react-autosuggest__suggestion--highlighted {
  padding: 15px;
  font-size: 10px;
  color: #000000;
  border-bottom: 1px solid #cccccc;
}
li.react-autosuggest__suggestion:last-child {
  border: none;
}
ul.react-autosuggest__suggestions-list {
  border: none;
}
.modal-data .input_selected input.react-autosuggest__input {
  background-color: var(--primary-bg);
  border-bottom: 1px solid var(--primary-main);
}
.react-autosuggest__input::placeholder {
  color: #c8c8c8;
  font-size: 12px;
}
.redcolor-font {
  color: #ff0000;
  font-size: 10px;
}
.quickRequest .modal-dialog .mandate-field.timeframe {
  color: #ff0000;
  vertical-align: bottom;
  font-size: 11px;
  padding-top: 34px;
}

.quickRequest .modal-dialog .inputArea .mandate-field {
  color: #ff0000;
  vertical-align: bottom;
  font-size: 11px;
  padding-top: 110px;
}

.model-header-instance {
  padding: 0 25px;
  font-size: 13px;
  font-weight: 500;
}

.text-muted {
  font-size: 11px;
}

.modal-body .modal-data-list {
  margin-top: 29px;
}

.businessJustification {
  margin-bottom: 40px !important;
}

.rangePicker {
  width: 388px;
}

.dialog-style {
  max-width: 504px;
  margin-left: 33%;
}
.dialog-content {
  padding: 0;
  margin: 0;
}

.dialog-content:first-child {
  padding-top: 0;
}
.btn-done {
  padding: 6px 18px;
  font-size: 12px;
  border: solid 1px #3751ff;
  background-color: var(--white);
  color: #3751ff;
  font-weight: 500;
  border-radius: 3px;
}
.btn-done:hover {
  border: solid 1px #3751ff;
  background-color: #3751ff;
  color: var(--white);
  cursor: pointer;
  border-radius: 3px;
}
