.list-dropdown-sortby {
    margin-left: 8px;
    width: 176px !important;
    border-radius: 3px;
    .Dropdown-control {
      border: none;

      .Dropdown-placeholder.is-selected {
        font-size: 12px;
        color: var(--grey-font);
        font-weight: 500;
      }
    }
    .Dropdown-option.is-selected {
      background-color: white
    }
    .Dropdown-menu {
      border: none;
      font-size: 12px;
      color: var(--grey-font);
      font-weight: 500;
    }
    .search_categories {
      font-size: 13px;
      padding: 5px;
      background: #fff;
      border-radius: 3px;
      height: 30px;
      overflow: hidden;
      position: relative;
      .select  {
        background-position: 80% center;
        .sort_by_label {
          width: 50%;
          color: #9b9b9b;
        }
        select {
          background: transparent;
          line-height: 1;
          border: 0;
          padding: 0;
          border-radius: 0;
          width: 120%;
          position: relative;
          z-index: 10;
          // font-size: 1em;
          font-size: 12px;
          color: var(--grey-font);
          font-weight: 500;
        }
      }
    }
  }