.MpaTcodeSelection {
  width: 32.4375rem;
}

.MpaTcodeSelection-dropdown {
  width: 32.4375rem;
}

.MpaTcodeSelection-Chips {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 32.4375rem;
  gap: 1rem;
}

.MpaTcodeSelection-maxSelectionCriticalMessage {
  color: var(--color-foreground-critical-medium, #cc1c10);
}
