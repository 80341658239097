.image-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  .upload-title {
    color: #212328;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 14px;
  }
  .file-input {
    display: none;
  }
  .upload-error {
    display: flex;
    p {
      margin-left: 4px;
      margin-bottom: 14px;
      color: #cc1c10;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .img-preview,
  .no-img-preview {
    width: 212px;
    height: 214px;
    border-radius: 100%;
    margin-bottom: 36px;
    margin-left: auto;
    margin-right: auto;
  }
  .no-img-preview {
    color: #fff;
    font-size: 80px;
    font-weight: 500;
    line-height: 40px;
    background-color: #9574f1;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-preview img {
    border-radius: 100%;
  }
  .upload-button {
    margin-bottom: 12px;
    z-index: 0;
  }
  .image-button-actions {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
      .upload-button {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        padding: 12px 15px;
        border-radius: 4px;
      }
    }
    .delete-button {
      color: #3751ff;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      background-color: #fff;
      padding: 11px 20px;
      border-radius: 4px;
    }
  }
}

.image-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
  // background: rgba(0, 0, 0, 0.8);
  .cropper-modal {
    background-color: transparent;
  }
  .cropper-view-box {
    outline: none;
  }
  .cropper-dashed.dashed-h,
  .cropper-dashed.dashed-v {
    border: none;
  }
  .cropper-line {
    background-color: transparent;
  }
  .cropper-point {
    height: 50px;
    width: 50px;
    border: 6px solid white;
    background: transparent;
    opacity: 1;
  }
  .cropper-move {
    cursor: grab;
  }
  .cropper-point.point-n,
  .cropper-point.point-e,
  .cropper-point.point-w,
  .cropper-point.point-s {
    display: none;
  }
  .cropper-point.point-se {
    border-top: none;
    border-left: none;
    border-bottom-right-radius: 3px;
  }
  .cropper-point.point-ne {
    border-bottom: none;
    border-left: none;
    border-top-right-radius: 3px;
  }
  .cropper-point.point-nw {
    border-bottom: none;
    border-right: none;
    border-top-left-radius: 3px;
  }
  .cropper-point.point-sw {
    border-top: none;
    border-right: none;
    border-bottom-left-radius: 3px;
  }
}

.preview-action-buttons {
  margin: 0;
  > button:nth-child(2) {
    margin-top: 0;
    margin-left: 8px;
  }
}
