.CertsActionButton {
  all: unset;
  position: relative;
  box-sizing: border-box;
  display: flex;
  border-radius: var(--border-radius-circle, 50%);
  height: 3rem;
  width: 3rem;
  caret-color: transparent;
  cursor: pointer;
  opacity: 1;
  transition:
    width var(--transition-deliberate-ease),
    opacity var(--transition-deliberate-ease) var(--transition-quick);
}
.CertsActionButton.CertsActionButton--isSelected {
  background: var(--color-background-neutral-subtlest, #ffffff);
}
.CertsActionButton-inner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  /* Centering with margin */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid var(--color-border-primary-subtle, #6e7fff);
  background: var(--color-background-neutral-subtlest, #ffffff);
  border-radius: var(--border-radius-circle, 50%);
  isolation: isolate;
  transition:
    background var(--transition-fast-in-out),
    border var(--transition-fast-in-out),
    color var(--transition-fast-in-out);
}
.CertsActionButton--isSelected .CertsActionButton-inner {
  height: 2.75rem;
  width: 2.75rem;
  background: var(--color-background-secondary-bold, #254b86);
  border: 1px solid transparent;
}

/* Tab outline */
.CertsActionButton[tabindex]:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  outline-offset: 0.125rem;
}

/* Icon */
.CertsActionButton span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CertsActionButton .Icon,
.CertsActionButton span svg,
.CertsActionButton span svg path {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--color-foreground-primary-medium, #3751ff);
  transition: fill var(--transition-fast-in-out);
}
.CertsActionButton--isSelected span svg path {
  fill: var(--color-foreground-neutral-inverse, #ffffff);
}

/* Overlay Base */
.CertsActionButton-overlay {
  position: absolute;
  z-index: var(--z-index-container-overlay);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-circle, 50%);
  background: transparent;
  opacity: 0;
  transition: all var(--transition-fast-in-out);
}
.CertsActionButton--isSelected .CertsActionButton-overlay {
  top: -0.0625rem;
  left: -0.0625rem;
  width: 2.75rem;
  height: 2.75rem;
  background: transparent;
  opacity: 0;
}

.CertsActionButton-borderIcon.Icon {
  position: absolute;
  top: -0.125rem;
  left: -0.125rem;
}
.CertsActionButton-borderIcon.Icon,
.CertsActionButton .CertsActionButton-borderIcon svg {
  fill: none;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: var(--border-radius-circle, 50%);
}

/* isLoading Lottie */
.CertsActionButton-borderLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3.375rem;
  width: 3.375rem;
}
.CertsActionButton--isLoading {
  pointer-events: none;
}
.CertsActionButton--isLoading {
  border: 1px solid transparent;
}
.CertsActionButton--isLoading .CertsActionButton-inner {
  height: 2.75rem;
  width: 2.75rem;
  background: var(--color-background-secondary-bold, #254b86);
  border: 1px solid transparent;
}
.CertsActionButton--isLoading .Icon svg path {
  fill: #fff;
}
.CertsActionButton--isLoading .CertsActionButton-overlay {
  opacity: 1;
  left: -0.0625rem;
  top: -0.0625rem;
  width: 2.75rem;
  height: 2.75rem;
  /* //TODO: this overlay color var is not working */
  background: rgba(255, 255, 255, 0.24);
}

/* Hover */
.CertsActionButton-inner:hover {
  border: 1px solid var(--color-border-primary-medium, #3751ff);
}

.CertsActionButton--isSelected .CertsActionButton-inner:hover {
  border: 1px solid transparent;
}
.CertsActionButton--isSelected span svg path {
  fill: var(--color-foreground-neutral-inverse, #ffffff);
}

.CertsActionButton-overlay:hover,
.CertsActionButton:hover .CertsActionButton-overlay,
.Icon:hover + .CertsActionButton-overlay {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(55, 81, 255, 0.08);
}

/* Pressed */
.CertsActionButton-overlay:active,
.CertsActionButton.CertsActionButton:active .CertsActionButton-overlay,
.Icon:active + .CertsActionButton-overlay {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(55, 81, 255, 0.12);
}

/* Selected */
.CertsActionButton--isSelected {
  border: 1px solid transparent;
  background: var(--color-background-secondary-bold, #254b86);
}
.CertsActionButton--isSelected svg path {
  fill: #fff;
}

.CertsActionButton--isSelected .CertsActionButton-overlay:hover,
.CertsActionButton.CertsActionButton--isSelected:hover
  .CertsActionButton-overlay,
.Icon:hover + .CertsActionButton--isSelected .CertsActionButton-overlay {
  opacity: 1;
  left: -0.0625rem;
  top: -0.0625rem;
  width: 2.75rem;
  height: 2.75rem;
  /* //TODO: this overlay color var is not working */
  background: rgba(255, 255, 255, 0.24);
}

.CertsActionButton--isSelected .CertsActionButton-overlay:active,
.CertsActionButton.CertsActionButton--isSelected:active
  .CertsActionButton-overlay,
.Icon:active + .CertsActionButton-overlay {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(33, 35, 40, 0.32);
}

/* Disabled */
.CertsActionButton--isDisabled .CertsActionButton-inner {
  background: var(--color-background-neutral-disabled-medium, #cecfd9);
  border: transparent;
  cursor: not-allowed;
}
.CertsActionButton--isDisabled .CertsActionButton-inner svg path {
  fill: var(--color-foreground-neutral-subtle, #717385);
}
.CertsActionButton.CertsActionButton--isSelected.CertsActionButton--isDisabled {
  cursor: not-allowed;
}

/* Disabled overlay */
.CertsActionButton.CertsActionButton--isDisabled .CertsActionButton-overlay,
.Icon:hover
  + .CertsActionButton.CertsActionButton--isDisabled.CertsActionButton--isSelected
  .CertsActionButton-overlay,
  /* Active */
  .CertsActionButton.CertsActionButton--isDisabled.CertsActionButton--isSelected
  .CertsActionButton-overlay:active,
  .CertsActionButton.CertsActionButton:active .CertsActionButton-overlay,
.Icon:active
  + .CertsActionButton.CertsActionButton--isDisabled.CertsActionButton--isSelected
  .CertsActionButton-overlay {
  opacity: 0;
}

/* Override disabled for these cases: worksForMe, approve, reassignUser, reassignItem */
.CertsActionButton--isDisabledException.CertsActionButton--isDisabled.CertsActionButton--isSelected
  .CertsActionButton-inner,
.CertsActionButton.CertsActionButton--isSelected.CertsActionButton--isDisabled
  .CertsActionButton-inner {
  border: 1px solid transparent;
  background: var(--color-background-secondary-bold, #254b86);
}
.CertsActionButton--isDisabledException.CertsActionButton--isSelected.CertsActionButton--isDisabled {
  cursor: not-allowed;
}

.CertsActionButton--isDisabledException.CertsActionButton--isDisabled.CertsActionButton--isSelected
  .CertsActionButton-inner
  svg
  path,
.CertsActionButton--isDisabled.CertsActionButton--isSelected
  .CertsActionButton-inner
  svg
  path {
  fill: var(--color-foreground-neutral-inverse, #ffffff);
}

.CertsActionButton--isDisabledException.CertsActionButton--isDisabled.CertsActionButton--isSelected .CertsActionButton-overlay,
  /* Hover */
  .CertsActionButton--isDisabledException.CertsActionButton--isDisabled.CertsActionButton--isSelected
  .CertsActionButton-overlay:hover,
  .CertsActionButton--isDisabledException.CertsActionButton--isDisabled.CertsActionButton--isSelected:hover
  .CertsActionButton-overlay,
.Icon:hover
  + .CertsActionButton--isDisabledException.CertsActionButton--isDisabled.CertsActionButton--isSelected
  .CertsActionButton-overlay,
  /* Active */
  .CertsActionButton--isDisabledException.CertsActionButton--isDisabled.CertsActionButton--isSelected
  .CertsActionButton-overlay:active,
  .CertsActionButton--isDisabledException.CertsActionButton--isSelected.CertsActionButton:active .CertsActionButton-overlay,
.Icon:active
  + .CertsActionButton--isDisabledException.CertsActionButton--isDisabled.CertsActionButton--isSelected
  .CertsActionButton-overlay, .CertsActionButton.CertsActionButton--isSelected.CertsActionButton--isDisabled
  .CertsActionButton-overlay,
  /* Hover */
  .CertsActionButton.CertsActionButton--isDisabled.CertsActionButton--isSelected
    .CertsActionButton-overlay:hover,
  .CertsActionButton.CertsActionButton--isDisabled.CertsActionButton--isSelected:hover
    .CertsActionButton-overlay, .CertsActionButton.CertsActionButton--isSelected.CertsActionButton--isDisabled
    .CertsActionButton-overlay:hover {
  background: rgba(255, 255, 255, 0.32);
  opacity: 1;
}

/* Hidden */
.CertsActionButton--isHidden,
.CertsActionButton--isHidden > .TooltipComponent-container {
  opacity: 0;
  pointer-events: none;
  width: 1rem;
  transition:
    width var(--transition-fast-ease) var(--transition-quick),
    opacity var(--transition-quick-ease);
}

.CertsActionButton-tooltip--shouldNotRender {
  display: none;
}

.CertsActionButton-consultIndicator {
  position: absolute;
  z-index: 1;
  position: absolute;
  right: 0px;
  top: -2px;
}

.CertsActionButton-consultIndicator div {
  display: flex;
  height: 16px;
  width: 16px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 64px;
  background: var(--color-background-secondary-bold, #254b86);
}

.CertsActionButton-consultIndicator div p {
  color: var(--color-foreground-neutral-inverse, #fff);
  text-align: center;
}

.CertsActionButton-consultIndicator span svg path {
  fill: #254b86 !important;
}

.CertsActionButton.CertsActionButton--isSelected.Tile--DashedBorder {
  stroke-dasharray: 4 4;
  background: none;
}
