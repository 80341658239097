.grid-searchfull {
  width: 284px;
  height: 36px;
  border: 1px solid var(--grey-border-input);
  .searchBox {
    padding-top: 0 !important;
    input::placeholder {
      color: var(--grey-font);
      opacity: 1;
    }
  }
}
