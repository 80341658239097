.tooltip {
  .popup {
    visibility: hidden;
  }
  &:hover {
    .popup {
      visibility: visible;
    }
  }
}
.popupPositionbelow{
  border: solid 1px #dddddd;
  box-shadow: 1px 3px 8px 1px #dfe0eb;
  width: max-content;
  max-width: 400px; 
  &::after {
    // content: '';
    position: absolute;
    bottom: 100%;
    left: 25%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #d7d0c8 transparent;
  }
}
.popupPositionabove{
  border: solid 1px #dddddd;
  box-shadow: 1px 3px 8px 1px #dfe0eb;
  width: max-content;
  max-width: 400px; 
  &::after {
    // content: '';
    position: absolute;
    left: 25%;
    top: 100%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #d7d0c8 transparent;

  }
}
