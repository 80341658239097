@keyframes panel-appear {
  from {
    left: -25%;
  }
  to {
    left: 0%;
  }
}

.Savi-Companion-Modal.ModalIsland-default {
  background: #fff;
  height: 100%;
  width: 30rem;
  left: 0%;
  transform: unset;
  top: 4rem;
  justify-content: start;
  border-radius: 0px;
  animation-name: panel-appear;
  animation-duration: 0.8s;
}

.Savi-Companion-Modal .ModalIsland-titleAndHeaderIcon {
  gap: 0.25rem;
}

.SaviCompanion-Main-true .ModalIsland-header {
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.Savi-Companion-Modal .ModalIsland-background.ModalIsland-background--isOpen {
  top: 4rem;
}

.Savi-Companion-Modal .ModalIsland-section {
  width: 100%;
  height: 100%;
  background: var(--color-page-subtle, #f2f4f8);
}

.Savi-Companion-Modal .Icon--large,
.Savi-Companion-Modal .Icon--large svg {
  width: 3rem;
  height: 3rem;
}

.Savi-Companion-Modal .ModalIsland-header {
  align-items: center;
  height: auto;
  padding: 0.5rem 1rem 0.25rem;
  border-bottom: 0px;
}

.Savi-Companion-Modal .Savi-Landing-Body {
  background: var(--background-neutral-subtlest, #fff);
  padding: 1rem 1rem 0.8rem 4.25rem;
  max-height: none;
  font-size: 0.875rem;
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.Savi-Companion-Modal .Savi-Landing-Body .Text {
  color: #000;
}

.Savi-Companion-Modal.ModalIsland .ModalIsland-section .ModalIsland-body {
  max-height: none !important;
  padding: 0 !important;
  height: calc(100% - 12rem);
  overflow-y: scroll;
}

.Savi-Companion-Modal.ModalIsland
  .ModalIsland-section
  .ModalIsland-body::-webkit-scrollbar-thumb {
  background-color: #cecfd9;
  background-color: var(--color-background-neutral-medium, #cecfd9);
  border-radius: 50%;
  border-radius: var(--border-radius-circle, 50%);
  background-clip: content-box;
  border: 3px solid #0000;
}

.Savi-Companion-Modal .Landing-buttons-wrapper {
  display: flex;
  margin-top: 1.5rem;
  justify-content: flex-end;
}

.Savi-Companion-Modal .Landing-buttons {
  height: 2.5rem;
  margin: 0.3125rem;
}

.Savi-Companion-Modal .Landing-buttons .Text {
  color: var(--color-foreground-neutral-inverse, #fff);
}

.Savi-Companion-Modal .Savi-highlight {
  background: var(
    --color-savi-gradient,
    linear-gradient(45deg, #4c00ac 0%, #8000ac 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Savi-Companion-Modal .SaviCompanionBody-Wrapper {
  padding: 1rem;
}

.Savi-Companion-Modal .Question-Header-Wrapper {
  display: flex;
  padding: 0.75rem 1rem;
  background: var(--background-neutral-subtlest, #fff);
  justify-content: start;
  gap: 0.5rem;
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  cursor: pointer;
}

.Savi-Companion-Modal .Question-Header {
  display: flex;
  align-items: center;
}

.Savi-Companion-Modal .Question-Header-Reason {
  width: 90%;
}

.Savi-Companion-Modal .Options-Wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: white;
  padding: 1rem 1.5rem 1.5rem;
  cursor: pointer;
}

.Savi-Companion-Modal .SaviCompanion-smartReviewWrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  padding: 0.75rem 1rem;
}

.Savi-Companion-Modal .Question-Header-Wrapper-Minimised:hover,
.Savi-Companion-Modal .SmartReview-Wrapper:hover {
  box-shadow: 0 0 0.5rem -0.25rem #1e1e1e;
}

.Savi-Companion-Modal .SaviCompanion-smartDecision-Icon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.Savi-Companion-Modal .Savi-Companion-Divider {
  background: var(--color-border-neutral-decorative, #ebecf2);
  height: 1px;
  width: 100%;
}

.Savi-Companion-Modal .Question-Header-Wrapper .Minimised {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.Savi-Companion-Modal .SaviCompanion-Footer {
  position: fixed;
  bottom: 0px;
  width: 30rem;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  background: white;
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.Savi-Companion-Modal .SaviCompanion-contextWrapper .flexWrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
/* Needed to cover other popovers */
.Savi-Companion-Modal
  .SaviCompanion-contextWrapper
  .flexWrapper
  .Popover-wrapper--isOpen {
  z-index: var(--z-index-popover);
}

.Savi-Companion-Modal .SaviCompanion-contextWrapper .flexContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem 1.5rem;
  color: var(--color-foreground-neutral-bold, #212328);
}

.Savi-Companion-Modal .SaviCompanion-contextWrapper .flexContainer p {
  color: var(--color-foreground-neutral-bold, #212328);
}

.Savi-Companion-Modal .SaviCompanion-contextWrapper .Popover-wrapper--isOpen {
  z-index: var(--z-index-popover);
}

.Savi-Companion-Modal
  .SaviCompanion-contextWrapper
  .Popover-wrapper--isOpen
  .Popover {
  position: fixed !important;
  width: 23.125rem;
  z-index: var(--z-index-modal-island);
}

.Savi-Companion-Modal .SaviCompanion-smartReviewWrapper .DisplayFlex-Column {
  display: flex;
  flex-direction: column;
}

.Savi-Companion-Modal .Savi-Popover-background {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--light-brand-secondary-overlay, rgba(0, 8, 64, 0.5));
  overflow: hidden;
  transition: opacity var(--transition-quick-in-out);
  opacity: 0;
  pointer-events: none;
  z-index: var(--z-index-modal-island);
}

.Savi-Companion-Modal .Savi-Popover-background--isOpen {
  opacity: 0.2;
  pointer-events: all;
  transition: opacity var(--transition-quick-in-out);
}

.Savi-Companion-Modal .DisplayNone {
  display: none;
}

.Savi-Companion-Modal .Savi-Companion-Loader-Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25.625rem;
  margin: 0.75rem 1rem;
  flex-direction: column;
}

.Savi-Companion-Modal .Savi-Companion-Loader-Body {
  width: 21.875rem;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}

.Savi-Companion-Modal .Savi-Companion-Loader-Text {
  color: var(--color-foreground-neutral-bold, #212328);
  text-align: center;
}

.Savi-Companion-Modal .Savi-Companion-Loader-Setting {
  width: 100%;
}

.Savi-Companion-Modal .Applied-Savi {
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  gap: 0.125rem;
}

.Savi-Companion-Modal .SaviCompanion-cancelSmartApprovals {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.Savi-Companion-Modal .SaviCompanion-cancelSmartApprovals button {
  height: 2rem;
}

.Savi-Companion-Modal .SaviCompanion-cancelSmartApprovals button .Text {
  color: var(--color-foreground-primary-medium, #3751ff);
}

.Savi-Companion-Modal .Savi-Companion-Error-Text {
  color: var(--color-foreground-critical-medium, #cc1c10);
}

.Savi-Companion-Modal .Savi-Companion-Loader-Body button {
  justify-content: center;
}

.Savi-Companion-Modal .Savi-Companion-Loader-Body .AlertText {
  display: flex;
  gap: 0.25rem;
}

.Savi-Companion-Modal .SaviCompanion-Footer .ApplyActionButton {
  height: 2.5rem;
  padding: 1rem 0.75rem;
}

.Savi-Companion-Modal .SaviCompanion-Footer .ApplyActionButton .Text {
  color: var(--color-foreground-neutral-inverse, #fff);
}

.Savi-Companion-Modal .SaviCompanion-contextWrapper .flexWrapper .Overwrite {
  height: 2rem;
}

.Savi-Companion-Modal .SaviCompanion-contextWrapper .flexWrapper .Keep {
  height: 2rem;
}

.Savi-Companion-Modal
  .SaviCompanion-contextWrapper
  .flexWrapper
  .Overwrite
  .Text {
  color: var(--color-foreground-primary-medium, #3751ff);
}

.Savi-Companion-Modal .SaviCompanion-contextWrapper .flexWrapper .Keep .Text {
  color: var(--color-foreground-neutral-inverse, #fff);
}

.Savi-Companion-Modal
  .SaviCompanion-smartReviewWrapper
  .SaviCompanion-smartReview-cancelConfirmMessage {
  align-self: center;
  color: var(--color-foreground-info-medium, #573bdc);
}

.Savi-Companion-Modal
  .SaviCompanion-smartDecision-Icon
  .Checkbox--base.Checkbox--disabled {
  background-color: #eeeeee;
  border-radius: 4px;
}

@media (max-width: 960px) {
  #SaviCompanion-smartReviewWrapper-mobile {
    display: block;
  }

  .SaviCompanion-mobileWrapper {
    justify-content: space-between;
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
  }

  .SaviCompanion-mobileWrapper .Icon--large,
  .SaviCompanion-mobileWrapper .Icon--large svg {
    width: 4.5rem;
    height: 4.5rem;
  }

  .SaviCompanion-mobileWrapper .SaviCompanion-mobileWrapper-Header {
    margin: 0.25rem;
    margin-top: 0.7rem;
  }

  .Savi-Companion-Modal.ModalIsland-default,
  .Savi-Companion-Modal .Savi-Companion-Loader-Body {
    width: 100%;
  }

  .Savi-Companion-Modal .ModalIsland-headerWrapper {
    border-bottom: none;
  }

  .Savi-Companion-Modal .Savi-Landing-Body {
    padding-left: 1.8rem;
  }

  .Savi-Companion-Modal .Landing-buttons-wrapper {
    margin-top: 1rem;
  }

  .SaviCompanion-smartReviewWrapper .SmartReview-Wrapper-iconsWrapper {
    display: flex;
    align-items: center;
  }

  .Savi-Companion-Modal .SaviCompanion-Footer .ApplyActionButton,
  .SmartDecision-Applied-Icons-Order {
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  .SmartDecision-Applied-Icons-Order {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .Savi-Companion-Modal .SaviCompanion-Footer {
    position: static;
    width: 100%;
  }

  .SaviCompanion-mobileWrapper .SaviCompanion-smartDecision-Icon {
    flex-direction: column;
    margin-bottom: 0;
  }

  .SaviCompanion-mobileWrapper .Savi-Companion-Modal .Options-Wrapper {
    gap: 1rem;
  }

  .SaviCompanion-mobileWrapper .Chip-content-container--medium {
    height: 1rem;
    padding: 0.7rem 0.5rem;
  }

  .Savi-Companion-Modal .Applied-Savi {
    justify-content: flex-end;
  }

  .Savi-Companion-Modal .Question-Header-Wrapper {
    align-items: center;
  }
}
