.plainDropDown {
  position: relative;
  :global .dropdown-toggle {
    font-size: 11px;
    padding: 0 10px;
    color: #a5abb4;
    &:after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
  :global .dropdown-menu {
    position: absolute;
    display: block;
    min-width: auto;
    padding: 0;
    margin: 0;
    width: fit-content;
  }
  :global .dropdown-item {
    font-size: 11px;
    color: #a5abb4;
    padding: 5px 10px;
  }
  :global .dropdown-item:active {
    color: #fff;
    background-color: #0056b3;
  }
}
