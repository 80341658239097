.ListRowTarget-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.125rem;
  min-width: 0;
  width: 100%;
  padding: 1rem 0.75rem;
}

.ListRowTarget-data-title {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-heading-3);
}

.ListRowTarget-data-description {
  overflow: hidden;
  color: var(--color-foreground-neutral-subtle, #717385);
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--typography-body-2);
  max-width: 100%;
}
