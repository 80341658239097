.container {
    width: 450px;
    background: #fff;
    border-radius: 4px;
    text-align: center;
    padding: 20px;
}
.headerSection {
    display: flex;
    justify-content: space-between;
    h2 {
        font-size: 18px;
    }
    svg {
        cursor: pointer;
    }
    font-weight: 300;
    font-family: Rubik, sans-serif;
    color: rgba(116,116,116,1);
}

.content {
    margin-top: 20px;
    padding: 24px 12px;
    background-color: #f7f8fc;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    .lastLoginTimeTitle {
        font-size: 12px;
        padding-right: 10px;
    }
    .lastLoginTime {
        font-size: 12px;
    }
    .lastLoginIP {
        font-size: 12px;
    }
    font-weight: 300;
    font-family: Rubik, sans-serif;
    color: rgba(116,116,116,1);
}
