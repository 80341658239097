.link {
  display: flex;
  span {
    color: var(--grey-font);
    cursor: pointer;
  }
  .active {
    color: black;
    margin: 12px;
  }
}
.separator {
  display: none;
}
