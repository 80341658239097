.saviyntRangePicker {
  display: flex;
  padding: 2px 2px;
  height: 38px;
  // padding: 10px;
  :global .ant-calendar-ok-btn {
    cursor: default;
    pointer-events: none;
    background-color: grey;
    opacity: 0.6;
  }
  :global .ant-calendar-picker {
    width: 20%;
    border: none;
    div{
      &:hover{
        border-bottom: solid 2px blue;
      }
    }
    .ant-input {
      font-size: 12px;
      font-weight: 500;
      border: none;
      color: black;
      width: 95%;
      &:hover {
        cursor: pointer;

      }
    }
  }
  .separator {
    padding-top: 4px;
  }
  .calendarIcon {
    // width: 20px;
    // height: 20px;
    padding-top: 5px;
    margin-right: 2px;
    :global svg.calender-icon {
      width: 20px;
    }
  }
}

.saviyntRangepickerDateselected {
  :global a.ant-calendar-time-picker-btn {
    border: 1px solid var(--primary-main);
    border-radius: 3px;
    font-size: 13px;
    height: 24px;
    padding: 0 7px;
    line-height: 22px;
    color: var(--primary-main);
    cursor: pointer;
  }
  :global a.ant-calendar-time-picker-btn:hover {
    background-color: var(--primary-main);
    color: #ffffff;
  }
}
.saviyntRangepickerTimeselected {
  :global a.ant-calendar-ok-btn {
    cursor: default;
    pointer-events: all;
  }
}
