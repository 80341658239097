.iconHover {
  outline: none !important;
  &:hover {
    span {
      svg {
        fill: var(--primary-main);
      }
    }
  }
}
.dropDownText {
  margin-top: 15px;
  height: 22px;
   div{
     display: flex;
     justify-content: center;
     flex-direction: column;
   }
 }

