.CertsConsultPopover-contextWrapper {
  position: relative;
  display: flex;
  width: max-content;
}

/* Content */
.CertsConsultPopover {
  min-width: 22rem;
  width: min-content;
  max-width: 40rem;
  padding: 1.5rem 0;
}

.CertsConsultPopover-header {
  display: flex;
  justify-content: space-between;
}

.CertsConsultPopover-title {
  color: var(--color-foreground-neutral-bold, #212328);
}

.CertsConsultPopover-content {
  padding: 0 1.5rem;
}

.CertsConsultPopover-note {
  color: var(--color-foreground-neutral-bold, #212328);
  /* padding: 0.5rem  1.5rem 0 1.5rem; */
  padding-top: 0.5rem;
}

.CertsConsultPopover-checkbox {
  padding-top: 0.75rem;
  padding-bottom: 1rem;
}

/* Control Buttons */
.CertsConsultPopover-controls {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.5rem;
  gap: 0.5rem;
}
