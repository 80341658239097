.DecisionLabel-item {
  display: flex;
  padding: 0.5rem 0;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  border-radius: 0.25rem;
}

.DecisionLabel-itemCount {
  color: var(--color-foreground-secondary-bold, #00245b);
  text-align: right;
  width: auto;
}

.DecisionLabel-itemLabel {
  color: var(--color-foreground-secondary-bold, #00245b);
  padding: 0.125rem 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.DecisionLabel-itemIcon {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.DecisionLabel-item .DecisionLabel-notify {
  color: var(--color-foreground-neutral-subtle, #717385);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font: var(--typography-body-3);
}
