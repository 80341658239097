.Checkbox--core {
  display: inline-flex;
  position: relative;
}

.Checkbox--core .Checkbox-input {
  position: absolute;
  appearance: none;
  opacity: 0;
}

.Checkbox-input:focus-visible + .Checkbox-icon:before {
  content: '';
  position: absolute;
  top: -0.1875rem; /* 3px */
  right: -0.1875rem;
  bottom: -0.1875rem;
  left: -0.1875rem;
  border: 2px solid #6e7fff;
  border-radius: 6px;
}

.Checkbox--core .Checkbox-icon {
  cursor: pointer;
  transition: all var(--transition-quick-ease);
  /* override Icon css */
  min-width: auto;
  min-height: auto;
}

/* TODO: increase specificty to override Icon css so
various size props work in PAM until we have capacity for RCA.
*/
.Checkbox .Checkbox-icon--small,
.Checkbox .Checkbox-icon--small > svg {
  width: 1rem;
  height: 1rem;
}
.Checkbox .Checkbox-icon--medium,
.Checkbox .Checkbox-icon--medium > svg {
  width: 1.25rem;
  height: 1.25rem;
}
.Checkbox .Checkbox-icon--large,
.Checkbox .Checkbox-icon--large > svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* hide all SVGs by default */
/* TODO: This is increased to address the css build order issue.
Should be just .Checkbox-icon after we know our stylesheets are in the same order as our DS.  */
.Checkbox-icon.Checkbox-icon {
  display: none;
}
/* only show SVGs that match the current state */
.Checkbox-icon--checked.Checkbox-icon--checked,
.Checkbox-icon--disabled.Checkbox-icon--disabled,
.Checkbox-icon--indeterminate.Checkbox-icon--indeterminate,
.Checkbox-icon--default.Checkbox-icon--default {
  display: inline-block;
}

/* on hover, show the hover svg only */
.Checkbox--core:hover .Checkbox-icon--default,
.Checkbox--core:hover .Checkbox-icon--active {
  display: none;
}
.Checkbox--core:hover .Checkbox-icon--hover {
  display: inline-block;
}
/* when "pressed" show the active svg only */
.Checkbox--core:active .Checkbox-icon--default,
.Checkbox--core:hover:active .Checkbox-icon--hover, /* combine hover and active */
.Checkbox--core:active .Checkbox-icon--hover {
  display: none;
}
.Checkbox--core:active .Checkbox-icon--active {
  display: inline-block;
}
