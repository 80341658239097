.CertPersonalization--ViewOptionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  .ButtonSelect--rectangle {
    border-radius: 4px;
    border: 1px solid var(--color-border-secondary-subtle, #426498);
    background: var(--color-background-secondary-subtle, #001a40);
  }
}

.CertPersonalization--ViewOptions {
  display: flex;
  position: absolute;
  width: 256px;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  border-radius: 4px;
  margin-top: 48px;

  ul {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    cursor: pointer;

    li {
      display: flex;
      padding: 4px 0px 4px 12px;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      margin-bottom: 4px;
    }

    li:hover {
      background: rgba(55, 81, 255, 0.1);
    }
  }

  .selected {
    display: flex;
    padding: 4px 0px 4px 12px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: rgba(55, 81, 255, 0.1);
  }
}

.CertPersonalization--clearButton {
  color: var(--color-foreground-primary-medium, #b2bcff);
  font-feature-settings:
    'clig' off,
    'liga' off;

  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}
