.appsItemHover {
  &:hover {
    i {
      svg {
        path {
          fill: var(--primary-main);
        }
      }
    }
  }
}
