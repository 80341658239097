.ModalIsland.Extend {
  max-width: 37.5rem;
}

.Extend-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.75rem;
  align-self: stretch;
}

.Extend .ModalIsland-section {
  width: 100%;
}

#ModalIsland.ModalIsland.Extend .ModalIsland-section .ModalIsland-body {
  background: var(--color-page-subtle, #f2f4f8);
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  flex: 1 0 0;
  align-self: stretch;
}

.Extend-timePicker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
}

.Extend-startTime {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

.Extend-startTime-label {
  display: flex;
  align-items: flex-start;
  gap: 0.125rem;
  color: var(--color-foreground-neutral-bold, #212328);
}

.Extend-startTime-value {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-foreground-secondary-bold, #00245b);
  text-overflow: ellipsis;
}

.Extend-endDateContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Extend-endDate {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.Extend-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Extend-description {
  width: 100%;
  height: 100%;
}

.FooterActionBar.Extend-footer {
  display: flex;
  height: 4rem;
  padding: 0.75rem 1.5rem;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  background: var(--color-background-neutral-subtlest, #ffffff);
}

.Extend-footer-buttons {
  display: flex;
  gap: 0.5rem;
}

/* overrides for extend modal: PAM-10747 */
.ModalIsland-body .Extend-content .react-datepicker__input-container {
  border-right: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.ModalIsland-body
  .Extend-content
  .react-datepicker__input-container
  .DatePicker-input:hover {
  border-top-right-radius: calc(var(--border-radius-sm) / 2);
  border-bottom-right-radius: calc(var(--border-radius-sm) / 2);
}

.ModalIsland-body .Extend-content .DateAndTimeInputField-inputContainer button {
  background: var(--color-background-neutral-subtlest);
}
/* end overrides for extend modal */
