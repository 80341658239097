.container {
    width: 350px;
    height: 250px;
    background: #fff;
    border-radius: 4px;
    text-align: center;
    padding: 20px;
}

.closeIcon {
    justify-content: end;
    display: flex;
    svg {
        cursor: pointer;
    }
}

.logo {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.title {
    font-size: 18px;
    font-weight: 300;
    font-family: Rubik, sans-serif;
    color: rgba(116,116,116,1);
}

.version {
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: 300;
    font-family: Rubik, sans-serif;
    color: rgba(116,116,116,1);
}
