.RowFilterButton {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
}

.RowFilterButton-trigger {
  margin-right: 0.5rem;
}

.RowsFilter-dropDown--active,
.RowsFilter-dropDown--disabled {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  position: absolute;
  top: calc(100% + 0.375rem);
  right: 0.5rem;
  width: 13.25rem;
  padding-inline: 0.25rem;
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 0.25rem);
  transition: all var(--transition-quick-in-out);
}

.RowsFilter-dropDown--disabled {
  z-index: var(--z-index-certs-landingpage-disabled);
  opacity: 0;
  overflow: hidden;
  max-height: 0;
}

.RowsFilter-dropDown--active {
  padding-block: 0.5rem;
  z-index: var(--z-index-certs-landingpage-dropdown);
  opacity: 1;
  overflow: auto;
  max-height: 32rem;
}

.RowsFilter-filterList {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  padding-left: 1.125rem;
  gap: 0.25rem;
}

.RowsFilter-filterListItem {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
  position: relative;
  transform: translateX(-1rem);
  padding-block: 0.25rem;
  padding-right: 0.5rem;
  cursor: pointer;
  border-radius: var(--border-radius-sm, 0.25rem);
}

.RowsFilter-filterListItemWrapper {
  width: calc(100% + 0.875rem);
  position: absolute;
  left: 0;
  height: 100%;
  transition: var(--transition-quick-out);
  border-radius: var(--border-radius-sm, 0.25rem);
}

.RowsFilter-filterListItem:hover .RowsFilter-filterListItemWrapper {
  background-color: #3751ff14;
}

.RowsFilter-filterListItemConnectors--top {
  position: absolute;
  bottom: 48%;
  left: -1px;
  width: 0.625rem;
  border-bottom: 1.5px solid #cfd9e9;
  border-left: 1.5px solid #cfd9e9;
  border-bottom-left-radius: 0.375rem;
}

.RowsFilter-filterListItemCheckboxParent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.RowsFilter-filterListItemCheckboxChild {
  display: contents;
  background-color: #ffffff;
}

.RowsFilter-filterListItemTextBox {
  align-self: stretch;
  width: 100%;
  margin: auto;
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-bold, #212328);
}
