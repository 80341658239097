.pageNotFound {
  display: flex;
  justify-content: space-around;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1021;
  height: 100%;
  position: fixed;
  background-color: var(--page-bg);

  > div {
    margin-top: 10%;
  }
  .logo {
    color: var(--primary-main);
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    padding-left: 25px;
    padding-top: 20px;
    margin-top: 0%;
  }

  .text-header {
    font-weight: 500;
    font-size: 124px;
    color: #2f3865;
    text-align: center;
  }

  .error-body {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #2f3865;
    text-align: center;
  }

  .error-details {
    width: 380px;
    font-size: 18px;
    text-align: center;
    color: #00020b;
    margin-bottom: 63px;
  }

  .btn-hback {
    border-radius: 3px;
    border: solid 1px #1f41ed;
    background-color: #1f41ed;
    font-family: Rubik;
    font-size: 24px;
    font-weight: 500;
    color: white;
  }

  .error-illustration {
    // position: absolute;
    right: 113px;
    // top: calc(50% - 150px);
  }
}
