.CertsControlsGroup-tabsAndControls .TabItem-label {
  white-space: nowrap;
}

.CertsControlTabs-tabTiem--isHighlighted .Badge.Badge--number {
  animation-duration: 1.5s;
  animation-name: tabFlashOnAction;
  animation-fill-mode: forwards;
}
.CertsControlTabs-tabTiem--isHighlighted .Badge.Badge--number .Badge-number {
  animation-duration: 1.5s;
  animation-name: tabFlashOnActionCount;
  animation-fill-mode: forwards;
}

@keyframes tabFlashOnAction {
  0% {
    outline: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
    background: var(--color-background-neutral-subtlest, #fff);
  }
  50% {
    outline: 1px solid var(--color-background-primary-bold, #3751ff);
    background: var(--color-background-primary-bold, #3751ff);
  }
  100% {
    outline: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
    background: var(--color-background-neutral-subtlest, #fff);
  }
}
@keyframes tabFlashOnActionCount {
  0% {
    color: var(--color-foreground-secondary-medium, #254b86);
  }
  30% {
    color: var(--color-foreground-neutral-inverse, #fff);
  }
  50% {
    color: var(--color-foreground-neutral-inverse, #fff);
  }
  70% {
    color: var(--color-foreground-neutral-inverse, #fff);
  }
  100% {
    color: var(--color-foreground-secondary-medium, #254b86);
  }
}
