.navColorsContainer {
    max-width: 800px;
}
.navLogoContainer {
    max-width: 680px;
}
.navColorsContaner {
    max-width: 800px;
}
.navColorsContaner {
    max-width: 800px;
}
.bodyText {
    color: var(--color-neutral-1000, #212328);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    ul {
        padding-left: 14px;
        li {
            list-style-type: disc;
        }
    }
}
.highlightText {
    color: var(--color-info-700, #573BDC);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    align-items: baseline;
    :global(.Icon) {
        position: relative;
        top: 5px;
        margin-right: 2px;
    }
    a {
        color: var(--color-info-700, #573BDC);
        text-decoration: underline;
        &:hover {
            text-decoration: underline;
        }
    }
}
.highlightTextTitle {
    font-weight: 500;
}