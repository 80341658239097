.IDAssignment {
  padding: 0;
  > div {
    border: none;
  }
  .MuiOutlinedInput-multiline {
    padding: 5px;
  }
  .MuiTextField-root {
    margin-top: 20px;
  }
}

.businessJustificationMandatory {
  label {
    &:after {
      content: '*';
      color: red;
    }
  }
}

.bj-ticket-section-id {
  position: relative;

  .ticket-area {
    margin-top: 20px;
  }
}
