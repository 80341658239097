.CertsModalCommentBox-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.CertsModalCommentBox-comments {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  padding-block: 0.5rem;
}
.CertsModalCommentBox-inputBox {
  width: 100%;
}
.CertsModalCommentBox-inputBox .InputField .InputField-labelContainer {
  position: absolute;
  top: 0;
}
.CertsModalCommentBox-inputBox.noCommentsPresent
  .InputField
  .InputField-inputContainer {
  margin-top: 2rem;
}
.CertsModalCommentBox-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.CertsModalCommentBox-comments {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  padding-block: 0.5rem;
}

.CertsModalCommentBox-inputBox {
  width: 100%;
}

.CertsModalCommentBox-inputBox .InputField .InputField-labelContainer {
  position: absolute;
  top: 0;
}

.CertsModalCommentBox-inputBox.noCommentsPresent
  .InputField
  .InputField-inputContainer {
  margin-top: 2rem;
}
