.MpaJustificationDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.MpaJustificationDetails-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 50%;
  padding-right: 1.5rem;
}

.MpaJustificationDetails-durationSelection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.MpaJustificationDetails-durationSelectionButtons {
  display: flex;
  gap: 0.5rem;
}

.MpaJustificationDetails-durationSelectionButtons .Button {
  width: max-content;
}

.MpaJustificationDetails-attachments {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
}
