@import url(../hideHostElements.css);

.ModalPage-root {
  position: fixed;
  left: 0;
  right: 0;
  min-height: 100vh;
  height: 100%;
  background: var(--color-page-subtle, #f2f4f8);
  overflow: hidden;
}

/* Header content */
.ModalPage-header {
  position: fixed;
  top: 0;
  width: 100%;
}

.ModalPage-subHeadingContent {
  display: flex;
  gap: 0.25rem;
}
.ModalPageHeader-subHeadingContent .ModalPage-subHeadingContentBody {
  margin: 0;
}

.ModalPage-alertBanner {
  position: fixed;
  top: 5.125rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--z-index-floating-banner);
  width: calc(100vw - 6rem);
}

.ModalPage-root .ModalPage-footer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0.75rem 3rem;
}

.ModalPage {
  position: absolute;
  top: 5rem;
  padding: 1.5rem 6rem;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 9rem);
  overflow-y: auto;
}

.ModalPage-scrollingArea {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
