.container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.divider {
    margin: 1rem;
}

.button {
    margin-left: 1.5rem;
}
