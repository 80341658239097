.ViewByType {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.ViewByType-loader {
  justify-content: center;
  display: flex;
  margin-top: 7rem;
}

.ViewByType-progressFailure {
  justify-content: center;
  margin-top: 4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.ViewByType-errorMessage {
  margin-right: 0.25rem;
  text-align: center;
}
