.CertsActionPopover-contextWrapper {
  position: relative;
  display: flex;
  width: max-content;
}

.CertsActionPopover-Title {
  margin-left: -0.8rem;
  padding-bottom: 1rem;
  width: 100%;
}

.CertsActionPopover-header-wrap {
  display: flex;
  align-items: flex-start;
  width: 105%;
}

/* Content */
.CertsActionPopover {
  min-width: 27rem;
  width: min-content;
  max-width: 60rem;
  padding: 1.5rem 2rem 1rem 2rem;
}

/* Control Buttons */
.CertsActionPopover-controls {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 0.5rem 0;
  gap: 0.5rem;
}
