.endSessiondialog{
background-color: var(--page-bg);
max-width: 537px;
.endTerminalDialog{
    padding: 25px;
    overflow: hidden;
    border-top: solid 0.5px #dcdcdc;
}
.overflowSession{
    overflow-y: auto;
    height: 34vh;
}
.sessionText{
    font-size: 12px;
    color: #617083;
    margin-bottom: 18px;
}
.endSessionList{
    border-radius: 3px;
    box-shadow: 1px 1px 4px 0 rgba(200, 200, 200, 0.5);
    border: solid 1px var(--grey-border);
    background-color: var(--white);
    padding: 9px 9px 9px 12px;
    margin-bottom: 8px;
}

.terminateSessionButton{
    border: solid 1px var(--primary-main);
}
.cancelSessionButton{
    border: solid 1px var(--primary-main);
    span{
        color: var(--primary-main);
    }
    &:hover{
        span{
            color: var(--primary-main);
        }
    }
}

.requestText{
    font-size: 9px;
    color: #a5abb4;
}

.validDate{
    border-right: solid 1px var(--primary-bg);


}

.dateText{
    font-size: 12px;
    color: #1e1e1e;

}

.hoursLeft{
    font-size: 8px;
    color: #a5abb4;
}

.requestSubText{
    font-size: 14px;
    color: #000000;
    font-weight: 500;
}

}

.endsessionFooter{
    padding-right: 25px;
    padding-bottom: 25px;
}
