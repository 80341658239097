.SpvEntitlements {
  width: 100%;
  height: 100%;
  max-height: 90%;
}

.SpvEntitlements-section {
  border-top: 1px solid var(--color-border-neutral-decorative, #cecfd9);
}

.SpvEntitlements-searchContainer {
  display: flex;
  padding: 1rem 0 1rem 1rem;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
}

.SpvEntitlements-search::placeholder {
  font: var(--typography-body-3);
}

.SpvEntitlements-showMoreEntitlements {
  font: var(--typography-body-3);
}

.SpvEntitlements-entitlementsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.SpvEntitlements-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
