.SpfEndpoint {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.SpfEndpoint-twoButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  align-self: stretch;
}

.SpfEndpoint-twoButtons--buttonStyle {
  white-space: nowrap;
}

.SpfEndpoint-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
