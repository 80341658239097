.MobileDrawer--isOpen--bodyScrollLock {
  overflow: hidden;
}

.MobileDrawer-background {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--light-brand-secondary-overlay, rgba(0, 8, 64, 0.5));
  overflow: hidden;
  transition: opacity var(--transition-quick-in-out);
  opacity: 0;
  pointer-events: none;
  z-index: var(--z-index-modal-island);
}
.MobileDrawer-background--isOpen {
  pointer-events: all;
}

.MobileDrawer {
  position: fixed;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-height: calc(100vh - 1.5rem);
  overflow: hidden;
  background: var(--color-background-neutral-subtlest, #fff);
  transition: height var(--transition-quick-in-out);
  z-index: var(--z-index-modal-island);
}

.MobileDrawer--isOpen {
  pointer-events: all;
}

.MobileDrawer-headerAndCloseOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3.5rem;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.MobileDrawer-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 1rem;
  padding-bottom: 1rem;
}

.MobileDrawer-content--withCloseButton {
  padding-top: 0.5rem;
}

.MobileDrawer-swipeHandlerWrap {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0.75rem 0;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.MobileDrawer-swipeHandleHandler {
  position: absolute;
  top: 0;
  left: -1rem;
  right: -1rem;
  height: 2.75rem;
  content: '';
}

.MobileDrawer-swipeHandle {
  width: 7.75rem;
  height: 0.25rem;
  border-radius: 0.25rem;
  background: var(--color-border-neutral-subtlest, #cecfd9);
}
