.BulkActionReassignModal .ModalIsland-header {
  height: auto;
}

.BulkActionReassignModal .ModalIsland-titleAndHeaderIcon {
  padding: 0.75rem 2rem;
  overflow: unset;
}

.BulkActionReassignModal .ModalIsland-titleContainer {
  gap: 0.5rem;
  overflow: unset;
}

.BulkActionReassignHeader {
  display: flex;
  align-items: center;
  position: relative;
}

.BulkActionReassignHeader-icon {
  display: flex;
  position: absolute;
  top: -1px;
  left: -2.5rem;
}

.BulkActionReassignHeader-title {
  color: var(--color-foreground-neutral-bold, #212328);
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.BulkActionReassignModal .ModalIsland-body {
  padding-inline: 3rem !important;
  max-height: calc(100vh - 16rem) !important;
  overflow: auto !important;
}

.BulkActionReassignModal .ModalIsland-body::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.BulkActionReassignBody {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-height: 20rem;
  width: 100%;
  gap: 1.5rem;
}

.BulkActionReassignBody-loaderWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BulkActionReassignBody-loader {
  margin-top: 10rem;
}

.BulkActionReassignBody-stats {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0.5rem;
}

.BulkActionReassignBody-statsObjects {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.BulkActionReassignBody-statsHeading {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
  color: var(--color-foreground-neutral-bold, #212328);
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.BulkActionReassignBody-statsHeadingIcon {
  margin-top: 0.375rem;
}

.BulkActionReassignBody-statsContent {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-foreground-secondary-bold, #00245b);
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.BulkActionReassignBody-stats .Checkbox-container {
  margin-top: 0.5rem;
}

.BulkActionReassignBody-stats .Checkbox-label {
  justify-content: space-between;
}

.BulkActionReassignBody-assigneeBox {
  position: relative;
}

.BulkActionReassignBody-assigneeBoxIcon {
  position: absolute;
  left: -1rem;
}

/* As other classes are overwriting this padding */
.BulkActionReassignBody-assigneeBoxList {
  width: 100%;
  padding: 0.25rem !important;
}

.BulkActionReassignBody-assigneeBox--disableMenu
  .InputField-inputContainer
  .ButtonCore {
  background: var(--color-page-subtle, #f2f4f8);
  pointer-events: none;
}

.BulkActionReassignBody-assigneeBox .Checkbox-container {
  margin-top: 1.25rem;
}

.BulkActionReassignFooter-footerButtonWrap {
  display: flex;
  gap: 0.5rem;
}

.BulkActionReassignFooter-footerButtonWrap .AlertBanner-container {
  position: fixed;
  width: calc(100% - 8rem);
}
