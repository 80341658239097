/* Used to  Make tooltip visible in a hidden container that will also still follow its parent*/
.CertsActionButtonsGroup-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 3rem;
  width: 12.5rem;
  transform: scale(1);
  isolation: isolate;
  transition: width var(--transition-steadystep-in-out);
}

.Tile--0 .CertsActionButtonsGroup-wrapper {
  background-color: var(--color-background-neutral-subtlest, #fff);
}
.Tile--1 .CertsActionButtonsGroup-wrapper {
  background-color: var(--color-background-secondary-subtlest, #f7f9fc);
}
.Tile--2 .CertsActionButtonsGroup-wrapper {
  background-color: var(--color-background-secondary-subtle, #f2f4f8);
}

.CertsActionButtonsGroup {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.1875rem;
  height: 3rem;
  width: 12.5rem;
  padding-right: 0.5rem;
  transition: width var(--transition-steadystep-in-out);
}

/* Buttons */
.CertsActionButtonsGroup-actionsOnly {
  position: absolute;
  left: -0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  height: 3.75rem;
  margin: 0.125rem 0;
  padding: 0 0.3125rem;
  transition: width var(--transition-steadystep-in-out);
}

/* Lengths based on button count */
/* Count 5 */
.CertsActionButtonsGroup-wrapper--isExpanded .CertsActionButtonsGroup--length5 {
  width: 19.75rem;
}
/* Count 4 */
.CertsActionButtonsGroup-wrapper--isExpanded .CertsActionButtonsGroup--length4 {
  width: 16.125rem;
}
/* Not expandable */
.CertsActionButtonsGroup.CertsActionButtonsGroup--length3,
.CertsActionButtonsGroup.CertsActionButtonsGroup--length2 {
  padding-right: 0.375rem;
}
.CertsActionButtonsGroup.CertsActionButtonsGroup--length2
  .CertsActionButtonsGroup-actionsOnly,
.CertsActionButtonsGroup.CertsActionButtonsGroup--length1
  .CertsActionButtonsGroup-actionsOnly {
  left: unset;
  right: 2.125rem;
}
.CertsActionButtonsGroup--isAtLeastThreeActions.CertsActionButtonsGroup--length2
  .CertsActionButtonsGroup-actionsOnly,
.CertsActionButtonsGroup--isAtLeastThreeActions.CertsActionButtonsGroup--length1
  .CertsActionButtonsGroup-actionsOnly {
  left: 0;
  right: unset;
}
.CertsActionButtonsGroup--length3 .CertsActionButtonsGroup-actionsOnly {
  width: 10.625rem;
}
.CertsActionButtonsGroup--length2 .CertsActionButtonsGroup-actionsOnly {
  width: 7.125rem;
}

/* Overlay */
.CertsActionButtonsGroup-overlay {
  position: absolute;
  z-index: var(--z-index-container-overlay);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 0;
  transition:
    background var(--transition-fast-in-out),
    opacity var(--transition-fast-in-out);
}

.Tile-leftHoverSide:hover
  ~ .Tile-actionButtonGroup
  .CertsActionButtonsGroup-overlay,
.Tile-checkboxHoverDisplay:hover
  ~ .Tile-actionButtonGroup
  .CertsActionButtonsGroup-overlay {
  opacity: 1;
  /* TODO: Overlay token still needs update */
  background: rgba(37, 75, 134, 0.08);
}

@media (max-width: 833px) {
  .Tile--isTileSidepanelSelected
    .Tile-leftHoverSide:hover
    ~ .Tile-actionButtonGroup
    .CertsActionButtonsGroup-overlay {
    opacity: 0;
    transition: unset;
  }
}
