.Checkbox-container .Checkbox.Checkbox--simpleText {
  border: 0;
  border-radius: 0;
  background-color: transparent;

  .Checkbox-label {
    padding: 0;
    gap: 0.5rem;
  }

  .Checkbox-labelText {
    font: var(--typography-body-2);
    color: var(--color-foreground-neutral-medium, #4a4c59);
  }

  /* mainly overrides from CheckboxWithText */
  &:active {
    border: 0;
  }
  &:hover {
    border: 0;
    box-shadow: none;
  }

  &.Checkbox--checked {
    border: 0;
  }
  &.Checkbox--checked:hover {
    border: 0;
    color: initial;
  }
  /* end overrides */
}
