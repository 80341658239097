.toggle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
}

.toggle-placement-right {
  flex-direction: row;
}

.toggle-placement-left {
  flex-direction: row-reverse;
}

.toggle-switch {
  cursor: pointer;
  display: inline-block;
  background: #ebedff;
  border-radius: 16px;
  width: 40px;
  height: 24px;
  position: relative;
  vertical-align: middle;
  transition: background var(--transition-quickstep);
  margin: 0 8px;
}

.toggle-switch.toogle-unchecked {
  border: 1px solid #6888b5;
}

.toggle-switch.toogle-checked {
  border: 1px solid transparent;
}

.toggle-switch:before,
.toggle-switch:after {
  content: '';
}

.toggle-switch:before {
  display: block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left var(--transition-quickstep);
}

.toogle-checked:before {
  background: #ffffff;
}

.toogle-unchecked:before {
  background: var(--color-foreground-neutral-subtle, #717385);
}

.toggle-switch:focus,
.toggle-switch:active,
.toggle-switch:focus-within,
.toggle-switch:focus-visible {
  outline: 2px solid #6e7fff;
  outline-offset: 1px;
}

.toggle-checkbox:not(:checked) + .toggle-switch:hover:before {
  box-shadow: 0px 0px 0px 8px rgba(55, 81, 255, 0.08);
}

.toggle-checkbox:checked + .toggle-switch:hover:before {
  box-shadow: 0px 0px 0px 8px rgba(55, 81, 255, 0.08);
}

.toggle-checkbox:not(:checked) + .toggle-switch:active:before {
  box-shadow: 0px 0px 0px 8px rgba(55, 81, 255, 0.12);
}

.toggle-checkbox:checked + .toggle-switch:active:before {
  box-shadow: 0px 0px 0px 8px rgba(55, 81, 255, 0.12);
}

.toggle-checkbox:checked + .toggle-switch {
  background: #254b86;
}

.toggle-checkbox:checked + .toggle-switch:before {
  left: 18px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-checkbox:disabled + .toggle-switch {
  border: none;
  cursor: not-allowed;
}

.toggle-checkbox:not(:checked):disabled + .toggle-switch {
  background: var(--color-background-neutral-disabled-medium, #cecfd9);
}

.toggle-checkbox:checked:disabled + .toggle-switch {
  background: var(--color-background-neutral-disabled-medium, #cecfd9);
}

.toggle-checkbox:not(:checked):disabled + .toggle-switch:before {
  top: 3px;
  background: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.toggle-checkbox:checked:disabled + .toggle-switch:before {
  top: 3px;
  left: 20px;
  background: #ffffff;
}

.toggle-checkbox:not(:checked):disabled + .toggle-switch:hover:before,
.toggle-checkbox:checked:disabled + .toggle-switch:hover:before {
  box-shadow: none;
}
