.popupContent {
  padding: 9px 19px 45px 19px;
  width: 392px;
  .popupHeaders {
    color: var(--black);
    font-size: 14px;
    font-weight: normal;
  }  
}
.subSection {
  color: var(--black);
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative;
  .subSectionTitle {
    font-size: 12px; 
    font-weight: 500;
  }
}
.timeFrameButtons {
  float: right;
  // margin-top: 17px;
}

.bjticketsection {
  position: relative;
}
.errorMsg{
  color: var(--failure-red);
  font-size: 12px;
  position: absolute;
  top: -12px;
}
.mandateField{
  color: var(--failure-red);
}
.btnCancel{
  font-size: 12px;
  font-weight: 500;
  margin-right: 18px;
  &:hover{
    color: var(--primary-main);
  }
}