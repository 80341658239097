.TypeLabel-item {
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
}

.TypeLabel-mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.TypeLabel-item .icon {
  display: flex;
  padding: 0.25rem 0.5rem 0 0;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.TypeLabel-itemIcon {
  height: 1.5rem;
  margin-top: 0.125rem;
}

.TypeLabel-itemLabel {
  display: flex;
  padding: 0.5rem 0 0.5rem 0;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
  color: var(--color-foreground-secondary-bold, #00245b);
}

.TypeLabel-progress {
  display: flex;
  padding: 0.5rem 0 0 0;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.125rem;
}

.TypeLabel-progress .TypeLabel-progressNumber {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.TypeLabel-progress .TypeLabel-progressPercentage {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.TypeLabel-progress .TypeLabel-progressStatus {
  color: var(--color-foreground-secondary-bold, #00245b);
}
