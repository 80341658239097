.CertsHeader {
  grid-column: 1 / -1;
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 3rem;
  height: min-content;
  width: 100%;
  background: var(--color-background-secondary-subtle, #001a40);
  z-index: var(--z-index-certs-landingpage-banner);
}

.CertsHeader-textWrapper {
  max-width: calc(100% - 20rem);
  width: 100%;
  min-width: 0;
}

.CertsHeader-text {
  color: var(--color-foreground-neutral-bold, #fff);
  overflow: hidden;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  width: 100%;
}

/* Controls */
.CertsHeader-functionGroup {
  display: flex;
  flex-shrink: 0;
}

.CertsHeader-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 3rem;
}

.CertsHeader-containerGreaterThan600 {
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  height: 3rem;
  display: flex;
}

.CertsHeader-closeBtn {
  align-self: flex-start;
}

/* Needs NEO enhancment. Post design changes this can be removed. 5/7/2024 */
.CertsHeader-button.Button-outlined {
  background: var(--color-background-secondary-subtle, #001a40);
  color: var(--color-foreground-primary-bold, #d1d7ff);
}

.CertsHeader-button.Button-outlined svg path {
  fill: var(--color-foreground-primary-bold, #d1d7ff);
}

.CertsHeader-button.Button-filled {
  background: var(--color-background-primary-bold, #8694ff);
  color: var(--color-foreground-neutral-inverse, #0c0d10);
}

.CertsHeader-button.Button-filled svg path {
  fill: var(--color-foreground-neutral-inverse, #0c0d10);
}
/* NEO Comment End */

.CertsHeader--isOpenViewOption {
  z-index: var(--z-index-certs-landingpage-dropdown);
}

.CertsHeader-containerSmallerThan600 {
  display: none;
}

@media (max-width: 1279px) {
  .CertsHeader {
    padding: 0.5rem 1.5rem;
  }
}

@media (max-width: 833px) {
  .CertsHeader-textWrapper {
    max-width: calc(100% - 15.375rem);
  }

  .CertsHeader-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
    white-space: normal;
    font: var(--typography-heading-2);
  }
}

@media (max-width: 600px) {
  .CertsHeader {
    padding: 0.5rem 1rem;
  }

  .CertsHeader-textWrapper {
    max-width: calc(100% - 5rem);
  }

  .CertsHeader-text {
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .CertsHeader-containerSmallerThan600 {
    display: block;
  }

  .CertsHeader-containerGreaterThan600 {
    display: none;
  }
}
