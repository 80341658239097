.CertsReassignModal-containerHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.CertsReassignModal-containerNoteIcon {
  width: var(--sizing-icon-sm, 1rem);
  height: var(--sizing-icon-sm, 1rem);
}
.CertsReassignModal-container-userDetailsName {
  color: var(--color-foreground-secondary-bold, #00245b);
  width: 30rem;
  overflow-wrap: break-word;
  font-weight: 400;
}

.CertsReassignModal-containerNoteText {
  color: var(--color-foreground-info-medium, #573bdc);
}

.CertsReassignModal-container-userDetails {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
}

.CertsReassignModal-details {
  display: flex;
  gap: 0.3rem;
  line-height: 1.25rem;
}

.CertsReassignModal-containerNoteIcon {
  margin-top: 0.125rem;
}

.CertsReassignModal-containerNote {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
}
.CertsReassignModal-includeCheckbox {
  width: 100%;
}

.CertsReassignModal-includeCheckbox .Checkbox-container {
  width: 100%;
}

.CertsReassignModal-includeCheckbox .Checkbox-label {
  justify-content: space-between;
}

.CertsReassignModal-containerCertifierSelection {
  display: flex;
  padding: 1.5rem 0;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}
.CertsReassignModal-containerCertifierSelection
  .CertsReassignModal-DropdownAsterisk {
  position: absolute;
  left: -0.6rem;
  color: var(--color-foreground-warning-medium);
}

.CertsReassignModal-containerCertifierSelection .Certs-dropdownSelect {
  width: 100%;
}

.CertsReassignModal-containerCertifierSelection
  .Certs-dropdownSelect
  .Menu-Item {
  padding: 0.25rem 0 0.25rem 0.75rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.CertsReassignModal-containerCertifierSelection
  .Certs-dropdownSelect
  .MenuItem-prefixIconAndLabel {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  color: #00245b;
  color: var(--color-foreground-secondary-bold, #00245b);
  -webkit-box-orient: vertical;
  align-self: stretch;
  padding-block: 0.25rem;
  display: -webkit-box;
  overflow: hidden;
}

.CertsReassignModal-containerCertifierSelection .anyWidthPositionRelative {
  width: 100%;
}

.CertsReassignModal-containerCertifierSelection
  .anyWidthPositionRelative
  .Menu-dropdown--isVisible {
  width: 100%;
  max-height: 12rem;
}

.CertsReassignModal-containerCertifierSelection .Checkbox-container {
  margin-top: 1.25rem;
}

.CertsReassignModal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

.CertsReassignModal-actionButtons {
  display: flex;
  gap: 0.5rem;
}

.CertsReassignModal-containerCertifierSelection .AlertBanner-container {
  width: 100%;
}

.CertsReassignModal-dropdown.selection--disabled {
  pointer-events: none;
}

.CertsUserListDropdown .MenuInput-items {
  max-height: 12rem;
  max-width: none;
}

@media (max-width: 600px) {
  .CertsReassignModal-mobileDrawer .MobileDrawer-content {
    background: var(--color-background-secondary-subtle, #f2f4f8);
    overflow: hidden;
  }

  .CertsReassignModal-containerCertifierSelection {
    padding: 2rem 0;
  }

  .CertsReassignModal-containerTitle {
    font-size: var(--font-size-14);
    line-height: 1.25rem;
  }

  .CertsReassignModal-container-userDetailsName {
    font-size: var(--font-size-14);
    font-weight: 400;
  }

  .CertsReassignModal-mobileDrawer .CertsReassignModal-footer {
    justify-content: flex-start;
  }

  .CertsReassignModal-mobileDrawer .CertsReassignModal-actionButtons {
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
  }

  .CertsReassignModal-mobileDrawer .CertsReassignModal-submitButton {
    display: block;
    width: 100%;
  }

  .CertsReassignModal-mobileDrawer .MobileDrawer-headerAndCloseOptions {
    height: auto;
    padding: 1rem;
  }

  .CertsReassignModal-mobileDrawer .mobileDrawerCloseButton {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .CertsReassignModal-mobileDrawer #mobileDrawerCloseButton {
    display: none;
  }

  .CertsReassignModal-mobileDrawer .CertsReassignModal-mobileDrawer-header {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .CertsReassignModal-mobileDrawer .CertsReassignModal-mobileDrawer-header svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .CertsReassignModal-mobileDrawer
    .CertsReassignModal-mobileDrawer-titleContainer {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .CertsReassignModal-mobileDrawer .MobileDrawer-content {
    padding: 1.5rem;
  }

  .CertsReassign-mobileDrawer-title {
    color: var(--color-foreground-neutral-bold, #212328);
  }

  .CertsReassignModal-mobileDrawer-subTitle {
    color: var(--color-foreground-neutral-medium, #4a4c59);
  }
}
