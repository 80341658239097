.MpaTimeAccessDuration
  .react-datepicker__time-list-item--disabled.react-datepicker__time-list-item--disabled {
  display: list-item;
}

/* High specificity to overridde datepicker package styles */
/* This is used to make the selected state not look like it works if the session is blocking the interval */
.MpaTimeAccessDuration.MpaTimeAccessDuration.MpaTimeAccessDuration.MpaTimeAccessDuration.MpaTimeAccessDuration
  li.react-datepicker__time-list-item--selected.react-datepicker__time-list-item--disabled,
.MpaTimeAccessDuration.MpaTimeAccessDuration.MpaTimeAccessDuration.MpaTimeAccessDuration.MpaTimeAccessDuration
  li.react-datepicker__time-list-item--selected.react-datepicker__time-list-item--disabled:hover {
  /* TODO: If Neo Datepicker is updated remove/replace these colors */
  /* Colors are from the datepicker package, any further alignment should be handled in Design-System update */
  background: #fff;
  color: #ccc;
  font-weight: 400;
}
.MpaTimeAccessDuration.MpaTimeAccessDuration.MpaTimeAccessDuration.MpaTimeAccessDuration.MpaTimeAccessDuration
  li.react-datepicker__time-list-item--selected.react-datepicker__time-list-item--disabled:hover {
  background: rgba(55, 81, 255, 0.08);
}
