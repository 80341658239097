.ReviewAndSubmitModal {
  width: 42.5rem;
}

.Certs-reviewAndSubmit-icon {
  display: none;
}

.Certs-reviewAndSubmit-showLabel,
.Certs-reviewAndSubmit-label {
  color: var(--color-foreground-primary-medium, #3751ff);
}

.Button-filled.Certs-reviewAndSubmit-btn .Certs-reviewAndSubmit-showLabel,
.Button-filled.Certs-reviewAndSubmit-btn .Certs-reviewAndSubmit-label {
  color: var(--color-foreground-neutral-inverse, #fff);
}

.ReviewAndSubmitModal .ModalIsland-section {
  max-width: 37.5rem;
  overflow: hidden;
}

/* important flag as in design system id is assigned which has higher priority */
.ReviewAndSubmitModal .ModalIsland-body {
  height: calc(100vh - 12rem) !important;
  max-height: 32rem !important;
  padding: 0 !important;
}

/* CSS rules for screens 475px and below */
@media (max-width: 475px) {
  .Certs-reviewAndSubmit-icon {
    display: block;
  }

  .Certs-reviewAndSubmit-showLabel {
    display: block;
  }

  .Certs-reviewAndSubmit-btn {
    padding: 0.625rem;
  }

  .Certs-reviewAndSubmit-label {
    display: none;
  }
}
