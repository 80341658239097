:root {
    --column-width: 1.5rem;
}

.row, .headingRow {
    display: flex;
    width: 100%;
    padding: 0.5rem 1rem;
}

.row {
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

.row:hover {
    background-color: var(--light);
}

.row:hover .hoverRow {
    visibility: visible;
}

.hoverRow {
    visibility: hidden;
    position: absolute;
    right: 80px;
}

.noHoverRow {
    display: none;
}

.timestamp {
    width: var(--column-width);
    margin-right: 1rem;
}

.message {
    width: calc(100% - var(--column-width));
    word-break: break-all;
}

.heading {
    font-width: 600;
    color: #747474;
}

.highlightRow {
    background-color: var(--primary-btn-bg);
}

.ribbon {
    background-color: var(--primary-btn-bg);
    padding: 6px 4px;
}

.item {
    padding: 4px 2px 4px 0;
}

.spacer {
    padding: 0px 4px;
}
