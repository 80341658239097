.SpvEndpoint {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  max-height: calc(100vh - 13.75rem);
  overflow: hidden auto;
}
