.SaviActionsFilter-wrapper .SaviActionsFilter-dropdown {
  padding: 0.75rem 0;
  position: absolute;
  background: var(--color-background-neutral-subtlest, #fff);
  z-index: var(--z-index-certs-landingpage-dropdown);
  border-radius: var(--border-radius-sm);
  margin-top: 0.5rem;

  /* TODO: use token when ready */
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  opacity: 0;
  width: 16rem;
  max-height: 32rem;
  transform-origin: top;
  transform: scaleY(0);
  transition:
    opacity var(--transition-quick-in-out),
    transform var(--transition-quick-in-out);
}

.SaviActionsFilter-wrapper
  .SaviActionsFilter-dropdown.SaviActionsFilter-dropdown--isVisible {
  transform: scaleY(1);
  opacity: 1;
}

.SaviActionsFilter-wrapper
  .SaviActionsFilter-dropdown
  .SaviActionsFilter-menuItem {
  cursor: pointer;
  color: #212328;
  color: var(--color-foreground-neutral-bold, #212328);
  font:
    400 0.875rem / 1.25rem 'Rubik',
    sans-serif;
  font: var(--typography-body-2);
  border-radius: 0.25rem;
  border-radius: var(--border-radius-sm, 4px);
  justify-content: left;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0 0.75rem 0.75rem 0.5rem;
  padding: 0.25rem 0.5rem;
  display: flex;
}

.SaviActionsFilter-wrapper
  .SaviActionsFilter-dropdown
  .SaviActionsFilter-menuItem:hover {
  background: #3751ff14;
}

.SaviActionsFilter-wrapper .SaviActionsFilter-footer {
  border-radius: 0 0 var(--border-radius-sm, 4px) var(--border-radius-sm, 4px);
  background: var(--color-background-neutral-subtlest, #fff);
  box-shadow: var(--box-shadow-pretoken-overlay-top);
  align-items: center;
  width: 100%;
  height: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  position: absolute;
  justify-content: flex-end;
}

.SaviActionsFilter-wrapper .SaviActionsFilter-menuItemText {
  color: var(--color-foreground-secondary-bold, #00245b);
}
