$button-size: 42px;

.container {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  overflow: hidden;
  padding: 0 calc($button-size/2);
  margin-left: -22px;
  margin-right: -22px;

  &.extendToEdge {
    padding-right: 0;
    .titlebar {
      padding-right: calc($button-size/2);
    }
  }
}

.titlebar {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;

  .arrows {
    display: flex;
    align-self: center;
    .arrow {
      padding-left: 10px;
    }
  }
}

.arrow {
  cursor: pointer;
  padding: 0;
  outline: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 3;

  &.hide {
    display: none;
  }
  .button {
    padding: 2px; //also change .card padding
  }
}

.slider {
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  .cardContainer {
    overflow: hidden;
    width: 100%;
    .scrollable {
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      flex-grow: 1;
      z-index: 1;
      position: relative;
      height: 100%;
      width: 100%;
      margin-bottom: -20px;
      padding-bottom: 20px;
    }
  }

  .arrow {
    position: absolute;
    top: 50%;
    bottom: 0;
    margin: calc(-1 * $button-size/2);
    opacity: 1;
    &.hide {
      opacity: 0;
    }

    &.left {
      left: 24px;
      // opacity: 0;
    }
    &.right {
      right: 15px;
      // opacity: 1;
    }
    .button {
      background-color: #f7f8fc;
      border-radius: 5px;
      width: 21px;
      height: 30px;
      margin-right: 12px;
      &:hover {
        cursor: pointer;
      }
      svg {
        opacity: 0.8;
      }
    }
  }
}

.card {
  padding: 10px 0;
}
