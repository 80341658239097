.SimpleAccordion--sod,
.SimpleAccordion--privileges {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.SimpleAccordion--sod {
  padding-block: 0.5rem 1rem;
}

.SimpleAccordion--privileges {
  padding-block: 1rem;
}

.SimpleAccordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  padding-block: 0.5rem;
  padding-inline: 0.5rem 1rem;
  transition: var(--transition-quick-out);
}

.SimpleAccordion-header:hover {
  background-color: #3851ff14;
}

.SimpleAccordion-headerTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 96%;
}

.SimpleAccordion-headerTitleText--closed {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.SimpleAccordion-headerTitleText--expanded {
  text-overflow: unset;
  white-space: normal;
  overflow: hidden;
}

.SimpleAccordion-headerIcon--open,
.SimpleAccordion-headerIcon--closed {
  display: flex;
}

.SimpleAccordion-headerIcon--open {
  transform: rotate(180deg);
}

.SimpleAccordion-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  padding: 0.5rem 1rem;
}
