.ListRow-labelValue,
.ListRow-title {
  display: flex;
}

.ListRow-labelValue-label {
  color: var(--color-foreground-neutral-subtle, #717385);
  font: var(--typography-body-3);
  line-height: 1.5rem;
  white-space: nowrap;
  padding-right: 0.25rem;
}

.ListRow-dot {
  height: 0.25rem;
  width: 0.25rem;
  background-color: var(--color-foreground-neutral-bold, #212328);
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
  top: 0.5625rem;
  margin: 0 0.5rem;
}

.ListRow-labelValue-text {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-3);
  line-height: 1.5rem;
}

/* css overrides to show ellipses when possible */
/* sessions section: */
.ListRowSession-data {
  .ListRow-title-content {
    display: flex;
    white-space: nowrap;
  }
  .ListRow-labelValue-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ListRow-description .ListRow-description-content:last-child {
    overflow: hidden;
  }
  .ListRow-title .ListRow-title-content:last-child {
    overflow: hidden;

    .ListRow-labelValue {
      overflow: hidden;
    }
  }
}

/* non session listrows (first row, should only be the target title) */
.LandingPage-listrow-section-endpoints .ListRow-title-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* row 2 of non session rows */
.ListRowTarget-data-description {
  .ListRow-description-content:last-child {
    overflow: hidden;

    .ListRow-labelValue {
      overflow: hidden;
    }
    .ListRow-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
/* end css overrides to show ellipses */
