/* This class is for future responsivness on overly large screens. */
.ModalPageHeader-background {
  margin: 0 auto;
  width: 100%;
  background: var(--color-background-secondary-subtle, #001a40);
  color: var(--color-foreground-neutral-bold, #fff);
  z-index: var(--z-index-page-header);
}

.ModalPageHeader {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 3rem;
  width: 100%;
  background: var(--color-background-secondary-subtle, #001a40);
}

.ModalPageHeader-textContainer {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.125rem;
  width: 100%;
}

.ModalPageHeader-title {
  margin: 0;
}

.ModalPageHeader-subHeadingContent {
  color: var(--color-foreground-neutral-bold, #fff);
}
.ModalPageHeader-subHeadingContent p {
  color: var(--color-foreground-neutral-bold, #fff);
  margin: 0;
}

.ModalPageHeader-closeButton {
  min-width: 2.5rem;
}

/* Use this to select children ypu want to omit. Example in ModalPageHeader: the Menu dropdown list */
/* 
  [data-theme='dark'] .child-selector {
  background-color: #333;
  color: #fff;
  } 
*/
