.SpvRoles {
  width: 100%;
  height: 100%;
}

.SpvRoles-tabContent {
  display: flex;
  padding: 0 1.5rem 3rem 1.5rem;
  max-height: calc(100vh - 15.25rem);
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}

.SpvRoles-Scheduler {
  padding: 1rem 0;
}
