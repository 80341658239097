.ARSAccordian {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}

.ARSAccordian.subbody {
  padding: 0 0 1.5rem 2rem;
  width: calc(100% - 2rem);
}

.ARSAccordian.subbody--1 {
  display: -webkit-box;
  width: calc(100% - 2rem);
  padding: 0.5rem 0 0.5rem 2rem;
}
