.UploadedFile-container {
  display: inline-flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  width: 100%;
}

.UploadedFile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--border-radius-sm, 4px);
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  background: var(--color-background-neutral-subtlest, #fff);
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  min-height: 4.5rem; /* 72px */
}

.UploadedFile-nameSizeContainer {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.UploadedFile-fileName {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.UploadedFile-fileSize {
  color: var(--color-foreground-neutral-subtle, #717385);
  font: var(--typography-body-2);
}

.UploadedFile-actionIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  margin-left: 1.5rem;
}

.UploadedFile-error {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.UploadedFile-errorIcon {
  height: 1rem;
}

.UploadedFile-errorMessage {
  color: var(--color-foreground-critical-medium, #cc1c10);
  font: var(--typography-body-3);
}

.UploadedFile--error {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}
