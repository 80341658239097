.sidebarItemHover {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  &:hover {
    i {
      svg {
        path {
          fill: var(--navbar-icon-hover);
        }
      }
    }
    a {
      color: var(--navbar-icon-hover);
    }
  }
  .navlabel{
    margin-left: 30px;
  
    }
}

.iconHover {
  outline: none !important;
  &:hover {
    span {
      svg {
        fill: var(--navbar-icon-hover);
      }
    }
  }
}

.sidebarLink {
  display: flex;
  align-items: center;
}
