.app-version-container {
   width: 350px;
   margin-bottom: 44px;
   background: #fff;
   border-radius: 4px;
   text-align: center;

   .app-version-logo {
       width: 65px;
       height: 65px;
       background-image: url(./Logo-round.png);
       background-size: contain;
       margin: 10px auto 12px auto;
   }

   .app-version-text-row1 {
        font-weight: bold;
        font-size: 18px;
   }

   .app-version-text-row2 {
       font-size: 12px;
       margin-top: 4px;
   }

   .app-version-text-row3 {
       font-size: 12px;
       margin-top: 30px;
   }

   .app-version-close-icon {
       text-align: right;
       margin: 10px;
   }
}

.app-version-container-paper {
    top: -150px;
}
