.CertsSidePanelCommentDrawer-contextWrapper {
  position: absolute;
  bottom: 4rem;
  left: 0;
  display: flex;
  height: 0; /* Start closed */
  width: 100%;
  background: var(--color-background-neutral-subtlest, #ffffff);
  transition: height var(--transition-quick-in-out);
  overflow: hidden;
}

.CertsSidePanelCommentDrawer-contextWrapper--isOpen {
  max-height: 17.5rem;
  height: auto;
  overflow: visible;
}

/* Content */
.CertsSidePanelCommentDrawer {
  height: 0;
  width: 100%;
  padding: 0 1.625rem;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  transition:
    height var(--transition-quick-in-out),
    padding-top var(--transition-quick-in-out),
    padding-bottom var(--transition-quick-in-out),
    box-shadow var(--transition-quick-in-out);
  overflow: hidden;
}

.CertsSidePanelCommentDrawer-contextWrapper--isOpen
  .CertsSidePanelCommentDrawer {
  max-height: 17.5rem;
  height: fit-content;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px -2px 16px -2px rgba(0, 0, 0, 0.08);
  overflow-y: scroll;
}

/* Control Buttons */
.CertsSidePanelCommentDrawer-controls {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 0.5rem 0;
  gap: 0.5rem;
}

.CertsSidePanelCommentDrawer .CertsCommentInputField-commentSection {
  padding-left: 1rem;
  padding-top: 1rem;
}

.CertsSidePanelCommentDrawer::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.CertsSidePanelCommentDrawer .CertsConsultedActionPopover,
.CertsSidePanelCommentDrawer .CertsConsultPopover {
  width: 100%;
  padding: 0;
  min-width: auto;
}

.CertsSidePanelCommentDrawer .CertsConsultedActionPopover-content,
.CertsSidePanelCommentDrawer .CertsConsultedActionPopover-controls,
.CertsSidePanelCommentDrawer .CertsConsultPopover-content,
.CertsSidePanelCommentDrawer .CertsConsultPopover-controls {
  padding: 0;
}
