.Badge {
  position: relative;
  border-radius: var(--border-radius-circle, 99rem);
}

.Badge-number {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.25rem;
  min-width: 1rem;
  height: 1rem;
  color: var(--color-foreground-neutral-inverse, #fff);
  font: var(--typography-body-bold-3);
}

/* Colors */
.Badge--primary {
  background: var(--color-background-primary-bold, #3751ff);
}
.Badge--secondary {
  background: var(--color-background-secondary-bold, #254b86);
}
.Badge--neutral {
  background: var(--color-background-neutral-subtle, #ebecf2);
}
.Badge--success {
  background: var(--color-background-success-bold, #028329);
}
.Badge--error {
  background: var(--color-background-critical-bold, #cc1c10);
}
.Badge--warning {
  background: var(--color-background-warning-bold, #f6a91c);
}
.Badge--info {
  background: var(--color-background-info-bold, #573bdc);
}

/* Alt text colors */
.Badge--neutral .Badge-number,
.Badge--warning .Badge-number {
  color: var(--color-foreground-neutral-bold, #212328);
}

/* Kinds */
.Badge--dot {
}
.Badge--number {
}
/* Sizes */
.Badge--small {
  min-height: 0.5rem;
  min-width: 0.5rem;
}

.Badge--medium {
  min-height: 0.75rem;
  min-width: 0.75rem;
}
.Badge--medium .Badge-number {
  padding: 0.1875rem 0.375rem 0.0625rem 0.375rem;
  min-width: 1.25rem;
  height: 1.25rem;
  font: var(--typography-body-bold-2);
}

/* Styles */
.Badge--filled:not(.Badge--number) {
  outline: 2px solid var(--color-border-secondary-decorative, #f2f4f8);
}
.Badge--outlined {
  background: var(--color-background-neutral-subtlest, #fff);
}

.Badge--medium.Badge--outlined {
  min-width: 1.25rem;
  height: 1.25rem;
}

/* Alt text colors */
.Badge--outlined.Badge--primary {
  outline: 1px solid var(--color-border-primary-subtlest, #d1d7ff);
}
.Badge--outlined.Badge--primary .Badge-number {
  color: var(--color-foreground-primary-medium, #3751ff);
}

.Badge--outlined.Badge--secondary {
  outline: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}
.Badge--outlined.Badge--secondary .Badge-number {
  color: var(--color-foreground-secondary-medium, #254b86);
}

.Badge--outlined.Badge--neutral {
  outline: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}
.Badge--outlined.Badge--neutral .Badge-number {
  color: var(--color-foreground-neutral-medium, #4a4c59);
}

.Badge--outlined.Badge--success {
  outline: 1px solid var(--color-border-success-subtlest, #bee4b6);
}
.Badge--outlined.Badge--success .Badge-number {
  color: var(--color-foreground-success-medium, #028329);
}

.Badge--outlined.Badge--error {
  outline: 1px solid var(--color-border-critical-subtlest, #fed1d0);
}
.Badge--outlined.Badge--error .Badge-number {
  color: var(--color-foreground-critical-medium, #cc1c10);
}

.Badge--outlined.Badge--warning {
  outline: 1px solid var(--color-border-warning-subtlest, #ffd988);
}
.Badge--outlined.Badge--warning .Badge-number {
  color: var(--color-foreground-warning-medium, #a36a00);
}

.Badge--outlined.Badge--info {
  outline: 1px solid var(--color-border-info-subtlest, #dfd4f4);
}
.Badge--outlined.Badge--info .Badge-number {
  color: var(--color-foreground-info-medium, #573bdc);
}
