.pagination-scroll-dropdown {
  width: 100%;
  position: relative;
  .selected-options {
    padding-bottom: 5px;
    padding-right: 8px;
    display: inline;
  }
  .formlayout {
    display: flex;
  }
  .dropdownicon {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 0;  }
}
#use-autocomplete-demo-popup {
  z-index: 99999 !important;
}
