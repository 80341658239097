.CertsAutoRevokeWarning-wrapper {
  position: absolute;
  top: 4.75rem;
  left: 3rem;
  right: 3rem;
  z-index: var(--z-index-floating-banner);

  display: flex;
  gap: 0.25rem;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  background: var(--color-background-warning-subtlest, #fef7eb);
  border-radius: var(--borderRadius-sm, 4px);
  border: 1px solid var(--color-border-warning-subtle, #f6a91c);
}

.CertsAutoRevokeWarning-sideIconWrapper {
  padding: 0.125rem 0;
}

.CertsAutoRevokeWarning-conent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.CertsAutoRevokeWarning-header {
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  padding: 0.5rem 0;
}
.CertsAutoRevokeWarning-headerTitle {
  color: var(--color-foreground-neutral-bold, #212328);
}
.CertsAutoRevokeWarning-headerDetail {
  color: var(--color-foreground-neutral-bold, #212328);
}

/* Body */
.CertsAutoRevokeWarning-expandedBody {
  padding-bottom: 0;
  height: 0;
  transition:
    height var(--transition-quickstep-in-out),
    padding-bottom var(--transition-quick-in-out) var(--transition-fast);
  overflow: hidden;
}
.CertsAutoRevokeWarning-expandedBody--isExpanded {
  padding-bottom: 1rem;
}
.CertsAutoRevokeWarning-expandedBody--isExpanded.CertsAutoRevokeWarning-expandedBody--length1 {
  height: 3.5rem;
}
.CertsAutoRevokeWarning-expandedBody--isExpanded.CertsAutoRevokeWarning-expandedBody--length2 {
  height: 4.75rem;
}
.CertsAutoRevokeWarning-expandedBody--isExpanded.CertsAutoRevokeWarning-expandedBody--length3 {
  height: 6rem;
}

.CertsAutoRevokeWarning-expandedBodyList {
  padding-left: 0.5rem;
}
.CertsAutoRevokeWarning-expandedBodyText {
  color: var(--color-foreground-neutral-bold, #212328);
}

.CertsAutoRevokeWarning-checkboxWrapper {
  padding-bottom: 0.5rem;
}
