.user-option {
    .user-menu-info-icon {
        margin: 5px 10px 5px 7px;
    }
} 

.top-bar-box .top-bar .user-profile .user-image {
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    background-color: #e8e9ed;
    text-align: center;
    line-height: 30px;
    color: #646464;
  }
  
  .top-bar-box .top-bar .user-profile .info {
    margin-left: 15px;
  }
  
  .top-bar-box .top-bar .user-profile .info .user-name {
    font-size: 14px;
    font-weight: 500;
    color: #646464;
  }
  
  .top-bar-box .top-bar .user-profile .dropdown-menu {
    right: 0;
    left: auto;
    border: 1px solid var(--primary-bg);
    border-radius: 3px;
    min-width: 220px;
    font-size: 11px;
    padding: 0;
    margin-top: -10px;
  }
  
  .top-bar-box .top-bar .user-profile .dropdown-menu .user-username {
    font-size: 14px;
    color: black;
    padding: 21px 15px 0px 15px;
    word-break: break-word;
  }
  
  .top-bar-box .top-bar .user-profile .dropdown-menu .user-designation {
    font-size: 12px;
    color: #999999;
    padding: 0px 15px 0px 15px;
    word-break: break-word;
  }
  .top-bar-box .top-bar .user-profile .dropdown-menu .user-manage {
    border-radius: 12px !important;
    padding: 5px 35px 5px 35px;
    margin-top: 8px;
    margin-left: 15px;
    margin-bottom: 15px;
  }
  .top-bar-box .top-bar .user-profile .dropdown-menu .user-option {
    padding-left: 15px;
    margin-bottom: 15px;
    border-top: solid 1px rgba(151, 151, 151, 0.1);
  }
  .top-bar-box .top-bar .user-profile .dropdown-item-new {
    /* padding: 15px 2px; */
    display: flex;
    border-bottom: none;
  }
  
  .top-bar-box .top-bar .user-profile .dropdown-item {
    border-bottom: 1px solid var(--primary-bg);
    padding: 15px 12px;
  }
  .top-bar-box .top-bar .user-profile .dropdown-item-link-new {
    color: #1e1e1e;
    font-weight: 500;
    font-size: 11px;
    font-family: Rubik, sans-serif;
  }
  .top-bar-box .top-bar .user-profile .dropdown-item-sublink {
    color: #999999;
    font-size: 10px;
  }
  .top-bar-box .top-bar .user-profile .dropdown-item.active,
  .top-bar-box .top-bar .user-profile .dropdown-item:active {
    background-color: #fff;
  }
  
  .top-bar-box .top-bar .user-profile .dropdown-item:last-child {
    border-bottom: none;
  }
  
  .top-bar-box .top-bar .user-profile .dropdown-item a {
    color: #1e1e1e;
    line-height: 22px;
  }
  
  .top-bar-box .top-bar .user-profile .dropdown-item a:focus,
  .top-bar-box .top-bar .user-profile .dropdown-item a:hover {
    color: var(--primary-main);
  }

  .userMenuIcon {
    margin-bottom: -9px;
    margin-right: 5px;
  }

  .dropdown-item-sublink{
    color: #999999;
    font-size: 10px;
  }

  .top-bar-box .top-bar .user-profile .dropdown-menu .user-username {
    font-size: 14px;
    color: black;
    padding: 21px 15px 0px 15px;
    word-break: break-word;
  }
