.divTableRow{
  &:last-child {
    position: relative;
    .justify-content-end {
      justify-content: center !important;
    }
  }
  &:hover {
    .icon-section {
      display: block !important;
    }
    .select-buttons {
      visibility: visible;
    }
    // .selected-area.not-selected {
    //   display: none;
    // }  
  }
}

.row-action-area {
  width: 100%;
  white-space: nowrap;
  position: relative;
  justify-content: flex-end;
  &.fixed-width-1 {
    // min-width: 95px;
  }
  &.fixed-width-2 {
    min-width: 190px;
  }
  .select-buttons {
    visibility: hidden;
    * + * {
      margin-left: 10px;
    }
  }
  .selected-area {
    display: flex;
    button:first-child {
      margin-right: 12px;
    }
    button:last-child {
      padding: 0;
    }
  }
  .invisible {
    visibility: hidden;
  }
  .hide {
    display: none;
  }
}