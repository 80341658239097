
.colourPickerPopper {
    padding: 8px;
    z-index: 1000;
    right: 30px;
    position: fixed;
    border-radius: 6px;
    background: var(--color-neutral-000, #FFF);
    background-color: #fff;
    box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.08);

    .chrome-picker {
        box-shadow: none !important;
        width: 240px !important;
        > div:first-child {
            height: 235px !important;
            padding-bottom: 0 !important;
        }
    }

  }
  .colourPickerPopperEdit {
      padding: 8px;
      z-index: 1000;
      right: 347px !important;
      position: fixed;
      border-radius: 6px;
      background: var(--color-neutral-000, #FFF);
      background-color: #fff;
      box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.08);
  
      .chrome-picker {
          box-shadow: none !important;
          width: 240px !important;
          > div:first-child {
              height: 235px !important;
              padding-bottom: 0 !important;
          }
      }
  
    }