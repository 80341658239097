.container {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-auto-columns: calc(100% / 12);
    column-gap: 16px;
    /*composes: gridContainer from './../../../../../ECMv6/assets/css/global.css';*/
    width: 100%;
}

.gridItemAuto {
    grid-column: auto / span 5;
}

.gridItemSpan4 {
    grid-column: auto / span 5;
    width: 100%;
}

.searchButton {
    grid-column: auto / span 2;
}
