.ListRow-labelValue,
.ListRow-description,
.ListRow-description-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ListRow-separator {
  margin: 0 0.5rem;
  align-self: center;
}

.ListRow-text {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
}

.ListRow-description-content-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
