@import './hardcodedBoxShadow.css';
@import './typography.css';
@import './variables.css';
@import './hardcodedLightColors.css';
@import './hardcodedDarkColors.css';
@import './zIndexing.css';
@import './transitions.css';
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500');

:root {
  /* ApplicationIcon Sizes */
  --application-icon-size-xxsmall: 1.25rem; /* 20px */
  --application-icon-size-xsmall: 1.5rem; /* 24px */
  --application-icon-size-small: 2rem; /* 32px */
  --application-icon-size-medium: 2.5rem; /* 40px */
  --application-icon-size-large: 3rem; /* 48px */
  --application-icon-size-xlarge: 3.5rem; /* 56px */
  --application-icon-size-xxlarge: 4rem; /* 64px */
}

*,
:before,
:after {
  box-sizing: border-box;
}

body {
  font-family: 'Rubik', Helvetica, Arial, serif;
}
