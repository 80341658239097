.Certs-userSelected .InputField--large {
  cursor: not-allowed;
  pointer-events: none;
}

.CertsUserListDropdown .MenuItem {
  padding: 0.25rem 0 0.25rem 0.75rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.CertsUserListDropdown .MenuItem-prefixIconAndLabel {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-foreground-secondary-bold, #00245b);
  font-feature-settings:
    'clig' off,
    'liga' off;
  padding-block: 0.25rem;
}

.CertsUserListDropdown
  .MenuItem-prefixIconAndLabel
  .MenuItem-prefixIconAndLabel-label {
  white-space: break-spaces;
  text-overflow: ellipsis;
  min-width: max-content;
  overflow: hidden;
  display: contents;
}

.CertsUserListDropdown--disableMenu {
  pointer-events: none;
}

.CertsUserListDropdown--disableMenu .InputField-inputContainer .InputCore {
  background: var(--color-page-subtle, #f2f4f8);
}

.CertsUserListDropdown--enableMenu .InputField-inputContainer .ButtonCore {
  background: transparent;
}
