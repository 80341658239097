.textarea {
  height: 90%;
  width: 99%;
  textarea {
    width: 100%;
    height: 100%;
  }
}
.newkpiText {
  width: 40vw;
  .bodyTextArea {
    pre {
      min-height: 300px;
    }
    textarea {
      min-height: 300px;
      border: solid 2px var(--primary-main) !important;
      border-radius: 3px;
    }
  }
  .summaryBottom {
    margin-bottom: 0px !important;
  }
}
.textAreaField {
  border: solid 2px var(--primary-main);
  border-radius: 3px;
}

.inputName {
  border: solid 1px rgba(116, 116, 116, 1);
  border-radius: 3px;
}
.scopeObject {
  font-size: small;
}
.editIconsstyle{
  padding-top: 1px;
    fill: blue;
    background-color: var(--page-bg);
    border-radius: 50%;
}
.editIcons {
  position: relative;
  font-size: 5px;
  display: none;
  align-self: flex-end;
  margin-right: 10px;
  .removeIcon{
padding: 2px;
margin-left: 5px;
padding-left: 3px !important;
padding-top: 3px;
  }
  .editIcon {
    vertical-align: bottom;
    background-color: var(--secondaryBg);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding-left: 1px;
  }
}
// .inputMargin{
//   margin-left: 67px;
// }
.previewContaner{
  padding: 16px 30px 16px 24px;
  border-bottom: solid 1px #dcdcdc;
}

.previewText{
  height: 42px;
  padding: 13px 0 14px 24px;
  background-color: #f5f5f5;
}
.contentBody{
  :global .MuiChip-labelSmall{
    color: var(--primary-main) !important;
  }
  
  :global .MuiChip-deleteIcon{
    color: var(--primary-main) !important;
  }
}

.requestActionTiles{
  min-width: 12.25rem;
  height: 5.75rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 7px;
  box-shadow: 1px 1px 5px 0 rgba(205, 206, 217, 0.5);
  border-radius: 6px;
  &:hover{
    background-color: var(--secondaryBg);
    cursor: pointer;
  }
  div{
    text-align: center;
  }

}
