.SmartFilter-wrapper .SmartFilter-button .ButtonSelect--pill-prefixIcon {
  display: flex;
  margin: auto;
}

/* .SmartFilter-wrapper .SmartFilter-button .ButtonSelect--pill-prefixIcon svg path {
    fill: unset;
} */

.SmartFilter-wrapper .SmartFilter-dropdown {
  position: absolute;
  background: var(--color-background-neutral-subtlest, #fff);
  z-index: var(--z-index-certs-landingpage-dropdown);
  border-radius: var(--border-radius-sm);
  padding: 0;
  margin-top: 0.5rem;

  /* TODO: use token when ready */
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  transition:
    opacity var(--transition-quick-in-out),
    transform var(--transition-quick-in-out);
}

.SmartFilter-wrapper .SmartFilter-dropdown .SmartFilter-dropdown-displayFlex {
  display: flex;
}

.SmartFilter-wrapper .SmartFilter-dropdown.SmartFilter-dropdown--isVisible {
  transform: scaleY(1);
  opacity: 1;
}

.SmartFilter-wrapper .SmartFilter-dropdown .SmartFilter-factorsPartition {
  width: 25rem;
  border-left: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.SmartFilter-wrapper
  .SmartFilter-dropdown
  .SmartFilter-factorsPartition
  .SmartFilters-factorsTitleWrapper {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  padding: 1.5rem 1.5rem 0rem;
}

.SmartFilter-wrapper .SmartFilter-dropdown .SmartFilter-footer {
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  padding: 0.75rem 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.SmartFilter-wrapper
  .SmartFilter-dropdown
  .SmartFilter-factorsPartition
  .SmartFilters-factorsItemWrapper {
  display: flex;
  gap: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  padding: 0.75rem 0.75rem 0.75rem 0.5rem;
}

.SmartFilter-wrapper
  .SmartFilter-dropdown
  .SmartFilter-factorsPartition
  .SmartFilters-factorsItemWrapper
  .SmartFilters-factorsItemTextWrapper {
  width: 16.5rem;
}

.SmartFilter-wrapper
  .SmartFilter-dropdown
  .SmartFilter-factorsPartition
  .SmartFilters-factorsItemWrapper
  .SmartFilters-factorsItemCheckbox {
  margin-left: auto;
  margin-bottom: auto;
}

.SmartFilter-wrapper
  .SmartFilter-dropdown
  .SmartFilter-factorsPartition
  .SmartFilters-factorsItemWrapper
  .SmartFilters-title,
.SmartFilter-wrapper
  .SmartFilter-dropdown
  .SmartFilter-factorsPartition
  .SmartFilters-factorsTitleWrapper
  .SmartFilters-title {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.SmartFilter-wrapper
  .SmartFilter-dropdown
  .SmartFilter-factorsPartition
  .SmartFilters-factorsItemWrapper
  .SmartFilters-description,
.SmartFilter-wrapper
  .SmartFilter-dropdown
  .SmartFilter-factorsPartition
  .SmartFilters-factorsTitleWrapper
  .SmartFilters-description {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.SmartFilter-wrapper
  .SmartFilter-dropdown
  .SmartFilter-factorsPartition
  .SmartFilter-factorsItemList {
  overflow: hidden auto;
  max-height: calc(100vh - 20rem);
  gap: 0.75rem;
  padding: 0rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
}

.SmartFilter-wrapper
  .SmartFilter-dropdown
  .SmartFilter-factorsPartition
  .SmartFilter-factorsItemList::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  border-radius: var(--border-radius-circle, 50%);
  background-clip: content-box;
  border: 3px solid #0000;
}

.SmartFilter-wrapper .SmartFilter-dropdown .SmartFilters-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ebecf280;
  z-index: 1;
}
