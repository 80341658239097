
.clasicGrid{/* DivTable.com */

.divTable1 {
  position: fixed;
  white-space: nowrap;
  display: block;
  z-index: 27;
}
.divTable1 .divTableHead {
  position: relative;
  z-index: 27;
}
.divTable1 .divTableBody {
  position: relative;
  z-index: -50;
  max-height: 2px;
  height: 2px;
  visibility: hidden;
  /* pointer-events: none;
  position: fixed;
  top: -1000px; */
}

.hideHeading {
  visibility: hidden;
}
.divTable {
  /* position: absolute; */
  display: table;
  width: 100%;
  font-size: 12px;
  background: #ffffff;
  white-space: nowrap;
  border: 1px solid var(--grey-border);
  border-radius: 3px;
  z-index: 26;
}
.divTableRow {
  display: table-row;
  position: relative;
}
.divTableBody .divTableRow:hover {
  box-shadow: 0px 0px 10px 0 #c8c8c8;
  -webkit-box-shadow: 0px 0px 10px 0 #c8c8c8;
  -moz-box-shadow: 0px 0px 10px 0 #c8c8c8;
}

.divTableHeading {
  display: table-header-group;
}

.divTableCell {
  display: table-cell;
  border-bottom: 1px solid var(--grey-border);
  font-size: 12px;
  color: #000000;
  padding: 12px;
  white-space: normal;
}
.divTableCell .cell_link {
  position: relative;
}
.divTableCell .cell_link button {
  color: #000000;
}
.divTableRow:hover .cell_link button {
  color: var(--primary-main);
  text-decoration: none;
}
.divTableHead {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  padding: 12px;
  display: table-cell;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid var(--grey-border);
  text-transform: uppercase;
  color: #717780;
  background-color: #ffffff;
  white-space: normal;
}
.divTableHead .active-col {
  position: relative;
}
.divTable .divTableHead:hover {
  color: #000000;
  cursor: pointer;
}
.divTableHeading {
  display: table-header-group;
}
.divTableBody {
  display: table-row-group;
}
.divTable .divTableHead:hover .filter_dropdown,
.divTable .divTableHead:focus .filter_dropdown,
.divTable .divTableHead.active .filter_dropdown,
.divTable .divTableHead:hover .col_sort,
.divTable .divTableHead:focus .col_sort,
.divTable .divTableHead.active .col_sort {
  opacity: 1;
}
button.filter_dropdown {
  vertical-align: top;
  line-height: 10px;
  height: 14px;
  width: 14px;
  text-align: center;
  background-color: transparent;
  margin-left: 5px;
  margin-top: 2px;
  display: inline-block;
  opacity: 0;
}
button.filter_dropdown i {
  height: 10px;
  padding-left: 1px;
}
/*To work only on Safari*/

/* .divTableRow:hover .divTableCell:first-child {
  -webkit-box-shadow: -8px 0 10px -6px#c8c8c8, 0 8px 8px -8px #c8c8c8,
    0 -8px 8px -8px #c8c8c8;
  -moz-box-shadow: -8px 0 10px -6px #c8c8c8, 0 8px 8px -8px #c8c8c8,
    0 -8px 8px -8px #c8c8c8;
  box-shadow: -8px 0 10px -6px#c8c8c8, 0 8px 8px -8px #c8c8c8,
    3px -8px 8px -8px #c8c8c8, 23px -8px 8px -8px #c8c8c8;
  background-color: white;
}

.divTableRow:hover .divTableCell {
  -webkit-box-shadow: 0 8px 8px -8px #c8c8c8, 3px -8px 8px -8px #c8c8c8;
  box-shadow: 3px 8px 8px -8px #c8c8c8, 23px 8px 8px -8px #c8c8c8,
    3px -8px 8px -8px #c8c8c8, 23px -8px 8px -8px #c8c8c8;
  -moz-box-shadow: 0 1px 10px #c8c8c8, 3px -1px 10px #c8c8c8;
  background-color: white;
}

.divTableRow:hover .divTableCell:last-child {
  -webkit-box-shadow: 8px 0 10px -6px #000000;
  -moz-box-shadow: 8px 0 10px -6px #000000;
  box-shadow: 8px 0 10px -6px #000000;
  background-color: white;
} */
.divTable .dropdown-menu {
  border-radius: 5px;
  box-shadow: 3px 3px 8px 0 #cdced9;
  background: var(--page-bg);
  border: 1px solid #dadee3;
  min-width: 70px;
  top: 22px;
  right: -15px;
  color: #000000;
  display: flex;
  flex-direction: column;
}
.divTable .dropdown-menu h4 {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  padding: 8px 16px;
  margin: 0;
}
.divTable .dropdown-menu .icon-close {
  font-size: 20px;
  color: #c8c8c8;
  position: absolute;
  right: 16px;
  top: 10px;
}

.divTableBody .divTableRow:last-child .divTableCell {
  border-bottom: none;
}
.divTableBody .divTableRow:last-child {
  border-radius: 0 0 3px 3px;
}
.divTableBody .divTableRow:last-child .divTableCell:first-child {
  border-radius: 0 0 0 3px;
}
.divTableBody .divTableRow:last-child .divTableCell:last-child {
  border-radius: 0 0 3px 0;
}
.divTableHead:first-child {
  border-radius: 3px 0 0 0;
}
.divTableHead:last-child {
  border-radius: 0 3px 0 0;
}
.sortableHelper {
  visibility: visible;
  opacity: 0.9;
  z-index: 1001;
  list-style: none;
  list-style-type: none;
  font-size: 12px;
  padding: 2px 10px;
}
.sortableHelper .customCheck,
.sortableHelper:hover .more {
  display: inline-block;
}
body > .sortableHelper {
  background-color: #ffffff;
  box-shadow: 1px 1px 6px 0 #d7d7d7;
  border-radius: 3px;
}
.sortableHelper .customCheck label {
  line-height: 14px;
  padding-left: 22px;
}
.manageCol {
  stroke: #a5abb4;
}
.manageCol:hover,
.manageCol:focus,
.manageCol:active,
.manageCol.active {
  stroke: var(--primary-main);
}
.divTableRow .divTableCell:nth-child(even) .gridLoading {
  width: 60%;
}
@keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}
.gridLoading {
  display: block;
  width: 90%;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.15);
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.row-error .divTableCell:first-child {
  border-left: solid 2px red;
}
.errorText {
  color: red;
  border: solid 1px #ec9a9a;
  padding: 5px;
  display: flex !important;
  justify-content: space-evenly;
  border-radius: 4px;
  background-color: rgba(227, 122, 122, 0.34);
}

.inst-error {
  font-size: 12px;
  font-weight: 500px;
}

.errorText {
  position: relative;
  display: inline-block;
}

.errorText .errorPopup {
  visibility: hidden;
  width: 245px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 11111;
  top: 125%;
  box-shadow: 4px 3px var(--primary-bg);
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.popupButtom::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 20%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.errorText:hover .errorPopup {
  visibility: visible;
  opacity: 1;
}

.popupBottom {
  top: 135%;
  left: 50%;
  margin-left: -60px;
}
/* filter popup specific - to be moved inside filter scss */
.divTableHead .col-filter-box {
  box-shadow: 0px 0px 10px 0 #c8c8c8;
  -webkit-box-shadow: 0px 0px 10px 0 #c8c8c8;
  -moz-box-shadow: 0px 0px 10px 0 #c8c8c8;
  max-height: 400px;
  overflow: auto;
}
.col-filter-list label {
  margin-bottom: 0px;
}

.col-filter-list label span:nth-child(2) {
  font-size: 12px;
}
}