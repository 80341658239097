.SpvPendingSession {
  width: 100%;
  height: 100%;
}

.SpvPendingSession-tabGroup {
  width: 100%;
  height: 2.5rem;
  align-items: center;
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.SpvPendingSession-tabContent {
  display: flex;
  padding: 0 1.5rem 3rem 1.5rem;
  max-height: calc(100vh - 15rem);
  flex-direction: column;
  overflow-y: auto;
}

.SpvPendingSession-assetTab {
  display: flex;
  max-height: calc(100vh - 15rem);
  flex-direction: column;
  overflow-y: auto;
}

.SpvPendingSession-tabSection {
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.SpvPendingSession-downloadFile {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.SpvPendingSession-pendingApproval {
  cursor: pointer;
  pointer-events: none;
}
