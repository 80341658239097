.ApplicationIcon {
  display: inline-block;
  transition: transform var(--transition-quick-in-out);
  border-radius: 50%;
  background-color: var(--color-background-primary-subtle);
}

.ApplicationIcon-icon {
  height: 100%;
  width: 100%;
  min-width: unset;

  /* Fit square icon in circle. */
  transform: scale(0.71);
}

.ApplicationIcon svg {
  height: 100%;
  width: 100%;
}

.ApplicationIcon--xxSmall {
  height: var(--application-icon-size-xxsmall);
  width: var(--application-icon-size-xxsmall);
  min-width: var(--application-icon-size-xxsmall);
  min-height: unset;
}

.ApplicationIcon--xSmall {
  height: var(--application-icon-size-xsmall);
  width: var(--application-icon-size-xsmall);
  min-width: var(--application-icon-size-xsmall);
  min-height: unset;
}

.ApplicationIcon--small {
  height: var(--application-icon-size-small);
  width: var(--application-icon-size-small);
  min-width: var(--application-icon-size-small);
  min-height: unset;
}

.ApplicationIcon--medium {
  height: var(--application-icon-size-medium);
  width: var(--application-icon-size-medium);
  min-width: var(--application-icon-size-medium);
  min-height: unset;
}

.ApplicationIcon--large {
  height: var(--application-icon-size-large);
  width: var(--application-icon-size-large);
  min-width: var(--application-icon-size-large);
  min-height: unset;
}

.ApplicationIcon--xLarge {
  height: var(--application-icon-size-xlarge);
  width: var(--application-icon-size-xlarge);
  min-width: var(--application-icon-size-xlarge);
  min-height: unset;
}

.ApplicationIcon--xxLarge {
  height: var(--application-icon-size-xxlarge);
  width: var(--application-icon-size-xxlarge);
  min-width: var(--application-icon-size-xxlarge);
  min-height: unset;
}
