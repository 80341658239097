.SpfSession {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.SpfSession-footer {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  width: 100%;
}

.SpfSession-countDownTimerContainer {
  width: 100%;
  justify-content: center;
  display: flex;
  white-space: nowrap;
}

.SpfSession-countDownTimer {
  color: var(--color-foreground-secondary-bold, #00245b);
  text-align: center;
}

.SpfSession-countDownTimer-startsInText,
.SpfSession-countDownTimer-leftText {
  color: var(--color-foreground-secondary-bold, #00245b);
  padding-right: 0.25rem;
}

.SpfSession-expiredTime {
  padding-right: 0.25rem;
}

.SpfSession-credentialedButtons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.SpfSession-credentiallessButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.SpfSession-twoButtons--buttonStyle {
  white-space: nowrap;
}

.SpfSession-twoButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  align-self: stretch;
}
