.Tile {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: var(--border-radius-sm, 0.25rem);
  height: 3.9375rem;
}

/* Levels */
.Tile--0 {
  top: 0;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  background: var(--color-background-neutral-subtlest, #fff);
  border-bottom: 1px solid transparent;
  z-index: var(--z-index-certs-landingpage-sticky-tile-0);
}
.Tile--1 {
  top: 3.9375rem;
  background: var(--color-background-secondary-subtlest, #f7f9fc);
  border-bottom: 0 solid transparent;
  z-index: var(--z-index-certs-landingpage-sticky-tile-1);
}
.Tile--2 {
  top: 7.875rem;
  z-index: var(--z-index-certs-landingpage-sticky-tile-2);
}

/* Shared values in levels */
.Tile--0,
.Tile--1 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.Cards-tree--hasEntChild .Tile--1,
.Tile--2 {
  border-bottom: 1px solid transparent;
}

.Tile-leftHoverSide {
  display: grid;
  min-height: 4rem;
  width: calc(100% - 16rem);
  padding-right: 1rem;
  cursor: pointer;
}

.Tile--isTileSidepanelSelected .Tile-leftHoverSide {
  padding-right: 2rem;
}

/* Left Hover Side Tab States */
.Tile-leftHoverSide[tabindex]:focus-visible {
  border-radius: var(--borderRadius-sm, 4px);
  outline: 3px solid var(--color-border-focus, #6e7fff);
  z-index: var(--z-index-certs-landingpage-tabbed-tile);
}

.Cards-tree--0 .Tile-leftHoverSide[tabindex]:focus-visible {
  background: var(--color-background-neutral-subtlest, #fff);
}

.Cards-tree--1 .Tile-leftHoverSide[tabindex]:focus-visible {
  background: var(--color-background-secondary-subtlest, #f7f9fc);
}

.Cards-tree--2 .Tile-leftHoverSide[tabindex]:focus-visible {
  background: var(--color-background-secondary-subtle, #f2f4f8);
}

/* Left Hover Side level adjustments */
.Tile-leftHoverSide--0 {
  grid-template-columns: 19rem auto 7rem;
}

.Tile-leftHoverSide--1 {
  grid-template-columns: 17rem auto 7rem;
}

.Tile-leftHoverSide--2 {
  grid-template-columns: 15rem auto 7rem;
}

.Card-treeLevelBody--1 .Tile-leftHoverSide--1 {
  position: sticky;
  z-index: var(--z-index-certs-landingpage-sticky-tile-1);
  top: 7.375rem;
}

/* Check Box Visiblity */
.Tile-checkboxHoverDisplay {
  cursor: pointer;
  justify-content: center;
  align-self: normal;
  align-items: center;
  width: 3.5rem;
  padding: 0.5rem;
  display: flex;
  position: relative;
}

.Tile-checkboxHoverDisplay--disabled {
  cursor: not-allowed;
}

.Tile-checkbox {
  display: none;
}

.Tile-checkboxIcon {
  display: block;
}

.Tile-checkboxHoverDisplay:hover .Tile-checkbox {
  display: block;
  height: 1.25rem;
}

.Tile-checkboxHoverDisplay:hover .Tile-checkboxIcon {
  display: none;
}

.Tile-checkbox--isCheckboxVisible {
  display: block;
}

.Tile-checkboxIcon--isCheckboxVisible {
  display: none;
}

.Tile-titleCol .Tile-titleValue,
.Tile-attribute .Tile-attributeValue {
  /* Column text Truncation */
  white-space: nowrap;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Tile-attribute .Tile-attributeValueHeading {
  white-space: nowrap;
}

.Tile-titleCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4rem;
  width: 17.5rem;
  padding: 0.5rem;
}

/* Levels */
.Tile--0 .Tile-titleCol {
  width: 19rem;
}

.Tile--1 .Tile-titleCol {
  width: 17rem;
}

.Tile--2 .Tile-titleCol {
  width: 15rem;
}

.Tile-titleCol .Typography-body3-d {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.Tile-titleCol .Typography-h3-d {
  color: var(--color-foreground-neutral-bold, #212328);
}

.Tile-titleCol .Typography-h3-d.Tile-titleValue--isNoValue {
  color: var(--color-foreground-neutral-subtle, #717385);
}

/* Attribute Columns */
.Tile-attributeCols {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  column-gap: 2.25rem;
  padding-left: 1.75rem;
  height: 4rem;
  width: 100%;
  overflow: hidden;
}

.Tile-attribute {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.125rem;
  height: 4rem;
  min-width: 10rem;
}

.Tile-attribute .Typography-body2-d {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.Tile-trustScore {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 4rem;
  transform: translateX(60%);
}

/* Trust score col display none on sidepanel open */
.Cards--sidebarOpen .Tile-trustScore {
  display: none;
}

.Cards--sidebarOpen .Tile-leftHoverSide {
  width: 100%;
}

.Cards--sidebarOpen .Tile-leftHoverSide--0 {
  grid-template-columns: 19rem auto;
}

.Cards--sidebarOpen .Tile-leftHoverSide--1 {
  grid-template-columns: 17rem auto;
}

.Cards--sidebarOpen .Tile-leftHoverSide--2 {
  grid-template-columns: 15rem auto;
}

.Tile-actionButtonGroup {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 12.5rem;
  transition: right var(--transition-steadystep-in-out);
}

/* Lengths */
.Tile-actionButtonGroup.Tile-actionButtonGroup--isExpanded.Tile-actionButtonGroup--length5 {
  right: 7.25rem;
}

.Tile-actionButtonGroup--length5 .CertsActionButtonsGroup-wrapper--isExpanded {
  width: 19.75rem;
}

.Tile-actionButtonGroup.Tile-actionButtonGroup--isExpanded.Tile-actionButtonGroup--length4 {
  right: 3.625rem;
}

.Tile-actionButtonGroup--length4 .CertsActionButtonsGroup-wrapper--isExpanded {
  width: 16.125rem;
}

/* Overlay */
.Tile-overlay {
  position: absolute;
  z-index: var(--z-index-container-overlay);
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% + 1px);
  background: transparent;
  opacity: 0;
  transition:
    background var(--transition-fast-in-out),
    opacity var(--transition-fast-in-out);
}

.Tile--0 .Tile-overlay {
  height: calc(100% + 2px);
}
.Tile--2 .Tile-overlay {
  top: -1px;
  height: calc(100% + 1px);
}
.Tile--2:hover .Tile-overlay {
  top: 0;
  height: calc(100% + 2px);
}

.Cards-tree--0 .Tile-overlay {
  border-top-left-radius: var(--border-radius-sm, 0.25rem);
  border-top-right-radius: var(--border-radius-sm, 0.25rem);
}
.Cards-tree--0.Cards-tree--hasNoChildren .Tile-overlay {
  border-radius: var(--border-radius-sm, 0.25rem);
}

/* Card level 1 top and bottom radius */
.Cards-tree--1 .Tile-overlay {
  border-top-right-radius: unset;
  height: calc(100% + 2px);
}

.Cards-tree--1:not(.Cards-tree--isTopTile) .Tile-overlay {
  border-top-left-radius: unset;
}

.Cards-tree--1:not(.Cards-tree--isBottomTile) .Tile-overlay {
  border-bottom-left-radius: unset;
}

.Cards-tree--1.Cards-tree--isBottomTile.Cards-tree--isTopTile {
  border-top-left-radius: var(--border-radius-sm, 0.25rem);
  border-bottom-left-radius: var(--border-radius-sm, 0.25rem);
}

/* Card level 2 top and bottom radius */
.Cards-tree--2.Cards-tree--isTopTile .Tile-overlay {
  border-top-left-radius: var(--border-radius-sm, 0.25rem);
}

.Cards-tree--2.Cards-tree--isBottomTile .Tile-overlay {
  border-top-left-radius: unset;
  border-bottom-left-radius: var(--border-radius-sm, 0.25rem);
}

.Cards-tree--2.Cards-tree--isBottomTile.Cards-tree--isTopTile .Tile-overlay {
  border-top-left-radius: var(--border-radius-sm, 0.25rem);
  border-bottom-left-radius: var(--border-radius-sm, 0.25rem);
}

/* Hover */
.Tile-leftHoverSide:hover ~ .Tile-overlay,
.Tile-checkboxHoverDisplay:hover ~ .Tile-overlay {
  opacity: 1;
  /* TODO: Overlay token still needs update */
  background: rgba(37, 75, 134, 0.08);
}

/* Side panel */
.Tile--isTileSidepanelSelected {
  width: calc(100% + 1.3125rem);
  border-bottom: 1px solid transparent;
  box-shadow: var(
    --box-shadow-pretoken-container-interaction-for-broken-certs-tile
  );
}

.Tile--0.Tile--isTileSidepanelSelected {
  border-right: unset;
}

/* Removing the cursor hover and overlay effect on selected Tile */
.Tile.Tile--isTileSidepanelSelected .Tile-leftHoverSide {
  cursor: default;
  padding-right: 2.75rem;
}

.Tile--isTileSidepanelSelected .Tile-leftHoverSide:hover ~ .Tile-overlay {
  opacity: 0;
  background: transparent;
}

/* Sticky Tile functionality */
.Tile.Tile--0.Tile--isStuck,
.Tile.Tile--1.Tile--isStuck {
  box-shadow: var(--box-shadow-pretoken-container-interaction);
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  border-radius: unset;
}

.Tile.Tile--0.Tile--isStuck {
  background: var(--color-background-neutral-subtlest, #fff);
  z-index: var(--z-index-certs-landingpage-action-floating-ui--0);
}
.Tile.Tile--1.Tile--isStuck {
  background: var(--color-background-secondary-subtlest, #f7f9fc);
  z-index: var(--z-index-certs-landingpage-action-floating-ui--1);
}

.Cards-tree--hasNoChildren .Tile--0.Tile--isStuck,
.Cards-tree--hasNoChildren .Tile--1.Tile--isStuck {
  z-index: initial;
  box-shadow: initial;
  border-bottom: initial;
}
.Cards-tree--hasNoChildren .Tile--isStuck.Tile--isActionPopoverOpen {
  z-index: var(--z-index-certs-landingpage-action-floating-ui-popover-open);
}

/* Sticky selected positions */
.Tile--0.Tile--isSidePanelVisible {
  z-index: var(--z-index-certs-landingpage-sticky-tile-0-isselected);
}

.Tile--1.Tile--isSidePanelVisible {
  z-index: var(--z-index-certs-landingpage-sticky-tile-1-isselected);
}

.Tile--2.Tile--isSidePanelVisible {
  z-index: var(--z-index-certs-landingpage-sticky-tile-2-isselected);
}

/* Double Tile to override the Cards border radius */
.Tile.Tile.Tile--isTileSidepanelSelected {
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 0.25rem) 0 0
    var(--border-radius-sm, 0.25rem);
}

/* If CertsActionButton tooltip or popover is open increase the z-index of the selected action */
.Tile:hover,
.Tile--isSidePanelVisible,
.Tile--isSidePanelVisible.Tile--isActionPopoverOpen {
  z-index: var(--z-index-certs-landingpage-action-floating-ui);
}
.Tile--isActionPopoverOpen {
  z-index: var(--z-index-certs-landingpage-action-floating-ui-popover-open);
}

.Tile.Tile--isTileSidepanelSelected {
  height: 3.9375rem;
}
.Cards-tree--isTopTile .Tile--isTileSidepanelSelected.Tile--2 {
  height: 3.875rem;
}

@media (max-width: 880px) {
  .CertificationDetails--isSidePanelVisible .Tile-attributeCols {
    display: none;
  }
  .CertificationDetails--isSidePanelVisible
    .Cards--sidebarOpen
    .Tile-leftHoverSide {
    grid-template-columns: 1fr;
  }
  .CertificationDetails--isSidePanelVisible .Cards--sidebarOpen .Tile-titleCol {
    width: 20rem;
  }
}

@media (max-width: 833px) {
  .Tile.Tile--isTileSidepanelSelected {
    width: 100%;
  }
  .Tile.Tile--isTileSidepanelSelected .CertsActionButtonsGroup-wrapper {
    background: var(--color-background-neutral-subtlest, #fff);
  }

  .Tile.Tile--isTileSidepanelSelected .Tile-leftHoverSide {
    padding-right: unset;
  }
}
