.PopoverContent {
  overflow-y: scroll;
}

.PopoverContent::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.PopoverContent-progressInfoTitle {
  padding: 1rem 0;
  color: var(--color-foreground-neutral-bold, #212328);
  display: flex;
  justify-content: flex-start;
}

.PopoverContent-progressBreakdown {
  display: flex;
  padding: 0.5rem 0;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.125rem;
}

.PopoverContent-progressBreakdown .PopoverContent-progressBreakdownCount {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.PopoverContent-progressBreakdown .PopoverContent-progressBreakdownPercentage {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.PopoverContent-progressBreakdown .PopoverContent-progressBreakdownStatus {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.PopoverContent-tabs {
  padding: 1rem 0;
}

.PopoverContent-tabs .TabContent {
  display: flex;
  padding: 0.25rem 0;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  flex-direction: column;
}

.PopoverContent-tabs .TabGroup {
  padding-bottom: 0.5rem 0;
  display: flex;
}

.PopoverContent-tabs .TabGroup .TabItem:first-of-type {
  border-radius: 0.25rem 0 0 0.25rem;
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  background: var(--color-background-neutral-subtlest, #fff);
}

.PopoverContent-tabs .TabGroup .TabItem:last-of-type {
  border-radius: 0 0.25rem 0.25rem 0;
  border-top: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  border-right: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  background: var(--color-background-neutral-subtlest, #fff);
}

.PopoverContent-tabs .TabGroup .TabItem--isActive:first-of-type {
  border-radius: 0.25rem 0 0 0.25rem;
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-border-secondary-subtlest, #cfd9e9);
}

.PopoverContent-tabs .TabGroup .TabItem--isActive:last-of-type {
  border-radius: 0 0.25rem 0.25rem 0;
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-border-secondary-subtlest, #cfd9e9);
}

.PopoverContent-tabs .TabGroup .TabItem {
  border-top: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  border-right: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  background: var(--color-background-neutral-subtlest, #fff);
}

.PopoverContent-tabs .TabGroup .TabItem--isActive {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-border-secondary-subtlest, #cfd9e9);
}

.PopoverContent-tabs .TabGroup .TabItem:hover {
  background: var(--color-border-secondary-subtlest, #cfd9e9);
  border: 1px solid var(--color-border-secondary-medium, #254b86);
}

.PopoverContent::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}
