.icon {
  display: inline-block;

  svg {
    display: table;
    vertical-align: middle;
    line-height: 1;
  }
}
.disabled {
  cursor: not-allowed !important;
}
.acceptBg {
  background-color: var(--primary-bg-light);
}
.selectIcon {
  &:hover {
    fill: #00a472;
    cursor: pointer;
  }
}
.selectIconAccept {
  &:hover {
    fill: #ffffff;
    cursor: pointer;
  }
}
.selectedAccept {
  margin-right: 12px !important;
  border-radius: 100%;
  fill: #ffffff;
  background-color: var(--success-green);
  .selectIconAccept {
    fill: #ffffff;
  }
}
.selectIconBg {
  border-radius: 100%;
  background-color: var(--secondaryBg);
  margin-right: 12px !important;
  &:hover {
    fill: #ffffff;
    background-color: var(--success-green);
    cursor: pointer;
    .selectIconAccept {
      fill: #ffffff;
      cursor: pointer;
    }
  }
}
.selectedReject {
  margin-right: 12px !important;
  border-radius: 100%;
  fill: #ffffff;
  background-color: #ff3737;
  .selectIconAccept {
    fill: #ffffff;
  }
}
.selectRejectIconBg {
  border-radius: 100%;
  background-color: var(--secondaryBg);
  margin-right: 12px !important;
  &:hover {
    fill: #ffffff;
    background-color: #ff3737;
    cursor: pointer;
    .selectIconAccept {
      fill: #ffffff;
      cursor: pointer;
    }
  }
}
.rejectIcon {
  fill: var(--primary-bg);
  margin-top: 5px;
  &:hover {
    .rejectCirle {
      fill: #ff3737;
      cursor: pointer;
    }
  }
}
.removeIcon {
  fill: var(--primary-bg);
  margin-top: 5px;
  &:hover {
    .rejectCirle {
      fill: var(--primary-main);
      cursor: pointer;
    }
  }
}
.largeComments {
  background-color: #e3e7ff;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 6px;
}
.smallComments {
  background-color: #e3e7ff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 3px;
}

.IconPosition {
  margin: 6px 0 0 6px;
}

.dropDownIcon {
  margin-left: 1px;
}
.requestEntcal {
  display: inline !important;
}
.removeFilled {
  fill: #f2f4ff;
  margin-top: 5px;
  position: relative;
  top: 10px;
  &:hover {
    cursor: pointer;
    .rejectCirle {
      fill: var(--primary-main);
      cursor: pointer;
    }
    .rejectCirlePath {
      stroke: var(--white);
    }
  }
}
