.Logo {
  display: inline-block;
}

.Logo svg {
  width: 100%;
  height: 100%;
}

.Logo--click {
  cursor: pointer;
}

.Logo--click:focus {
  outline: 1px dotted;
}
