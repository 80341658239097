.apps-recommendation-section {
  background: #cfd9e9;
  .recommendation-title {
    display: flex;
    margin-bottom: 27px;
    padding: 48px 48px 0;
    .apps-recommendation-title {
      color: #212328;
      font-size: 19px;
      font-weight: 500;
      line-height: 133.4%;
      margin-right: 16px;
      margin-bottom: 0;
    }
    .apps-recommendation-description {
      color: #212328;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .recommendation-pagination-view {
    display: flex;
    flex-direction: column;
    .recommendation-apps {
      display: flex;
      width: 100%;
      justify-content: center;
      justify-content: flex-start;
      margin-left: 74px;
    }
    .recommended-card {
      width: 280px;
      margin-right: 12px;
    }
    .pagination-section {
      margin-bottom: 24px;
    }
    .MuiPagination-root {
      margin-bottom: 48px;
      display: flex;
      justify-content: center;
      li {
        margin-right: 8px;
      }
    }
    .MuiPaginationItem-page {
      background-color: #fff;
      width: 8px;
      height: 8px;
      min-width: 8px;
      font-size: 0px;
    }
    .MuiPaginationItem-textPrimary.Mui-selected {
      background-color: #3751ff;
    }
    .prev-button {
      position: absolute;
      left: 10px;
    }
    .next-button {
      position: absolute;
      right: 10px;
    }
  }
  .recommendation-swiper-view {
    display: none;
  }
  .recommendation-cards-section {
    .slide-button {
      min-width: 48px;
      height: 176px;
      border-radius: 50px;
      background: #f2f4f8;
      z-index: 20;
      padding: 0;
      &:hover {
        background: #f7f9fc;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
        cursor: pointer;
      }
    }

    .recommended-card {
      border-radius: 24px;
      background: #f2f4f8;
      cursor: pointer;
      padding: 24px 24px 8px 24px;
      &:hover {
        background: #fff;
        border-radius: 24px;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
      }
      .appSection-with-description {
        padding-bottom: 20px;
      }
      .appSection-without-description {
        padding-bottom: 43px;
      }
      .app-section {
        display: flex;
        .app-icon {
          img {
            width: 48px;
            height: 48px;
            object-fit: contain;
            border-radius: 100%;
          }
        }
        .app-content {
          margin-left: 20px;
          .app-title {
            color: #212328;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 4px;
          }
          .app-description {
            color: #4a4c59;
            height: 43px;
            width: 150px;
            overflow: hidden;
            position: relative;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
          }
        }
        .app-without-description {
          display: flex;
          align-items: center;
        }
      }
      .app-button {
        display: flex;
        justify-content: center;
        border-top: 1px solid #d1d7ff;
        button {
          color: #3751ff;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin: 10px 0;
          &:hover {
            background-color: #fff;
          }
        }
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .apps-recommendation-section {
    .recommendation-cards-section.recommendation-pagination-view {
      .recommended-card {
        padding: 18px 12px;
      }
      .app-section {
        .app-content {
          margin-left: 10px;
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .apps-recommendation-section {
    .recommendation-title {
      flex-direction: column;
      align-items: center;
      .apps-recommendation-title {
        margin-bottom: 5px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .apps-recommendation-section {
    .recommendation-title {
      .apps-recommendation-description {
        font-size: 15px;
        text-align: center;
        line-height: 150%;
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .apps-recommendation-section {
    .recommendation-title {
      .apps-recommendation-description {
        font-size: 14px;
      }
    }
    .recommendation-pagination-view {
      .recommendation-apps {
        // justify-content: center;
        // margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .apps-recommendation-section {
    .recommendation-title {
      .apps-recommendation-description {
        font-size: 13px;
      }
    }
    .recommendation-pagination-view {
      display: none;
    }
    .recommendation-swiper-view {
      display: flex;
      padding-bottom: 28px;
      padding-left: 24px;
      .swiper {
        width: 100%;
        height: 100%;
      }
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .swiper-slide {
        width: 240px;
        margin-right: 12px !important;
      }
      .recommended-card {
        width: 240px;
        padding: 20px 18px 0 18px;
      }
    }
    .recommendation-cards-section {
      .recommended-card {
        .appSection-with-description {
          padding-bottom: 8px;
        }
        .appSection-without-description {
          padding-bottom: 31px;
        }
        .app-section {
          .app-content {
            .app-description {
              width: 137px;
            }
          }
        }
      }
    }
  }
}
