.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--light-brand-secondary-overlay, rgba(0, 8, 64, 0.5));
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
    transition: opacity var(--transition-quick-in-out);
    opacity: 0;
    pointer-events: none;
  }
  
  .Overlay--visible {
    opacity: 1;
    pointer-events: auto;
  }


