/* Comment Section */
.CertsCommentInputField-commentSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
/* Label Asterisk */
.CertsCommentInputField-Asterisk--isRequired {
  position: relative;
  top: 0.3125rem;
  right: 0.3125rem;
}
.CertsCommentInputField-Asterisk--isCritical,
.CertsCommentInputField-Asterisk--isWarning {
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  font: var(--typography-heading-3);
}
.CertsCommentInputField-Asterisk--isWarning {
  color: var(--color-foreground-warning-medium);
}

.CertsCommentInputField-Asterisk--isCritical {
  color: var(--color-foreground-critical-medium);
}
.CertsCommentInputField-label {
}

.CertsCommentInputField-chips {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;

  align-self: stretch;
  flex-wrap: wrap;
}
/* Truncation to the letter */
.CertsCommentInputField-chips .Chip-label {
  overflow: hidden;
  white-space: nowrap;
  width: min-content;
  max-width: 31.25rem;
  text-overflow: ellipsis;
}
