/* TODO: @Jacob, speak with a PAM dev to update so we can remove this: :not(.SidePanel--isResponsivenessDisabled) */
.ModalSidePanel-background,
.ModalSidePanel-moveTileBtnGroup {
  display: none;
  pointer-events: none;
}

@media (max-width: 833px) {
  .ModalSidePanel--isOpen--bodyScrollLock:not(
      .SidePanel--isResponsivenessDisabled
    ) {
    padding-right: var(--scrollbar-width-saviynt-ds);
    overflow: hidden;
  }

  .ModalSidePanel-background:not(.SidePanel--isResponsivenessDisabled) {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--light-brand-secondary-overlay, rgba(0, 8, 64, 0.5));
    overflow: hidden;
    transition: opacity var(--transition-quick-in-out);
    opacity: 0;
    pointer-events: none;
    z-index: var(--z-index-modal-island);
  }

  .SidePanel.ModalSidePanel:not(.SidePanel--isResponsivenessDisabled) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: calc(100% - 6rem);
    height: calc(100vh - 4rem);
    max-width: 28.5rem;
    border-radius: var(--border-radius-sm);
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--transition-quick-in-out);
    z-index: var(--z-index-modal-island);
  }

  .ModalSidePanel-moveTileBtnGroup:not(.SidePanel--isResponsivenessDisabled) {
    position: absolute;
    top: 50%;
    right: -3.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    pointer-events: all;
    z-index: var(--z-index-modal-island);
    transform: translate(0, -50%);
  }

  .ModalSidePanel .SidePanel-header:not(.SidePanel--isResponsivenessDisabled) {
    max-width: 28.5rem;
    border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
  }
  .ModalSidePanel
    .SidePanel-headerText:not(.SidePanel--isResponsivenessDisabled) {
    word-break: break-all;
  }

  .SidePanel-innerContent:not(.SidePanel--isResponsivenessDisabled) {
    display: grid;
    grid-template-rows: min-content auto 4rem;
    width: 100%;
  }

  .ModalSidePanel:not(.SidePanel--isResponsivenessDisabled)
    .SidePanelView
    .TabContent {
    height: 100%;
    /* Commented out size is for footer action bar size !== 'large' */
    /* max-height: calc(100vh - 13.75rem); */
    /* Footer action bar size === 'large' */
    max-height: calc(100vh - 16rem);
    position: unset;
  }

  .ModalSidePanel:not(.SidePanel--isResponsivenessDisabled) .FooterActionBar {
    overflow: hidden;
    border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
  }
  .ModalSidePanel:not(.SidePanel--isResponsivenessDisabled)
    .FooterActionBar--large {
    height: 4.0625rem;
  }
}
