.homepage-container {
  overflow-x: hidden;
  width: 100%;
  background-color: #f1f3fb;

  .homepage-kpis,
  .homepage-recentAssignments {
    margin: 2.5rem 2.0625rem 3rem 3rem;
  }
}
