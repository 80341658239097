.DatabaseJitModal {
  .ModalIsland-section {
    width: 100%;
  }
  .ModalIsland-header {
    align-items: center;
    height: 4.5rem;
  }
  .ModalIsland-titleContainer {
    justify-content: center;
  }
  .ModalIsland-title {
    font: var(--typography-heading-2);
    color: var(--color-neutral-1000, #212328);
  }
  .ModalIsland-subtitle {
    font: var(--typography-body-1);
    color: var(--color-neutral-600, #717385);
  }
}

#ModalIsland.ModalIsland.DatabaseJitModal {
  width: calc(100% - 3rem);
}

#ModalIsland.ModalIsland.DatabaseJitModal
  .ModalIsland-section
  .ModalIsland-body {
  max-width: 75rem;
  height: 35rem;
  max-height: calc(100vh - 10.5rem);
}

.DatabaseJitModal .ModalIsland-titleAndHeaderIcon .Icon {
  align-self: flex-start;
}

.DatabaseJitModal-accountDetailsWrapper {
  display: flex;
  gap: 1.5rem;
  padding: 1.5rem;
}

.DatabaseJitModal-accountNameWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 14.625rem;
}
.DatabaseJitModal-accountNameValue {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.DatabaseJitModal-accountDescWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100vw;
}
.DatabaseJitModal-accountDesc {
  color: var(--color-foreground-neutral-bold, #212328);
}
.DatabaseJitModal-accountDescValue {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.DatabaseJitModal-entitlementsHeaderWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 0.75rem;
}

.DatabaseJitModal-checkboxes {
  .CheckboxGroup {
    display: grid; /* TODO: maybe use the grid component in CheckboxGroup later */
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

.DatabaseJitModal-checkboxes .Checkbox-container {
  .Checkbox-label {
    justify-content: space-between;
  }
  .Checkbox-labelText {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    height: 2.5rem;
  }
}

.DatabaseJitModal-footerActionBar {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  width: 100%;
}
