.CertsFooterActionBar {
  grid-column: 1 / -1;
  grid-row: 4;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4rem;
  background: var(--color-background-neutral-subtlest, #fff);
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.12);
  z-index: var(--z-index-certs-landingpage-footer);
}
.CertsFooterActionBar--isProgressBarOpen {
  z-index: var(--z-index-certs-landingpage-footer-progress-open);
}

.CertsFooterActionBar--isSidePanelVisible {
  grid-column: 1 / 9;
  width: calc(100% + 1px);
}

.CertsFooterActionBar-phases {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
  padding-inline: 3rem;
  display: flex;
  align-items: center;
  transition: var(--transition-steady-ease);
}

.CertsFooterActionBar-phases1--isSidePanelVisible {
  transform: translateY(100%);
}

.CertsFooterActionBar-phases2--isSidePanelVisible {
  transform: translateY(-100%);
  background: #0e153b;
}

/* Placholder styles only remove on updated bar */
.CertsFooterActionBar-progressBar {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  width: 50%;
  align-items: center;
  gap: 0.5rem;
  border-radius: var(--borderRadius-sm, 0.25rem);
  transition: background-color var(--transition-quick-in-out);
}

/* Clickable overlay */
.CertsFooterActionBar-togglePopover {
  position: absolute;
  width: 100%;
  height: 100%;
}

.CertsFooterActionBar-progressBar.CertsFooterActionBar-progressBar--isHovered {
  background: var(--color-background-secondary-subtlest, #f7f9fc);
}

.CertsFooterActionBar-progress-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.CertsFooterActionBar-progressPercentage {
  color: var(--color-foreground-neutral-bold, #212328);
}

.CertsFooterActionBar-progressErrorMessage {
  color: var(--color-critical-600, #e5362f);
  margin-right: 0.25rem;
  text-align: center;
}

.CertsFooterActionBar-progressFailure {
  display: flex;
  align-items: center;
}

.CertsFooterActionBar-linearProgess {
  width: 100%;
}

.CertsFooterActionBar.CertsFooterActionBar--isBulkAction {
  height: auto;
}

.CertsFooterActionBar--isBulkAction
  .CertsFooterActionBar-phases1--isSidePanelVisible {
  display: none;
}

.CertsFooterActionBar--isBulkAction
  .CertsFooterActionBar-phases2--isSidePanelVisible {
  position: absolute;
}

/* Right side */
.CertsFooterActionBar-progressMessage {
  position: absolute;
  padding: 0.125rem;
  right: 14.25rem;
  width: max-content;
  top: 50%;
  transform: translate(0, -50%);
  color: var(--color-foreground-neutral-medium, #4a4c59);
  transition:
    opacity var(--transition-steady-ease),
    top var(--transition-deliberatestep-ease);
  pointer-events: none;
}

/* Basic Animation for message */
.CertsFooterActionBar-progressMessage--isVisible {
  top: 50%;
  opacity: 1;
  background: var(--color-background-neutral-subtlest, #fff);
}

.CertsFooterActionBar-progressMessage--isHidden {
  top: 3.25rem;
  opacity: 0;
}

.BulkActionFooter-controlsWrapper {
  display: flex;
  flex-direction: row;
}

.CertsFooterActionBar--isBulkAction .CertsFooterActionBar-phases {
  padding: 0.75rem 1.5rem;
}

/* Resonsive */

@media (max-width: 1279px) {
  .CertsFooterActionBar-phases {
    padding-inline: 1.5rem;
  }

  .CertsFooterActionBar-progressBar {
    padding: 0.5rem 1rem;
  }
  .LinearProgressBar {
    min-width: unset;
  }

  .CertsFooterActionBar--isSidePanelVisible.CertsFooterActionBar--isBulkAction {
    height: auto;
  }
  .CertsFooterActionBar--isBulkAction .CertsFooterActionBar-phases {
    padding: 0.75rem 1.5rem;
  }

  .CertsFooterActionBar--isSidePanelVisible.CertsFooterActionBar--isBulkAction
    .BulkActionFooter-wrapper {
    flex-wrap: unset;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .CertsFooterActionBar--isSidePanelVisible.CertsFooterActionBar--isBulkAction
    .BulkActionFooter-wrapper
    .BulkActionFooter-infoBox {
    width: 100%;
  }
  .CertsFooterActionBar--isSidePanelVisible.CertsFooterActionBar--isBulkAction
    .BulkActionFooter-wrapper
    .BulkActionFooter-btnBox {
    justify-content: space-between;
    width: 100%;
  }
  .CertsFooterActionBar--isSidePanelVisible
    .CertsFooterActionBar-progressMessage {
    right: 12.5rem;
  }
  .CertsFooterActionBar--isSidePanelVisible
    .CertsFooterActionBar-progressMessage--isVisible {
    right: 12.5rem;
  }

  .CertsFooterActionBar.CertsFooterActionBar--isBulkAction {
    height: auto;
  }

  .CertsFooterActionBar--isBulkAction .CertsFooterActionBar-phases {
    padding: 0.75rem 1.5rem;
  }

  .CertsFooterActionBar--isBulkAction .BulkActionFooter-wrapper {
    flex-wrap: unset;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .CertsFooterActionBar--isBulkAction
    .BulkActionFooter-wrapper
    .BulkActionFooter-infoBox {
    width: 100%;
  }

  .CertsFooterActionBar--isBulkAction
    .BulkActionFooter-wrapper
    .BulkActionFooter-btnBox {
    justify-content: space-between;
    width: 100%;
  }
}

/* START - Can be removed post any progress message design changes */
@media (max-width: 1023px) {
  .CertsFooterActionBar-progressMessage {
    right: 12.25rem;
  }

  .CertsFooterActionBar--isSidePanelVisible {
    grid-column: 1 / 8;
  }
}
/* END - Can be removed post any progress message design changes*/

/* CSS rules for screens between 476px and 599px */
@media (max-width: 599px) {
  .CertsFooterActionBar-phases {
    padding: 1rem;
  }
  .CertsFooterActionBar-backBtn,
  .CertsFooterActionBar-linearProgess {
    display: none;
  }

  .CertsFooterActionBar-progressBar {
    width: auto;
    padding: 0.5rem;
  }

  .CertsFooterActionBar-progressMessage {
    right: 40%;
  }

  .CertsFooterActionBar-progress-content {
    justify-content: flex-start;
  }

  .PopoverProgressBreakdown {
    width: 93vw;
    margin-left: -0.5rem;
    height: 75vh;
    max-height: 75vh;
  }
}

/* CSS rules for screens 476px and below */
@media (max-width: 475px) {
  .CertsFooterActionBar-phases {
    padding: 1rem;
  }

  .CertsFooterActionBar-backBtn,
  .CertsFooterActionBar-linearProgess {
    display: none;
  }

  .CertsFooterActionBar-progressBar {
    width: auto;
    padding: 0.5rem;
  }

  .CertsFooterActionBar-progressMessage {
    right: 18%;
  }

  .CertsFooterActionBar-progressMessage-showLabel {
    right: 40%;
  }

  .CertsFooterActionBar-progress-content {
    justify-content: flex-start;
  }

  .PopoverProgressBreakdown {
    width: 93vw;
    margin-left: -0.5rem;
    height: 75vh;
    max-height: 75vh;
  }
}
