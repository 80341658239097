/* ==========================================================================
   Base styles
   ========================================================================== */

*,
*::before,
*::after {
    box-sizing: border-box;
}


html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* Adjust font size */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

html,
body {
    height: 100%;
}

body {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #1e1e1e;
    text-align: left;
    background-color: #f5f7fa;
}

a {
    color: #0062cc;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}

a:hover,
a:focus {
    color: #0056b3;
    text-decoration: none;
    transition: all 0.2s;
}



.login {
    min-height: 100vh;
}



.login-heading {
    font-weight: 500;
    font-size: 36px;
    color: #0062cc;
}


.saviynt-login-logo,
.ibmaccesshub-logo {
    width: 100%;
    padding-bottom: 30px;
}

.btn-login {
    font-size: 12px;
    letter-spacing: 0.05rem;
    padding: 13px 37px;
    border-radius: 3px;
    background-color: #0062cc !important;
    border-color: #0062cc !important;
}

.btn-login:hover,
.btn-login:focus {
    background-color: #0062cc !important;
    border-color: #0062cc!important;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
    border-radius: 3px;
    border: solid 1px  #DFE0EB;
    height: 46px;
    display: flex;
}

.form-label-group>input,
.form-label-group>label {
    /* padding: var(--input-padding-y) var(--input-padding-x); */
    height: auto;
    border-radius: 3px;
    font-size: 14px;
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    cursor: text;
    /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
}

.form-label-group input:focus::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input:focus:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input:focus::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input:focus::-moz-placeholder {
    color: transparent;
}

.form-label-group input:focus::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
}

.loginarea {
    background-color: #ffffff;
}

/* .py-5 {
       padding-top: 100px !important;
   }
   
   .mb-4,
   .my-4 {
       margin-bottom: 100px !important;
   } */

.icon-placeholder {
    padding: 15px;
    line-height: 0;
    border-right: 1px solid #DFE0EB;
    color: #DFE0EB;
}

.login {
    padding-top: 100px !important;
}

.login-heading {
    margin-bottom: 100px !important;
}

.login .form-label-group .form-control {
    border: none;
}

.login .form-label-group .form-control:focus {
    box-shadow: none;
}

.form-control::placeholder {
    color: #a5abb4;
    font-size: 12px;
}

.login-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.forgot_link a {
    color: #a5abb4;
    margin: 16px 0 0 7px;
    display: inline-block;
    font-size: 12px;
}

.slide-item {
    flex-direction: column;
    padding: 72px 0 0 82px;
    position: relative;
    height: 485px;
}

.taglines {
    position: absolute;
    right: 70px;
    bottom: 70px;
}

.taglines .title {
    font-size: 30px;
    margin-bottom: 15px;
    color: #ffffff;
}

.taglines .subtitle {
    color: #00aeef;
}

.bg-image {
    background-color: #0f2446;
}

.btn-okta {
    line-height: 37px;
    box-shadow: 0 1px 4px 0 rgba(180, 180, 180, 1);
    padding: 5px 15px;
}