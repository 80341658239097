.MenuMultiFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  width: 100%;
  border-radius: 0 0 var(--border-radius-sm, 4px) var(--border-radius-sm, 4px);
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  background: var(--color-background-neutral-subtlest, #fff);
}
