.Global404Certs-pageNotFound {
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1021;
  height: 100%;
  position: fixed;
  background-color: var(--page-bg);
  gap: 5rem;
}

.Global404Certs-pageNotFound > div {
  margin-top: 3%;
}

.Global404Certs-pageNotFound .Global404Certs-logo {
  color: var(--primary-main);
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 500;
  padding-left: 1.563rem;
  padding-top: 1.25rem;
  margin-top: 0%;
}

.Global404Certs-pageNotFound .Global404Certs-text-header {
  font-weight: 500;
  font-size: 7.75rem;
  color: var(--color-navy-900, #00245b);
  text-align: center;
}

.Global404Certs-pageNotFound .Global404Certs-error-body {
  font-size: 1.875rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
  color: var(--color-navy-900, #00245b);
  text-align: center;
}

.Global404Certs-pageNotFound .Global404Certs-error-details {
  width: 100%;
  font-size: 1.125rem;
  text-align: center;
  color: var(--color-neutral-1000, #212328);
  margin-bottom: 3.938rem;
}

.Global404Certs-pageNotFound .Global404Certs-btn-back-container {
  display: flex;
  justify-content: center;
}

.Global404Certs-pageNotFound .Global404Certs-btn-back {
  width: 25%;
  border-radius: 0.188rem;
  border: solid 1px var(--color-indigo-800, #2035cb);
  background-color: var(--color-indigo-800, #2035cb);
  font-family: Rubik;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-neutral-000, #ffffff);
  padding: 0.375rem 0.75rem;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}

.Global404Certs-pageNotFound .Global404Certs-error-illustration {
  right: 7.063rem;
  display: block;
}

@media (max-width: 62rem) {
  .Global404Certs-error-illustration {
    display: none;
  }
}

.Global404Certs-pageNotFound .Global404Certs-error-content {
  display: flex;
  flex-direction: column;
}
