.flexContainer {
    display: flex;
    width: 100%;
}

@media all and (min-width: var(--mobile-landscape)) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-auto-columns: calc(100% / 4);
        grid-template-rows: auto;
        column-gap: 16px;
    }
}

@media all and (min-width: var(--tablet-portrait)) {
    .gridContainer {
        grid-template-columns: repeat(8, minmax(0, 1fr));
        grid-auto-columns: calc(100% / 8);
    }
}

@media all and (min-width: var(--desktop-portrait)) {
    .gridContainer {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-auto-columns: calc(100% / 12);
        column-gap: 24px;
    }
}
