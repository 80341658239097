/* TODO: Reduce specificity if we ever fix the style build order */
/* DatePicker (AKA Input) */
.DatePicker-wrapper.DatePicker-wrapper {
  width: calc(100% - 2.3125rem);
}

.DatePicker-calendar.DatePicker-calendar {
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  border: none;
  border-radius: var(--border-width-xlg);
  font: var(--typography-body-2);
}

.DatePicker-input.DatePicker-input {
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
  color: var(--color-foreground-secondary-bold, #00245b);
  width: 100%;
  text-overflow: ellipsis;
  transition: border var(--transition-fast-in-out);
}

.DatePicker-input--large.DatePicker-input--large {
  padding: 0.875rem 2.25rem 0.875rem 0.5rem;
  font: var(--typography-body-2);
  height: 3rem;
}

.DatePicker-input--secondary.DatePicker-input--secondary {
  background: var(--color-background-secondary-subtlest, #f7f9fc);
}

/* Outline logic for all cases */
.DatePicker-input.DatePicker-input.DatePicker-input-noOutline:focus,
.DateAndTimeInputField-suffixButton.DateAndTimeInputField-suffixButton:focus {
  outline: none;
}
.DatePicker-input.DatePicker-input:focus {
  outline: 2px solid var(--color-border-focus, #6e7fff);
}

.DatePicker-input.DatePicker-input:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

/* State related */
.DatePicker-input--isCritical.DatePicker-input--isCritical,
.DatePicker-input--isCritical.DatePicker-input--isCritical:focus,
.DatePicker-input--isCritical.DatePicker-input--isCritical:focus:hover,
.DatePicker-input--isCritical.DatePicker-input--isCritical:hover {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.DatePicker-input--isDisabled.DatePicker-input--isDisabled {
  pointer-events: none;
  border: 1px solid transparent;
  color: var(--color-foreground-neutral-subtle, #717385);
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
}

.DateAndTimeInputField-inputContainer--isReadOnly.DateAndTimeInputField-inputContainer--isReadOnly {
  height: 1.5rem;
}
.DateAndTimeInputField-inputContainer--isReadOnly.DateAndTimeInputField-inputContainer--isReadOnly
  .DateAndTimeInputField-suffixButton {
  display: none;
}
.DatePicker-input--isReadOnly.DatePicker-input--isReadOnly {
  height: 1.5rem;
  padding: 0;
  border: 1px solid transparent;
  pointer-events: none;
}
/* Close button */
.DatePicker-input--isReadOnly.DatePicker-input--isReadOnly
  + .react-datepicker__close-icon.react-datepicker__close-icon,
.DatePicker-input--isDisabled.DatePicker-input--isDisabled
  + .react-datepicker__close-icon.react-datepicker__close-icon {
  display: none;
}
