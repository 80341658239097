:root {
  /* NEO - Design System Library */
  --z-index-modal-island: 1202;
  --z-index-floating-banner: 1120;
  --z-index-sidepanel: 2;
  --z-index-tabgroup-sticky-last-child: 2;
  --z-index-tabgroup-left-right-pointers: 1;
  --z-index-tabitem-over-width-border: 1;
  --z-index-react-date-picker-popper: 1010;
  --z-index-popover: 1000;
  --z-index-tooltip: 1000;
  --z-index-page-header: 999;
  --z-index-accordion-button: 4;
  --z-index-container-overlay: -1;

  /* CPAM Landing Page */
  --z-index-alertbanners: 11;
  --z-index-dropdown: 10;
  --z-index-landingpage-listrows: 3;
  --z-index-landingpage-sidepanel: 2;

  /* Certs Landing Page */
  --z-index-certs-landingpage-dropdown: 60;
  --z-index-certs-landingpage-filters: 50;
  --z-index-certs-landingpage-footer-progress-open: 50;
  --z-index-certs-landingpage-more-filters: 49;
  --z-index-certs-landingpage-advanced-filters--active: 49;
  --z-index-certs-landingpage-advanced-filters: 48;
  --z-index-certs-landingpage-banner: 42;
  --z-index-certs-landingpage-header: 41;
  --z-index-certs-landingpage-footer: 40;
  --z-index-certs-landingpage-action-floating-ui-popover-open: 39;
  --z-index-certs-landingpage-action-floating-ui--0: 38;
  --z-index-certs-landingpage-action-floating-ui--1: 37;
  --z-index-certs-landingpage-action-floating-ui: 36;
  --z-index-certs-landingpage-sticky-tile-0-isselected: 30;
  --z-index-certs-landingpage-sticky-tile-1-isselected: 29;
  --z-index-certs-landingpage-sticky-tile-2-isselected: 28;
  --z-index-certs-landingpage-sidepanel: 20;
  --z-index-certs-landingpage-sticky-tile-0: 2;
  --z-index-certs-landingpage-sticky-tile-1: 1;
  --z-index-certs-landingpage-tabbed-tile: 1;
  --z-index-certs-landingpage-searchbar-overlay: 1;
  --z-index-certs-landingpage-sidepanel-footer: 1;
  --z-index-certs-landingpage-sticky-tile-2: 0;

  --z-index-certs-landingpage-disabled: -100;

  /* Common */
  --z-index-root-level: 0;
  --z-index-disabled: -100;
}
