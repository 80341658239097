.FooterActionBar {
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  background: var(--color-background-neutral-subtlest, #ffffff);
  width: 100%;
}

.FooterActionBar--alignRight {
  justify-content: flex-end;
}

.FooterActionBar--isScrolled {
  box-shadow: var(--box-shadow-pretoken-overlay-top);
  border-top: 1px solid transparent;
}

.FooterActionBar--large {
  height: 4rem;
}

.FooterActionBar--small {
  height: 3rem;
}
