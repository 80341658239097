.TooltipComponent {
  background: var(--color-background-neutral-subtlest, #0c0d10);
  min-height: 2rem;
  width: max-content;
  padding: 0.5rem 0.75rem;
  border-radius: var(--border-radius-sm, 0.25rem);
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  z-index: var(--z-index-tooltip);
}
.TooltipComponent-text {
  color: var(--color-foreground-neutral-bold, #fff);
  margin: 0;
}

/* Empty text */
.TooltipComponent.TooltipComponent--isTextEmpty {
  visibility: hidden;
  opacity: 0;
}

/* Empty text */
.TooltipComponent--isWordBreak {
  word-break: break-word;
}
