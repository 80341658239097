.container {
  font-family: inherit;
  width: 100%;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  background-color: var(--page-bg);
  overflow: hidden;

  .scrollable {
    overflow: hidden;
    overflow-y: auto;
    & > div:last-child {
      margin-bottom: 50px;
    }
  }
}

.navigator {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;
  color: var(--grey-font-input);
  margin-left: 40px;
  width: 92%;
  min-height: 33px;
  max-height: 33px;

  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  div:not(.hide) + div {
    margin-left: 30px;
  }
  & > div {
    border-bottom: 3px solid transparent;
    padding-bottom: 13px;
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      outline: 0;
    }
    &.hightlight {
      color: #000000;
      border-bottom: 3px solid var(--primary-main);
    }
  }
}
