.ARSTimeLine1 {
  position: relative;
  height: 100%;
}

.ARSTimeLine1-icon {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.375rem;
  border-radius: var(--border-radius-circle, 1rem);
  border: 2px solid var(--Light-Brand-Secondary-500, #033176);
}

.ARSTimeLine1-icon--fill {
  background: var(--Light-Brand-Secondary-500, #033176);
}

.ARSTimeLine1-icon--critical {
  background: var(--Light-Critical-500, #cc1c10);
  border: 2px solid var(--Light-Critical-500, #cc1c10);
}

.ARSTimeLine1-icon--outline {
  background: transparent;
}

.ARSTimeLine1-line {
  width: 0.125rem;
  position: absolute;
  top: 1.875rem;
  bottom: -1.125rem;
  left: 50%;
  transform: translateX(-50%);
}

.ARSTimeLine1-line--none {
  display: none;
}

.ARSTimeLine1-line--fill {
  background: var(--Light-Brand-Secondary-500, #033176);
}

.ARSTimeLine1-line--fill.ARSTimeLine1-line--critical {
  background: var(--Light-Critical-500, #cc1c10);
}

.ARSTimeLine1-line--outline {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  overflow: hidden;
}

.ARSTimeLine1-lineChild--outline {
  width: 100%;
  min-height: 0.75rem;
  background: var(--Light-Brand-Secondary-500, #033176);
  border-radius: var(--border-radius-sm, 0.25rem);
}

.ARSTimeLine1-lineChild--outline:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ARSTimeLine1-lineChild--outline:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ARSTimeLine1-lineChild--critical {
  background: var(--Light-Critical-500, #cc1c10);
}
