.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__line {
  width: 13px;
  height: 1px;
  border: solid 1px gray;
}

.dropdown-link {
  border-radius: 50% !important;
}

.side_drawer_icon {
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-direction: column;
  justify-content: center;
}

.side_drawer_icon:hover {
  cursor: pointer;
}

.saviynt_logo {
  flex-direction: column;
  display: flex;
  -ms-flex-align: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  margin-right: 14px;
  margin-left: 6px;
}

/* .app_name {
  margin-left: 15px;
} */
.divider {
  color: gray;
  border: solid 1px #b4b4b4;
  width: 0.5px;
  height: 15.5px;
}
.displayNameAlign{
height: 0;
margin-top: 0;
display: flex;
align-items: center;
}

.app-swithcer-menu {
  width: 317px;
  height: auto;
  box-shadow: 1px 4px 10px 0 #e1e2e5;
  background-color: #ffffff;
  position: absolute;
  top: 50px;
  right: 35%;
}

.popup-switchermenu {
  padding: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.icon,
.popup-switchermenu .icon {
  display: flex;
  justify-content: center;
}

.app-label {
  padding-top: 5px;
  color: black;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.ant-tabs-nav .ant-tabs-tab {
  font-size: 12px;
}

.ant-tabs-nav .ant-tabs-tab:first-child {
  left: 27px !important;
}

.ant-tabs-ink-bar {
  left: 25px !important;
}

.popup-switchermenu .nav-icon:hover,
.popup-switchermenu .nav-icon:focus {
  color: #0056b3;
  text-decoration: none;
}

.ant-tabs-bar {
  margin-bottom: 0px !important;
}

.user-profile .notification {
  padding-right: 10px !important;
}

.user-profile .app-switcher {
  padding-right: 20px !important;
  padding-top: 13px !important;
}

.user-profile .grid_search {
  padding-right: 22px !important;
}

.ant-tabs-nav .ant-tabs-tab:last-child {
  padding-left: 34px;
}

a .cl-logo {
  width: 100px;
}

.notificationIcon {
  stroke: #787878 !important;
  fill: #fefefe !important;
}

.notificationIcon:hover,
.notificationIcon:focus {
  stroke: var(--primary-main) !important;
}

.notification .MuiBadge-badge-68 {
  width: 9px;
  height: 9px;
  border: solid 0.8px #ffffff;
  background-color: var(--primary-main);
  margin-top: 2px;
  margin-right: 2px;
}

.mandatory {
  color: var(--mandatory-red);
}

/* .userMenuIcon {
  margin-bottom: -9px;
  margin-right: 5px;
}
.dropdown-item-link-new{
  color: #1e1e1e;
  font-weight: 500;
}
.dropdown-item-sublink{
  color: #999999;
  font-size: 10px;
} */
.recetnActivite {
  padding: 24px;
  background-color: #f7f8fc;
  border-radius: 3px;
  width: 370px;
}

.tableActivite {
  max-height: 55px;
  padding: 15px 0 20px 0;
}

.userLoginText h6 {
  font-size: 11px;
  color: var(--grey-font);
}

.timeLoginText h6 {
  font-size: 12px;
  color: #000000;
  margin-bottom: 0px;
}

.lastLoginIP {
  color: var(--grey-font);
  font-size: 12px;
  float: right;
}

/* .user-option .user-menu-info-icon {
  margin: 5px 10px 5px 7px;
} */