.InSeparator {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Colors */
.InSeparator-neutralSubtle.InSeparator-neutralSubtle {
  color: var(--color-foreground-neutral-subtle, #717385);
}
.InSeparator-neutralInverse.InSeparator-neutralInverse {
  /* TODO: Fx to use variable when updated */
  color: #cecfd9;
}

/* Fonts */
.InSeparator-body2.InSeparator-body2 {
  font: var(--typography-body-2);
}
.InSeparator-body3.InSeparator-body3 {
  font: var(--typography-body-3);
}
