.SmartFilterButtonSelect-wrapper .SmartFilterButtonSelect-button {
  border: 1px solid var(--color-border-savi-subtle, #d057ff);
  background: var(--color-background-neutral-subtlest, #fff);
}

.SmartFilterButtonSelect-wrapper
  .SmartFilterButtonSelect-button.SmartFilterButtonSelect-button--IsOpen {
  border: 1px solid var(--color-border-savi-medium, #a800e0);
  background: var(--color-background-savi-subtle, #f9eefc);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
}

.SmartFilterButtonSelect-wrapper
  .SmartFilterButtonSelect-button
  .ButtonSelect--pill-prefixIcon {
  display: flex;
  margin: auto;
}
