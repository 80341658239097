.popup-switchermenu .disabled svg,
.popup-switchermenu .disabled svg:hover,
.popup-switchermenu .disabled svg:focus {
    /* cursor: default */
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    stroke: #9fa2b4 !important;
}

.popup-switchermenu svg:hover,
.popup-switchermenu svg:focus {
    stroke: #3651ff !important;
}

.popup-switchermenu svg {
    stroke: #9fa2b4 !important;
}

/* .popup-switchermenu div{
    padding-bottom: 30px;
} */
.ant-tabs-nav .ant-tabs-tab-active {
    color: #3751ff !important;
}
.ant-tabs-ink-bar {
    background-color: #3751ff !important;
}

/* .apps-menu:not(:nth-child(5)):not(:nth-child(6)) {
    padding-bottom: 20px;
} */
.apps-menu {
    padding-bottom: 10px !important;
    padding-top: 10px;
    padding-right: 0px !important;
  }
/* .apps-menu:not(:nth-child(2)):not(:nth-child(4)):not(:nth-child(6)) {
    padding-right: 30px;
} */

.popup-switchermenu .app-label {
    color: #1e1e1e !important;
}

.ant-tabs-tab .ant-tabs-tab {
    width: 59px;
    height: 14px;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b4b4b4;
}

.apps {
    padding-top: 20px;
}

.alignIcon {
    margin-left: 12px;
}

.alignLabel {
    padding: 0px;
    margin-left: 7px;
    margin-bottom: 5px;
}

/*----------------------*/

.ant-tabs-nav-animated > div{
    display: flex;
    justify-content: space-between;
    padding: 1px 43px 1px 43px;

}
.ant-tabs-nav-wrap{
    border: solid 1px white;
}
.ant-tabs-nav-animated{
    padding-top: 15px;
}
.ant-tabs-top-content {
    margin :0 !important;
}
.ant-tabs-tabpane-inactive {
    display:none;
}
.ant-tabs-tab-active{
    font-weight:500;
}
.ant-tabs-tab-prev {
    display:none;
}
.ant-tabs-tab-next{
    display:none;
}
.ant-tabs-ink-bar-animated{
    display:none !important;
}
.ant-tabs-top-bar{
    border: solid 1px white !important;
    outline: none !important;
}
.ant-tabs-nav-scroll {
    display: none;
}