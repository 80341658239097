.container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.mobileAppBar {
  box-shadow: none !important;
  background-color: #00245b !important;
}

.iconContainer {
  padding-right: 10px;
}

.navUsername {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-left: 16px;
  max-width: 297px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.headerUsernameTooltip {
  left: -42px;
  max-width: none;
}

.profileContainer {
  display: flex;
  align-items: center;
}

.profileMainContainer {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

:global(#pendo-resource-center-container) {
  left: 50% !important; /* !important is used to ensure these styles take precedence over Pendo's default styles */
  top: 50% !important;
  transform: translate(-50%, -50%);
  position: fixed !important;
}
