.container {
  border-radius: 5px;
  box-shadow: 3px 3px 8px 0 #cdced9;
  background: var(--page-bg);
  border: 1px solid var(--grey-border);
  min-width: 270px;
  color: #000000;

  form {
    padding-top: 0.5em;
  }
}

.header {
  padding: 8px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  // border-bottom: 1px solid var(--grey-border);

  .title {
    padding-left: 8px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    align-self: center;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}

.sortable {
  display: flex;
  justify-content: space-between;
  z-index: 2000;
  padding: 2px 10px;
  height: 30px;
  * {
    justify-self: center;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  .columnCheck {
    width: 15px;
    height: 16px;
  }
}

.button {
  color: var(--grey-font);
  font-size: 12px;
  text-transform: capitalize;
  margin: 0 0 8px 8px;
  border: none;
  &:hover,
  &:focus {
    color: var(--primary-main);
    background-color: transparent;
  }
}
