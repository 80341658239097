.body {
  .Nav_RecentAssignmentsWidget {
    .Nav_RecentAssignmentsWidget-heading {
      margin-bottom: 1rem;
    }

    .Nav_RecentAssignmentsWidget-rows {
      border-radius: var(--border-radius-lg, 1.5rem);
      box-shadow: 0rem 0.125rem 0.75rem 0rem #0000000f;
      background: var(--color-foreground-neutral-inverse, #ffffff);
      padding: 1rem 1.5rem;

      .Nav_RecentAssignmentsWidget-rows-row {
        --sourceHeadingLineHeight: 1.25rem;
        --sourceIdLineHeight: 1.25rem;
        --rowVerticalPadding: 1rem;

        border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
        padding: var(--rowVerticalPadding) 0.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        height: calc(
          var(--sourceHeadingLineHeight) + var(--sourceIdLineHeight) +
            var(--rowVerticalPadding) * 2
        );

        &:last-child {
          border-bottom: none;
        }

        &.Nav_RecentAssignmentsWidget-rows-row--error {
          gap: 0.25rem;

          .Nav_RecentAssignmentsWidget-rows-row-errorIcon {
            flex-shrink: 0;
          }

          .Nav_RecentAssignmentsWidget-rows-row-errorMessage {
            color: var(--color-foreground-critical-medium, #cc1c10);
          }
        }
      }

      .Nav_RecentAssignmentsWidget-empty {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        .Nav_RecentAssignmentsWidget-emptyMessage {
          color: var(--color-foreground-neutral-bold, #212328);
        }
      }
    }

    &.Nav_RecentAssignmentsWidget--error {
      border: 1px solid var(--color-border-critical-medium, #cc1c10);
      border-radius: var(--border-radius-lg, 1.5rem);
      display: flex;
      gap: 1rem;
      align-items: center;
      background: var(--color-background-critical-subtlest, #fdf5f4);
      color: var(--color-foreground-neutral-bold, #212328);
      padding: 0.625rem;

      .Nav_RecentAssignmentsWidget-errorIconContainer {
        border: solid 0.5rem var(--color-foreground-critical-medium, #cc1c10);
        border-radius: 1rem;

        .Nav_RecentAssignmentsWidget-errorIconContainer-errorIcon {
          display: block;
          margin: -0.375rem;
        }
      }

      .Nav_RecentAssignmentsWidget-errorMessage {
        color: var(--color-foreground-neutral-bold, #212328);
      }
    }
  }
}
