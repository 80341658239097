.anchorButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  text-align: left;
  color: var(--primary-main);
}
.anchorButton:hover,
.anchorButton:focus {
  outline: none;
}
