.selected-tickets {
  > * + * {
    margin-left: 10px;
  }
}

.popup-container {
  .popup-header {
    font-size: 14px;
    color: #000;
    padding: 13px 20px 0 20px;
  }
  .popup-content {
    padding: 0 20px;
    position: relative;
    .used-tickets {
      span {
        font-size: 12px;
        color: #8a8b8a;
        font-weight: 500;
      }
      .ticket-labels {
        > * {
          cursor: pointer;
        }
        > * + * {
          margin-left: 8px;
        }
      }
    }
    input {
      padding: 7px 15px;
    }
  }
  .popup-actions {
    position: absolute;
    right: 20px;
    bottom: 15px;
    > * + * {
      margin-left: 6px;
    }
  }
}
