.CertsSidePanel {
  position: absolute;
  top: 0;
  grid-row: 2 / 5;
  grid-column: 9 / -1;
  box-shadow: var(--box-shadow-pretoken-container-interaction);
  width: 100%;
  height: 100%;
  z-index: var(--z-index-certs-landingpage-sidepanel);
}

.CertsSidePanel-layout {
  overflow: hidden;
}

.CertsSidePanel .SidePanel-headerTitle {
  word-break: break-all;
}

.CertsSidePanel .SidePanel-headerDescription {
  word-break: break-all;
}

@media (max-width: 1023px) {
  .CertsSidePanel {
    grid-column: 8 / -1;
  }
}

@media (max-width: 833px) {
  .CertsSidePanel {
    box-shadow: unset;
    z-index: var(--z-index-modal-island);
  }

  .ModalSidePanel.CertsSidePanel .SidePanelView .TabContent {
    max-height: calc(100vh - 15.25rem);
  }
}
