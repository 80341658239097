.info {
    margin: 1rem 1rem 0 1rem;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.boldText {
    font-weight: 500;
}

.children {

}
