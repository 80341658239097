:is(a, div).Nav_ShortcutTile {
  .Nav_ShortcutTile-content {
    background-color: var(--color-background-neutral-subtlest, #ffffff);
    border: 0.0625rem solid var(--color-background-neutral-subtlest, #ffffff);
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: var(--border-radius-lg, 1.5rem);
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.06) 0 0 1.5625rem; /* var(--box-shadow-container-default) is broken */
    text-decoration: none;
    float: right;
    cursor: pointer;
    transition: box-shadow var(--transition-fast-out);

    &:hover,
    &:focus {
      box-shadow: 0rem 0.25rem 1rem 0rem rgba(0, 0, 0, 0.16); /* var(--box-shadow-container-interaction) is broken */
      transition: box-shadow var(--transition-quick-out);
    }

    &:active {
      border-color: var(--color-border-secondary-subtle, #6888b5);
    }

    .Nav_ShortcutTile-content-icon svg {
      width: 5.0625rem;
      height: 5rem;
    }

    .Nav_ShortcutTile-content-label {
      text-align: center;
      line-height: 1.25rem;
      height: 3.75rem;
      display: flex;
      align-items: center;
      color: var(--color-foreground-neutral-bold, #212328);
      word-break: break-word;

      .Nav_ShortcutTile-content-label-text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  &.Nav_ShortcutTile--mobile {
    .Nav_ShortcutTile-content {
      padding: 1.25rem;
      position: relative;

      .Nav_ShortcutTile-content-label {
        position: absolute;
        top: 7.5rem;
      }
    }
  }
}
