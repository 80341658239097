.templateDialog {
  background-color: var(--page-bg);
  width: 537px;
  height: 518px;
  .createTemplatelDialog {
    padding: 25px;
    padding-top: 16px;
    overflow: hidden;
  }
  .templateText {
    font-size: 12px;
    color: #617083;
  }

  .templateBody {
    border-radius: 3px;
    border: solid 1px var(--grey-border);
    background-color: var(--white);
    padding: 20px 43px 23px 43px;
    margin-top: 19px;
    display: flex;
    flex-direction: column;
  }
  .templateName {
    font-size: 11px;
    font-weight: 500;
    color: #1e1e1e;
    &::after {
      content: '*';
      color: red;
    }
  }
  .templateIdText {
    font-size: 9px;
    font-weight: 500;
    color: #a5abb4;
  }

  .templateUserText {
    font-size: 14px;
    font-weight: 500;
    color: #1e1e1e;
  }
  .templateTextfield {
    div {
      border-bottom: solid 1px #e1e1e1;
      margin-bottom: 30px;
    }
  }
  .templateTextarea {
    border-radius: 3px;
    border: solid 1px var(--grey-border);
    background-color: #fafafa;
    resize: none;
    font-size: 10px;
    padding: 7px 10px 0 7px;
  }
  ::placeholder {
    color: #c8c8c8;
  }

  .userLabel {
    display: flex;
    margin-top: 30px;
  }
}

.headerTemplate {
  font-size: 16px;
  color: #000000;
}
.templateHead {
  padding-bottom: 8px;
  border-bottom: solid 0.5px #dcdcdc;
}

.closeTemplate {
  height: 32px;
  border-radius: 3px;
  font-size: 12px;
  color: var(--primary-main) !important;
  font-weight: 500;
  padding: 9px 14px;
  &:hover {
    border: 1px solid var(--primary-main) !important;
  }
}

.templateFooter {
  padding-right: 25px;
  padding-bottom: 25px;
}

.newTemplate .labelTxt {
  color: #a5abb4;
  display: block;
  margin-bottom: 5px;
  font-size: 10px;
}
.cardBody {
  padding: 20px 4px;
  display: flex !important;
}

.newTemplate .dataTxt {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  // display: block;
  word-break: break-word;
}

.newTemplate {
  font-size: 12px;
  //   font-weight: 500;
  //   color: #000000;
  // display: block;
}

.cardRight {
  width: 65%;
}
.cardLeft {
  width: 30%;
  overflow-wrap: break-word;
  margin-right: 25px;
}

.mandateField {
  color: #ff0000;
}

.sesTerminate p {
  margin: 0;
}
.requestLink {
  color: var(--primary-main);
  &:hover {
    cursor: pointer;
  }
}
.btnCreate {
  height: 32px;
  border-radius: 3px;
  border: 1px solid var(--primary-main);
  font-size: 12px;
  color: var(--primary-main);
  font-weight: 500;
  padding: 9px 14px;
  &:hover {
    background-color: var(--primary-main) !important;
    color: var(--white) !important;
  }
  &:disabled {
    border: 1px solid var(--grey-border);
  }
}
