.BulkActionConsultModal .ModalIsland-header {
  height: auto;
}

.BulkActionConsultModal .ModalIsland-titleAndHeaderIcon {
  padding: 0.75rem 2rem;
  overflow: unset;
}

.BulkActionConsultModal .ModalIsland-titleContainer {
  gap: 0.5rem;
  overflow: unset;
}

.BulkActionConsultHeader {
  display: flex;
  align-items: center;
  position: relative;
}

.BulkActionConsultHeader-icon {
  display: flex;
  position: absolute;
  top: -1px;
  left: -2.5rem;
}

.BulkActionConsultHeader-title {
  color: var(--color-foreground-neutral-bold, #212328);
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.BulkActionConsultModal .ModalIsland-body {
  padding-inline: 3rem !important;
  max-height: calc(100vh - 16rem) !important;
  overflow: auto !important;
}

.BulkActionConsultModal .ModalIsland-body::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.BulkActionConsultBody {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-height: 20rem;
  width: 100%;
  gap: 1.5rem;
}

.BulkActionConsultBody-loaderWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BulkActionConsultBody-loader {
  margin-top: 10rem;
}

.BulkActionConsultBody-stats {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0.5rem;
}

.BulkActionConsultBody-statsObjects {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.BulkActionConsultBody-statsHeading {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
  color: var(--color-foreground-neutral-bold, #212328);
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.BulkActionConsultBody-statsHeadingIcon {
  margin-top: 0.375rem;
}

.BulkActionConsultBody-statsContent {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-foreground-secondary-bold, #00245b);
  font-feature-settings:
    'clig' off,
    'liga' off;
}

.BulkActionConsultBody-stats .Checkbox-container {
  margin-top: 0.5rem;
}

.BulkActionConsultBody-stats .Checkbox-label {
  justify-content: space-between;
}

.BulkActionConsultBody-assigneeBox {
  position: relative;
}

.BulkActionConsultBody-assigneeBoxIcon {
  position: absolute;
  left: -1rem;
}

/* As other classes are overwriting this padding */
.BulkActionConsultBody-assigneeBoxList {
  width: 100%;
  padding: 0.25rem !important;
}

.BulkActionConsultBody-assigneeBoxList .MenuItem {
  padding: 0.25rem 0 0.25rem 0.75rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.BulkActionConsultBody-assigneeBoxList .MenuItem-prefixIconAndLabel {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-foreground-secondary-bold, #00245b);
  font-feature-settings:
    'clig' off,
    'liga' off;
  padding-block: 0.25rem;
}

.BulkActionConsultFooter-footerButtonWrap {
  display: flex;
  gap: 0.5rem;
}

.BulkActionConsultFooter-footerButtonWrap .AlertBanner-container {
  position: fixed;
  width: calc(100% - 8rem);
}
