.filterRangePicker {
  position: relative;
  line-height: 1;
  margin-top: -5px;

  .rangePickerIcon {
    // position: absolute;
  }
  :global .ant-calendar-picker-icon {
    display: none;
  }
  :global .ant-calendar-range-picker-input {
    // width: 50%;
  }
  :global span.ant-calendar-range-picker-separator {
    margin-right: 0px;
  }
  :global span.ant-calendar-picker-input {
    display: flex;
    justify-content: space-between;
  }
}
.datefilterLayout {
  display: flex;
  align-items: center;
  .calendarIcon {
    margin-right: 5px;
  }
}
