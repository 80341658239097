:root {
  /* Box Shadow */
  --box-shadow-pretoken-overlay-left: -4px 0px 12px 0px rgba(0, 0, 0, 0.12);
  --box-shadow-pretoken-overlay-right: 4px 0px 12px 0px rgba(0, 0, 0, 0.06);
  --box-shadow-pretoken-overlay-top: 0px -4px 12px 0px rgba(0, 0, 0, 0.06);
  --box-shadow-pretoken-overlay-bottom: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  --box-shadow-pretoken-overlay-interaction: 0px 2px 16px 2px
    rgba(0, 0, 0, 0.16);
  --box-shadow-pretoken-button-interaction: 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
  /* TODO: Needs to be removed after Tile.js isSelected state z-indexing is fixed */
  --box-shadow-pretoken-container-interaction-for-broken-certs-tile: -10px 2px
    12px 0px rgba(0, 0, 0, 0.06);
  --box-shadow-pretoken-container-interaction: -10px 2px 12px -6px rgba(0, 0, 0, 0.16);
  --box-shadow-pretoken-container-default: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
}
