.notification-dialog-container {
    // width: 518px;
    // height: 393px;
    text-align: center;
    padding: 40px;
    .notification-dialog-close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .notification-dialog-title {
        font-size: 15px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 24px;
    }

    .notification-dialog-details {
        font-size: 14px;
        color: #747474;
        margin-bottom: 24px;
    }

    .notification-icon {
        display: inline-block;
        width: 68px;
        height: 68px;
        margin-bottom: 32px;
        &.success {
            background: url('/ECMv6/assets/images/notification/success.svg') no-repeat !important;
        }

        &.failure {
            background: url('/ECMv6/assets/images/notification/failure.svg') no-repeat !important;
        }
    }
}