.LinearProgressBar {
  display: flex;
  gap: var(--spacing-300, 0.5rem);
  border-radius: 4px;
  background: var(--color-background-neutral-subtle, #ebecf2);
  height: 4px;
  width: 100%;
  min-width: 12.5rem;
}

.LinearProgressBar > div {
  display: flex;
  border-radius: var(--border-radius-sm, 0.25rem);
  border-radius: 4px;
}

.LinearProgressBar-filler--default {
  background: var(--color-background-secondary-bold, #254b86);
}

.LinearProgressBar-filler--success {
  background: var(--color-success-700, #028329);
}

.LinearProgressBar-filler--warning {
  background: var(--color-warning-400, #f6a91c);
}

.LinearProgressBar-filler--critical {
  background: var(--color-critical-700, #cc1c10);
}

.LinearProgressBar .show-pointer {
  border-radius: 4px 0 0 4px;
  border-right: 2px solid #ffff;
}
