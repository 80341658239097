.sod-single-container.sod-anim {
  opacity: 1;
  transition: 1.5s;
}
.sod-single-carousal-container {
  margin: 30px 30px 40px 94px;
  .carousal_title{
    display:flex;
    align-items: center;
  }
  .subtext{
    margin-left:3px;
    font-size: 13px;
font-weight: 500;
color: #212121;
padding-top:2px;
  }
}
.entitilement-item {
  min-height: 175px;
  background-color: #ffffff;
  border-radius: 1px;
  border: solid 1px #dcdee1;
  margin-bottom: 16px;
  padding: 13px 17px 14px 17px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  .entitlementlabelsap {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 10px;
    color: #747474;
  }
  .entitlementlabel{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.08;
    color: #1e1e1e;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .entitlementvalsaplayout{
    display:flex;
    margin-top: 10px;
    flex-flow: wrap;
  }
    .entitlementvalsap {
      font-size: 13px;
      line-height: 1.38;
      color: black;
      word-break: break-word;
      text-decoration: underline;
      margin-bottom: 5px;
      padding-left: 0px;
    }
    .iconlayout{
      display:flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .sapicon{
      margin-right: 15px;
      width: 25px;
    }
    .sapicontext{
      font-size: 12px;
      color: #000000;
    }
    .sapval{
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #000000 !important;
      margin-bottom: 10px;
    }
  .entitlementval {
    font-size: 13px;
    line-height: 1.38;
    color: var(--grey-font);
    word-break: break-word;
  }
}
.violation-header{
  display:flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #dcdee1;
  .violation-left{
    align-items: center;
    justify-content: center;
    width:100%;
    display:flex;
    margin-left: 70px;
  }
  .icon{
    margin-left: auto;
    margin-right:20px;
    cursor: pointer;
  }
}
.sod-single-container {
  .mitigated-layout{
    margin: 24px 26px 20px 94px !important;
    width: 50%;
  }
  .commentsmandatory{
    color:red;
    margin-top: 10px;
    margin-left:3px;
    font-size: 22px;
  }
  opacity: 0;
  transition: 2s;
  margin-top: 59px;
  border-radius: 5px;
  border: solid 1px #dee0e3;
  background-color: #ffffff;
.recalculate{
margin-right: 25px;
cursor: pointer;
padding: 8px;
border-radius: 100%;

}
.recalculate:hover{
background-color: rgba(55,81,255,0.08);
}
  .sod-single-header {
    min-height: 76px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #212121;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }
  .sod-namesection {
    display: flex;
    flex-flow: column;
    margin-right: auto;
  }
  .recommended{
    margin-top:10px;
    font-size: 12px;
    color: #000000;
  }
  .carousal-item-container {
    border-radius: 3px;
    background-color: #eae6f9;
    padding: 22px 22px 16px 21px;
    min-width: 348px;
    max-width: 350px;
    .function-name-layout {
      min-height: 40px;
      background-color: #ffffff;
      margin-bottom: 8px;
      border-radius: 1px;
      border: solid 1px #dcdee1;
      border-left: solid 3px #7761d1;
      display: flex;
      align-items: center;
      .function-bg {
        padding: 10px;
        display: flex;
        margin-left: 14px;
      }
      .function-dot {
      margin-top:3px;
        min-width: 13px;
        width: 13px;
        height: 13px;
        background-color: #7761d1;
        border-radius: 100%;
        margin-right: 9px;
      }
      .function-name {
        font-size: 12px;
        font-weight: 500;
        color: #7761d1;
      }
    }
  }
  .sod-single-content-header.multi {
    background-color: #f5f6f7;
    border-bottom: solid 1px #dee0e3;
    display: flex;
    align-items: center;
    padding: 20px 31px 20px 31px;
    .mitigation-layout{
      display: flex;
      margin-left: auto;
      justify-content: center;
      align-items: center;
    }
    .recommended{
      margin-top:10px;
      font-size: 12px;
      color: #000000;
    }
    .risktype {
      margin-top: 0px;
    }
    .high-layout {
      margin-right: 15px;
      display: flex;
      align-items: baseline;
      justify-content: center;
    }
    .back {
      display: flex;
      align-self: center;
    }
  }
  .sod-single-content-header {
    background-color: #f5f6f7;
    display: flex;
    flex-flow: row;
    padding: 18px 31px 18px 31px;
    align-items: center;
    .risktype {
      align-self: baseline;
      border-radius: 3px;
      background-color: #7761d1;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      margin-right: 16px;
      margin-top: 8px;
      min-width: 54px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .LOW {
      background-color: #ff9200;
      color: #ffffff;
    }
    .Low {
      background-color: #ff9200;
      color: #ffffff;
    }
    .Critical {
      background-color: #ec4c46;
      color: #ffffff;
    }
    .name {
      font-size: 18px;
      font-weight: 500;
      color: #1e1e1e;
    }
    .subname {
      font-size: 12px;
      color: var(--grey-font);
    }
    .mitigated{
      margin-top: 5px;
    }
    .mitigatedicon{
      display: flex;
      align-self: center;
      margin-right: -5px;
       }
    .mitigated-text{
      font-size: 12px;
      color: #000000;
    }
    .function-dot {
      margin-top:3px;
      min-width: 13px;
      width: 13px;
      height: 13px;
      background-color: #7761d1;
      border-radius: 100%;
      margin-right: 9px;
    }
    .previous-control{
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: var(--primary-main);
      margin-right:27px;
      display: flex;
      .separator{
        border-left: solid 1px #c8c9d3;
        margin-left: 30px
      }
      .historylayout{
        cursor: pointer;
      }
    }
  }
  .sod-single-coments {
    margin: 10px 83px 7px 94px;
    .commentwidth{
      width:95%;
    }
  }
}
.mitigated-layout{
  margin: 24px 26px 0px 26px;
  .mitigated-editlayout{
    display:flex;
    .label{
      color: var(--primary-main);
      font-size: 13px;
      padding: 0px 15px;
      cursor: pointer;
    }
  }
  .mitigated-titlelayout{
   display: flex;
  justify-content: space-between;
  margin-right: 35px;
  }
  .mitigated-title{
    font-size: 12px;
    font-weight: 500;
    color: black;
    margin-bottom: 8px;
  }
  .mitigated-sublayout{
    justify-content: space-between;
    margin-right: 50px;
          display: flex;
    .control-layout{
      min-width: 150px;
     .controlname-label{
      font-size: 11px;
      color: #747474;
      margin-top: 3px;
     }
     .controlname-value{
      font-size: 12px;
      color:black;
     }
    }
  }

}
.sod-multiple-container {
  .busjust-layout{
    margin: 24px 26px 0px 26px;
  }
  .busjust-layout-text{
    width: 95%;
  }
  .commentsmandatory{
    color:red;
    margin-top: 10px;
    margin-left:3px;
    font-size: 22px;
  }
  padding-bottom: 17px;
  margin-top: 59px;
  border-radius: 5px;
  border: solid 1px #dee0e3;
  background-color: #ffffff;
  .sod-single-header {
    min-height: 76px;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    color: #212121;
    font-size: 16px;
  }
  .left-pane {
    width: 55%;
    padding: 17px 26px 26px 26px;
    .sort-by {
      margin-bottom: 17px;
      font-size: 12px;
      color: #b4b4b4;
    }
  }
  .function-layout {
    border-radius: 5px;
    box-shadow: 3px 3px 8px 0 rgba(223, 224, 235, 0.5);
    border: solid 1px #dee0e3;
    background-color: #ffffff;
    margin: 24px 26px 0px 26px;
    .function-bg {
      padding: 10px 20px;
      display: flex;
      background-color: #ffffff;
      margin-left: 2px;
    }
    .function-dot {
      margin-top:3px;
      min-width: 13px;
      width: 13px;
      height: 13px;
      background-color: #7761d1;
      border-radius: 100%;
      margin-right: 9px;
    }
    .function-name {
      font-size: 12px;
      font-weight: 500;
      color: #1e1e1e;
    }
    .expand-layout {
      display: flex;
      flex-flow: column;
      width: 100%;
      position: absolute;
      top: 50px;
      z-index: 20;
      background-color: #eae6f9;
      transition-duration: 240ms;
    }
    .function-header {
      padding: 10px;
      border-bottom: solid 3px #7761d1;
      display: flex;
      align-items: center;
      .funcicon {
        margin-right: 17px;
        width: 15px;
      }
      .funcname {
        margin-left: 15px;
        font-size: 15px;
        font-weight: 500;
        color: #1e1e1e;
        margin-bottom: 0px;
      }
    }
  }
  .right-pane {
    width: 45%;
    min-height: 538px;
    border-radius: 5px;
    border: solid 1.2px var(--primary-main);
    .header {
      padding: 5px 10px 20px 10px;
      min-height: 154px;
      border-radius: 5px;
      background-color: var(--primary-bg);
      width: 100%;
      .risktype {
        border-radius: 3px;
      color: #ffffff;
      background-color: #7761d1;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        margin-top: 10px;
        min-width: 54px;
        min-height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
      }
      .LOW {
        background-color: #ff9200;
        color: #ffffff;
      }
      .Low {
        background-color: #ff9200;
        color: #ffffff;
      }
      .Critical {
        background-color: #ec4c46;
        color: #ffffff;
      }
      .name {
        font-size: 18px;
        font-weight: 500;
        color: #1e1e1e;
        margin-bottom: 3px;
      }
      .subname {
        font-size: 12px;
        font-weight: normal;
        color: var(--grey-font);
      }
      .recommended{
        margin-top:10px;
        font-size: 12px;
        color: #000000;
      }
      .mitigation-layout{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 14px;
        width: 100%;
        padding: 0px 10px;
      }
      .previous-control{
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: var(--primary-main);
        display: flex;
        padding-left:10px;
        .separator{
          padding-left: 12px;
          border-left: solid 1px #c8c9d3;
          margin-right: 17px;
        }
        .historylayout{
          cursor: pointer;
        }
      }
      .zoom {
        padding-top: 13px;
        padding-left: 13px;
        width: 50px;
        height: 50px;
        display: flex;
        margin-left: auto;
        cursor: pointer;
      }
      .zoom:hover {
        background: rgba(0, 0, 0, 0.08);
        border-radius: 100%;
      }
      .header-layout {
        margin-top: -40px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .sod-multiple-content-header.select {
    border-radius: 5px;
    border: solid 1.2px var(--primary-main);
  }
  .sod-multiple-content-header {
    min-height: 74px;
    border-radius: 5px;
    border: solid 1px #dcdee1;
    background-color: #ffffff;
    display: flex;
    flex-flow: row;
    padding: 17px 18px;
    margin-bottom: 9px;
    align-items: center;
    .risktype {
      border-radius: 3px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      background-color: #7761d1;
      margin-right: 16px;
      min-width: 54px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .LOW {
      background-color: #ff9200;
      color: #ffffff;
    }
    .Low {
      background-color: #ff9200;
      color: #ffffff;
    }
    .Critical {
      background-color: #ec4c46;
      color: #ffffff;
    }
    .name {
      font-size: 15px;
      font-weight: 500;
      color: var(--grey-font);
    }
    .subname {
      font-size: 12px;
      font-weight: normal;
      color: var(--grey-font);
    }
    .mitigated{
      margin-left: auto;
    }
    .mitigatedicon{
   display: flex;
   align-self: center;
   margin-right: -5px;

    }
    .mitigated-text{
      font-size: 12px;
      color: #000000;
    }
    .function-dot {
      margin-top:3px;
      min-width: 13px;
      width: 13px;
      height: 13px;
      background-color: #7761d1;
      border-radius: 100%;
      margin-right: 9px;
    }
  }
  .sod-single-coments {
    margin: 28px 83px 32px 94px;
  }
  
}

.selected-miti{
  font-family: Rubik;
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.23;
  color: var(--grey-font);
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.selected-miti-associated{
  
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  line-height: 1.23;
  color: var(--grey-font);
}


.busijusti-history{
  max-width: 500px;
  min-width: 306px;
  padding: 16px;
  .history{
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    color: black;
  }
  .iconLetter{
    display: flex;
    height: 40px;
    min-width: 40px;
    background-color: var(--grey-font);
    color: white;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    }
    .iconLayout{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
}

.export-content-layout{
  margin-top: 25px;
  .export-content-label{
    font-size: 12px;
    color: black;
    margin-bottom: 6px;
  }
  .MuiChip-root{
border-radius: 3px;
background-color: var(--primary-bg);
  }
  .MuiChip-label{
color: var(--primary-main);
  }
  .MuiChip-deleteIcon {
    color: var(--primary-main);
  }
}