.Menu {
  position: relative;
}

.Menu-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 32rem;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  transition: height var(--transition-quick-in-out);
  z-index: var(--z-index-dropdown);
}

.Menu-items {
  list-style: none;
  margin: 0;
  padding: 0.75rem;
  min-width: min-content;
  white-space: nowrap;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 4px);
  min-width: 10rem;
  width: 100%;
  max-width: 30rem;
  overflow-y: auto;
  scrollbar-width: thin;
}

/* Sizes */
.Menu-dropdown--small {
  width: 10rem;
}
.Menu-dropdown--medium {
  width: 16rem;
}
.Menu-dropdown--large {
  width: 24rem;
}
.Menu-dropdown--xLarge {
  width: 30rem;
}

.Menu button label {
  display: initial; /* fixes text spacing issue in button label */
}

/* Simple */
.Menu--simple .Menu-trigger {
  width: min-content;
}

.Menu-button {
  display: flex;
  gap: 0.125rem;
  padding: 0.25rem 0.5rem;
  color: var(--color-foreground-neutral-medium, #4a4c59);
  width: min-content;
  border-radius: var(--border-radius-sm);
  background: transparent;
  border: 0;
  transition: background var(--transition-quick-in-out);
  cursor: pointer;
}
.Menu-button:hover {
  /* TODO: use css var when working */
  background: rgba(55, 81, 255, 0.08);
}
.Menu-button:active {
  /* TODO: use css var when working */
  background: rgba(55, 81, 255, 0.1);
}
.Menu--simple .Menu-button[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
  outline-offset: 0.125rem;
}
