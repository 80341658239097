/* TODO: Reduce specificity if we ever fix the style build order */
/* Selected range */
.react-datepicker__day--in-selecting-range.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__day.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
  font: var(--typography-body-2);
  background: transparent;
  border-radius: 0;
  color: var(--color-foreground-neutral-bold, #212328);
}
.react-datepicker__day--in-selecting-range.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range.react-datepicker__day--in-range {
  font: var(--typography-body-2);
  background: rgba(37, 75, 134, 0.1);
  border-radius: 0;
  color: var(--color-foreground-neutral-bold, #212328);
}
.react-datepicker__day--in-selecting-range.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range.react-datepicker__day--in-range:hover {
  color: var(--color-foreground-neutral-inverse, #fff);
  background: var(--color-background-secondary-bold, #254b86);
  border-radius: 0;
}
/* End range past endDate value style */
.react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-end,
.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-end:hover {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-neutral-inverse, #fff);
  border-radius: 0;
  background: var(--color-background-secondary-bold, #254b86);
}
/* Not able to combine: 10/11/2023. dev: Jacob Eades */
.react-datepicker__month--selecting-range.react-datepicker__month--selecting-range
  .react-datepicker__day--selected.react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--in-range:not(
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range
  ) {
  background: rgba(37, 75, 134, 0.1);
  border-radius: 0;
}
.react-datepicker__month--selecting-range.react-datepicker__month--selecting-range
  .react-datepicker__day--in-range:not(
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range
  ) {
  background: rgba(37, 75, 134, 0.1);
  border-radius: 0;
}
/* Start only */
.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-start {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-neutral-inverse, #fff);
  border-radius: 0;
  background: var(--color-background-secondary-bold, #254b86);
}
/* Start and end hover */
.react-datepicker__day--selected.react-datepicker__day--selected.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-start:hover,
.react-datepicker__day--selected.react-datepicker__day--selected.react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-end:hover {
  font: var(--typography-body-bold-2);
}
