.CertsReassignPopover-contextWrapper {
  position: relative;
  display: flex;
  width: max-content;
}

/* Content */
.CertsReassignPopover {
  min-width: 21rem;
  width: min-content;
  max-width: 40rem;
  padding: 1.5rem 0;
}

.CertsReassignPopover-note {
  color: var(--color-foreground-neutral-bold, #212328);
  padding: 0 1.5rem;
}

/* Control Buttons */
.CertsReassignPopover-controls {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.5rem;
  gap: 0.5rem;
}
