.Grid-item {
  &.Grid-item--desktop-12 {
    grid-column-end: span 12;
  }
  &.Grid-item--desktop-11 {
    grid-column-end: span 11;
  }
  &.Grid-item--desktop-10 {
    grid-column-end: span 10;
  }
  &.Grid-item--desktop-9 {
    grid-column-end: span 9;
  }
  &.Grid-item--desktop-8 {
    grid-column-end: span 8;
  }
  &.Grid-item--desktop-7 {
    grid-column-end: span 7;
  }
  &.Grid-item--desktop-6 {
    grid-column-end: span 6;
  }
  &.Grid-item--desktop-5 {
    grid-column-end: span 5;
  }
  &.Grid-item--desktop-4 {
    grid-column-end: span 4;
  }
  &.Grid-item--desktop-3 {
    grid-column-end: span 3;
  }
  &.Grid-item--desktop-2 {
    grid-column-end: span 2;
  }
  &.Grid-item--desktop-1 {
    grid-column-end: span 1;
  }
  &.Grid-item--desktop-0 {
    display: none;
  }

  @media (max-width: 1024px) {
    &.Grid-item--tablet-12,
    &.Grid-item--tablet-11,
    &.Grid-item--tablet-10,
    &.Grid-item--tablet-9,
    &.Grid-item--tablet-8,
    &.Grid-item--tablet-7,
    &.Grid-item--tablet-6,
    &.Grid-item--tablet-5,
    &.Grid-item--tablet-4,
    &.Grid-item--tablet-3,
    &.Grid-item--tablet-2,
    &.Grid-item--tablet-1 {
      grid-column-end: span 1;
      display: initial;
    }
    &.Grid-item--tablet-12 {
      grid-column-end: span 12;
    }
    &.Grid-item--tablet-11 {
      grid-column-end: span 11;
    }
    &.Grid-item--tablet-10 {
      grid-column-end: span 10;
    }
    &.Grid-item--tablet-9 {
      grid-column-end: span 9;
    }
    &.Grid-item--tablet-8 {
      grid-column-end: span 8;
    }
    &.Grid-item--tablet-7 {
      grid-column-end: span 7;
    }
    &.Grid-item--tablet-6 {
      grid-column-end: span 6;
    }
    &.Grid-item--tablet-5 {
      grid-column-end: span 5;
    }
    &.Grid-item--tablet-4 {
      grid-column-end: span 4;
    }
    &.Grid-item--tablet-3 {
      grid-column-end: span 3;
    }
    &.Grid-item--tablet-2 {
      grid-column-end: span 2;
    }
    &.Grid-item--tablet-1 {
      grid-column-end: span 1;
    }
    &.Grid-item--tablet-0 {
      display: none;
    }
  }

  @media (max-width: 600px) {
    &.Grid-item--mobile-12,
    &.Grid-item--mobile-11,
    &.Grid-item--mobile-10,
    &.Grid-item--mobile-9,
    &.Grid-item--mobile-8,
    &.Grid-item--mobile-7,
    &.Grid-item--mobile-6,
    &.Grid-item--mobile-5,
    &.Grid-item--mobile-4,
    &.Grid-item--mobile-3,
    &.Grid-item--mobile-2,
    &.Grid-item--mobile-1 {
      grid-column-end: span 1;
      display: initial;
    }
    &.Grid-item--mobile-12 {
      grid-column-end: span 12;
    }
    &.Grid-item--mobile-11 {
      grid-column-end: span 11;
    }
    &.Grid-item--mobile-10 {
      grid-column-end: span 10;
    }
    &.Grid-item--mobile-9 {
      grid-column-end: span 9;
    }
    &.Grid-item--mobile-8 {
      grid-column-end: span 8;
    }
    &.Grid-item--mobile-7 {
      grid-column-end: span 7;
    }
    &.Grid-item--mobile-6 {
      grid-column-end: span 6;
    }
    &.Grid-item--mobile-5 {
      grid-column-end: span 5;
    }
    &.Grid-item--mobile-4 {
      grid-column-end: span 4;
    }
    &.Grid-item--mobile-3 {
      grid-column-end: span 3;
    }
    &.Grid-item--mobile-2 {
      grid-column-end: span 2;
    }
    &.Grid-item--mobile-1 {
      grid-column-end: span 1;
    }
    &.Grid-item--mobile-0 {
      display: none;
    }
  }
}
