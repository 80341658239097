.container {
    width: 100%;
}

.center {
    text-align: center;
}

.leftAlign {
    text-align: left;
}

.title {
    color: var(--home-main-title);
}

.subTitle {
    color: var(--home-subtitle);
}