.CustomPropertiesModal {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 85.375rem;
  min-height: 48rem;
}

#ModalIsland.ModalIsland.CustomPropertiesModal
  .ModalIsland-section
  .ModalIsland-body {
  max-height: calc(100vh - 14rem);
}

.CustomPropertiesModal-modalIslandContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  min-height: 31.8rem;
}

.CustomPropertiesModal .ModalIsland-section {
  width: 100%;
  max-width: calc(100vw - 3rem);
}

.CustomPropertiesModal .ModalIsland-header {
  border-radius: 0.25rem 0.25rem 0 0;
}

.CustomPropertiesModal-customPropertiesRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 1rem 0;
  align-self: stretch;
  flex-basis: 50%;
  box-sizing: border-box;
  gap: 0.5rem;
  max-width: 48%;
}
.CustomPropertiesModal-customPropertiesRow--withBorder {
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.CustomPropertiesModal-customPropertiesRow-name {
  display: flex;
}

.CustomPropertiesModal-customPropertiesRow-value {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.CustomPropertiesModal-footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.CustomPropertiesModal-footerActionBar {
  border-radius: 0 0 0.25rem 0.25rem;
}
