/* We expect to be placed in a flexbox */
.Nav_ShortcutsWidget {
  --content-height: 10.375rem;

  container: Nav_ShortcutsWidget / inline-size;

  .Nav_ShortcutsWidget-tiles {
    display: flex;
    justify-content: end;
    gap: 0.875rem;

    .Nav_ShortcutsWidget-tiles-tile {
      width: 14.5rem;
      height: var(--content-height);
    }
  }

  .Nav_ShortcutsWidget-pageIndicator {
    width: 2.5rem;
    height: 1.5rem;
    padding: 0.5rem;
    border-radius: var(--border-radius-circle, 50%);
    background: rgb(
      from var(--color-navy-1000) r g b / var(--opacity-600)
    ); /* var(--color-overlay-secondary); is broken */
    margin: 1rem auto 0 auto;
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      border-radius: var(--border-radius-circle, 50%);
      position: absolute;
      top: 0.5rem;
    }

    &:before {
      background-color: var(--color-foreground-primary-medium, #3751ff);
      left: 0.5rem;
    }

    &::after {
      background-color: var(--color-neutral-000, #ffffff);
      left: 1.5rem;
    }

    &.Nav_ShortcutsWidget-pageIndicator--end {
      &:before {
        background-color: var(--color-neutral-000, #ffffff);
      }

      &:after {
        background-color: var(--color-foreground-primary-medium, #3751ff);
      }
    }
  }

  &.Nav_ShortcutsWidget--error,
  &.Nav_ShortcutsWidget--loading {
    background-color: var(--color-background-neutral-subtlest, #ffffff);
    border-radius: var(--border-radius-lg, 1.5rem);
    padding: 1.5rem 2.5rem;
    display: flex;
    align-items: center;
    height: var(--content-height);
    flex-grow: 1;
    gap: 0.5rem;
  }

  &.Nav_ShortcutsWidget--error {
    justify-content: start;

    .Nav_ShortcutsWidget-errorMessage {
      color: var(--color-foreground-critical-medium, #cc1c10);
    }

    .Nav_ShortcutsWidget-errorIcon {
      flex-shrink: 0;
    }
  }

  &.Nav_ShortcutsWidget--loading {
    justify-content: center;
  }

  &.Nav_ShortcutsWidget--isTablet,
  &.Nav_ShortcutsWidget--isMobile {
    .Nav_ShortcutsWidget-tiles {
      justify-content: center;
    }
  }
}
