.SchedulerBlock {
  width: 100%;
}

.SchedulerBlock-modal {
  padding: 0 1.5rem;
}

/* Time Range */
.SchedulerBlock-timeRange {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 8.75rem;
  gap: 0.125rem;
  white-space: nowrap;
}

/* Details */
.SchedulerBlock-detailsContainer {
  display: flex;
  gap: 0.25rem;
}

/* Booked block */
.SchedulerBlock-booked {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  pointer-events: none;
}

.SchedulerBlock-booked-content {
  width: 100%;
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  border: 1px solid var(--color-border-critical-subtlest, #fed1d0);
  background: var(--color-critical-100, #fdeeed);
}

.SchedulerBlock-booked-timeRange {
  color: var(--color-foreground-critical-bold, #700600);
  width: 8.75rem;
}

.SchedulerBlock-booked-details {
  color: var(--color-foreground-critical-bold, #700600);
  gap: 0.25rem;
}

.SchedulerBlock-booked-detailsText-action {
  color: var(--color-foreground-critical-bold, #700600);
}

/* Available block */
.SchedulerBlock-available {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.SchedulerBlock-available-content {
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  background: var(--color-background-secondary-bold, #254b86);
}

.SchedulerBlock-available-timeRange {
  color: var(--color-foreground-neutral-inverse, #fff);
}

.SchedulerBlock-available-details {
  color: var(--color-foreground-neutral-inverse, #fff);
}

/* Complete block */
.SchedulerBlock-complete {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  pointer-events: none;
}

.SchedulerBlock-complete-content {
  width: 100%;
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  background: var(--color-background-neutral-subtlest, #fff);
}

.SchedulerBlock-complete-timeRange {
  color: var(--color-foreground-neutral-subtle, #717385);
  width: 8.75rem;
}

.SchedulerBlock-complete-details {
  color: var(--color-foreground-neutral-subtle, #717385);
  gap: 0.25rem;
}

.SchedulerBlock-complete-detailsText-action {
  color: var(--color-foreground-neutral-subtle, #717385);
}
