.CertsDetailsList {
  grid-row: 3;
  grid-column: 1 / -1;
  position: relative;
  padding: 0 3rem;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  flex-grow: 1;
  overflow-y: auto;
  background: var(--page-bg, #f7f8fc);
  /* overflow-x prevent the actionButton Group hidden items from flashing a scroll bar a the bottom on tab switching or re-rendering list data */
  overflow-x: hidden;
}

.CertsDetailsList::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.CertificationDetails--isSidePanelVisible .CertsDetailsList {
  grid-column: 1 / 10;
  grid-row: 3 / 4;
  padding-right: calc(8.3333vw + 1.25rem);
}
/* Hide scrollbar for Chrome, Safari and Opera */
.CertificationDetails--isSidePanelVisible .CertsDetailsList::-webkit-scrollbar {
  display: none;
}
.CertificationDetails--isSidePanelVisible .CertsDetailsList {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.CertsDetailsList--isMoreDataAvailable {
  padding-bottom: 6rem;
}
.CertsDetailsList--isMoreDataAvailable.CertsDetailsList--isLoading {
  padding-bottom: 0;
}

.CertsDetailsList-errorHandling {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4rem;
}

.CertsDetailsList-noDataToShow {
  position: absolute;
  top: 50%;
  left: 50%;
}

.CertificationDetails-emptyCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.CertificationDetails-emptyOrError {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem;
  height: 100%;
  width: 100%;
  max-width: 37.5rem;
  text-align: center;
}

/* Loader */
.CertsDetailsList-loader {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
}

.CertsDetailsList-loader .Loader {
  width: 3rem;
  height: 3rem;
}

/* Target Loader */
.CertsDetailsList-targetLoader {
  width: 100%;
  height: 3rem;
}
.CertsDetailsList--isMoreDataAvailable .CertsDetailsList-targetLoader,
.CertsDetailsList--isLoading .CertsDetailsList-targetLoader {
  height: 0;
}

/* Resonsive */
@media (max-width: 1279px) {
  .CertsDetailsList {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 6rem;
  }
}

@media (max-width: 1023px) {
  .CertificationDetails--isSidePanelVisible .CertsDetailsList {
    grid-column: 1 / 9;
  }
}

@media (max-width: 833px) {
  .CertificationDetails--isSidePanelVisible .CertsDetailsList {
    grid-column: 1 / -1;
    padding-right: 1.5rem;
  }
  .CertificationDetails--isSidePanelVisible
    .CertsFooterActionBar--isSidePanelVisible {
    grid-column: 1 / -1;
  }
  .CertificationDetails--isSidePanelVisible .CertsControlsGroup {
    grid-column: 1 / -1;
  }
}
