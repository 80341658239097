.CertsConsultModal-containerHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.CertsConsultModal-details {
  display: flex;
  gap: 0.3rem;
}

.CertsConsultModal-containerNoteIcon {
  width: var(--sizing-icon-sm, 1rem);
  height: var(--sizing-icon-sm, 1rem);
}

.CertsConsultModal-container-userDetailsName {
  color: var(--color-foreground-secondary-bold, #00245b);
  width: 30rem;
  overflow-wrap: break-word;
}

.CertsConsultModal-container-certifier {
  color: var(--color-foreground-neutral-bold, #212328);
}

.CertsConsultModal-containerNoteText {
  color: var(--color-foreground-info-medium, #573bdc);
}

.CertsConsultModal-containerTitle {
  color: var(--color-foreground-neutral-bold, #212328);
}

.CertsConsultModal-container-userDetails {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
}

.CertsConsultModal-containerNote {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
}
.CertsConsultModal-includeCheckbox {
  width: 100%;
}

.CertsConsultModal-includeCheckbox .Checkbox-container {
  width: 100%;
}

.CertsConsultModal-includeCheckbox .Checkbox-label {
  justify-content: space-between;
}

.CertsConsultModal-containerCertifierSelection {
  display: flex;
  padding: 1.5rem 0;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}

.CertsConsultModal-containerCertifierSelection
  .CertsConsultModal-DropdownAsterisk {
  position: absolute;
  left: -0.6rem;
  color: var(--color-foreground-warning-medium);
}

.CertsConsultModal-containerCertifierSelection
  .Certs-dropdownSelect
  .Menu-Item {
  padding: 0.25rem 0 0.25rem 0.75rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.CertsConsultModal-containerCertifierSelection
  .Certs-dropdownSelect
  .MenuItem-prefixIconAndLabel {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  color: #00245b;
  color: var(--color-foreground-secondary-bold, #00245b);
  -webkit-box-orient: vertical;
  align-self: stretch;
  padding-block: 0.25rem;
  display: -webkit-box;
  overflow: hidden;
}

.CertsConsultModal-containerCertifierSelection .anyWidthPositionRelative {
  width: 100%;
}

.CertsConsultModal-containerCertifierSelection
  .anyWidthPositionRelative
  .Menu-dropdown--isVisible {
  width: 100%;
  max-height: 12rem;
}

.CertsConsultModal-containerCertifierSelection .Checkbox-container {
  margin-top: 1.25rem;
}

.CertsConsultModal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

.CertsConsultModal-actionButtons {
  display: flex;
  gap: 0.5rem;
}

.CertsConsultModal-containerCertifierSelection .AlertBanner-container {
  width: 100%;
}

.CertsConsultModal-dropdown.selection--disabled {
  pointer-events: none;
}

.CertsConsultModal-comments label {
  font: var(--typography-heading-4);
  color: var(--color-foreground-neutral-bold, #212328);
}
