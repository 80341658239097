.BulkActionFooter-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
}

.BulkActionFooter-infoBox {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5rem;
  min-width: 15rem;
  width: 50%;
}

.BulkActionFooter-infoBox .BulkActionFooter-clearSelectionIcon {
  display: none;
}

.BulkActionFooter-infoBoxTitle,
.BulkActionFooter-infoBoxDescription {
  color: var(--color-gray-000);
}

.BulkActionFooter-infoBoxDescription {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.BulkActionFooter-btnBox {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  min-width: 15rem;
  gap: 0.5rem;
  width: 50%;
  height: min-content;
}

.BulkActionFooter-btnBoxVariableBtns {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: nowrap;
}

.BulkActionFooter-btnBoxButton {
  background-color: transparent;
}

.BulkActionFooter-btnBoxMenu {
  position: relative;
  display: flex;
  align-items: center;
}

.BulkActionFooter-btnBoxButton .Button-content-container {
  position: relative;
}

.BulkActionFooter-btnBoxButton:hover .BulkActionFooter-btnBoxButtonPopOver {
  display: flex;
}

.BulkActionFooter-btnBoxButtonPopOver {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  bottom: 2.25rem;
  left: -0.75rem;
  width: max-content;
  max-width: 10.5rem;
  padding: 0.5rem 0.75rem;
  background: #fff;
  border-radius: var(--border-radius-sm, 0.25rem);
  z-index: var(--z-index-certs-landingpage-dropdown);
  color: #212328;
  font: var(--typography-body-3);
  white-space: normal;
}

.BulkActionFooter-btnBoxButtonPopOver::before {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 1rem;
  height: 1rem;
  background: #fff;
}

.BulkActionFooter-btnBoxMenuDropUp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 2.5rem;
  right: -1rem;
  min-width: 10rem;
  padding: 0.75rem;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 0.25rem);
  box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.08);
  transition: var(--transition-quick-out);
}

.BulkActionFooter-btnBoxMenuDropUpListItem {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 0.25rem;
  grid-gap: 0.25rem;
  overflow: hidden;
  width: 100%;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  white-space: nowrap;
  transition: var(--transition-quick-out);
}

.BulkActionFooter-btnBoxMenuDropUpListItem:hover {
  background: #3751ff14;
  border-radius: var(--border-radius-sm, 0.25rem);
}

.BulkActionFooter-btnBoxMenuDropUpListItemText {
  color: var(--color-foreground-secondary-bold, #00245b);
  text-overflow: ellipsis;
}

.BulkActionsBody-checkBoxDiv {
  display: flex;
  position: relative;
}

.BulkActionsBody-checkBoxDivIcon {
  position: absolute;
  left: -1rem;
}

.BulkActionsBody-checkBoxDiv .Checkbox-container {
  width: 100%;
}

.BulkActionsBody-checkBoxDiv .Checkbox-container .Checkbox-errorWrapper .Icon {
  height: 1rem;
  width: 1rem;
  margin-left: 0.313rem;
}

@media (min-width: 475px) and (max-width: 599px) {
  .BulkActionFooter-infoBoxDescription {
    white-space: normal;
  }

  .BulkActionFooter-infoBox .BulkActionFooter-clearSelectionIcon {
    position: absolute;
    display: block;
  }

  .BulkActionFooter-btnClearSelection {
    display: none;
  }

  .BulkActionFooter-btnBoxMenuDropUp {
    right: 0;
  }

  .BulkActionFooter-infoBoxDescription {
    width: 95%;
  }
}
