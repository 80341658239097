.ReviewAndSubmitModal-body {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ReviewAndSubmitModal-bodyError {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  gap: 1rem;
  margin: auto;
}

.ReviewAndSubmitModal-bodyErrorButtons {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.ReviewAndSubmitModal-bodyLoader--loading,
.ReviewAndSubmitModal-bodyLoader--finished,
.ReviewAndSubmitModal-bodyLoader--hidden,
.ReviewAndSubmitModal-bodyStep1--loading,
.ReviewAndSubmitModal-bodyStep1--visible,
.ReviewAndSubmitModal-bodyStep1--hidden,
.ReviewAndSubmitModal-bodyStep2--loading,
.ReviewAndSubmitModal-bodyStep2--visible,
.ReviewAndSubmitModal-bodyStep2--hidden {
  transition: var(--transition-deliberatestep);
  justify-content: center;
  align-items: center;
  min-width: 100%;
  display: flex;
}

.ReviewAndSubmitModal-bodyLoader--finished {
  transform: translateX(-100%);
}

.ReviewAndSubmitModal-bodyStep1--visible {
  transform: translateX(-100%);
}

.ReviewAndSubmitModal-bodyStep2--hidden {
  transform: translateX(-100%);
}

.ReviewAndSubmitModal-bodyLoader--hidden {
  transform: translateX(-200%);
}

.ReviewAndSubmitModal-bodyStep1--hidden {
  transform: translateX(-200%);
}

.ReviewAndSubmitModal-bodyStep2--visible {
  transform: translateX(-200%);
}
