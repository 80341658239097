.DynamicSort-sort {
  position: relative;
}

.DynamicSort-flexWrapper .Popover {
  padding: 1rem;
  z-index: 49;
}

.DynamicSort-flexContainer {
  flex-direction: column;
  gap: 1rem;
  width: 17rem;
}

.DynamicSort-popoverHeader {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}

.DynamicSort-popoverDescription {
  margin-top: 1rem;
}

.DynamicSort-popoverCloseIcon {
  margin-top: -0.25rem;
}

.DynamicSort-Popovercontrols {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.DynamicSort-trigger {
  margin-right: 0.5rem;
}

.Dynamic-dropdown {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: var(--z-index-certs-landingpage-dropdown);
  top: calc(100% + 0.375rem);
  left: 0;
  width: max-content;
  background-color: #ffffff;
  border: 1px solid #e4e7eb;
  border-radius: var(--border-radius-sm, 0.25rem);
}

.Dynamic-dropdown.sort-options-wrap {
  display: flex;
  align-items: center;
  padding-block: 1rem;
  padding-inline: 1rem 0.75rem;
}

.Dynamic-dropdown.sort-options-wrap .Menu-button {
  padding: 0.25rem;
  font-size: var(--font-size-12, 0.75rem);
  color: var(--color-foreground-secondary-bold, #00245b);
}

.Dynamic-dropdown.sort-options-wrap .Menu-button,
.Dynamic-dropdown.sort-options-wrap
  .selected-options-trigger
  .ButtonSelect
  label {
  padding: 0.5rem;
  font-size: var(--font-size-12, 0.75rem);
  color: var(--color-foreground-secondary-bold, #00245b);
}

.Dynamic-dropdown.sort-options-wrap .Menu .Menu-button,
.Dynamic-dropdown.sort-options-wrap .selected-options-trigger .ButtonSelect {
  margin-right: 0.5rem;
}

.Dynamic-dropdown.sort-options-wrap .Menu:first-child .Menu-button {
  min-width: 0.375rem;
}

.Dynamic-dropdown.sort-options-wrap .Menu:not(:first-child) .Menu-button {
  min-width: 7.125rem;
}

.Dynamic-dropdown .MenuItem {
  justify-content: space-between;
}

.Dynamic-dropdown.sort-options-wrap .Menu .Menu-button .Icon {
  width: initial;
  min-width: initial;
}

.SortSearchBar {
  padding: var(--spacing-300, 0.5rem);
}

.SortSearchBar .InputField-inputContainer input {
  font-size: var(--font-size-12, 0.75rem);
}

.SortSearchBar .InputField-inputContainer input::placeholder {
  font-size: var(--font-size-12, 0.75rem);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.sort-clear {
  margin-left: 0.25rem;
  cursor: pointer;
}

.Dynamic-sortlistWrap {
  min-height: 3rem;
  max-height: calc(100vh - 18rem);
  overflow: scroll;
  margin-bottom: 0.25rem;
}

.DynamicSort-sortTriggerWrap {
  position: relative;
}

.DynamicSort-sortTriggerWrap .Badge {
  position: absolute;
  top: -0.25rem;
  right: 0;
}

.DynamicSort-sortTriggerWrap .Button-outlined svg path {
  fill: var(--color-foreground-neutral-bold, #3751ff1f);
}

.CertificationDetails
  .CertificationDetails-header
  .CertificationDetails-header-container
  .CertificationDetails-header-controls
  .DynamicSort-trigger:hover {
  border-color: var(--color-foreground-primary-medium, #3751ff);
}

.DynamicSort-sortTriggerWrap .Button-outlined:hover svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}

.CertificationDetails
  .CertificationDetails-header
  .CertificationDetails-header-container
  .CertificationDetails-header-controls
  .DynamicSort-trigger--isSelected {
  border-color: var(--color-border-secondary-medium, #254b86);
}

.DynamicSort-sort .search-description {
  margin: 0.5rem;
  margin-bottom: 0;
  font-size: var(--font-size-12, 0.75rem);
  color: var(--color-foreground-neutral-subtle);
}

.Dynamic-dropdown.sort-options-wrap .selected-options-trigger .ButtonSelect {
  justify-content: space-between;
  min-width: 10.625;
  height: 2.3rem;
  padding: 0.5rem;
  background: transparent;
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-12, 0.75rem);
  color: var(--color-foreground-secondary-bold, #00245b);
}

.Dynamic-dropdown.sort-options-wrap .sort-option-second-level {
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  top: 3.875rem;
  left: 17px;
  border: none;
}

.Dynamic-dropdown.sort-options-wrap .DynamicSort-direction {
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  top: 3.875rem;
  left: 60%;
  border: none;
}

.DynamicSort-sort .Dynamic-sortlistWrap .MenuItem--isChecked {
  background: none;
  border-bottom: 2px solid var(--color-border-neutral-decorative, #ebecf2);
  border-radius: 0;
  padding-bottom: 0.5rem;
}

.DynamicSort-sort .Dynamic-sortlistWrap .MenuItem p {
  color: var(--color-foreground-secondary-bold, #00245b);
  padding: 4px 12px 4px 4px;
}

.DynamicSort-sort .Dynamic-sortlistWrap .MenuItem--isChecked p {
  color: var(--color-foreground-secondary-bold, #00245b);
}
