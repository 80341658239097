.Savi-Companion-Popover-Wrapper {
  display: block;
  width: 18.75rem;
  padding: 1rem;
}

.Savi-Companion-Popover-Wrapper .DisplayFlex {
  display: flex;
  gap: 0.25rem;
  background: var(
    --color-savi-gradient,
    linear-gradient(45deg, #4c00ac 0%, #8000ac 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0.5rem;
}

.Savi-Companion-Popover-Wrapper .DescriptionText {
  margin-top: 0.75rem;
  color: var(--color-foreground-neutral-bold, #212328);
  padding-bottom: 1rem;
}

.Savi-Companion-Popover-Wrapper .flexWrapper {
  display: flex;
  justify-content: flex-end;
}

.Savi-Companion-Popover-Wrapper .flexWrapper button {
  height: 2rem;
  width: 4rem;
  font-size: 0.75rem;
  padding: 0.625rem 0.75rem;
}

.Savi-Companion-Popover .Popover:has(> .Savi-Companion-Popover-Wrapper) {
  z-index: var(--z-index-modal-island);
}
