/* container houses the optional helper text */
.Upload-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Upload-headerWrapper {
  display: flex;
  align-items: center;
  gap: 0.125rem;
}
.Upload-headerWrapper .TooltipComponent-container {
  height: 1rem;
}

.Upload-headerText {
  color: var(--color-foreground-neutral-bold, #212328);
}

.Upload-helperText {
  font: var(--typography-body-3);
  color: var(--color-foreground-neutral-medium, #4a4c59);
  align-self: flex-start;
}

.Upload-dropzone {
  border-radius: var(--border-radius-sm, 4px);
  min-height: 10rem; /* 160px */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  transition: border-color var(--transition-quick);

  border: 1px dashed var(--color-border-primary-subtle, #6e7fff);
  background: var(--color-background-neutral-subtlest, #fff);
}

.Upload-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 0.75rem;
}

.Upload-content .Upload-icon {
  height: 2.25rem;
}

.Upload-icon .Icon {
  width: 2.25rem;
  height: 2.25rem;
}

.Upload-instructions {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
}

.Upload-maxNumberUploaded {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
  gap: 0.75rem;
}

.Upload-filetypes {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: var(--color-foreground-neutral-subtle, #717385);
  font: var(--typography-body-3);
}

.Upload-dropzoneAllowedFilesInfo {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Upload-input {
  display: none;
}

/* contains the trigger button and optional InlineMessage */
.Upload-buttonTriggerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.Upload-buttonTriggerContainer .Upload-filetypes {
  align-items: flex-start;
}

.Upload-buttonTriggerContainer .Upload-buttonAllowedFilesInfo {
  display: flex;
}

.Upload-buttonTrigger {
  min-width: 6.688rem;
}

.Upload--isDragging {
  background: rgba(55, 81, 255, 0.08);
  cursor: pointer;
}

.Upload-dropzone--isMaxNumberUploaded {
  background: var(--color-background-neutral-subtlest, #fff);
  border: 1px dashed var(--color-foreground-neutral-disabled, #9ea1b1);
}

.Upload-dropzone--isMaxNumberUploaded .Upload-instructions {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
  font: var(--typography-body-3);
}
