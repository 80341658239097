.MuiPickersCalendarHeader-iconButton:hover,
.MuiPickersCalendarHeader-iconButton:focus
{
    background: transparent !important;
}

.MuiPickersCalendarHeader-iconButton {
    background: transparent !important;
}

.dateRangePicker {
    .MuiPickersCalendarHeader-switchHeader {
        .MuiTypography-alignCenter {
            width: 20%;
            left: 45%
        }
    }
}