.menuItem {
  display: flex;
  margin: 0 4px 4px 4px;
  min-height: 48px;
  justify-content: center;
  align-content: center;
  color: var(--SIDEMENU-TEXT-COLOR);
  border-radius: 3px;
}

.selected {
  position: relative;
  visibility: visible;
  border-left: 2px solid;
  border-radius: 3px;
  margin: 3px 0;
  width: 2px;
  left: 3px;
}

.notSelected {
  visibility: hidden;
}

.level3Text {
  padding: 0 12px;
}

.level3Label {
  padding-left: 24px;
}

.bold span {
  font-weight: 700;
}

.selectedMenuItem {
  color: var(--SIDEMENU-ICON-PRIMARY);
  /* background: var(--SIDEMENU-HOVER-BACKGROUND); */
}

.menuItem.selectedMenuItem .selectedMenuBackground,
.menuItem.selectedMenuItem .selectedMenuBackground:hover {
  background-color: var(--SIDEMENU-SELECTED-BACKGROUND);
}

.notSelectedMenuItem {
  background: transparent;
}

a,
a:hover {
  text-decoration: none;
}

.listItem {
  background: var(--SIDEMENU-MENUITEM-BACKGROUND);
}

.itemText {
  transform: translateX(-18px);
}

.subtitle {
  font-size: 0.7rem;
  transform: translate(-70px, 15px);
}

.expandIcon {
  padding-right: 8px;
}

.spacer {
  margin-bottom: 12px;
}

.parentArrowIcon {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}
.childArrowIconClose {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.childArrowIconOpen {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
