.paginationComponent {
  display: flex;
  color: #6d737c;
  font-size: 11px;
  margin-top: 10px;
  .disabled{
    visibility: hidden;

  }
  .pagiLoader{
width: 15px;
height: 15px;
  }
  :global .dropdown-menu {
    min-width: auto;
    padding: 0;
    margin: 0;
  }
  :global .dropdown-toggle {
    font-size: 11px;
    padding: 0 10px;
    color: #6d737c;
  }
  :global .dropdown-item {
    font-size: 11px;
    color: #6d737c;
    padding: 5px 10px;
  }
  :global .dropdown-item:active {
    color: #fff;
    background-color: #0056b3;
  }
}
.goPrevious {
  margin-right: 10px !important;
  margin-left: 10px !important;
  color:black !important;
}
.goPrevious:hover{
  color: var(--primary-main) !important;
  }
  .goNext{
    color:black !important;
  }
  .goNext:hover{
    color: var(--primary-main) !important;
  }

  
