[data-theme='dark'] {
  --color-border-focus: var(--color-indigo-600);
  --color-border-savi-decorative: var(--color-purple-900);
  --color-border-savi-subtlest: var(--color-purple-800);
  --color-border-savi-subtle: var(--color-purple-600);
  --color-border-savi-medium: var(--color-purple-400);
  --color-border-savi-bold: var(--color-purple-100);
  --color-border-info-decorative: var(--color-info-900);
  --color-border-info-subtlest: var(--color-info-800);
  --color-border-info-subtle: var(--color-info-600);
  --color-border-info-medium: var(--color-info-400);
  --color-border-info-bold: var(--color-info-100);
  --color-border-warning-decorative: var(--color-warning-900);
  --color-border-warning-subtlest: var(--color-warning-800);
  --color-border-warning-subtle: var(--color-warning-400);
  --color-border-warning-medium: var(--color-warning-400);
  --color-border-warning-bold: var(--color-warning-100);
  --color-border-success-decorative: var(--color-success-900);
  --color-border-success-subtlest: var(--color-success-800);
  --color-border-success-subtle: var(--color-success-600);
  --color-border-success-medium: var(--color-success-400);
  --color-border-success-bold: var(--color-success-100);
  --color-border-critical-decorative: var(--color-critical-900);
  --color-border-critical-subtlest: var(--color-critical-800);
  --color-border-critical-subtle: var(--color-critical-600);
  --color-border-critical-medium: var(--color-critical-400);
  --color-border-critical-bold: var(--color-critical-100);
  --color-border-secondary-decorative: var(--color-navy-900);
  --color-border-secondary-subtlest: var(--color-navy-800);
  --color-border-secondary-subtle: var(--color-navy-600);
  --color-border-secondary-medium: var(--color-navy-400);
  --color-border-secondary-bold: var(--color-navy-100);
  --color-border-primary-decorative: var(--color-indigo-900);
  --color-border-primary-subtlest: var(--color-indigo-800);
  --color-border-primary-subtle: var(--color-indigo-600);
  --color-border-primary-medium: var(--color-indigo-400);
  --color-border-primary-bold: var(--color-indigo-100);
  --color-border-neutral-decorative: var(--color-neutral-900);
  --color-border-neutral-subtlest: var(--color-neutral-800);
  --color-border-neutral-subtle: var(--color-neutral-600);
  --color-border-neutral-medium: var(--color-neutral-400);
  --color-border-neutral-bold: var(--color-neutral-100);
  --color-background-blue-subtlest: var(--color-blue-1100);
  --color-background-blue-subtle: var(--color-blue-1000);
  --color-background-coral-subtlest: var(--color-coral-1100);
  --color-background-coral-subtle: var(--color-coral-1000);
  --color-background-green-subtlest: var(--color-green-1100);
  --color-background-green-subtle: var(--color-green-1000);
  --color-background-purple-subtlest: var(--color-purple-1100);
  --color-background-purple-subtle: var(--color-purple-1000);
  --color-background-orange-subtlest: var(--color-orange-1100);
  --color-background-orange-subtle: var(--color-orange-1000);
  --color-background-pink-subtlest: var(--color-pink-1100);
  --color-background-pink-subtle: var(--color-pink-1000);
  --color-background-teal-subtlest: var(--color-teal-1100);
  --color-background-teal-subtle: var(--color-teal-1000);
  --color-background-desaturated-inverse: var(--color-gray-000);
  --color-background-desaturated-subtlest: var(--color-gray-1100);
  --color-background-desaturated-subtle: var(--color-gray-1000);
  --color-background-desaturated-medium: var(--color-gray-900);
  --color-background-desaturated-bold: var(--color-gray-400);
  --color-background-savi-subtlest: var(--color-purple-1100);
  --color-background-savi-subtle: var(--color-purple-1000);
  --color-background-savi-medium: var(--color-purple-900);
  --color-background-savi-bold: var(--color-purple-400);
  --color-background-info-subtlest: var(--color-info-1100);
  --color-background-info-subtle: var(--color-info-1000);
  --color-background-info-medium: var(--color-info-900);
  --color-background-info-bold: var(--color-info-400);
  --color-background-warning-subtlest: var(--color-warning-1100);
  --color-background-warning-subtle: var(--color-warning-1000);
  --color-background-warning-medium: var(--color-warning-900);
  --color-background-warning-bold: var(--color-warning-300);
  --color-background-success-subtlest: var(--color-success-1100);
  --color-background-success-subtle: var(--color-success-1000);
  --color-background-success-medium: var(--color-success-900);
  --color-background-success-bold: var(--color-success-400);
  --color-background-critical-subtlest: var(--color-critical-1100);
  --color-background-critical-subtle: var(--color-critical-1000);
  --color-background-critical-medium: var(--color-critical-900);
  --color-background-critical-bold: var(--color-critical-400);
  --color-background-secondary-subtlest: var(--color-navy-1100);
  --color-background-secondary-subtle: var(--color-navy-1000);
  --color-background-secondary-medium: var(--color-navy-900);
  --color-background-secondary-bold: var(--color-navy-400);
  --color-background-primary-subtlest: var(--color-indigo-1100);
  --color-background-primary-subtle: var(--color-indigo-1000);
  --color-background-primary-medium: var(--color-indigo-900);
  --color-background-primary-bold: var(--color-indigo-400);
  --color-background-neutral-disabled-medium: var(--color-neutral-900);
  --color-background-neutral-disabled-subtle: var(--color-neutral-900);
  --color-background-neutral-subtlest: var(--color-neutral-1100);
  --color-background-neutral-subtle: var(--color-neutral-1000);
  --color-background-neutral-medium: var(--color-neutral-900);
  --color-background-neutral-bold: var(--color-neutral-400);
  --color-page-subtle: var(--color-navy-1000);
  --color-page-subtle-desaturated: var(--color-neutral-1000);
  --color-page-bold: var(--color-neutral-1100);
  --color-foreground-blue-subtle: var(--color-blue-400);
  --color-foreground-coral-subtle: var(--color-coral-400);
  --color-foreground-green-subtle: var(--color-green-400);
  --color-foreground-purple-subtle: var(--color-purple-400);
  --color-foreground-orange-subtle: var(--color-orange-400);
  --color-foreground-pink-subtle: var(--color-pink-400);
  --color-foreground-teal-subtle: var(--color-teal-400);
  --color-foreground-savi-subtle: var(--color-purple-600);
  --color-foreground-savi-medium: var(--color-purple-400);
  --color-foreground-savi-bold: var(--color-purple-200);
  --color-foreground-info-subtle: var(--color-info-600);
  --color-foreground-info-medium: var(--color-info-400);
  --color-foreground-info-bold: var(--color-info-200);
  --color-foreground-warning-subtle: var(--color-warning-600);
  --color-foreground-warning-medium: var(--color-warning-400);
  --color-foreground-warning-bold: var(--color-warning-200);
  --color-foreground-success-subtle: var(--color-success-600);
  --color-foreground-success-medium: var(--color-success-400);
  --color-foreground-success-bold: var(--color-success-200);
  --color-foreground-critical-subtle: var(--color-critical-600);
  --color-foreground-critical-medium: var(--color-critical-400);
  --color-foreground-critical-bold: var(--color-critical-200);
  --color-foreground-secondary-subtle: var(--color-navy-600);
  --color-foreground-secondary-medium: var(--color-navy-400);
  --color-foreground-secondary-bold: var(--color-navy-200);
  --color-foreground-primary-subtle: var(--color-indigo-600);
  --color-foreground-primary-medium: var(--color-indigo-400);
  --color-foreground-primary-bold: var(--color-indigo-200);
  --color-foreground-neutral-inverse: var(--color-neutral-1100);
  --color-foreground-neutral-disabled: var(--color-neutral-700);
  --color-foreground-neutral-subtle: var(--color-neutral-400);
  --color-foreground-neutral-medium: var(--color-neutral-200);
  --color-foreground-neutral-bold: var(--color-neutral-000);
}
