@import url('./hideHostElements.css');

.CertificationDetails {
  background: var(--page-bg, #f7f8fc);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content min-content auto 4rem;
}

.CertificationDetails-esCheckFailModal .ModalIsland-alertCloseButton {
  display: none;
}

.CertsExport {
  .AlertBanner-critical {
    background: #cc1c10;
    color: #fff;
  }

  .AlertBanner-information--low {
    background: #f1ecfd;
    border: 1px solid #573bdc;
    color: #212328;
  }

  .ButtonIcon-ghost svg path {
    fill: #212328;
  }
}

/* Needed to cover other popovers */
.CertificationDetails .Popover-wrapper--isOpen {
  z-index: var(--z-index-popover);
}
