.Checkbox.Checkbox--base {
  display: inline-flex;
  width: auto;
}

.Checkbox.Checkbox--base .Checkbox-label {
  padding: 0;
}

.Checkbox:not(.Checkbox--disabled, .Checkbox--hoverEffectDisabled)
  .Checkbox-icon:hover {
  color: var(--color-foreground-secondary-medium, #254b86);
  border: none;
  border-radius: 50%;
  outline: 11px solid #3751ff14;
  background: #3751ff14;
}

.Checkbox.Checkbox--disabled .Checkbox-icon {
  cursor: not-allowed;
}

.Checkbox.Checkbox--disabled .Checkbox-label {
  cursor: not-allowed;
}
