.ReviewAndSubmitModal .ModalIsland-titleAndHeaderIcon {
  padding: 0.75rem 1.5rem;
  overflow: unset;
}

.ReviewAndSubmitModal .ModalIsland-header {
  height: auto;
}

.ReviewAndSubmitModal .ModalIsland-titleContainer {
  gap: 0.5rem;
  overflow: unset;
}

.ReviewAndSubmitModal-header {
  position: relative;
}

.ReviewAndSubmitModal-headerIcon--hidden,
.ReviewAndSubmitModal-headerIcon--visible {
  display: flex;
  align-items: center;
  position: absolute;
  padding: 0.25rem;
  cursor: pointer;
  background: transparent;
  border-radius: var(--border-radius-circle, 50%);
  transition:
    background var(--transition-fast-in-out),
    left var(--transition-faststep);
}

.ReviewAndSubmitModal-headerIcon--hidden {
  left: -5rem;
}

.ReviewAndSubmitModal-headerIcon--visible {
  left: -2.5rem;
}

.ReviewAndSubmitModal-headerIcon--visible:hover {
  background: #5c5e6e14;
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}
