.MenuNested-nestedTrigger {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
}

.MenuNested-nestedTriggerLabel {
  color: var(--color-foreground-secondary-bold, #00245b);
  font: var(--typography-body-2);
  display: inline-block;
  display: -webkit-box;
  padding: 0.25rem 0.75rem 0.25rem 0;
  /* Using margin instead of pading for bottom because you can actually see the top of the line 3 wrapped text */
  padding-bottom: 0;
  margin-bottom: 0.25rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 30rem;
}
