.saviynt_logo1 {
    flex-direction: column;
    display: flex;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
}

.divider1 {
    color: gray;
    border: solid 0.3px #b4b4b4;
    width: 0.5px;
    height: 6.5px;
    margin-top: 14px;
    margin-right: 2px;
    margin-left: 4px;
}

.left-menu {
    padding: 0 25px;
}

.left-menu li {
    line-height: 25px;
    padding-right: 0px !important;
    /* margin-bottom: 6px !important; */
}

.left-menu li a,
.left-menu li label {
    font-size: 12px;
    color: var(--navbar-label-color);
    text-decoration: none;
    display: block;
    white-space: nowrap;
    margin-bottom: 0 !important;
}

.popup-switchermenu .nav-icon,
.left-menu li a .nav-icon {
    stroke: #9fa2b4;
    line-height: 1;
}

.left-menu li a:hover .nav-icon,
.left-menu li a:focus .nav-icon,
.left-menu li a .nav-icon.active {
    stroke: #3651ff;
}

.left-menu li a .icon-up,
.left-menu li a .icon-down {
    margin-top: 5px;
    float: right;
}

.left-menu .submenu {
    padding: 0px 0px 0px 0px;
}

.left-menu .submenu li {
    margin-top: 0px;
    line-height: 10px;
    margin-left: -10px;
}

.sidebar {
    background-color: #ffffff;
    padding: 15px 0 0 0;
    /* border-right: 1px solid #e6e6e6; */
    float: left;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    position: absolute;
    width: 230px;
}

.left-menu .submenu li a {
    border-radius: 4px !important;
    padding-left: 0 !important;
    padding: 7px 12px 7px 17px;
    color: #1e1e1e;
    font-weight: 500;
    margin-left: 9px;
}

.left-menu .badge-light {
    width: 41px;
    border-radius: 11px;
    background-color: #f0f1f7;
    color: #9fa2b4;
    font-size: 12px;
    margin-left: 18px;
    padding: 4px 0;
}

.sidebar .left-menu > :nth-child(2) {
    margin-top: 30px !important;
}

.sidebar .left-menu > :nth-child(3) {
    margin-top: 50px !important;
    border-top: solid 1px #dadee3;
}

.sidebar .left-menu > :nth-child(3) label {
    padding-top: 10px !important;
}
.grid-navbar-container {
    display: inline-grid;
    grid-template-columns: auto auto auto auto;
}
.grid-navbar-item {
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 5px;
    font-size: 14px;
}

.navbar-request-section {
    margin-top: 13px;
}

.list-side-section {
    width: 330px;
    font-family: 'Rubik', sans-serif;
}

.item-names .MuiTypography-body2 {
    font-size: 9px !important;
    color: var(--navbar-label-color);
}
.item-names {
    margin: 0px !important;
}

.item-names .MuiList-padding {
    padding-top: 2px !important;
    padding-bottom: 9px !important;
  
  }

.sidebar-icon-color svg path {
    fill: var(--navbar-icon-color);
}

.sidebar-icon-color:hover svg path {
    fill: var(--navbar-icon-hover);
}

.sidebar-icon-color{
  display:flex;
  align-items: center;
}
.sidebar-icon{
  width: 24px;
  height: 34px;
  padding-top: 3px;
  fill: var(--navbar-icon-color);
}
.left-menu li i.active svg path {
    fill: var(--navbar-icon-active);
}

a .saviynt-logo-size {
    width: 85px;
    margin-top: 7px;
    margin-left:-10px;
}

/* .divider {
  color: gray;
  border: solid 0.1px #b4b4b4;
  width: 0.5px;
  height: 6.5px;
} */

.left-menu-sidebar {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 8px !important;

}

/* =============Sidebar Navigation CSS============= */

.toggle-class {
    position: absolute;
    right: 28px;
    font-size: 24px;
    font-weight: 600;
    fill: #646464;
    line-height: 1;
}

.toggle-class:hover,
.toggle-class:focus {
    text-decoration: none;
    opacity: 0.5;
}

.logo,
.logo-small {
    color: #3751ff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    padding-left: 25px;
}

.side-nav {
    height: 506px;
    overflow: auto;
    margin: 30px 0 0 0;
}

.left-menu {
    padding: 0px;
}

.left-menu li a {
    font-size: 12px;
    color: var(--navbar-label-color);
    text-decoration: none;
    display: block;
    white-space: nowrap;
}

.left-menu li a .nav-icon {
    float: left;
    margin: 0 10px 0 0;
    stroke: #9fa2b4;
    line-height: 1;
}

.left-menu li a:hover .nav-icon,
.left-menu li a:focus .nav-icon,
.left-menu li a .nav-icon.active {
    stroke: #3651ff;
}

.left-menu li a .icon-up,
.left-menu li a .icon-down {
    margin-top: 5px;
    float: right;
}

.left-menu .submenu {
    padding-left: 0;
}

.left-menu .submenu li a {
    border-radius: 4px;
    color: #80829e;
    padding: 12px 12px 12px 17px;
}
.left-menu li a:hover,
.left-menu li a:focus,
.left-menu li a.active,
.left-menu .submenu li a:hover,
.left-menu .submenu li a:focus,
.left-menu .submenu li a.active {
    /* background-color: rgba(201, 216, 252, 0.5); */
    color: #3751ff;
}
.left-menu .badge-light {
    width: 41px;
    border-radius: 11px;
    background-color: #f0f1f7;
    color: #9fa2b4;
    font-size: 12px;
    margin-left: 18px;
    padding: 4px 0;
}
.hidescroll {
    overflow-y: hidden;
}
.closeSidebar .logo,
.logo-small {
    display: none;
}

.closeSidebar .logo-small {
    display: block;
}

.closeSidebar .sidebar {
    width: 60px;
    overflow: hidden;
}

.closeSidebar .submenu {
    display: none;
}

.toggle-class .la-angle-right,
.closeSidebar .toggle-class .la-angle-left {
    display: none;
}

.closeSidebar .toggle-class .la-angle-right {
    display: block;
}

.ml-auto, .mx-auto {
    margin-left: auto !important;
}

.MuiIconButton-root {
    border-radius: 50% !important;
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.app_name {
    display:flex !important;
    align-items: center !important;
}

/*.mt-4 {*/
/*    margin-top: 2.3rem!important;*/
/*}*/

.MuiIconButton-label {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
}

.MuiSvgIcon-root {
    font-size:20px !important;
}

.MuiBadge-dot {
    border-radius: 4px !important;
}

.left-menu-sidebar .navlabel {
    color: var(--navbar-label-color);
    margin-left: 8px;
}

.left-menu-sidebar .sidebarLink.active {
    color: var(--navbar-label-hover);
}

.sidebarLink:hover .navlabel {
    color: var(--navbar-label-active);
}

.sidebar-groupname {
    display: flex;
}

.sidebar-groupname-label {
    margin-top: 13px;
    font-size: 12px;
    color: var(--navbar-label-color) !important;
    margin-bottom: 10px !important;
    margin-left: 8px;
}

.sidebar-expand-icon {
    margin-left: auto !important;
    margin-right: 15px !important;
    color: var(--navbar-label-color) !important;
    margin-top: -8px !important;
}

.sidebar-searchbar {
    margin-left: 6% !important;
    margin-top: 4% !important;
    border: solid 1px #dddddd !important;
    width: 86%;
    padding: 4px !important;
    margin-bottom: 20px !important;
}

.sidebar-searchbar .MuiInputBase-root {
    color : var(--navbar-label-color) !important;   
}
 
.sidebar-menu-groupitem {
    margin-top: -8% !important;
    padding-left: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.sidebar-menu-groupitem:hover{
    border-radius: 3px;
  }

.remove-orphan-menulabel {
    margin-bottom: -17px!important;
    margin-top: -9px !important;
}

.left-menu-sidebar .MuiListItem-button:hover,  .left-menu-sidebar .MuiListItem-button:focus {
    background-color: var(--navbar-label-hover);
    color: var(--navbar-label-active);
}