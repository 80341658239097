.body {
  /* We expect to be placed in a flexbox */
  .Nav_KpisWidget {
    container: Nav_KpisWidget / inline-size;

    .Nav_KpisWidget-heading {
      margin-bottom: 1rem;

      /* Hide the heading unless there's at least one tile showing, since tiles can disappear if they error */
      display: none;

      &:has(+ .Nav_KpisWidget-tiles .Nav_KpisWidget-tiles-tile),
      &:has(+ .Nav_KpisWidget-loading) {
        display: block;
      }
    }

    .Nav_KpisWidget-tiles {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25rem;

      .Nav_KpisWidget-tiles-tile {
        /* This keeps the tiles the same size when they are laid out on 1 row or on 3/4 rows */
        flex: 1 0 14rem;
        max-width: 25rem;
        height: 4.5rem;

        /* This keeps the tiles same size when there are three tiles wrapped to two rows */
        &:is(:nth-child(1), :nth-child(2)):has(~ :nth-child(3)),
        &:nth-child(3):not(:has(~ :nth-child(4))) {
          @container Nav_KpisWidget (width < 42.5rem) and (width >= 28.25rem) {
            flex: 0 0 calc(50% - 0.125rem);
          }
        }

        /* This keeps the tiles same size, and the rows the same count, when
        there are four tiles wrapped to two rows */
        &:is(:nth-child(1), :nth-child(2), :nth-child(3)):has(~ :nth-child(4)),
        &:nth-child(4) {
          @container Nav_KpisWidget (width < 56.75rem) and (width >= 28.25rem) {
            flex: 0 0 calc(50% - 0.125rem);
          }
        }
      }
    }

    .Nav_KpisWidget-loading {
      background: var(--color-foreground-neutral-inverse, #ffffff);
      border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
      padding: 1rem 1.25rem;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4.5rem;
    }

    &.Nav_KpisWidget--error {
      border: 1px solid var(--color-border-critical-medium, #cc1c10);
      border-radius: var(--border-radius-lg, 1.5rem);
      display: flex;
      gap: 1rem;
      align-items: center;
      background: var(--color-background-critical-subtlest, #fdf5f4);
      color: var(--color-foreground-neutral-bold, #212328);
      padding: 0.625rem;

      .Nav_KpisWidget-errorIconContainer {
        border: solid 0.5rem var(--color-foreground-critical-medium, #cc1c10);
        border-radius: 1rem;

        .Nav_KpisWidget-errorIconContainer-errorIcon {
          display: block;
          margin: -0.375rem;
        }
      }

      .Nav_KpisWidget-errorMessage {
        display: flex;
        flex-direction: column;
        color: var(--color-foreground-neutral-bold, #212328);
      }
    }
  }
}
