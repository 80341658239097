.SpvAccounts {
  width: 100%;
  height: 100%;
}

.SpvAccounts-tabGroup {
  width: 100%;
  height: 2.5rem;
  align-items: center;
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  padding: 0 0.5rem;
}

.SpvAccounts-tabContent {
  display: flex;
  padding: 0 1.5rem 3rem 1.5rem;
  max-height: calc(100vh - 15rem);
  flex-direction: column;
  overflow-y: auto;
}

.SpvAccounts-tabSection {
  border-top: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}

.SpvAccounts-Scheduler {
  padding: 1rem 0;
}
