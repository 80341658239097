.filterButtonContainer {
    width: 25%;
    margin-left: 1rem;
}

.heading {
    margin: 1rem 1rem 0.5rem 1rem;
    width: 178px;
    font-weight: 500;
    color: #1E1E1E;
}

.listContainer {
    display: flex;
    flex-direction: column;
}

.clearButton {
    display: flex;
    align-items: center;
    margin: 0 1rem;
}

.checkboxList {
    margin: 0 1rem;
}

.checkboxList label {
    margin-bottom: 0;
    width: 100%;
}

.hr {
    margin: 0.5rem 1rem;
    border-top: 1px solid #CDCED8;
}
