.Category-content {
  display: flex;
  align-items: flex-start;
  gap: 0.2rem;
  align-self: stretch;
  color: var(--color-foreground-neutral-subtle, #717385);
}

.Category-text--bold {
  color: var(--color-foreground-neutral-bold, #212328);
}
