.ViewByDecision {
  width: 100%;
  padding-top: 0.25rem;
}

.ViewByDecision-loader {
  justify-content: center;
  display: flex;
  margin-top: 7rem;
}

.ViewByDecision-progressFailure {
  justify-content: center;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.ViewByDecision-errorMessage {
  text-align: center;
  margin-right: 0.25rem;
}

.ViewByDecision .ViewByDecision-todoSection {
  display: flex;
  padding: 0.25rem 0;
  align-items: center;
  align-self: stretch;
}

.ViewByDecision-todo,
.ViewByDecision-consulted {
  display: flex;
  padding: 0.25rem 0;
  align-items: center;
  align-self: stretch;
  gap: 0.25rem;
}

.ViewByDecision-done {
  display: flex;
  padding: 0.25rem 0;
  align-items: center;
  align-self: stretch;
  gap: 0.25rem;
}

.ViewByDecision-todoCount,
.ViewByDecision-consultedCount,
.ViewByDecision-doneCount {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.ViewByDecision-todoLabel,
.ViewByDecision-consultedLabel,
.ViewByDecision-doneLabel {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.ViewByDecision-divider {
  display: flex;
  padding: 0.25rem 0;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.ViewByDecision-doneItems {
  display: flex;
  padding-left: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
}
