.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 100vh;
}

.container {
    /*margin: 4.25rem var(--gridMargin) 1rem var(--gridMargin);*/
    margin: 1rem 4.25rem 1rem 4.25rem;
}

.footer {

}
