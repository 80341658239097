.popup-switchermenu .disabled svg,
.popup-switchermenu .disabled svg:hover,
.popup-switchermenu .disabled svg:focus {
  /* cursor: default */
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  stroke: #9fa2b4 !important;
}

.popup-switchermenu svg:hover,
.popup-switchermenu svg:focus {
  stroke: var(--primary-main) !important;
}

.popup-switchermenu svg {
  stroke: #9fa2b4 !important;
}

/* .popup-switchermenu div{
    padding-bottom: 30px;
} */
.ant-tabs-nav .ant-tabs-tab-active {
  color: var(--primary-main) !important;
}
.ant-tabs-ink-bar {
  background-color: var(--primary-main) !important;
}

.apps-menu {
  padding-bottom: 10px;
  padding-top: 10px;
}

/* .apps-menu:not(:nth-child(5)):not(:nth-child(6)) {
  padding-bottom: 20px;
}

.apps-menu:not(:nth-child(2)):not(:nth-child(4)):not(:nth-child(6)) {
  padding-right: 30px;
} */

.popup-switchermenu .app-label {
  color: #1e1e1e !important;
}

.ant-tabs-tab .ant-tabs-tab {
  width: 59px;
  height: 14px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b4b4b4;
}

.apps {
  padding-top: 20px;
}

.alignIcon {
  margin-left: 12px;
}

.alignLabel {
  padding: 0px;
  margin-left: 7px;
  margin-bottom: 5px;
}
