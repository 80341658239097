.FieldSet-fieldset,
.FieldSet-fieldset--compact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
}

.FieldSet-fieldset {
  gap: 0.25rem;
}

.FieldSet-fieldset--compact {
  gap: 0.125rem;
}

.FieldSet-fieldsetLabel {
  display: flex;
  align-items: flex-start;
  gap: 0.125rem;
  align-self: stretch;
  color: var(--color-foreground-neutral-subtle, #717385);
}

.FieldSet-fieldsetValue--filled,
.FieldSet-fieldsetValue--blank {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  flex: 1 0 0;
  word-break: break-word;
}

.FieldSet-fieldsetValue--filled {
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-foreground-secondary-bold, #00245b);
}

.FieldSet-fieldsetValue--blank {
  color: var(--color-foreground-neutral-subtle, #717385);
}
