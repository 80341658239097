.request-access-modal {
  .access-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    h1 {
      color: #000;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
  }
  .requestSection-title {
    text-align: center;
    h4 {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
  .requesting-for {
    .requestingForDropdown {
      margin: 0px auto 32px;
      float: none;
      display: table;
      margin-bottom: 32px;
      .dropdown-options {
        width: 400px;
      }
      .MuiAutocomplete-option[aria-selected='true'] {
        background-color: transparent !important;
        font-weight: 500;
      }
      .MuiAutocomplete-option[data-focus='true'],
      .MuiAutocomplete-option[aria-selected='true']:hover {
        background-color: rgba(55, 81, 255, 0.08);
      }
      .selected-icon {
        width: 30px;
      }
      .option-value-selected {
        font-weight: 500;
      }
    }
  }
  .requesting-to {
    .request-tiles {
      display: flex;
      gap: 1rem;
      .request-icon {
        margin-bottom: 16px;
      }
      .request-tile {
        width: 200px;
        height: 144px;
        padding: 16px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .request-title {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .request-tile.active {
        border: 1px solid #6888b5;
        background: #f7f9fc;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
        cursor: pointer;
        &:hover {
          background: rgba(37, 75, 134, 0.08);
        }
        &:active {
          border: 1px solid #254b86;
          background: rgba(37, 75, 134, 0.12);
        }
        .request-title {
          color: #212328;
        }
      }
      .request-tile.disabled {
        border: 1px solid #cecfd9;
        background: #fff;
        cursor: not-allowed;
        .request-title {
          color: #9ea1b1;
        }
      }
      &--hasLessThan3Tiles {
        .request-tile {
          flex-grow: 1;
        }
      }
      &--hasOneTile {
        justify-content: center;
        margin-top: 2rem;
      }
    }
  }
}

.no-access-bg-desktop {
  display: flex;
}

.no-access-content {
  padding: 32px 48px !important;
  .no-access-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      color: #212328;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 12px;
    }
    p {
      color: #4a4c59;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 24px;
    }
    .no-access-close-btn {
      padding: 10px;
      color: #3751ff;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      background: #fff;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
  .request-access-modal {
    .requesting-for {
      .requestingForDropdown {
        .dropdown-options {
          width: 336px;
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */

@media screen and (max-width: 600px) {
  .no-access-bg-desktop {
    top: -7px;
    position: relative;
  }
}

@media screen and (max-width: 480px) {
  .request-access-mobile-modal {
    .requestSection-title {
      h4 {
        color: #000;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 16px;
      }
    }
    .requesting-for {
      margin-bottom: 32px;
      .radio-cards {
        .radio-card {
          padding: 12px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          background: #fff;
          h5 {
            color: #00245b;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin: 0;
          }
          .MuiFormControlLabel-root {
            margin: 0;
          }
          .MuiRadio-root {
            padding: 0;
          }
          .MuiSvgIcon-root {
            width: 1.25em;
            height: 1.25em;
          }
        }
        .radio-card.active {
          border: 1px solid #254b86;
          .MuiSvgIcon-root {
            fill: #254b86;
          }
          .MuiIconButton-label .MuiSvgIcon-root:nth-child(2) {
            transform: scale(1.3);
          }
        }
        .radio-card.inactive {
          border: 1px solid #cfd9e9;
          .MuiSvgIcon-root {
            fill: #6888b5;
          }
        }
      }
    }
    .requesting-to {
      .request-tiles {
        .request-tile {
          padding: 12px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          .request-title {
            color: #00245b;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-left: 8px;
          }
        }
        .request-tile.active {
          border: 1px solid #6888b5;
          background: #f7f9fc;
          box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
          cursor: pointer;
          &:hover {
            background: rgba(37, 75, 134, 0.08);
          }
          &:active {
            border: 1px solid #254b86;
            background: rgba(37, 75, 134, 0.12);
          }
          .request-title {
            color: #00245b;
          }
        }
        .request-tile.disabled {
          border: 1px solid transparent;
          background: rgba(158, 161, 177, 0.12);
          cursor: not-allowed;
          .request-title {
            color: #9ea1b1;
          }
        }
      }
    }
  }
  .no-access-bg-desktop {
    display: none;
  }
  .no-access-content {
    padding: 0 !important;
    padding-top: 100px !important;
    .no-access-modal {
      background-image: url(/ECMv6/assets/images/NoAccessResponsive.svg);
      background-repeat: no-repeat;
      background-size: cover;
      height: 450px;
      .no-access-section {
        top: 145px;
        position: relative;
        padding: 20px 30px;
      }
    }
  }
}
