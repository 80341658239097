.container {
    background-color: white;
}

.calendarIcon {
    composes: calendarIcon from './../DateTimeRangePicker/DateTimeRangePicker.module.css';
}

.popoverContainer {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 700px;
}

.dateContainer {
    display: flex;
    width: 100%;
}
