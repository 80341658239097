.viewmore{
  width: 20px;
    height: 20px;
    background-color: #ffffff;
    color: var(--primary-main);
}
.viewless{
  transform: rotate(180deg);
}
.viewmoreTexts{
  font-size: 12px;
  color: var(--primary-main);
}
.viewmoreLayout{
  display: flex;
  justify-content: center;
  color: var(--primary-main);
  align-items: center;
}
.privilegeLayout{
  margin: 0px 0px;
  .privilegeTitle{
    color: #000000;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
.listpriLayout{
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  .entLayout{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding-right:10px;
    padding-left:0px;
    .entlistlabel{
      color: var(--grey-font);
      font-size: 11px;
      text-transform: capitalize;
      word-break: break-word;
    }
    .entlistVal{
      color: #000000;
      font-size: 12px;
      word-break: break-word;
    }
  }
}