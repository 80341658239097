.CertsControlsMore .Menu-dropdown {
  z-index: var(--z-index-certs-landingpage-dropdown);
}

.CertsControlsMore-trigger {
  margin-right: 0.5rem;
}

/* Override chevon removal from Groups file */
.CertsControlsGroup-controls
  .CertsControlsMore-trigger
  .ButtonSelect--rectangle-chevron {
  display: inline-block;
}

/*
.CertsControlsMore-dropdown {
  margin-top: 0.25rem;
  right: 0;
}
.CertsControlsMore-dropdown--isVisible {
  z-index: var(--z-index-certs-landingpage-more-filters);
}
.CertsControlsMore-suffixIcon {
  height: 1rem;
}

.CertsControlsMore-dropdown .Icon svg path {
  fill: var(--color-foreground-secondary-bold, #00245b);
} */
