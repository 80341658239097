.ReviewAndSubmitModal-bodyStep2Wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1.5rem 3rem;
  overflow: auto;
}

.ReviewAndSubmitModal-bodyStep2Wrapper--withESignature {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.ReviewAndSubmitModal-bodyStep2Wrapper::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.ReviewAndSubmitModal-bodyStep2WrapperAlertBox {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem 1rem;
  width: 100%;
  z-index: var(--z-index-certs-landingpage-dropdown);
}

.ReviewAndSubmitModal-bodyStep2WrapperComments {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  padding-block: 0.5rem;
}

.ReviewAndSubmitModal-bodyStep2WrapperCommentsDescriptionInput
  .InputField
  .InputField-labelContainer {
  position: absolute;
  top: 1.5rem;
}

.ReviewAndSubmitModal-bodyStep2WrapperCommentsDescriptionInput.noCommentsPresent
  .InputField
  .InputField-inputContainer {
  margin-top: 2rem;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignatureESig {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  position: relative;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignatureESigBox
  .InputField
  .InputField-labelContainer {
  position: absolute;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignatureESigBox
  .InputField
  .InputField-inputContainer {
  display: none;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignatureESigBox
  .InputField
  .InlineMessage {
  position: absolute;
  bottom: 2.75rem;
  padding: 0;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePad {
  margin-top: 1.5rem;
  position: relative;
  width: 100%;
  height: 10.25rem;
  background: var(--color-page-bold, #fff);
  border-radius: var(--border-radius-sm, 0.25rem);
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabGroup {
  width: 100%;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabGroupSpacer--before {
  padding-left: 1.5rem;
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabGroupSpacer--after {
  width: 100%;
  min-width: 1.5rem;
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabContent {
  width: 100%;
  height: 7.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabContentBgText {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-60%);
  padding-inline: 0.5rem;
  color: var(--color-foreground-neutral-subtle, #717385);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Rubik;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 2.5rem;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabContentInput {
  width: 100%;
  height: 100%;
  background: transparent;
  text-align: center;
  border: none;
  outline: none;
  padding: 1rem;
  z-index: var(--z-index-certs-landingpage-dropdown);
  color: var(--color-foreground-neutral-bold, #212328);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Dancing Script';
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 400;
  line-height: 2.5rem;
  transform: translateY(-0.375rem);
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabContentInput.filled,
.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabContentInput:focus {
  background: var(--color-page-bold, #fff);
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabContentDraw {
  width: 100%;
  height: 100%;
  z-index: var(--z-index-certs-landingpage-dropdown);
  transform: translateY(-0.375rem);
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabContentDraw.hideBgText {
  background: var(--color-page-bold, #fff);
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabContentUpload {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabContentUpload
  .UploadedFile {
  border: none;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadTabContentUpload
  .Upload-buttonTriggerContainer {
  width: 100%;
  top: 50%;
  left: 0;
  position: absolute;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadClearBtn {
  margin-top: 2rem;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignaturePadClearBtn.noCommentsPresent {
  margin-top: 0.25rem;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignatureTnCText {
  text-align: justify;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignatureTnC .InputField {
  gap: 0;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignatureTnCCheckBox
  .InputField
  .InputField-labelContainer {
  padding-top: 0.75rem;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignatureTnCCheckBox
  .InputField
  .InputField-labelContainer
  .Checkbox-labelText {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.ReviewAndSubmitModal-bodyStep2WrapperSignatureTnCCheckBox
  .InputField
  .InputField-inputContainer {
  display: none;
}

.ReviewAndSubmitModal-bodyStep2WrapperSignatureTnCCheckBox
  .InputField
  .InlineMessage {
  padding: 0;
}
