.MenuMulti-wrapper {
  position: relative;
  .MenuMulti-items .Icon svg {
    width: unset;
  }
}

.MenuMulti-trigger {
}

.MenuMulti-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 26.5625rem;
  background: var(--color-background-neutral-subtlest, #fff);
  z-index: var(--z-index-dropdown);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  transition: height var(--transition-quick-in-out);
  width: max-content;
  overflow: hidden;
}

.MenuMulti-items {
  list-style: none;
  margin: 0;
  padding: 0.75rem;
  min-width: min-content;
  white-space: nowrap;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 4px);
  min-width: 10rem;
  width: 100%;
  max-width: 30rem;
  max-height: 32rem;
  overflow-y: auto;
  scrollbar-width: thin;
}

/* Sizes */
.MenuMulti-dropdown--small {
  width: 10rem;
}
.MenuMulti-dropdown--medium {
  width: 16rem;
}
.MenuMulti-dropdown--large {
  width: 24rem;
}
.MenuMulti-dropdown--xLarge {
  width: 30rem;
}

.MenuMulti-items--hasNoText {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
}
/* safari */
.MenuMulti-items::-webkit-scrollbar {
  width: 0.25rem;
}

.MenuMulti-items::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
}

.MenuMulti button label {
  display: initial; /* fixes text spacing issue in button label */
}

.MenuMulti-items-selectionDivider {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  height: 1.0625rem;

  div {
    height: 1px;
    width: 100%;
    background-color: var(--color-border-neutral-decorative, #ebecf2);
  }
}

.MenuMulti-headerText {
  color: var(--color-foreground-neutral-subtle, #717385);
  padding: 0.5rem 0.75rem 0rem 0.75rem;
}

.MenuMulti--hasNoData {
  padding: 0.25rem 0.75rem;
  font: var(--typography-body-3);
  color: var(--color-foreground-neutral-subtle, #717385);
  text-align: center;
}

.MenuMulti--hasNoData:hover {
  background: var(
    --color-background-primary-on-subtle-hover,
    rgba(55, 81, 255, 0.08)
  );
}
