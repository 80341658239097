.popupMoreComments{
  max-height: 100% !important;
  width: 100%;
border-radius: 0 !important;
height: 100%;
}
.commentsTitle{
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  line-height: 1.25;
}
.commentsSubTitle{
  font-size: 12px;
  color: var(--grey-font);
  margin-bottom: 0;
  font-weight: normal;
}

.fileseparator{
  height: 1px;
  margin-bottom: 10px;
  margin-left: 10px;
  opacity: 0.5;
  background-color: #dddddd;
  margin-top: 20px;
}
.commentsModalfooter{
  padding-right: 36px;
  box-shadow: 0 -2px 4px 0 rgba(225, 226, 229, 0.5);
  button{
    font-size: 11px;
    height: 32px;
  }
}