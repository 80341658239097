.Avatar {
  /* TODO: Remove !important if Metronic from bootstrap is removed*/
  border-radius: 4rem !important;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: var(--color-background-neutral-medium, #cecfd9);
  color: var(--color-foreground-secondary-medium, #254b86);
}

.Avatar-img {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  border-radius: 100%;
  border: 2px solid var(--color-border-secondary-inverse, #f2f4f8);
}

.Avatar--xxSmall,
.Avatar--xxSmall img,
.Avatar--xxSmall svg {
  font: var(--typography-body-bold-3);
  width: 1.25rem;
  height: 1.25rem;
}

.Avatar--xSmall,
.Avatar--xSmall img,
.Avatar--xSmall svg {
  font: var(--typography-body-bold-2);
  width: 1.5rem;
  height: 1.5rem;
}

.Avatar--small,
.Avatar--small img,
.Avatar--small svg {
  font: var(--typography-body-bold-1);
  width: 2rem;
  height: 2rem;
}

.Avatar--medium,
.Avatar--medium img,
.Avatar--medium svg {
  font: var(--typography-heading-2);
  width: 2.5rem;
  height: 2.5rem;
}

.Avatar--large,
.Avatar--large img,
.Avatar--large svg {
  font: var(--typography-heading-1);
  width: 3rem;
  height: 3rem;
}

.Avatar--xLarge,
.Avatar--xLarge img,
.Avatar--xLarge svg {
  font: var(--typography-heading-1);
  width: 3.5rem;
  height: 3.5rem;
}

.Avatar--xxLarge,
.Avatar--xxLarge img,
.Avatar--xxLarge svg {
  font: var(--typography-display);
  width: 4rem;
  height: 4rem;
}
