/* =============Footer CSS============== */

.left-align{
  max-width:  68%;
}

.footer-layout {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 8px 20px 5px 20px;
  font-size: 12px;
  font-weight: 400;
  color: var(--home-subtitle);
}

.footer-container {
  height: 36px;
  position: relative;
}
