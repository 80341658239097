/* Button */
.CertsAdvancedFilterButton {
  margin-right: 0.5rem;
}

.AdvancedFilters-filterBar--isActive,
.AdvancedFilters-filterBar--isDisabled {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding-inline: 3rem;
  background: var(--page-bg, #f7f8fc);
  transition: height var(--transition-quick-out);
}

.AdvancedFilters-filterBar--isDisabled {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.AdvancedFilters-filterBar--isActive {
  opacity: 1;
  overflow: unset;
  height: auto;
  padding-top: 0.5rem;
  background: var(--page-bg, #f7f8fc);
}

.AdvancedFilters-filterBar--isActive .MenuMulti-dropdown.MenuMulti-dropdown {
  min-width: 12rem;
  max-width: 17rem;
}

.AdvancedFilters-filterBar--isActive .MenuMulti-items .MenuItemMulti-label {
  word-wrap: break-word;
}

.AdvancedFilters-filterBarDropDownWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.AdvancedFilters-filterBar--isActive .MenuMulti-dropdown .MenuMulti-items {
  max-height: calc(100vh - 24rem);
  min-height: 3rem;
  min-width: unset;
}

.AdvancedFilters-filterBarMenuItemHeaderText {
  white-space: nowrap;
}

.AdvancedFilters-filterBarMenuItemHeaderTextPreToken {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.AdvancedFilters-filterBarMenuItemHeaderTextPostToken {
  color: var(--color-foreground-neutral-bold, #212328);
}

.AddFilterDropdown-dropDown--isDisabled,
.AddFilterDropdown-dropDown--isActive {
  box-shadow: 0 0.125rem 1rem 0.125rem rgba(0, 0, 0, 0.08);
  position: absolute;
  top: calc(100% + 0.375rem);
  left: 0;
  overflow: hidden;
  width: 18.5rem;
  background-color: #fff;
  border: 1px solid #e4e7eb;
  border-radius: var(--border-radius-sm, 0.125rem);
  transition: var(--transition-quickstep-in-out);
}

.AddFilterDropdown-dropDown--isDisabled {
  opacity: 0;
  max-height: 0;
}

.AddFilterDropdown-dropDown--isActive {
  opacity: 1;
}

.AddFilterDropdown-dropDownRight {
  left: unset;
  right: 0;
}

.AddFilterDropdown-dropDownHeadingDescription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.125rem;
  padding: 0.5rem;
}

.AddFilterDropdown-dropDownSearchBar {
  padding-inline: 0.5rem;
}

.AddFilterDropdown-dropDownFilterList {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 0.5rem;
  overflow: auto;
  padding: 0.75rem;
  margin-block: 0.25rem;
  width: calc(100% - 0.125rem);
  min-height: 3rem;
  max-height: calc(90vh - 20rem);
}

.AddFilterDropdown-dropDownFilterList::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.AddFilterDropdown-dropDownFilterListItems {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
}

.AddFilterDropdown-dropDownFilterListItemsWrapper {
  display: flex;
  transition: var(--transition-quickstep-in-out);
  cursor: pointer;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 0.5rem;
  border-radius: var(--border-radius-sm, 0.25rem);
  padding: 0.375rem 0.5rem;
}

.AddFilterDropdown-dropDownFilterListItemsWrapper:hover {
  background-color: var(--color-background-secondary-subtle, #f2f4f8);
}

.AddFilterDropdown-dropDownFilterListItemsCustomButtom--isEnabled,
.AddFilterDropdown-dropDownFilterListItemsCustomButtom--isDisabled {
  cursor: pointer;
  border-radius: var(--border-radius-circle, 50%);
}

.AddFilterDropdown-dropDownFilterListItemsCustomButtom--isEnabled {
  background: var(--color-background-secondary-bold, #254b86);
}

.AddFilterDropdown-dropDownFilterListItemsCustomButtom--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
}

.AddFilterDropdown-dropDownFilterListItemsCustomButtomText--isEnabled,
.AddFilterDropdown-dropDownFilterListItemsCustomButtomText--isDisabled {
  color: var(--color-foreground-neutral-bold, #212328);
}

.AdvancedFilters-filterBarDropDownWrap .AddFilterDropdown-dropDown--isActive,
.AdvancedFilters-filterBarDropDownWrap .AddFilterDropdown-dropDown--isDisabled {
  z-index: var(--z-index-certs-landingpage-advanced-filters);
}

.AdvancedFilters-filterBar--isActive .MenuMulti-dropdown {
  z-index: var(--z-index-certs-landingpage-advanced-filters--active);
}

/* Tranition added to comonents in the contianter to avoid using overflow hidden */
.CertsControlsGroup-advancedFilters .ButtonIcon {
  transition: opacity var(--transition-steady-ease) var(--transition-fast);
}
.AdvancedFilters-filterBar--isDisabled .ButtonIcon {
  opacity: 0;
}
.AdvancedFilters-filterBar--isActive .ButtonIcon {
  opacity: 1;
}

.AdvancedFilters-linkBtnWrapper {
  display: flex;
  align-items: center;
  height: 2rem;
}

.AdvancedFilters-triggerBadgeWrap {
  z-index: 1;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background: var(--color-background-secondary-bold, #254b86);
  border-radius: var(--border-radius-circle);
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.25rem;
  display: flex;
}

.AdvancedFilters-triggerBadgeWrapText {
  color: var(--color-foreground-neutral-inverse, #fff);
  text-align: center;
}

/**
  For mobile responsive design below 600px (Drawer componenet)
**/

.AdvancedFilters-filterDrawer {
  justify-content: flex-start;
  background: var(--color-background-secondary-subtle, #f2f4f8);
}

.AdvancedFilters-filterDrawer .MobileDrawer-headerAndCloseOptions,
.AdvancedFilters-filterDrawer .MobileDrawer-content {
  background: var(--color-background-neutral-subtlest, #fff);
}

.AdvancedFilters-filterDrawer .MobileDrawer-content {
  overflow: hidden !important;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
  align-items: flex-start;
  height: 100%;
  background: var(--color-background-secondary-subtle, #f2f4f8);
}

.AdvancedFilters-filterDrawerSegments {
  background: var(--color-background-secondary-subtle, #f2f4f8);
  min-width: 100%;
  height: 100%;
  position: relative;
  transform: translateX(0%);
  transition: var(--transition-quick-in-out);
}

.AdvancedFilters-filterDrawerSegmentsStep1,
.AdvancedFilters-filterDrawerSegmentsStep2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.AdvancedFilters-filterDrawerSegmentsStep1--transtioned,
.AdvancedFilters-filterDrawerSegmentsStep2--transtioned {
  transform: translateX(-100%);
}

.AdvancedFilters-filterDrawerHeader {
  width: 100%;
  display: flex;
  padding: 1.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  background: var(--color-background-neutral-subtlest, #fff);
}

.AdvancedFilters-filterDrawerHeaderTitle {
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
}

.AdvancedFilters-filterDrawerHeaderTitle--focused {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.AdvancedFilters-filterDrawerHeaderTitleAppliedTo {
  display: flex;
}

.AdvancedFilters-filterDrawerHeaderTitleAppliedTo--categories {
  color: var(--color-foreground-neutral-bold, #212328);
  font-feature-settings:
    'liga' off,
    'clig' off;
}

.AdvancedFilters-linkBtnWrapper--mobile {
  display: flex;
  align-items: center;
  height: 2rem;
  width: 4rem;
}

.AdvancedFilters-filterDrawerBodyStep1 {
  display: flex;
  padding-inline: 1rem;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  height: calc(100% - 9rem);
  overflow-y: auto;
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  background: var(--color-background-neutral-subtlest, #fff);
}

.AdvancedFilters-filterDrawerBodyStep1::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.AdvancedFilters-filterDrawerBodyStep1MenuItems {
  display: flex;
  width: 100%;
  min-height: 3rem;
  padding: 0.5rem 0 0.5rem 0.75rem;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 0.25rem;
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  transition: var(--transition-quick-in-out);
}

/* .AdvancedFilters-filterDrawerBodyStep1MenuItems:hover {
  background-color: #3751ff14;
} */

.AdvancedFilters-filterDrawerBodyStep1MenuItemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  width: calc(100% - 2rem);
}

.AdvancedFilters-filterDrawerBodyStep1MenuItemsWrapper--filterApplied {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.25rem;
}

.AdvancedFilters-filterDrawerBodyStep1MenuItemsText--noFilterApplied,
.AdvancedFilters-filterDrawerBodyStep1MenuItemsText--filterApplied {
  overflow: hidden;
  /* color: var(--color-foreground-secondary-bold, #00245b); */
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.AdvancedFilters-filterDrawerBodyStep1MenuItemsText--noFilterApplied {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.AdvancedFilters-filterDrawerBodyStep1MenuItemsText--filterApplied {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.AdvancedFilters-filterDrawerBodyStep1MenuItemsTextBadge {
  transform: translateY(-0.25rem);
}

.AdvancedFilters-filterDrawerBodyStep1MenuItemsText--optionsSelected {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--color-foreground-neutral-subtle, #717385);
  text-overflow: ellipsis;
  text-align: left;
}

.AdvancedFilters-filterDrawerBodyStep2 {
  height: calc(100% - 9rem);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--color-background-neutral-subtlest, #fff);
}

.AddFilterDropdown-dropDownHeadingDescription--mobileResponsive,
.AddFilterDropdown-dropDownSearchBar--mobileResponsive {
  padding-inline: 1rem;
  padding-top: 1rem;
}

.AddFilterDropdown-dropDownFilterList--mobileResponsive {
  flex-flow: column;
  justify-content: flex-start;
  margin-block: 0.25rem;
  padding-inline: 1rem;
  padding-top: 0.25rem;
  display: flex;
  overflow-y: auto;
}

.AddFilterDropdown-dropDownFilterList--mobileResponsive::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.AddFilterDropdown-dropDownFilterListItems--mobileResponsive {
  align-self: stretch;
  align-items: center;
  display: flex;
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.AddFilterDropdown-dropDownFilterListItemsWrapper--mobileResponsive {
  cursor: pointer;
  flex-flow: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 0.675rem 0.75rem;
  display: flex;
  transition: var(--transition-quickstep-in-out);
}

.AddFilterDropdown-dropDownFilterListItemsWrapper--mobileResponsive:hover {
  background-color: var(--color-background-secondary-subtle, #f2f4f8);
}

.AdvancedFilters-filterDrawerFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.75rem 1.5rem;
  display: flex;
  flex-direction: column;
  background: var(--color-background-neutral-subtlest, #fff);
}

.AdvancedFilters-filterDrawerFooter .Button-content-container {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

.SmartFilter-dropdown-displayFlex--mobileResponsive {
  padding: 0.75rem 1.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: stretch;
}

.SmartFilter-dropdown-displayFlex--mobileResponsive::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.SmartFilter-factorsItemList--mobileResponsive {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.75rem;
}

.SmartFilter-dropdown-displayFlex--mobileResponsive
  .SmartFilter-factorsItemList--mobileResponsive
  .RadioButton-title {
  font: var(--typography-body-2);
}

.SmartFilter-dropdown-displayFlex--mobileResponsive
  .SmartFilter-factorsItemList--mobileResponsive
  .RadioButton-description {
  font: var(--typography-body-3);
}

.SmartFilter-dropdown-displayFlex--mobileResponsive
  .SmartFilter-factorsItemList--mobileResponsive
  .RadioButton-button {
  height: var(--icon-size-xsmall);
  width: var(--icon-size-xsmall);
}

.SaviFilter-dropdown-displayFlex {
  padding: 0.25rem 1rem;
}

.AdvancedFilters-filterDrawerStep2NormalFilterOptions {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0.25rem 1rem;
  margin-top: 0.5rem;
  overflow-y: auto;
}

.AdvancedFilters-filterDrawerStep2NormalFilterOptions::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.AdvancedFilters-filterDrawerStep2NormalFilterOptionsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 3rem;
  min-height: 3rem;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  transition: var(--transition-quick-in-out);
}

.AdvancedFilters-filterDrawerStep2NormalFilterOptionsWrapper:hover {
  background-color: #3751ff14;
}

.AdvancedFilters-filterDrawerStep2NormalFilterOptionsWrapper
  .Checkbox-container {
  align-self: stretch;
  height: 100%;
  justify-content: center;
}

.AdvancedFilters-filterDrawerStep2NormalFilterOptionsWrapper
  .Checkbox-container
  .Checkbox {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.AdvancedFilters-filterDrawerStep2NormalFilterOptionsWrapper
  .Checkbox-container
  .Checkbox-label {
  height: 100%;
  padding: 0.5rem 0 0.5rem 0.75rem !important; /* To override the checkbox gap - only for this div */
  gap: 1rem !important; /* To override the checkbox gap - only for this div */
}

.AdvancedFilters-filterDrawerStep2NormalFilterOptionsWrapper
  .Checkbox-container
  .Checkbox-label
  .Checkbox-labelText {
  white-space: normal;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
}

.AdvancedFilters-filterDrawerClearAndRemoveBtns {
  font: var(--typography-body-2);
  color: var(--color-foreground-secondary-bold, #00245b);
}

.AdvancedFilters-filterDrawerStep2NormalFilter--noDataScreen {
  text-align: center;
  margin-top: 1rem;
}

/* Responsive */
@media (max-width: 1279px) {
  .AdvancedFilters-filterBar--isActive {
    padding-inline: 1.5rem;
  }
}
