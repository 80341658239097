.datePicker-custom-style {
    .MuiPickersCalendarHeader-iconButton:hover,
    .MuiPickersCalendarHeader-iconButton:focus {
        background: transparent !important;
    }

    .MuiPickersCalendarHeader-iconButton {
        background: transparent !important;
    }

    .MuiPickersCalendarHeader-switchHeader {
        .MuiTypography-alignCenter {
            width: 20%;
            left: 45%;
            font-size: 14px;
            font-weight: 500;
            color: #242424;
        }
    }

    .MuiPickersCalendarHeader-daysHeader {
        left: 17px;
        position: relative;
        .MuiPickersCalendarHeader-dayLabel {
            font-size: 12px;
            font-weight: 500;
            color: #242424;
            text-transform: uppercase;
            width: 40px;
            margin: 0;
            letter-spacing: 40px;
            overflow: hidden;
        }
    }

    .MuiPickersCalendar-week {
        .MuiPickersDay-day {
            font-size: 12px;
            font-weight: normal;
            color: #242424;
        }
        .MuiPickersDay-daySelected {
            font-weight: 500;
            color: #ffffff;
        }
        .MuiPickersDay-dayDisabled {
            color: #b4b4b4;
        }
        .MuiIconButton-root:hover,
        .MuiIconButton-root:focus {
            background-color: rgba(55, 81, 255, 1) !important;
            color: #fff;
        }
    }
}
