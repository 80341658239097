.container {
    display: flex;
    width: 100%;
    align-items: center;
    margin-left: 3rem;
}

.label {
    width: 50px;
    margin-bottom: 0;
}

.quickHeading {
    margin-bottom: 1rem;
    margin-left: 3rem;
}

.grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 3rem;
}

.gridItem {
    /*grid-column: auto / span 2;*/
    margin: 0 1rem 1rem 0;
}
