.MenuItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0.25rem 0 0.25rem 0.75rem;
  border-radius: var(--border-radius-sm, 4px);
  color: var(--color-foreground-secondary-bold, #00245b);
  font: var(--typography-body-2);
  transition: background var(--transition-quick-in-out);
  cursor: pointer;
  overflow: hidden;
}

.MenuItem-suffixIcon {
  height: 1.5rem;
  width: 1.5rem;
}

.MenuItem .Icon svg path {
  fill: var(--color-foreground-secondary-bold, #00245b);
}

.MenuItem:not(.MenuItem--isDisabled):hover {
  /* TODO: use css var when working */
  background: rgba(55, 81, 255, 0.08);
}

.MenuItem:not(.MenuItem--isDisabled):active {
  /* TODO: use css var when working */
  background: rgba(55, 81, 255, 0.1);
}

.MenuItem:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  border-radius: 4px;
}

.MenuItem--isDisabled {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
}
.MenuItem--isDisabled .Icon svg path {
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
}
.MenuItem--isDisabled:hover {
  cursor: not-allowed;
}

.MenuItem--isChecked {
  border-radius: 4px;
  background: var(
    --light-brand-primary-press-selected,
    rgba(55, 81, 255, 0.12)
  );
}

.MenuItem-prefixIconAndLabel {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
}

.MenuItem-prefixIconAndLabel-label {
  display: inline-block;
  display: -webkit-box;
  padding: 0.25rem 0.75rem 0.25rem 0;
  /* Using margin instead of pading for bottom because you can actually see the top of the line 3 wrapped text */
  padding-bottom: 0;
  margin-bottom: 0.25rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 30rem;
}

.MenuItem--isChecked.MenuItem--hasSuffix {
  .MenuItem-prefixIconAndLabel,
  .MenuItem-prefixIconAndLabel-label {
    max-width: 26.75rem;
  }

  &.MenuItem--hasPrefix .MenuItem-prefixIconAndLabel-label {
    max-width: 24.5rem;
  }
}

.MenuItem--hasPrefix .MenuItem-prefixIconAndLabel-label {
  max-width: 25.75rem;
}

/* Baselines for different dropdown sizes */
.Menu-dropdown--small .MenuItem-prefixIconAndLabel-label,
.Menu-dropdown--medium .MenuItem-prefixIconAndLabel-label,
.Menu-dropdown--large .MenuItem-prefixIconAndLabel-label,
.Menu-dropdown--xLarge .MenuItem-prefixIconAndLabel-label {
  width: unset;
}

/* Dropdown with suffix icons and prefix */
.Menu-dropdown--small,
.Menu-dropdown--medium,
.Menu-dropdown--large,
.Menu-dropdown--xLarge {
  & .MenuItem--hasPrefix .MenuItem-prefixIconAndLabel,
  & .MenuItem--hasPrefix .MenuItem-prefixIconAndLabel-label {
    &.Menu-dropdown--small {
      max-width: 6rem;
    }
    &.Menu-dropdown--medium {
      max-width: 12rem;
    }
    &.Menu-dropdown--large {
      max-width: 20rem;
    }
    &.Menu-dropdown--xLarge {
      max-width: 26rem;
    }
  }
}

/* Checked menu items with only suffix icon */
.Menu-dropdown--small .MenuItem--isChecked.MenuItem--hasSuffix,
.Menu-dropdown--medium .MenuItem--isChecked.MenuItem--hasSuffix,
.Menu-dropdown--large .MenuItem--isChecked.MenuItem--hasSuffix,
.Menu-dropdown--xLarge .MenuItem--isChecked.MenuItem--hasSuffix {
  .MenuItem-prefixIconAndLabel,
  .MenuItem-prefixIconAndLabel-label {
    &.Menu-dropdown--small {
      max-width: 10rem;
    }
    &.Menu-dropdown--medium {
      max-width: 16rem;
    }
    &.Menu-dropdown--large {
      max-width: 24rem;
    }
    &.Menu-dropdown--xLarge {
      max-width: 30rem;
    }
  }
}

/* Checked, with both prefix and suffix icons */
.Menu-dropdown--small
  .MenuItem--isChecked.MenuItem--hasSuffix.MenuItem--hasPrefix,
.Menu-dropdown--medium
  .MenuItem--isChecked.MenuItem--hasSuffix.MenuItem--hasPrefix,
.Menu-dropdown--large
  .MenuItem--isChecked.MenuItem--hasSuffix.MenuItem--hasPrefix,
.Menu-dropdown--xLarge
  .MenuItem--isChecked.MenuItem--hasSuffix.MenuItem--hasPrefix {
  .MenuItem-prefixIconAndLabel,
  .MenuItem-prefixIconAndLabel-label {
    &.Menu-dropdown--small {
      max-width: 4rem;
    }
    &.Menu-dropdown--medium {
      max-width: 10rem;
    }
    &.Menu-dropdown--large {
      max-width: 18rem;
    }
    &.Menu-dropdown--xLarge {
      max-width: 24rem;
    }
  }
}
