.ISPM-IframeLoaderSection {
  height: calc(100vh - 100px);
  transition: width 0.3s ease;
  width: 100%;
}

.ISPM-IframeLoaderSection-iframe {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  border: none;
}

.ISPM-IframeLoaderSection--shimmer-ui {
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  animation: ISPM-IframeLoaderSection-shimmer 1.5s infinite;
  margin-top: 10px;
}

@keyframes ISPM-IframeLoaderSection-shimmer {
  0% {
    background-position: -100vw 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
