/* TODO: Reduce specificity if we ever fix the style build order */
/* Month Year Dropdown */
.react-datepicker__month-year-dropdown-container.react-datepicker__month-year-dropdown-container {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  border-radius: var(--border-radius-circle, 99rem);
}
.react-datepicker__month-year-dropdown-container.react-datepicker__month-year-dropdown-container:hover {
  background-color: rgba(92, 94, 110, 0.12);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

.react-datepicker__month-year-read-view.react-datepicker__month-year-read-view {
  visibility: visible !important;
  color: var(--color-neutral-1000, #212328);
  font: var(--typography-body-bold-1);
}

.react-datepicker__month-year-dropdown.react-datepicker__month-year-dropdown {
  left: 1rem;
  margin-top: 1rem;
  text-align: left;
  font: var(--typography-body-2);
  max-height: 15rem;
  padding: 0.25rem;
  background: var(--color-background-neutral-subtlest, #fff);
  border: none;
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  transition: all var(--transition-fast-in-out);
  overflow-y: scroll;
}
/* Scrollbar */
.react-datepicker__month-year-dropdown.react-datepicker__month-year-dropdown::-webkit-scrollbar {
  width: 0.5rem;
  padding: 0.25rem;
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  background: var(--color-background-neutral-subtlest, #fff);
}
.react-datepicker__month-year-dropdown.react-datepicker__month-year-dropdown::-webkit-scrollbar-thumb {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border-radius: var(--border-width-xlg, 0.1875rem);
}

.react-datepicker__month-year-option.react-datepicker__month-year-option {
  border-radius: var(--border-radius-sm);
  padding: 0.25rem 0.5rem;
  transition: all var(--transition-fast-in-out);
}
.react-datepicker__month-year-option.react-datepicker__month-year-option:hover {
  background: rgba(55, 81, 255, 0.08);
}
.react-datepicker__month-year-option.react-datepicker__month-year-option:active {
  background: rgba(55, 81, 255, 0.1);
}

.react-datepicker__month-year-option--selected_month-year.react-datepicker__month-year-option--selected_month-year {
  font: var(--typography-body-bold-2);
  background: rgba(55, 81, 255, 0.1);
  padding: 0.25rem 0.5rem;
  border-radius: var(--border-radius-sm);
}
.react-datepicker__month-year-option--selected_month-year.react-datepicker__month-year-option--selected_month-year:hover {
  background: rgba(55, 81, 255, 0.15);
}

/* Remove check */
.react-datepicker__month-year-option--selected.react-datepicker__month-year-option--selected {
  display: none;
}

/* TODO: Replace with ButtonIcon */
.react-datepicker__month-year-read-view--down-arrow.react-datepicker__month-year-read-view--down-arrow {
  position: absolute;
  top: 0.125rem;
  right: -1.5rem;
  transform: translateY(0);
  background-image: url('data:image/svg+xml, %3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20id%3D%22chevron-down%22%3E%0A%3Cpath%20id%3D%22Vector%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.14645%207.39669C3.34171%207.20143%203.65829%207.20143%203.85355%207.39669L12%2015.5431L20.1464%207.39669C20.3417%207.20143%2020.6583%207.20143%2020.8536%207.39669C21.0488%207.59195%2021.0488%207.90854%2020.8536%208.1038L12.3536%2016.6038C12.1583%2016.7991%2011.8417%2016.7991%2011.6464%2016.6038L3.14645%208.1038C2.95118%207.90854%202.95118%207.59195%203.14645%207.39669Z%22%20fill%3D%22%23212328%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
  background-size: contain;
  /* Removing the default arrow */
  border: 0 solid transparent;
  content: '';
  height: 1.25rem;
  width: 1.25rem;
}

.react-datepicker__current-month.react-datepicker__current-month {
  display: none;
}

.react-datepicker__month.react-datepicker__month {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  margin: 0.75rem 1rem 1rem 1rem;
}
