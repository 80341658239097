.SidePanel {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    flex-direction: column;
    border-left: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
    background: #fff;
    height: calc(100vh - 65px);
    width: 563px;
    z-index: 2;
    position: fixed;
    top: 65px;
    bottom: 0;
    right: 0px;
    opacity: 1;
    animation: slideLeft 230ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  @keyframes slideLeft {
    0% {
      right: -500px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  
  .SidePanelHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid var(--color-border-neutral-decorative, #cecfd9);
  }

  .SidePanelClose {
    cursor: pointer;
  }
  
  .SidePanel-header-icon,
  .SidePanel-header-icon svg {
    width: 3rem;
    height: 3rem;
  }
  
  .SidePanel-header-text {
    padding-left: 0.75rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    align-self: center;
  }
  
  .SidePanelHeaderTitle {
    color: var(--color-foreground-neutral-bold, #212328);
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  
  .SidePanel-header-description {
    color: var(--color-foreground-neutral-subtle, #717385);
    font: var(--typography-body-3);
  }
  
  .SidePanelContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #fff;
    height: 100%;
    width: 100%;
    padding: 24px;
    max-height: 100%;
    overflow: auto;
  }
  
  .SidePanelFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: 12px 24px;
    border-top: 1px solid var(--color-border-neutral-decorative, #cecfd9);
    background: #FFF;
    background: var(--color-background-neutral-subtlest, #FFF);
    width: 100%;
  }