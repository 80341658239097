.SpvFutureApprovedSession {
  width: 100%;
  height: 100%;
}

.SpvFutureApprovedSession-tabGroup {
  width: 100%;
  height: 2.5rem;
  align-items: center;
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.SpvFutureApprovedSession-tabContent {
  display: flex;
  padding: 0 1.5rem 3rem 1.5rem;
  max-height: calc(100vh - 15rem);
  flex-direction: column;
  overflow-y: auto;
}

.SpvFutureApprovedSession-assetTab {
  display: flex;
  max-height: calc(100vh - 15rem);
  flex-direction: column;
  overflow-y: auto;
}

.SpvFutureApprovedSession-tabSection {
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}
