/* ToDo -
.MuiTabs-root {
    .MuiButtonBase-root{
        padding-left: 0;
        padding-right: 32px;
    }
}
*/
.entitlement-heading {
    width: 149px;
    height: 17px;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

.ele-container {
   padding: 5px !important;
   margin-left: -10px !important;
}
.entitlement-type-header {
    width: 92px;
    height: 13px;
    font-family: Rubik;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--grey-font);
}
.system-name-heading{
    width: 74px;
    height: 13px;
    font-family: Rubik;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--grey-font);
}
