:root {
    --header-height: 64px;
    --footer-height: 42px;
    --sidebar-minified-width: 60px;
}

@keyframes fadeInFromNone {
    0% {
        opacity: 0;
    }

    45% {
        opacity: 0;
    }

    50% {
        opacity: .2;
    }

    60% {
        opacity: .4;
    }

    80% {
        opacity: .8;
    }

    100% {
        opacity: 1;
    }
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.container {
    margin: 2rem 1rem 1rem 3rem;
    height: calc(100vh - var(--header-height) - var(--footer-height));
}

.footer {
    padding: 1.5rem 0 0 1.5rem;
    margin-left: 3rem;
}

.requestHomeWithSideBar {
    margin-left: 175px;
}

.requestHomeWithoutSideBar {
    margin-left: 0;
}