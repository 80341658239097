.CertsSidePanelBody {
  height: 100%;
}

.CertsSidePanelBody-tabGroup {
  width: 100%;
}

.CertsSidePanelBody-tabGroupEmpty {
  width: 100%;
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

/* Entitlement menu */
.CertsSidePanelBody-tabEntMenu {
  display: flex;
  gap: 0.25rem;
  flex: 1;
  position: relative;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.CertsSidePanelBody-tabEntMenu:hover {
  border-bottom: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.CertsSidePanelBody-tabEntMenuHeading {
  display: flex;
  align-items: center;
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-bold, #212328);
}

.CertsSidePanelBody-tabEntMenuHeadingText {
  margin-inline: 1rem 0.25rem;
}

.CertsSidePanelBody-tabEntMenuDropDown--expanded {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  z-index: var(--z-index-certs-landingpage-dropdown);
  top: calc(2.5rem + 1px);
  right: 0;
  min-width: 10rem;
  max-width: 30rem;
  max-height: 32rem;
  padding: 0.75rem;
  box-shadow: 0 0.125rem 1rem 0.125rem rgba(0, 0, 0, 0.08);
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 0.25rem);
}

.CertsSidePanelBody-tabEntMenuDropDown--hidden {
  display: none;
  z-index: var(--z-index-certs-landingpage-disabled);
}

.CertsSidePanelBody-tabEntMenuDropDownListItem {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-block: 0.25rem;
  padding-left: 0.75rem;
  border-radius: var(--border-radius-sm, 0.25rem);
}

.CertsSidePanelBody-tabEntMenuDropDownListItemContainer {
  display: flex;
  gap: 0.25rem;
}

.CertsSidePanelBody-tabEntMenuDropDownListItemContainerSpan {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  font: var(--typography-body-2);
  color: var(--color-foreground-secondary-bold, #00245b);
  text-overflow: ellipsis;
}

/* Content */
.CertsSidePanelBody-tabContent--summary,
.CertsSidePanelBody-tabContent--history,
.CertsSidePanelBody-tabContent--entitlement,
.CertsSidePanelBody-tabContent--sod,
.CertsSidePanelBody-tabContent-privileges,
.CertsSidePanelBody-tabContent--child {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  height: calc(100% - 2.5rem);
}

.CertsSidePanelBody-tabContent--summary::-webkit-scrollbar-thumb,
.CertsSidePanelBody-tabContent--history::-webkit-scrollbar-thumb,
.CertsSidePanelBody-tabContent--entitlement::-webkit-scrollbar-thumb,
.CertsSidePanelBody-tabContent--sod::-webkit-scrollbar-thumb,
.CertsSidePanelBody-tabContent-privileges::-webkit-scrollbar-thumb,
.CertsSidePanelBody-tabContent--child::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.CertsSidePanelBody-tabContent--summary {
  padding: 0 1.5rem 1.5rem 1.5rem;
  padding-right: 0.875rem;
}

.CertsSidePanelBody-tabContent--history {
  gap: 0.5rem;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  padding-right: 0.875rem;
}

.CertsSidePanelBody-tabContent--entitlement,
.CertsSidePanelBody-tabContent--sod,
.CertsSidePanelBody-tabContent-privileges,
.CertsSidePanelBody-tabContent--child {
  padding: 0 1.5rem;
  padding-right: 0.875rem;
}

.CertsSidePanelBody-tabContentLoadBtn {
  display: flex;
  padding-block: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
}

/* Summary */
.CertsSidePanelBody-tabContentSummaryShorts {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 0.5rem;
  padding-block: 1rem;
  width: 100%;
}

.CertsSidePanelBody-tabContentSummaryShortsStatus {
  align-self: stretch;
}

.CertsSidePanelBody-tabContentSummaryContainer {
  display: flex;
  gap: 0.5rem;
  position: relative;
  margin-bottom: 0.5rem;
}

.CertsSidePanelBody-tabContentSummaryShortsRisk {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.CertsSidePanelBody-tabContentSummaryShortsRiskList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
  height: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  border-radius: var(--border-radius-sm, 0.25rem);
}

.CertsSidePanelBody-tabContentSummaryShortsRiskListItem {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  width: 100%;
}

.CertsSidePanelBody-tabContentSummaryShortsRiskListItemText {
  color: var(--color-foreground-secondary-bold, #00245b);
  width: 100%;
}

.CertsSidePanelBody-tabContentSummaryShortsRiskListItemIcon {
  margin-top: 1px;
}

.CertsSidePanelBody-tabContentSummaryLatest {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  padding-block: 1rem;
}

.CertsSidePanelBody-tabContentSummaryAttributes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  padding-block: 1rem;
}

/* History / TimeLine */
.CertsSidePanelBody-tabContentTimeLineBlock {
  display: flex;
  gap: 0.75rem;
  position: relative;
}

.CertsSidePanelBody-tabContentTimeLineBlock--noData {
  position: absolute;
  top: 50%;
  text-align: center;
  padding-right: 1.5rem;
  transform: translateY(-50%);
}

.CertsSidePanelBody-tabContentTimeLineBlockBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  margin-bottom: 0.75rem;
}

.CertsSidePanelBody-tabContentTimeLineBlockBodyTitle {
  color: var(--color-foreground-secondary-bold, #00245b);
}

/* Entitlement */
.CertsSidePanelBody-tabContentEntitlementSearch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  padding-block: 1rem;
}

/* SOD */
.CertsSidePanelBody-tabContentSodRisk {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
}

.CertsSidePanelBody-tabContentSodRiskText {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.CertsSidePanelBody-tabContentSodRiskButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
}

.CertsSidePanelBody-tabContentSodFuncGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.CertsSidePanelBody-tabContentSodFuncGroupTitle {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.CertsSidePanelBody-tabContentSodFuncGroupBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  border-radius: var(--border-radius-sm, 0.25rem);
}

/* Privileges */
.CertsSidePanelBody-tabContentPrivilegesList {
  margin-left: 1.25rem;
  font-size: var(--font-size-14);
  color: var(--color-foreground-secondary-bold, #00245b);
}

.CertsSidePanelBody-tabContentPrivilegesListItem {
  list-style: disc;
}

/* Children, Dependents */
.CertsSidePanelBody-tabContentChildDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  padding-block: 1rem;
}

/* TODO: Temp solution */
.CertsSidePanelBody-tabContentChildDescriptionGap {
  color: transparent !important;
}

/* Error handling */
.CertsSidePanelBody-tabContent--isError {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: calc(100% - 2.5rem);
  padding: 1.5rem;
  text-align: center;
}

.CertsSidePanelBody-tabContentDescription--isError {
  padding-left: 1rem;
  margin-top: 0.25rem;
}

.CertsSidePanelBody-tabContentRefreshBtn--isError {
  margin-top: 1rem;
}

/* ARS */
.AccessRequestHistoryBtn-sidePanelMode {
  color: var(--color-foreground-primary-medium, #3751ff);
  font: var(--typography-body-bold-2);
}

.AccessRequestHistoryBtn-sidePanelMode:hover {
  text-decoration: underline;
}

@media (max-width: 1279px) {
  /* TODO: Tabs need MORE btn NEO enhancement then we can remove these */
  .CertsSidePanelBody-tabGroup .TabItem {
    padding: 0.5rem;
  }
  /* TODO: END */
}

@media (max-width: 833px) {
  .CertsSidePanelBody--isSidePanelLoading .TabContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
