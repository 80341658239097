.expandBar {
  position: relative;
  border-radius: 50%;
  .workNotesStatus{
    padding: 1px 6px;
    border-radius: 1px;
    background-color: #d6e4fd;
    margin-top: 5px;
    font-size: 11px;
    color: #646464;
    font-style: italic;
  }
  .statusFontWeight{
    font-weight: 500;
  }
  .finalstatus{
    font-size: 14px;
    font-style: italic;
  }
  .statusColors{
    .Pending{
      background-color: #617083;
    }
    .PendingApproval{
      color: #000000;
    }
    .Discontinued{
      background-color:red;
    }
    .Expired{
      background-color:red;
    }
    .Rejected{
      background-color:red;
    }
  }

    .dotColor {
      display: inline-block;
      width: 10px;
      height: 10px;
      text-align: center;
      border-radius: 50%;
      background: #00a472;
      margin-top: 3px;
    }


  .lastdotColor {
    display: inline-block;
    width: 11px;
    height: 11px;
    text-align: center;
    border-radius: 50%;
    background: #b4b4b4;
    margin-top: 3px;
  }

  i {
    font-size: 12px;
    font-style: normal;
  }

  .Pending{
    color: #617083;
  }
.PendingApproval{
  color: #000000;
}
  .Discontinued{
    color: red;


  }
  .Expired{
    color: red;
  }
  .Rejected{
    color: red;
  }
  .Approved{
    color: #00a472;

  }
}
.expandArrowFail.ready{
  &:after {
    display: none;
  }
}
.expandArrowFail.det{
  &:first-child {
    margin-bottom: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 14px;
    width: 2px;
    height: 23px;
    background: repeating-linear-gradient(to bottom,#70757a 0px,#70757a 3px,transparent 2px,transparent 5px);
    opacity: 0.24;
    margin-top: 15px;
  }
}
.expandArrowFail {
  &:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 2px;
    height: 28px;
    background: repeating-linear-gradient(to bottom,#ee5357 0px,#ee5357 3px,transparent 2px,transparent 5px);
    opacity: 0.24;
    margin-top: 15px;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
}
.expandArrow.ready{
  &:after {
    display: none;
  }
}
.expandArrow.det{
  &:first-child {
    margin-bottom: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 14px;
    width: 2px;
    height: 23px;
    background: repeating-linear-gradient(to bottom,#70757a 0px,#70757a 3px,transparent 2px,transparent 5px);
    opacity: 0.24;
    margin-top: 15px;
  }
}
.expandArrow {
  &:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 2px;
    height: 28px;
    background: repeating-linear-gradient(to bottom,#00a472 0px,#00a472 3px,transparent 2px,transparent 5px);
    opacity: 0.24;
    margin-top: 15px;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
}
.attach{
  margin-top: 20px;
  &:after {
    height: 12vh !important;
  }
    }
.expandComment {
 
  &:first-child {
    &:after {
      height: 15vh;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 3px;
    height: 48px;
    background: repeating-linear-gradient(to bottom,#00a472 0px,#00a472 3px,transparent 2px,transparent 5px);
    opacity: 0.24;
    margin-top: 15px;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
}
.reqbyheading{
  font-style: italic;
}
.assigneeName{
  color: #747474;
  font-size: 11px;
  font-weight: 500;
}
.cardExpandLayout{
  width: 100%;
  max-height: 10rem;
overflow-y: scroll;
}
.reqStatus {
  justify-content: space-between;
  font-size:12px !important;
    h6 {
    font-size: 12px;
    font-weight: normal;
    color: #000000;
  }
}
.taskReject{
  color: #ee5357;
}
.taskGranted{
  color: #31b793
}
.taskRevoked{
  color: #000000;
}
.lastTaskStyle{
  font-size: 14px !important;
  font-weight: 500;
  font-style: italic;
  text-transform: capitalize;
}
.reqFailBg{
  background-color: #ee5357 !important;
}
.reqApproveBg{
  background-color: #31b793 !important;
}
.reqBusinessJusti{
  font-size: 12px;
  font-weight: 500;
  color: var(--grey-font);
}
.requested{
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #000000 !important;
}
.createDateText{
  color: var(--grey-font);
}

.taskWeight{
  font-weight: normal;
}

.typeLabelText{
  font-weight: 500;
}


.reqSpan{
  color: var(--grey-font);
  font-size: 11px;
}

.dateStatusalign{
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 73px;
}

.dummyview{
  min-height:43px;
}

.statusDate {
  position: relative;
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    padding-left: 4px;
    h6 {
      padding-left: 7px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 11px;
    width: 1px;
    height: 28px;
    background: repeating-linear-gradient(to bottom,#70757a 0px,#70757a 3px,transparent 2px,transparent 5px);
    opacity: 0.64;
    margin-top: 15px;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
}

.cardExpandText {
  font-size: 12px;
  font-weight: normal;
  color: #000000;
}

.dateColorText{
  color: #70757a;
  font-size: 10px;
  min-width: 40px;
}

.commentBox {
  border: solid 1px var(--primary-main);
  position: absolute;
  width: 15vw;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 2px 2px 4px 0 #cdced9;
  textarea {
    border: none;
    outline: none;
    width: 100%;
    resize: none;
  }

  button {
    font-size: 8px;
    color: var(--grey-font);
  }
}

.attachedComments {
  width: 114px;
  margin-top: 14px;
  margin-right: 5px;
  border-width: 0.5px;
  border: solid 1px var(--grey-border);
  padding: 11px 6px;
  border-radius: 3px;
  .commentAttached {
    height: 22px;
    stroke: #9b9b9b !important;
  }
}
.commentindlayout{
  display:flex;
  margin: 15px 0px;
}
.attachmentLayout{
  display: flex;
  flex-flow: wrap;
    margin-top: 10px;
}
.attachtitle{
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  margin-top: 35px;
  margin-bottom: -5px;
  border-top: solid 1px var(--grey-border);
    padding-top: 16px;
}
.date{
  font-size: 12px !important;
  font-style: italic;
  line-height: 1.08;
  color: #70757a;
}
.requestor{
  font-size: 12px !important;
  color: black;
  margin-top: -3px;
}
.displayname{
  font-size: 12px;
  font-weight: 500 !important;
  color:black;
}
.commentsTextarea{
  width: 100%;
  outline: none;
  border: none;
  resize: none;
}
.approvalIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#f3f3f3;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  border: solid 1px #dfdfdf;
  .textColor{
    color: var(--grey-font);
    font-size: 12px;
  }

}
.commentsAttachedText {
  font-size: 11px;
  font-weight: normal;
}
.commentsText{
  margin-top:10px;
  min-height: 40px;
  font-size: 13px;
  color: black;
  border-radius: 4px;
  background-color: #f4f4f4;
  padding: 13px 16px 12px 16px;
}
.leaveComment{
  font-weight: 500;
  color: var(--grey-font);
  font-size:12px;
}

.addTextarea {
  width: 100%;
  border-radius: 3px;
  border: solid 1px #b4b4b4;
  background-color: #ffffff;
  height: 90px;
  resize: none;
  padding-left: 14px;
  padding-top: 5px;
  margin-top: 5px;
  font-size: 12px;
}

.contentText {
  margin-right: 80px;
}

.fileIcon {
  height: 22px;
  width: 22px;
}

.morecommentmodal{
  justify-content: space-between;
  padding: 0px;
  margin-top: -5px;
  .expandBar{
    min-height: 60px;
  }
}

.statusspanColor{
color: var(--grey-font);
}

.statusspanColorDate{
  color: var(--grey-font);
  opacity: 0;
}
.statusspanColorDateShown{
  opacity:1;
}

.ant-upload-select-text,
.ant-upload-list {
  width: 100%;
  padding-left: 16px;
  padding-bottom: 15px;
}

.dropzone .ant-upload-list {
  display: flex;
  flex-flow: wrap;
  display: none;
}

.dropzone {
  :global .ant-upload-select-text{
    width: 100%;
  }
}

::placeholder{
  color: #b4b4b4;
}


// .ant-upload-select-text,
// .ant-upload-list {
//   width: 100%;
//   padding-left: 16px;
//   padding-bottom: 15px;
// }

:global .ant-upload-list{
  display: none;
}
.dropzone {
  border: solid 1px lightgray;
  padding: 0 5px 10px 19px;
  margin-top: 8px;
  border-radius: 3px;
}
textarea.instanceCheckoutComment {
  border: none;
}


textarea.instanceCheckoutComment:focus {
  box-shadow: none;
}

.attachedFileList {
  display: flex;
  flex-wrap: wrap;
}

.attachedFile {
  border: solid 1px var(--grey-border);
  padding:6px;
  border-radius: 3px;
  margin-top: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  svg{
    height: 20px;
  }
}

.attachedText {
  font-size: 8px;
  min-width: 60px;
  color: black;
  margin-left: 3px;
  margin-top: 5px;
}
.filelayout{
display:flex;
justify-content: space-between;
}
.attachedText span:nth-child(2) {
  color: #9b9b9b;
  font-size: 7px;
}

.closeAttachement {
  background-color: var(--grey-border);
  border-radius: 50%;
  padding-top: 3px;
  padding-left: 4px;
  height: 17px;
  width: 16px;
}

.statusHeight {
  margin-top: 40px
}

@media only screen and (min-width: 1900px){

  .statusHeight{
    margin-top: 134px;
  }
}

.editTextarea{
  border: none;
  outline: none;
  resize: none;
  background-color: var(--page-bg);
  font-style: italic;
  height: 35px;
  
  }

  .editIcon{
    margin-bottom: 6px;
    height: 30px;
  }

