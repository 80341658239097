.MenuItemMulti {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
  border-radius: var(--border-radius-sm, 4px);
  padding: 0.25rem 0 0.25rem 0.75rem;
  margin: 0;
}
.MenuItemMulti:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
}
.MenuItemMulti:not(.MenuItemMulti--isDisabled)
  .MenuItemMulti-prefixIcon
  svg
  path {
  fill: var(--color-foreground-secondary-bold, #00245b);
}

.MenuItemMulti-checkbox {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}

.MenuItemMulti-checkbox .Checkbox.Checkbox--base {
  height: 1rem;
}

.MenuItemMulti:not(.MenuItemMulti--isDisabled):hover {
  /* TODO: use css var when working */
  background: rgba(55, 81, 255, 0.08);
}

.MenuItemMulti:not(.MenuItemMulti--isDisabled):active {
  /* TODO: use css var when working */
  background: rgba(55, 81, 255, 0.1);
}

.MenuItemMulti:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  border-radius: 4px;
}

.MenuItemMulti--isDisabled {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
}
.MenuItemMulti--isDisabled:hover {
  cursor: not-allowed;
}

.MenuItemMulti-label {
  display: inline-block;
  width: 100%;
  color: var(--color-foreground-secondary-bold, #00245b);
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
