/* Increased specificity due to style collision in dev, with sass file selector #ModalIsland:not(.showSelectLogoModal) .ModalIsland-body */
#ModalIsland.ModalIsland.SessionTargetDetailsModalIsland {
  max-width: 37.5rem;
  width: 100%;
}
.SessionTargetDetailsModalIsland.ModalIsland .ModalIsland-section {
  width: 100%;
}

/* Main Content */
.SessionTargetDetailsModalIsland-ModalIslandContent {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

/* Footer */
.SessionTargetDetailsModalIsland .FooterActionBar {
  justify-content: end;
}
.SessionTargetDetailsModalIsland-FooterActionBar {
  display: flex;
  gap: 0.5rem;
}
