.tooltip {
  div {
    visibility: hidden;
  }
  &:hover {
    div {
      visibility: visible;
    }
  }
}

.lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: inherit;
  word-wrap: break-word;
}
