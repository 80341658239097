.toggle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.leftAlign {
    flex-direction: row-reverse;
}

.rightAlign {
    flex-direction: row;
}

label {
    font-size: '14px';
    font-weight: 400;
    margin: 0;
}