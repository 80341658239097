$contentBodyColor: var(--page-bg);

.tab-sidebar {
  padding-top: 20px;
}

.rolesummary-tab-container {
  .rolesFound{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    margin-top: 50px;
  }
  // padding: 42px 58px 0 60px;
  .tab-body {
    // padding: 20px 0 27.5px 0;
    // padding:38px 24px 14px 24px;
    // border-top: solid 1px var(--grey-border);
    // border-bottom: solid 1px var(--grey-border);
    // margin: 34px 0 28px 0;
    .basics-label {
      font-size: 14px;
      padding-top: 20px;
      color: #000000;
    }
    .basics-risk{
      margin-top: -18px !important;
    }
    .basics-container {
      padding: 8px 21px 15px 16px;
      width: 100%;
      line-height: 28px;
      margin-top: 11px;
      .high,
      .low,
      .medium {
        border-radius: 3px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff !important;
        min-height: 22px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .high {
        background-color: red;
        width: 70px;
      }
      .medium {
        background-color: #ffb100;
        width: 70px;
      }
      .low {
        background-color: rgba(84, 203, 161, 1);
      }
      .role-name {
        display: flex;
        flex-flow: column;
        padding-left: 0px;
      }

      label {
        // margin-bottom: 26px;
      }
      .label-key {
        height: 13px;
        font-family: Rubik;
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--grey-font);
        // line-height: 4px;
      }
      .description{
overflow:hidden;
white-space: nowrap;
text-overflow: ellipsis;

      }
      .label-value {
        // height: 14px;
        word-break: break-word;
        font-family: Rubik;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
      }
    }
  }

  .body-content {
    padding-left: 0px;
    background-color: var(--page-bg);
    padding: 20px;
    margin-bottom: 8px;
  }
.syscritical-padding{
  padding-right: 50px;
}
  .body-content2 {
    padding: 23px 49px 35px 47px;
    background-color: $contentBodyColor;
    width: 308px;
    line-height: 28px;
    margin-top: 11px;
  }

  .custom-body {
    font-size: 12px;
    padding: 24px 0 0 16px;
    line-height: 30px;
  }
}
