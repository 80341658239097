.CertsSidePanelFooter {
  position: absolute;
  bottom: 0;
  background: var(--color-background-neutral-subtlest, #fff);
  height: 4rem;
  width: 100%;
  padding: 0.5rem 1rem;
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.06);
}

.CertsActionButtonsGroup-footerGroup {
  width: 100%;
}
.CertsActionButtonsGroup-sidePanelFooter {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.ModalSidePanel .CertsSidePanelFooter {
  border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
}

@media (max-width: 1297px) {
  .CertsSidePanelFooter {
    padding: 0.5rem;
  }
}
