.SpfAccounts {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.SpfAccounts-twoButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  align-self: stretch;
}

.SpfAccounts-twoButtons--buttonStyle {
  white-space: nowrap;
}
