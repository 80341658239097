.MenuInput-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 32rem;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  transition: height var(--transition-quick-in-out);
  z-index: var(--z-index-dropdown);
}

.MenuInput-items {
  list-style: none;
  margin: 0;
  padding: 0.75rem;
  min-width: min-content;
  white-space: nowrap;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 4px);
  min-width: 10rem;
  width: 100%;
  max-width: 30rem;
  overflow-y: auto;
  scrollbar-width: thin;
}

.MenuInput--isLoading {
  display: flex;
  padding: 0.25rem 0.75rem;
  align-items: center;
  gap: 0.25rem;
  font: var(--typography-body-3);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.MenuInput--hasNoData {
  padding: 0.25rem 0.75rem;
  font: var(--typography-body-3);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.MenuInput--hasNoData:hover {
  background: var(
    --color-background-primary-on-subtle-hover,
    rgba(55, 81, 255, 0.08)
  );
}

.MenuInput .Menu-dropdown {
  overflow-x: hidden;
  padding: 0.75rem;
}

.MenuInput .MenuItem {
  justify-content: space-between;
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  width: 100%;
  margin: unset;
}

.MenuInput.MenuInput--hasSuffixIcon .MenuItem--isChecked {
  background: none;
  padding-right: 0;
}

.MenuInput .MenuItem:not(.MenuItem--isDisabled):hover {
  background: rgba(55, 81, 255, 0.08);
}

.MenuInput .MenuInput-dropdown--small {
  width: 10rem;
}
.MenuInput .MenuInput-dropdown--medium {
  width: 16rem;
}
.MenuInput .MenuInput-dropdown--large {
  width: 24rem;
}
.MenuInput .MenuInput-dropdown--xLarge {
  width: 30rem;
}
