@import './helperstyles/DatePicker.css';
@import './helperstyles/Calendar.css';
@import './helperstyles/CalendarRange.css';
@import './helperstyles/MonthYearDropdown.css';
@import './helperstyles/CloseButton.css';
@import './helperstyles/SuffixButton.css';
@import './helperstyles/TimeSelect.css';

/* TODO: Reduce specificity if we ever fix the style build order */
/* Master container */
.DateAndTimeInputField-container.DateAndTimeInputField-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
  height: min-content;
}
.DateAndTimeInputField-inputContainer.DateAndTimeInputField-inputContainer {
  position: relative;
  margin-bottom: 0;
}
.DateAndTimeInputField-inputContainer--isDisabled.DateAndTimeInputField-inputContainer--isDisabled {
  cursor: not-allowed;
}

.DateAndTimeInputField-labelContainer.DateAndTimeInputField-labelContainer {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-heading-4);
}

.react-datepicker-popper.react-datepicker-popper {
  z-index: var(--z-index-react-date-picker-popper);
}

/* States */
.DateAndTimeInputField-input--isCritical.DateAndTimeInputField-input--isCritical,
.DateAndTimeInputField-input--isCritical.DateAndTimeInputField-input--isCritical:focus,
.DateAndTimeInputField-input--isCritical.DateAndTimeInputField-input--isCritical:focus:hover,
.DateAndTimeInputField-input--isCritical.DateAndTimeInputField-input--isCritical:hover {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.DateAndTimeInputField--isRequired.DateAndTimeInputField--isRequired {
  position: relative;
  top: 5px;
  right: 5px;
}
.DateAndTimeInputField--isRequired--isCritical.DateAndTimeInputField--isRequired--isCritical,
.DateAndTimeInputField--isRequired--isWarning.DateAndTimeInputField--isRequired--isWarning {
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  font: var(--typography-heading-3);
}

.DateAndTimeInputField--isRequired--isWarning.DateAndTimeInputField--isRequired--isWarning {
  color: var(--color-foreground-warning-medium);
}
.DateAndTimeInputField--isRequired--isCritical.DateAndTimeInputField--isRequired--isCritical {
  color: var(--color-foreground-critical-medium);
}

.DateAndTimeInputField--isRequired.DateAndTimeInputField--isRequired {
  position: relative;
  top: 5px;
  right: 5px;
}

/* Error message */
.DateAndTimeInputField-errorMessage.DateAndTimeInputField-errorMessage {
  color: var(--color-border-critical-medium, #cc1c10);
}
/* Time picker */
.react-datepicker--time-only.react-datepicker--time-only {
  width: 5.75rem;
  overflow: hidden;
  border-radius: var(--border-width-xlg, 0.1875rem);
}
.react-datepicker__time.react-datepicker__time {
  width: max-content;
  font: var(--typography-body-2);
  border-radius: var(--border-width-xlg, 0.1875rem);
}
.react-datepicker__header--time--only.react-datepicker__header--time--only
  .react-datepicker__time-container,
.react-datepicker__time-container.react-datepicker__time-container {
  float: left;
  border-radius: var(--border-width-xlg, 0.1875rem);
}
.react-datepicker__time-container.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100%;
  background: #fff;
}
.react-datepicker__time-container.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  display: flex;
  flex-direction: column;
  width: max-content;
  max-height: 19rem;
  height: 100%;
  padding-top: 0.25rem;
}
/* Scrollbar */
.react-datepicker__time-container.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar {
  width: 0.5rem;
  padding: 0.25rem;
  background-color: #fff;
}
.react-datepicker__time-container.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border-radius: var(--border-width-xlg, 0.1875rem);
}

/* Remove time header */
.react-datepicker__time-container.react-datepicker__time-container.react-datepicker__header,
.react-datepicker__time-container.react-datepicker__time-container.react-datepicker__header:not(
    .react-datepicker__header--has-time-select
  ) {
  display: none;
}
/* Not selected */
.react-datepicker__time-container.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 0.25rem 0.5rem;
  width: calc(100% - 0.5rem);
  margin: 0 0.25rem 0 0.25rem;
  border-radius: var(--border-width-xlg, 0.1875rem);
  transition: all var(--transition-fast-in-out);
}
.react-datepicker__time-container.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: rgba(55, 81, 255, 0.08);
}
.react-datepicker__time-list-item.react-datepicker__time-list-item:active {
  background: rgba(55, 81, 255, 0.1);
}
/* Selected */
.react-datepicker__time-container.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-neutral-bold, #212328);
  background: rgba(55, 81, 255, 0.1);
}
.react-datepicker__time-container.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__time-list-item:active {
  background: rgba(55, 81, 255, 0.08);
}
/* Disabled */
.react-datepicker__time-list-item--disabled.react-datepicker__time-list-item--disabled {
  display: none;
}

/* Date and time adjustments */
.react-datepicker__time-container.react-datepicker__time-container {
  border-left: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  border-radius: 0 var(--border-width-xlg) 0 0;
}
.react-datepicker-time__header.react-datepicker-time__header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 0.5rem);
  height: 3.5rem;
  background-color: #fff;
  border-bottom: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  border-radius: 0 var(--border-width-xlg) 0 0;
  font: var(--typography-body-bold-1);
  color: var(--color-neutral-1000, #212328);
}
/* Display none when in time only container */
.react-datepicker__header--time--only.react-datepicker__header--time--only
  .react-datepicker-time__header {
  display: none;
}

.DateAndTimeInputField-inputContainer--isReadOnly.DateAndTimeInputField-inputContainer--isReadOnly
  input {
  border: none;
  background-color: transparent;
}

.DatePicker-input::placeholder {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-subtle, #717385);
}
