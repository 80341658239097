.ColumnFilterButton {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
}

.ColumnFilterButton-trigger {
  margin-right: 0.5rem;
}

.ColumnsFilter-dropDown--active,
.ColumnsFilter-dropDown--disabled {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  position: absolute;
  top: calc(100% + 0.375rem);
  right: 0.5rem;
  left: unset;
  min-width: 22.125rem;
  padding-inline: 0.25rem;
  background: var(--color-background-neutral-subtlest, #fff);
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  border-radius: var(--border-radius-sm, 0.25rem);
  transition: all var(--transition-quick-in-out);
}

.ColumnsFilter-dropDown--disabled {
  height: 0;
  opacity: 0;
  z-index: var(--z-index-certs-landingpage-disabled);
  overflow: hidden;
}

.ColumnsFilter-dropDown--active {
  padding-block: 0.25rem;
  opacity: 1;
  min-height: 10.75rem;
  max-height: calc(100vh - 13rem);
  z-index: var(--z-index-certs-landingpage-filters);
  overflow: auto;
}

.ColumnsFilter-dropDownBody {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  overflow-y: auto;
}

.ColumnsFilter-dropDownBody::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.ColumnsFilter-dropdownTabGroup {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.ColumnsFilter-dropdownTabGroupTabItems {
  flex: auto;
  justify-content: center;
}

.ColumnsFilter-dropDownBodyNote {
  padding-inline: 0.5rem;
  padding-top: 0.5rem;
}

.ColumnsFilter-dropDownBodyFilterList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.ColumnsFilter-dropDownBodyFilterListContainer {
  display: block;
  width: 100%;
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItems {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.25rem;
  position: relative;
  padding-right: 0.5rem;
  padding-block: 0.25rem;
  background-color: var(--color-neutral-000, #fff);
  border-radius: var(--border-radius-sm, 0.25rem);
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItems:hover {
  background: rgba(55, 81, 255, 0.08);
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItemsDragger {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  visibility: hidden;
  padding: 0.5rem;
  cursor: grab;
  border-radius: var(--border-radius-circle, 50%);
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItems:hover
  .ColumnsFilter-dropDownBodyFilterListContainerSubItemsDragger {
  visibility: visible;
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItems:hover
  .ColumnsFilter-dropDownBodyFilterListContainerSubItemsDragger:hover {
  background: rgba(92, 94, 110, 0.08);
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItemsDraggerToolTip {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  transform: translate(-2.375rem, 2.625rem);
  padding: 0.5rem 0.75rem;
  background-color: var(--color-foreground-neutral-bold, #212328);
  border-radius: var(--border-radius-sm, 0.25rem);
  font: var(--typography-body-3);
  color: var(--color-foreground-neutral-inverse, #fff);
  white-space: nowrap;
  z-index: var(--z-index-certs-landingpage-filters);
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItemsDraggerToolTip::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--color-foreground-neutral-bold, #212328);
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItemsDraggerToolTip:has(
    + .ColumnsFilter-dropDownBodyFilterListContainerSubItemsDragger:hover
  ) {
  display: flex;
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItemsDraggerFiller {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  visibility: hidden;
  padding: 0.5rem;
  cursor: grab;
  border-radius: var(--border-radius-circle, 50%);
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItemsCheckBox {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
  width: 100%;
  padding-block: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItemsCheckBox > * {
  padding: 0.25rem;
}

.ColumnsFilter-dropDownBodyFilterListContainerSubItemsCheckBoxText {
  align-self: stretch;
  width: 100%;
  margin: auto;
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-bold, #212328);

  white-space: normal;
  text-overflow: ellipsis;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
