/* Suffix Button */
.DateAndTimeInputField-suffixButton {
  all: unset;
  position: absolute;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  top: 0;
  right: 0;
  padding: 0.75rem 0.5rem;
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  border-left: 1px solid transparent;
  cursor: pointer;
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  background: transparent;
  height: 3rem;
  transition: all var(--transition-fast-in-out);
  isolation: isolate;
}
.DateAndTimeInputField-suffixButton-icon {
  height: 1.25rem;
  width: 1.25rem;
}
.DateAndTimeInputField-suffixButton--isReadOnly {
  pointer-events: none;
  background: var(--color-background-neutral-subtlest, #fff);
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}
.DateAndTimeInputField-suffixButton:hover {
  border: 1px solid var(--color-border-secondary-subtle, #cecfd9);
  background: var(--color-background-neutral-subtlest, #fff);
}
.DateAndTimeInputField-suffixButton-overlay {
  position: absolute;
  z-index: var(--z-index-container-overlay);
  right: -1px;
  top: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border: 1px solid transparent;
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  background: transparent;
  opacity: 0;
  transition: all var(--transition-fast-in-out);
}
.DateAndTimeInputField-suffixButton-icon {
  transition: all var(--transition-fast-in-out);
}
.DateAndTimeInputField-suffixButton-overlay:hover,
.DateAndTimeInputField-suffixButton:hover
  .DateAndTimeInputField-suffixButton-overlay,
.DateAndTimeInputField-suffixButton-icon:hover
  + .DateAndTimeInputField-suffixButton-overlay {
  opacity: 1;
  background: rgba(55, 81, 255, 0.08);
}
.DateAndTimeInputField-suffixButton-overlay:active,
.DateAndTimeInputField-suffixButton:active
  .DateAndTimeInputField-suffixButton-overlay,
.DateAndTimeInputField-suffixButton-icon:active
  + .DateAndTimeInputField-suffixButton-overlay {
  opacity: 1;
  background: rgba(55, 81, 255, 0.1);
}

.DateAndTimeInputField-suffixButton[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
}

/* States */
/* DateAndTimeInputField Suffix Button isRequired */
.DateAndTimeInputField-suffixButton--isCritical {
  border: 1px solid var(--color-foreground-critical-medium);
  border-left: 1px solid transparent;
}
.DateAndTimeInputField-suffixButton--isCritical:hover {
  border: 1px solid var(--color-foreground-critical-medium);
}
/* DateAndTimeInputField Suffix Button isDisabled */
.DateAndTimeInputField-suffixButton:disabled,
.DateAndTimeInputField-suffixButton-overlay--isDisabled {
  border: 1px solid transparent;
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  cursor: not-allowed;
}
.DateAndTimeInputField-suffixButton-neutral--isDisabled {
  border: 1px solid var(--color-foreground-neutral-subtle);
  border-left: 1px solid transparent;
}
.DateAndTimeInputField-suffixButton-secondary--isDisabled {
  border: 1px solid var(--color-foreground-neutral-subtle);
  border-left: 1px solid transparent;
}
.DateAndTimeInputField-suffixButton-overlay:hover,
.DateAndTimeInputField-suffixButton:disabled:hover
  .DateAndTimeInputField-suffixButton-overlay--isDisabled,
.DateAndTimeInputField-suffixButton-icon:hover
  + .DateAndTimeInputField-suffixButton-overlay--isDisabled {
  opacity: 0;
}
