.Cards {
  width: 100%;
}
/* Levels */
.Cards--0 {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: var(--border-radius-sm, 0.25rem);
  margin-top: 0.0625rem;
}
.Cards--sidebarOpen {
  grid-column: 1 / 9;
}

.Cards--1 {
  padding: 0.5rem 0 1.5rem 2rem;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: 0 0 var(--border-radius-sm, 0.25rem)
    var(--border-radius-sm, 0.25rem);
}

.Cards--2 {
  padding: 0.5rem 0 0 2rem;
}

/* Tree */
.Cards-tree {
  width: 100%;
  opacity: 1;
  transition:
    padding-bottom 300ms ease-out,
    height 500ms ease-out,
    opacity 150ms ease-out 350ms;
}

/* Tree Levels */
.Cards-tree--0 {
  border-radius: var(--border-radius-sm, 0.25rem);
  box-shadow: var(--box-shadow-pretoken-container-default);
}

.Cards-tree--1,
.Cards-tree--2 {
  background: var(--color-background-secondary-subtlest, #f7f9fc);
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  border-left: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.Cards-tree--2 {
  height: 4rem;
  background: var(--color-background-secondary-subtle, #f2f4f8);
}

/* Conditional styles */
.Cards-tree--isTopTile {
  border-top-left-radius: 0.25rem;
  border-top: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.Cards-tree--isBottomTile {
  border-bottom-left-radius: 0.25rem;
}

.Cards-tree--hasEntChild {
  padding-bottom: 1.5rem;
}

.Cards--3 {
  display: none;
}

.Cards-tree--hasNoChildren .Tile--0,
.Cards-tree--hasNoChildren .Tile--1 {
  border-radius: var(--border-radius-sm, 0.25rem);
}

/* Card removal transitions */
.Cards-tree--0.Cards-tree--isBeingRemoved,
.Cards-tree--1.Cards-tree--isBeingRemoved,
.Cards-tree--2.Cards-tree--isBeingRemoved {
  /* NOTE: important required to override dynamic inline height styles without having to remove them */
  height: 0rem !important;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  padding-bottom: 0;
}
.Cards-tree--1.Cards-tree--isBeingRemoved .Tile--1 {
  top: 0;
}

/* Responsive */
@media (max-width: 1279px) {
}
