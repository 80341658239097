.container {
  position: relative;
  // display: flex;
  // width: 15%;
  font-size: 11px;
  font-weight: 500;
  padding-top: 7px;
  // margin-right: 10px;
  line-height: 18px;
  color: var(--grey-font);
  div {
    cursor: pointer;
    border-bottom: solid 1px var(--grey-font);
    padding-bottom: 3px;
  }
  .displayValue {
    font-weight: 600;
  }
  .manualInput {
    width: 47px;
    border: none;
    outline: none;
    height: 19px;
    font-weight: 600;
    font-size: 11px;
    border-bottom: solid 2px green;
  }
  .reducedHeight {
    height: 50px !important;
  }
  ul {
    top: 30px;
    height: 250px;
    overflow-y: scroll;
    z-index: 2000;
    position: absolute;
    box-shadow: 3px 3px 8px 0 #bfc0ca;
    width: 70px;
    background: #ffffff;
    li {
      padding: 2px 0px 2px 5px;
      &:hover {
        background-color: var(--grey-border);
      }
    }
    .disabled {
      background-color: var(--grey-border);
      cursor: not-allowed;
    }
  }
  ul::-webkit-scrollbar {
    width: 4px;
    // background-color: #615c5c;
  }
  ul::-webkit-scrollbar-thumb {
    background-color: #615c5c;
  }
}
