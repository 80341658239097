.InterPoint {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

/* Colors */
.InterPoint-neutralBold {
  color: var(--color-foreground-neutral-bold, #212328);
}

.InterPoint-neutralSubtle {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.InterPoint-neutralSubtlest {
  color: var(--color-foreground-neutral-subtlest, #fff);
}

.InterPoint-criticalBold {
  color: var(--color-foreground-critical-bold, #700600);
}

/* Fonts */
.InterPoint-body2 {
  font: var(--typography-body-2);
}

.InterPoint-body3 {
  font: var(--typography-body-3);
}
