.select-dropdown {
  .Dropdown-root {
    border-radius: 3px;
    border: 1px solid var(--primary-bg);
  }
  .is-open {
    border: solid 1px var(--primary-main);
  }
  .Dropdown-control {
    border-radius: 3px;
    border: 1px solid transparent;
  }
  .Dropdown-placeholder {
    font-size: 12px;
    color: #b4b4b4;
  }
  .Dropdown-menu {
    border-radius: 3px;
    box-shadow: 3px 3px 8px 0 rgba(205, 206, 217, 0.5);
    border: solid 1px var(--primary-main);
    background-color: #ffffff;
    margin-top: 10px;
  }
  .Dropdown-option {
    font-size: 12px;
    color: var(--grey-font);
  }
  .Dropdown-arrow {
    border-color: #d8d8d8 transparent transparent;
  }
  .is-open .Dropdown-arrow {
    border-color: transparent transparent var(--primary-main);
  }
}

.error-message {
  font-size: 11px;
  color: red;
  position: absolute;
  bottom: -9px;
}

.form-label-material {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 0.8571428571428571rem;
  font-family: Rubik, sans-serif;
  font-weight: 400;
  line-height: 1;
}

.autogenerate-passowrd {
  color: var(--primary-main);
  cursor: pointer;
}