[data-theme='light'] {
  --color-border-focus: var(--color-indigo-500);
  --color-border-savi-decorative: var(--color-purple-100);
  --color-border-savi-subtlest: var(--color-purple-200);
  --color-border-savi-subtle: var(--color-purple-500);
  --color-border-savi-medium: var(--color-purple-700);
  --color-border-savi-bold: var(--color-purple-900);
  --color-border-info-decorative: var(--color-info-100);
  --color-border-info-subtlest: var(--color-info-200);
  --color-border-info-subtle: var(--color-info-500);
  --color-border-info-medium: var(--color-info-700);
  --color-border-info-bold: var(--color-info-900);
  --color-border-warning-decorative: var(--color-warning-100);
  --color-border-warning-subtlest: var(--color-warning-200);
  --color-border-warning-subtle: var(--color-warning-400);
  --color-border-warning-medium: var(--color-warning-700);
  --color-border-warning-bold: var(--color-warning-900);
  --color-border-success-decorative: var(--color-success-100);
  --color-border-success-subtlest: var(--color-success-200);
  --color-border-success-subtle: var(--color-success-500);
  --color-border-success-medium: var(--color-success-700);
  --color-border-success-bold: var(--color-success-900);
  --color-border-critical-decorative: var(--color-critical-100);
  --color-border-critical-subtlest: var(--color-critical-200);
  --color-border-critical-subtle: var(--color-critical-500);
  --color-border-critical-medium: var(--color-critical-700);
  --color-border-critical-bold: var(--color-critical-900);
  --color-border-primary-decorative: var(--color-indigo-100);
  --color-border-primary-subtlest: var(--color-indigo-200);
  --color-border-primary-subtle: var(--color-indigo-500);
  --color-border-primary-medium: var(--color-indigo-700);
  --color-border-primary-bold: var(--color-indigo-900);
  --color-border-secondary-decorative: var(--color-navy-100);
  --color-border-secondary-subtlest: var(--color-navy-200);
  --color-border-secondary-subtle: var(--color-navy-500);
  --color-border-secondary-medium: var(--color-navy-700);
  --color-border-secondary-bold: var(--color-navy-900);
  --color-border-neutral-decorative: var(--color-neutral-100);
  --color-border-neutral-subtlest: var(--color-neutral-200);
  --color-border-neutral-subtle: var(--color-neutral-500);
  --color-border-neutral-medium: var(--color-neutral-700);
  --color-border-neutral-bold: var(--color-neutral-900);
  --color-background-blue-subtlest: var(--color-blue-50);
  --color-background-blue-subtle: var(--color-blue-100);
  --color-background-blue-medium: var(--color-blue-200);
  --color-background-blue-bold: var(--color-blue-700);
  --color-background-coral-subtlest: var(--color-coral-50);
  --color-background-coral-subtle: var(--color-coral-100);
  --color-background-coral-medium: var(--color-coral-200);
  --color-background-coral-bold: var(--color-coral-700);
  --color-background-green-subtlest: var(--color-green-50);
  --color-background-green-subtle: var(--color-green-100);
  --color-background-green-medium: var(--color-green-200);
  --color-background-green-bold: var(--color-green-700);
  --color-background-purple-subtlest: var(--color-purple-50);
  --color-background-purple-subtle: var(--color-purple-100);
  --color-background-purple-medium: var(--color-purple-200);
  --color-background-purple-bold: var(--color-purple-700);
  --color-background-orange-subtlest: var(--color-orange-50);
  --color-background-orange-subtle: var(--color-orange-100);
  --color-background-orange-medium: var(--color-orange-200);
  --color-background-orange-bold: var(--color-orange-700);
  --color-background-pink-subtlest: var(--color-pink-50);
  --color-background-pink-subtle: var(--color-pink-100);
  --color-background-pink-medium: var(--color-pink-200);
  --color-background-pink-bold: var(--color-pink-700);
  --color-background-teal-subtlest: var(--color-teal-50);
  --color-background-teal-subtle: var(--color-teal-100);
  --color-background-teal-medium: var(--color-teal-200);
  --color-background-teal-bold: var(--color-teal-700);
  --color-background-desaturated-subtlest: var(--color-gray-000);
  --color-background-desaturated-subtle: var(--color-gray-100);
  --color-background-desaturated-medium: var(--color-gray-200);
  --color-background-desaturated-bold: var(--color-gray-700);
  --color-background-desaturated-inverse: var(--color-gray-1000);
  --color-background-savi-subtlest: var(--color-purple-50);
  --color-background-savi-subtle: var(--color-purple-100);
  --color-background-savi-medium: var(--color-purple-200);
  --color-background-savi-bold: var(--color-purple-700);
  --color-background-info-subtlest: var(--color-info-50);
  --color-background-info-subtle: var(--color-info-100);
  --color-background-info-medium: var(--color-info-200);
  --color-background-info-bold: var(--color-info-700);
  --color-background-warning-subtlest: var(--color-warning-50);
  --color-background-warning-subtle: var(--color-warning-100);
  --color-background-warning-medium: var(--color-warning-200);
  --color-background-warning-bold: var(--color-warning-400);
  --color-background-success-subtlest: var(--color-success-50);
  --color-background-success-subtle: var(--color-success-100);
  --color-background-success-medium: var(--color-success-200);
  --color-background-success-bold: var(--color-success-700);
  --color-background-critical-subtlest: var(--color-critical-50);
  --color-background-critical-subtle: var(--color-critical-100);
  --color-background-critical-medium: var(--color-critical-200);
  --color-background-critical-bold: var(--color-critical-700);
  --color-background-secondary-subtlest: var(--color-navy-50);
  --color-background-secondary-subtle: var(--color-navy-100);
  --color-background-secondary-medium: var(--color-navy-200);
  --color-background-secondary-bold: var(--color-navy-700);
  --color-background-primary-subtlest: var(--color-indigo-50);
  --color-background-primary-subtle: var(--color-indigo-100);
  --color-background-primary-medium: var(--color-indigo-200);
  --color-background-primary-bold: var(--color-indigo-700);
  --color-background-neutral-disabled-medium: var(--color-neutral-200);
  --color-background-neutral-disabled-subtle: var(--color-neutral-100);
  --color-background-neutral-subtlest: var(--color-neutral-000);
  --color-background-neutral-subtle: var(--color-neutral-100);
  --color-background-neutral-medium: var(--color-neutral-200);
  --color-background-neutral-bold: var(--color-neutral-700);
  --color-page-subtle-desaturated: var(--color-gray-50);
  --color-page-subtle: var(--color-navy-100);
  --color-page-bold: var(--color-neutral-000);
  --color-foreground-blue-subtle: var(--color-blue-500);
  --color-foreground-coral-subtle: var(--color-coral-500);
  --color-foreground-green-subtle: var(--color-green-500);
  --color-foreground-purple-subtle: var(--color-purple-500);
  --color-foreground-orange-subtle: var(--color-orange-500);
  --color-foreground-pink-subtle: var(--color-pink-500);
  --color-foreground-teal-subtle: var(--color-teal-500);
  --color-foreground-savi-subtle: var(--color-purple-500);
  --color-foreground-savi-medium: var(--color-purple-700);
  --color-foreground-savi-bold: var(--color-purple-900);
  --color-foreground-info-subtle: var(--color-info-500);
  --color-foreground-info-medium: var(--color-info-700);
  --color-foreground-info-bold: var(--color-info-900);
  --color-foreground-warning-subtle: var(--color-warning-500);
  --color-foreground-warning-medium: var(--color-warning-700);
  --color-foreground-warning-bold: var(--color-warning-900);
  --color-foreground-success-subtle: var(--color-success-500);
  --color-foreground-success-medium: var(--color-success-700);
  --color-foreground-success-bold: var(--color-success-900);
  --color-foreground-critical-subtle: var(--color-critical-500);
  --color-foreground-critical-medium: var(--color-critical-700);
  --color-foreground-critical-bold: var(--color-critical-900);
  --color-foreground-secondary-subtle: var(--color-navy-500);
  --color-foreground-secondary-medium: var(--color-navy-700);
  --color-foreground-secondary-bold: var(--color-navy-900);
  --color-foreground-primary-subtle: var(--color-indigo-500);
  --color-foreground-primary-medium: var(--color-indigo-700);
  --color-foreground-primary-bold: var(--color-indigo-900);
  --color-foreground-neutral-inverse: var(--color-neutral-000);
  --color-foreground-neutral-disabled: var(--color-neutral-400);
  --color-foreground-neutral-subtle: var(--color-neutral-600);
  --color-foreground-neutral-medium: var(--color-neutral-800);
  --color-foreground-neutral-bold: var(--color-neutral-1000);
}
