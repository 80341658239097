.ReviewAndSubmitModal-footerButtonWrap {
  display: flex;
  gap: 0.5rem;
}

.ReviewAndSubmitModal-footerButtonWrap .AlertBanner-container {
  position: absolute;
  left: 3rem;
  right: 3rem;
  top: 4.25rem;
}
