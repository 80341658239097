/* hide host toast messages */
.main.hideHostMessages .response-msg {
  display: none;
}

/* hide host snackbar messages */
.main.hideHostMessages .snackBarBackground {
  display: none;
}

/* hide host app loading spinner */
.main.hideHostLoader .loading-container {
  display: none;
}

/* hide host app "dynamicModules" */
.main.hideHostLoader .dynamicModules {
  display: none;
}
