.EntitlementsModal {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 85.375rem;
  min-height: 48rem;
}

#ModalIsland.ModalIsland.EntitlementsModal
  .ModalIsland-section
  .ModalIsland-body {
  max-height: calc(100vh - 14rem);
}

.EntitlementsModal-modalIslandContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20rem;
  min-height: 31.8rem;
}

.EntitlementsModal .ModalIsland-section {
  width: 100%;
  max-width: calc(100vw - 3rem);
}

.EntitlementsModal-accountsHelperText {
  width: 100%;
  margin-bottom: 1rem;
}

.EntitlementsModal-searchContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-200, 0.25rem);
  width: 50%;
  margin-bottom: 1rem;
}

.EntitlementsModal-search::placeholder {
  font: var(--typography-body-2);
}

.EntitlementsModal-entitlementsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.EntitlementsModal-entitlementsRow {
  display: flex;
  padding: 1rem 0;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
}
.EntitlementsModal-entitlementsRow--withBorder {
  border-bottom: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.EntitlementsModal-footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.EntitlementsModal-footerActionBar {
  border-radius: 0 0 0.25rem 0.25rem;
}

.EntitlementsModal-entitlementsRow-header,
.EntitlementsModal-entitlementsRow-metaData {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.EntitlementsModal-entitlementsRow-entitlementName {
  width: 100%;
  white-space: nowrap;
}

.EntitlementsModal-entitlementsRow-fields {
  width: 100%;
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.EntitlementsModal-entitlementsRow-label {
  width: 100%;
  white-space: nowrap;
  color: var(--color-foreground-neutral-subtle, #717385);
}

.EntitlementsModal-entitlementsRow-value {
  width: 100%;
  white-space: nowrap;
  color: var(--color-foreground-neutral-bold, #212328);
}

.EntitlementsModal-entitlementsRow-description {
  margin-top: 0.5rem;
  color: var(--color-foreground-secondary-bold, #00245b);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.EntitlementsModal-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28rem;
}
