.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 16px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button__line {
    width: 13px;
    height: 1px;
    border: solid 1px gray;
}

.side_drawer_icon {
    display: flex;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    flex-direction: column;
    justify-content: center;
}

.side_drawer_icon:hover {
    cursor: pointer;
}
.saviynt_logo {
    flex-direction: column;
    display: flex;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    margin-right: 14px;
    margin-left: 6px;
}
/* .app_name {
  margin-left: 15px;
} */
.divider {
    color: gray;
    border: solid 1px #b4b4b4;
    width: 0.5px;
    height: 15.5px;
}
.app-swithcer-menu {
    width: 317px;
    height: auto;
    box-shadow: 1px 4px 10px 0 #e1e2e5;
    background-color: #ffffff;
    position: absolute;
    top: 50px;
    right: 35%;
}

.popup-switchermenu {
    padding: 10px;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;

}

.icon,
.popup-switchermenu .icon {
    display: flex;
    justify-content: center;
}

.app-label {
    padding-top: 5px;
    color: black;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.ant-tabs-nav .ant-tabs-tab {
    font-size: 12px;
}

.ant-tabs-nav .ant-tabs-tab:first-child {
    left: 27px !important;
}

.ant-tabs-ink-bar {
    left: 25px !important;
}

.popup-switchermenu .nav-icon:hover,
.popup-switchermenu .nav-icon:focus {
    color: #0056b3;
    text-decoration: none;
}

.ant-tabs-bar {
    margin-bottom: 0px !important;
}

.user-profile .notification {
    padding-right: 10px !important;
}

.user-profile .app-switcher {
    padding-right: 20px !important;
    padding-top: 13px !important;
}

.user-profile .grid_search {
    padding-right: 22px !important;
}

.ant-tabs-nav .ant-tabs-tab:last-child {
    padding-left: 34px;
}

a .cl-logo {
    width: 100px;
}

.notificationIcon{
    stroke: #787878 !important;
    fill: #fefefe !important;
}

.notificationIcon:hover,
.notificationIcon:focus {
    stroke: #3651ff !important;
}

.notification .MuiBadge-badge-68 {
    width: 9px;
    height: 9px;
    border: solid 0.8px #ffffff;
    background-color: #3751ff;
    margin-top: 2px;
    margin-right: 2px;
}

/* =============Header CSS============== */
.top-bar-box-ecm {
    background-color: #ffffff;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99999 !important;
    position: fixed;
    /* padding-left: 216px; */
    border-bottom: 1px solid #e6e6e6;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 62px;
    box-shadow: 3px 3px 6px 0 #e1e2e5;
}

.top-bar-box {
    background-color: #ffffff;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    position: fixed;
    /* padding-left: 216px; */
    border-bottom: 1px solid #e6e6e6;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 62px;
    box-shadow: 3px 3px 6px 0 #e1e2e5;
}

.top-bar-box .top-bar {
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.top-bar-box.extendH,
.top-bar-box.extendH .top-bar {
    height: 62px;
}
.page-title {
    font-size: 20px;
    font-weight: 500;
    color: #1e1e1e;
    -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
    margin: 0; /*====Apply margin 0 when breadcrumb is not there====*/
}

.sub-title {
    color: #646464;
    font-size: 12px;
    margin-bottom: 28px;
}

.page-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-right: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    white-space: nowrap;
    flex-direction: column;
    justify-content: center;
    /* padding: 30px 0 0 30px; */
    padding-left: 10px; /*====Apply pedding-left only when breadcrumb is not there====*/
}

.displayName {
    width: 183px;
}

.page-sequence {
    font-size: 15px;
    color: #646464;
    display: block;
    padding-left: 14px;
    width: 183px !important;
    height: 18px;
    font-family: Rubik;
    font-size: 14px;
    font-weight: bold;
}
.dropdown-link {
     border-radius: 50% !important;
}
.app_name .page-sequence{
    margin:auto;
    display: flex;
}
.page-sequence .link {
    pointer-events: none;
    cursor: auto;
}
.p-2 {
    padding:8px;
}
.top-bar-box .top-bar .user-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* .top-bar-box .top-bar .user-profile .user-image {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    text-align: center;
    line-height: 22px;
    color: #646464;
} */

.top-bar-box .top-bar .user-profile .info {
    margin-left: 15px;
}

.top-bar-box .top-bar .user-profile .info .user-name {
    font-size: 14px;
    font-weight: 500;
    color: #646464;
}
/* .top-bar-box .top-bar .user-profile .dropdown-menu {
    right: 0;
    left: auto;
    border: 1px solid #dfe0eb;
    border-radius: 3px;
    min-width: max-content;
    font-size: 11px;
    padding: 0;
} */
.top-bar-box .top-bar .user-profile .dropdown-item {
    border-bottom: 1px solid #dfe0eb;
    padding: 15px 12px;
}

.top-bar-box .top-bar .user-profile .dropdown-item.active,
.top-bar-box .top-bar .user-profile .dropdown-item:active {
    background-color: #ffffff;
}
.top-bar-box .top-bar .user-profile .dropdown-item:last-child {
    border-bottom: none;
}
.top-bar-box .top-bar .user-profile .dropdown-item a {
    color: #1e1e1e;
    line-height: 22px;
}
.top-bar-box .top-bar .user-profile .dropdown-item a:hover,
.top-bar-box .top-bar .user-profile .dropdown-item a:focus {
    color: #3751ff;
}
.top-bar-box .top-bar .top-side-box {
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    position: relative;
    /* display: none; */
}

.top-bar-box .top-bar .top-side-box .notification-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.top-bar-box .top-bar .top-side-box .notification-box .notification-item {
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    margin: 0 10px;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.top-bar-box
.top-bar
> .top-side-box
> .notification-box
> .notification-item
> .la {
    position: relative;
    font-size: 1.5rem;
    color: #787878;
}

.top-bar-box
.top-bar
> .top-side-box
> .notification-box
> .notification-item
> .bullet {
    width: 9px;
    height: 9px;
    top: 5px;
    right: 0px;
    background: #3751ff;
    position: absolute;
    border-radius: 50%;
}

.closeSidebar .top-bar-box {
    padding-left: 60px;
}

.serach-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.searchContainer {
    background-color: #ffffff;
    width: 175px;
    height: 35px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px 0 rgba(223, 225, 235, 0.5);
    border: 1px solid #c8c8c8;
    overflow: hidden;
    position: relative;
}

.searchContainer label {
    display: flex;
    margin: 0;
}

.searchContainer:focus-within svg {
    display: none;
}

.searchContainer:focus-within .searchBox::placeholder {
    color: transparent;
}

.searchIcon {
    position: absolute;
    color: #bcc0c8;
    z-index: 2;
    left: 10px;
    font-size: 18px;
    top: 10px;
}

.container-fluid{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

/* .user-image  {
    padding: 5px;
} */


.searchBox {
    border: 0;
    outline: none;
    font-size: 12px;
    font-weight: 500;
    color: #b7bcc4;
    flex: 1;
    height: 35px;
    padding-left: 11px;
    width: 100%;
    padding-top: 7px;
}

.searchBox:focus {
    padding-left: 10px;
}

.gridsearch {
    margin: -3px 0 0 25px;
}

.grid-searchfull {
    background-color: white;
    display: flex;
    padding: 0 10px;

}

.gridSearch-icon {
    margin-top: 10px;
}

/*ant tabs */
.popup-switchermenu .disabled svg,
.popup-switchermenu .disabled svg:hover,
.popup-switchermenu .disabled svg:focus {
    /* cursor: default */
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    stroke: #9fa2b4 !important;
}

.popup-switchermenu svg:hover,
.popup-switchermenu svg:focus {
    stroke: #3651ff !important;
}

.popup-switchermenu svg {
    stroke: #9fa2b4 !important;
}

/* .popup-switchermenu div{
    padding-bottom: 30px;
} */
.ant-tabs-nav .ant-tabs-tab-active {
    color: #3751ff !important;
}
.ant-tabs-ink-bar {
    background-color: #3751ff !important;
}

.apps-menu:not(:nth-child(5)):not(:nth-child(6)) {
    padding-bottom: 20px;
}

.apps-menu:not(:nth-child(2)):not(:nth-child(4)):not(:nth-child(6)) {
    padding-right: 30px;
}

.popup-switchermenu .app-label {
    color: #1e1e1e !important;
}

.ant-tabs-tab .ant-tabs-tab {
    width: 59px;
    height: 14px;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b4b4b4;
}

.apps {
    padding-top: 20px;
}

.alignIcon {
    margin-left: 12px;
}

.alignLabel {
    padding: 0px;
    margin-left: 7px;
    margin-bottom: 5px;
}

.logout {
    display:block;
}

.mt-4 {
    margin: auto !important;
}

.MuiDrawer-modal {
    z-index: 100000 !important;
}

.userMenuIcon {
    margin-bottom: -9px;
}

.recetnActivite {
    padding: 24px;
    background-color: #f7f8fc;
    border-radius: 3px;
    width: 370px;
}

.tableActivite {
    max-height: 55px;
    padding: 15px 0 20px 0;
}

.userLoginText h6 {
    font-size: 11px;
    color: var(--grey-font);
}

.timeLoginText h6 {
    font-size: 12px;
    color: #000000;
    margin-bottom: 0px;
}

.lastLoginIP {
    color: var(--grey-font);
    font-size: 12px;
    float: right;
}
