.Header {
  display: flex;
  height: 3.5rem;
  padding: 0.75rem 3rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.0625rem solid var(--color-border-neutral-decorative, #ebecf2);
  background: var(--color-background-neutral-subtlest, #fff);
  z-index: var(--z-index-page-header);
}

.Header--scrolled {
  box-shadow: var(--box-shadow-pretoken-overlay-bottom);
  border-bottom: 0.0625rem solid transparent;
  background: var(--color-background-neutral-subtlest, #fff);
}

.Header-extraContent {
  display: flex;
  width: 6.448rem;
  height: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
}

.Header-logo {
  width: 6.448rem;
  height: 1.5rem;
}

.Header-actionItems {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
}

.Header-avatar:hover span {
  opacity: 1;
  border: none;
  background: rgba(92, 94, 110, 0.08);
}
