.emptyScreenLayout{
background-color: white;
min-height: 412px;
.emptyScreenInnerLayout{
    padding:70px 200px 42px 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.screenIcon{
    height:100px;
}
.title{
    font-size: 16px;
    font-weight: 500;
    color: var(--black-li);
    margin-bottom:17px;
    margin-top:16px;
}
.subtitle{
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: var(--grey-font);
    margin-bottom:36px;
}
.btnDetails{
    text-transform: none;
    min-height: 36px;
}
.btnText{
    color: var(--primary-main);
    cursor: pointer;
}
}
.insidePanelLayout{
    .recentActivity {
        padding-top: 25px;
        padding-left: 39px;
        padding-bottom: 25px;
        position: sticky;
        top: 0px;
        background-color: var(--dark-bg);
        z-index: 999;
        border-bottom: solid 1px var(--dark-line);
        .activityTitle {
          font-size: 18px;
          font-weight: 500;
          color: var(--dark-text-title);
        }
      }
      .screenIcon{
        height:161px;
        width:300px;
    }
    .emptyScreenInnerLayout{
        padding:60px 32px 42px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .title{
        font-size: 13px;
        text-align: center;
        color: var(--home-featuretile-title);
        object-fit: contain;
        margin-top:30px;
        margin-bottom:20px;
        font-weight: 500;
    }
    .subtitle{
        object-fit: contain;
        font-size: 13px;
  line-height: 1.62;
  text-align: center;
  color: var(--home-subtitle);
  margin-bottom:41px;
    }
    .support{
        font-size: 12px;
        line-height: 1.5;
        color: #7c7c8b;
        margin-top: 62px;
    }
    .btnDetails{
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: var(--primary-main);
        background-color:white;
        text-transform: none;
    }
}


