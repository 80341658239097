.body {
  .Nav_KpiTile {
    background: var(--color-foreground-neutral-inverse, #ffffff);
    border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
    padding: 1rem 1.25rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      background: var(--color-background-secondary-subtlest, #f7f9fc);
    }

    &:active {
      background: var(--color-background-secondary-subtlest, #f7f9fc);
      border: 1px solid var(--color-border-secondary-subtle, #6888b5);
    }

    .Nav_KpiTile-main {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .Nav_KpiTile-metric {
        font-size: var(--font-size-32, 2rem);
        color: var(--color-foreground-secondary-medium, #254b86);
      }

      .Nav_KpiTile-label {
        color: var(--color-foreground-neutral-medium, #4a4c59);
      }
    }

    &.Nav_KpiTile--loading {
      justify-content: center;
    }
  }
}
