.BulkActionEndDateModal .ModalIsland-header {
  height: auto;
}

.BulkActionEndDateModal .ModalIsland-titleAndHeaderIcon {
  padding: 0.75rem 2rem;
  overflow: unset;
}

.BulkActionEndDateModal .ModalIsland-titleContainer {
  gap: 0.5rem;
  overflow: unset;
}

.BulkActionEndDateHeader {
  display: flex;
  align-items: center;
  position: relative;
}

.BulkActionEndDateHeader-icon {
  display: flex;
  position: absolute;
  top: -1px;
  left: -2.5rem;
}

.BulkActionEndDateModal .ModalIsland-body {
  padding-inline: 3rem !important;
  max-height: calc(100vh - 16rem) !important;
  overflow: auto !important;
}

.BulkActionEndDateModal .ModalIsland-body::-webkit-scrollbar-thumb {
  background-color: var(--color-background-neutral-medium, #cecfd9);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: var(--border-radius-circle, 50%);
}

.BulkActionEndDateBody {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  gap: 1.5rem;
}

.BulkActionEndDateFooter-footerButtonWrap {
  display: flex;
  gap: 0.5rem;
}

.BulkActionEndDateFooter-footerButtonWrap .AlertBanner-container {
  position: fixed;
  width: calc(100% - 8rem);
}
