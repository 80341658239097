.LandingPageSidePanel {
  width: 100%;
  height: 100%;
}

.LandingPageSidePanel-noTabGroupHeader {
  border-bottom: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  padding-bottom: 1rem;
}
.LandingPageSidePanel-noTabGroupHeader + .SidePanel-layout {
  padding-top: 0;
}
.LandingPageSidePanel-noTabGroupHeader + .SidePanel-layout .SpvEndpoint {
  max-height: calc(100vh - 12.25rem);
}

.LandingPageSidePanel .SidePanel-innerContent {
  overflow: hidden;
}

/* TODO: @Huy, Work with UX to fix the CTS and COC buttons are to long for smaller screens */
@media (max-width: 1330px) {
  .LandingPageSidePanel .SidePanelFooter {
    padding: 0.75rem;
  }
}
