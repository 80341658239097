.Grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  max-width: 1440px;
  min-width: 375px;
}

.Grid-item > .Grid {
  min-width: initial;
}
