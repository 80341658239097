/* CLOSED */
.CertsSearchBar {
  position: relative;
  width: 2.5rem;
  max-width: 19.125rem;
  transition:
    width var(--transition-quick-in-out),
    padding var(--transition-quick-in-out) 100ms,
    border var(--transition-fast-in-out);
}
.CertsSearchBar .InputField-Container {
  width: 100%;
  transition:
    width var(--transition-quick-in-out),
    padding var(--transition-quick-in-out) 100ms,
    border var(--transition-fast-in-out);
}
/* CertsSearchBar is the button wrapper aspect of the search bar */
.CertsSearchBar-button {
  all: unset;
  position: absolute;
  top: -2.5rem;
  left: 0;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: var(--border-radius-sm);
  pointer-events: all;
  cursor: pointer;
  isolation: isolate;
}
.CertsSearchBar:hover .InputField-input {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.CertsSearchBar .InputField-input-prefixIcon--medium {
  padding-left: 0;
}

/* Overlay Hover */
.CertsSearchBar-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: var(--border-radius-sm);
  background: transparent;
  opacity: 0;
  transition: all var(--transition-fast-in-out);
  z-index: var(--z-index-certs-landingpage-searchbar-overlay);
  pointer-events: none;
}
.CertsSearchBar-button:hover ~ .CertsSearchBar-overlay {
  opacity: 1;
  background: rgba(37, 75, 134, 0.08);
}

.CertsSearchBar .InputField-Container {
  pointer-events: none;
}

/* Icon */
.CertsSearchBar .InputField-prefixIcon--medium,
.CertsSearchBar .InputField-prefixIcon--medium svg {
  width: 1.25rem;
  height: 1.25rem;
  transition:
    width var(--transition-quick-in-out),
    height var(--transition-quick-in-out),
    fill var(--transition-quick-in-out);
}
.CertsSearchBar .InputField-prefixIcon--medium svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
}

.CertsSearchBar input::placeholder {
  opacity: 0;
  visibility: hidden;
}

/* OPEN */
.CertsSearchBar--isExpanded {
  min-width: 11rem;
  width: 100%;
  max-width: 19.1875rem;
}
.CertsSearchBar--isExpanded .InputField-input-prefixIcon--medium {
  padding-left: 1.75rem;
}
.CertsSearchBar--isExpanded .InputField-Container {
  pointer-events: all;
}
.CertsSearchBar--isExpanded .CertsSearchBar-button {
  pointer-events: none;
}

.CertsSearchBar--isExpanded .CertsSearchBar-overlay {
  display: none;
}
/* Icon */
.CertsSearchBar--isExpanded .InputField-prefixIcon--medium,
.CertsSearchBar--isExpanded .InputField-prefixIcon--medium svg {
  width: 1rem;
  height: 1rem;
}
.CertsSearchBar--isExpanded .InputField-prefixIcon--medium svg path {
  fill: var(--color-foreground-neutral-subtle, #717385);
}

.CertsSearchBar--isExpanded input::placeholder {
  opacity: 1;
  visibility: visible;
}

.CertsSearchBar--isDisabled {
  pointer-events: none;
}
