.Checkbox.Checkbox-card .Checkbox-label {
  padding: 1rem;
}

.Checkbox.Checkbox-card .Checkbox-textWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 0.5rem;
}

.Checkbox-card .Checkbox-labelText {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.Checkbox-card .Checkbox-iconTextWrapper {
  display: flex;
}

.Checkbox-card .Checkbox-iconTextWrapper .Checkbox-iconWrapper {
  align-self: flex-start;
}

.Checkbox.Checkbox-card.Checkbox--disabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
}
