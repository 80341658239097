body {
  position: relative;
  padding-bottom: 3rem;
}

.dynamicModules {
  /* padding:62px 0 60px 216px; */
  /*padding: 60px 0 0 0;*/
  /* margin: 2rem 0 2rem 2rem; */
}

.extendedTabHeight {
  /* padding:62px 0 60px 216px; */
  padding: 50px 0 0 0;
}

/* bootstrap overrides */
.datepicker-panel {
  position: absolute;
  top: 103%;
  display: flex;
}

.datepicker-panel .react-datepicker {
  display: flex;
  opacity: 0.9;
  z-index: 2000;
}

.dropdownHeader .dropdown-menu {
  display: block;
}

.main-container {
  height: 100%;
}


#pageLayout.LayoutWithoutSideNav {
  margin-left: 0 !important;
}

#pageLayout.LayoutWithSideNav,
#pageLayout.LayoutWithSideNavMenuOpen {
  & .userDetails-container {
    container-type: inline-size;
  }
}

#pageLayout.LayoutWithSideNav {
  margin-left: 73px !important;
}

#pageLayout.LayoutWithSideNavMenuOpen {
  margin-left: 240px !important;
}

@media screen and (max-width: 480px) {
  #pageLayout.LayoutWithSideNav {
    margin-left: 0 !important;
  }

  #pageLayout.LayoutWithSideNavMenuOpen {
    margin-left: 0 !important;
  }
}

body .dropdown-menu li>.MuiSwitch-root {
  display: inline-flex !important;
  padding: 0 !important;
}