.InlineMessage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem;
}
.InlineMessage.InlineMessage--background {
  min-height: 2rem;
}

.InlineMessage-leftContent {
  display: flex;
  width: 100%;
}

.InlineMessage--small {
  font: var(--typography-body-3);
}
.InlineMessage--medium {
  font: var(--typography-body-2);
}

.InlineMessage-leftIconContainer,
.InlineMessage-dismissContainer {
  align-self: flex-start;
}

.InlineMessage-textContainer {
  word-break: break-word;
}

.InlineMessage-leftIconContainer,
.InlineMessage-textContainer,
.InlineMessage-linkContainer {
  margin-right: 0.25rem;
}

.InlineMessage-dismissContainer {
  cursor: pointer;
}

/* size variations */
.InlineMessage--small .InlineMessage-leftIconContainer,
.InlineMessage--small .InlineMessage-leftIconContainer svg {
  width: 1rem;
  height: 1rem;
}
.InlineMessage--medium .InlineMessage-leftIconContainer,
.InlineMessage--medium .InlineMessage-leftIconContainer svg {
  width: 1.25rem;
  height: 1.25rem;
}

.InlineMessage--small .InlineMessage-dismissContainer,
.InlineMessage--small .InlineMessage-dismissContainer span,
.InlineMessage--small .InlineMessage-dismissContainer svg {
  width: 1rem;
  height: 1rem;
}
.InlineMessage--medium .InlineMessage-dismissContainer,
.InlineMessage--medium .InlineMessage-dismissContainer span,
.InlineMessage--medium .InlineMessage-dismissContainer svg {
  width: 1.25rem;
  height: 1.25rem;
}

.InlineMessage--medium .InlineMessage-dismissContainer {
  transform: translate(0, 1px);
}

/* color variations including Link */
.InlineMessage--neutral,
.InlineMessage--neutral .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-neutral-medium, #4a4c59);
  fill: var(--color-foreground-neutral-medium, #4a4c59);
}

.InlineMessage--critical,
.InlineMessage--critical .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-critical-medium, #cc1c10);
  fill: var(--color-foreground-critical-medium, #cc1c10);
}
.InlineMessage--critical .Link .Link-wrapper {
  color: var(--color-foreground-critical-bold, #700600);
}

.InlineMessage--warning,
.InlineMessage--warning .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-warning-medium, #a36a00);
  fill: var(--color-foreground-warning-medium, #a36a00);
}
.InlineMessage--warning .Link .Link-wrapper {
  color: var(--color-foreground-warning-bold, #5c3c00);
}

.InlineMessage--success,
.InlineMessage--success .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-success-medium, #028329);
  fill: var(--color-foreground-success-medium, #028329);
}
.InlineMessage--success .Link .Link-wrapper {
  color: var(--color-foreground-success-bold, #033a0f);
}

.InlineMessage--info,
.InlineMessage--info .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-info-medium, #573bdc);
  fill: var(--color-foreground-info-medium, #573bdc);
}
.InlineMessage--info .Link .Link-wrapper {
  color: var(--color-foreground-info-bold, #332196);
}

.InlineMessage--disabled,
.InlineMessage--disabled .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
}

/* Background text color varents */
.InlineMessage--critical.InlineMessage--background,
.InlineMessage--critical .InlineMessage-linkContainer .Icon svg path {
  color: var(--color-foreground-critical-bold, #700600);
  fill: var(--color-foreground-critical-bold, #700600);
}

.InlineMessage--warning.InlineMessage--background,
.InlineMessage--warning .InlineMessage-linkContainer .Icon svg path {
  color: var(--color-foreground-warning-bold, #5c3c00);
  fill: var(--color-foreground-warning-bold, #5c3c00);
}

.InlineMessage--success.InlineMessage--background,
.InlineMessage--success .InlineMessage-linkContainer .Icon svg path {
  color: var(--color-foreground-success-bold, #033a0f);
  fill: var(--color-foreground-success-bold, #033a0f);
}

.InlineMessage--info.InlineMessage--background,
.InlineMessage--info .InlineMessage-linkContainer .Icon svg path {
  color: var(--color-foreground-info-bold, #332196);
  fill: var(--color-foreground-info-bold, #332196);
}

.InlineMessage--background .InlineMessage-leftIconContainer,
.InlineMessage--background .InlineMessage-textContainer,
.InlineMessage--background .InlineMessage-linkContainer,
.InlineMessage--background .InlineMessage-dismissContainer {
  padding: 0.25rem 0;
}

.InlineMessage--background {
  border-radius: var(--border-radius-sm, 4px);
}

/* background color variations */
.InlineMessage--background.InlineMessage--critical {
  background: var(--color-background-critical-subtle, #fdeeed);
  border-left: 2px solid var(--color-border-critical-medium, #cc1c10);
}
.InlineMessage--background.InlineMessage--warning {
  background: var(--color-background-warning-subtle, #fff1d9);
  border-left: 2px solid var(--color-border-warning-subtle, #f6a91c);
}

.InlineMessage--background.InlineMessage--success {
  background: var(--color-background-success-subtle, #e5f7e0);
  border-left: 2px solid var(--color-border-success-subtle, #43a34d);
}

.InlineMessage--background.InlineMessage--info {
  background: var(--color-background-info-subtle, #f1ecfd);
  border-left: 2px solid var(--color-border-info-medium, #573bdc);
}
