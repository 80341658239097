.container {
    composes: flexContainer from './../../../ECMv6/assets/css/global.css';
    flex-direction: column;
    /*height: calc(100vh - 44px);*/
}

.defaultContainer {
    composes: container;
    justify-content: center;
    align-items: center;
    margin-top: -10%;
    height: 100vh;
    margin-bottom: 110px;
}

.resultsContainer {
    composes: container;
    margin-top: 80px;
}

.defaultContent {
    composes: flexContainer from './../../../ECMv6/assets/css/global.css';
    flex-direction: column;
    width: 70%;
    margin-top: 1rem;
}

.content {
    composes: flexContainer from './../../../ECMv6/assets/css/global.css';
    flex-direction: column;
    margin-top: 1rem;
}

.searchBoxContainer {
    composes: flexContainer from './../../../ECMv6/assets/css/global.css';
    justify-content: center;
    align-items: flex-start;
}

.searchBoxContainerBox {
    background-color: var(--primary-btn-bg);
    /*border: var(--primary-card-bg-text) 2px solid;*/
    padding: 2rem;
    border-radius: 3px;
}
