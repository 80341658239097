.CertsConsultedActionPopover-contextWrapper {
  position: relative;
  display: flex;
  width: max-content;
}
/* Needed to cover other popovers */
.CertsConsultedActionPopover-contextWrapper .Popover-wrapper--isOpen {
  z-index: var(--z-index-popover);
}

/* Content */
.CertsConsultedActionPopover {
  min-width: 22rem;
  width: min-content;
  max-width: 40rem;
  padding: 1.5rem 0;
}

.CertsConsultedActionPopover-loader {
  display: flex;
  justify-content: center;
}

.CertsConsultedActionPopover-errorMsg {
  display: flex;
  justify-content: center;
}

.CertsConsultedActionPopover-errorMsg p {
  color: var(--color-foreground-critical-subtle, #e5362f);
}

.CertsConsultedActionPopover-header {
  display: flex;
  justify-content: space-between;
}

.CertsConsultedActionPopover-actionStatus {
  display: flex;
}

.CertsConsultedActionPopover-title {
  color: var(--color-foreground-neutral-bold, #00245b);
  margin-left: 0.5rem;
}

.CertsConsultedActionPopover-content {
  padding: 0 1rem;
}

.CertsConsultedActionPopover-details {
  color: var(--color-foreground-neutral-bold, #212328);
  /* padding: 0.5rem  1.5rem 0 1.5rem; */
  padding-top: 0.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;

  align-self: stretch;
  margin-left: 32px;
}
.CertsConsultedActionPopover-details div {
  width: 50%;
}

/* .CertsConsultedActionPopover-heading{

} */

.CertsConsultedActionPopover-info {
  overflow: hidden;
  color: var(--color-foreground-secondary-bold, #00245b);
  font-feature-settings:
    'clig' off,
    'liga' off;
  text-overflow: ellipsis;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.CertsConsultedActionPopover-commentsInfo {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}

/* Control Buttons */
.CertsConsultedActionPopover-controls {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  gap: 0.5rem;
}
