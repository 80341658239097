.single-enpoint {
  .item-img {
    width: 50px;
    height: 50px;
    background-color: inherit;
    border-radius: 100%;
    margin: 30px auto 38px;
    position: relative;
    img {
      position: relative;
      background-color: #fff;
      // border-radius: 100%;
    }
    .app-initial {
      position: absolute;
      top: 14px;
      text-align: center;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 1;
    }
    &.img-error {
      background-color: var(--grey-border);
      img {
        display: none;
      }
      &.item-img-round {
        overflow: hidden;
      }
    }
  }

  h6 {
    font-size: 11px;
    // font-weight: 700;
    // color: #1e1e1e;
    font-weight: 500;
    color: var(--black-medium-text);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 1px;
    padding-bottom: 3px;
  }
  .item-footer {
    border-top: 1px solid rgba(205, 206, 217, 0.5);
    padding: 12px 0;
  }
  .footer-content {
    font-size: 10px;
    line-height: 1.1;
  }
  .item-description {
    color: var(--grey-header);
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: ignore next */
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    padding-left: 1px;
  }
  &:hover {
    .hover-content {
      bottom: 0%;
      transition: top 0.9s ease;
    }
  }

  .hover-content {
    bottom: 0%;
    color: #fff;
    height: 100%;
    left: 0%;
    text-align: center;
    position: absolute;
    transition: bottom 0.9s ease;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 -1px 5px 0 rgba(205, 206, 217, 0.5);
    background-color: #ffffff;
    padding: 6px 11px;
    height: 116px;
    z-index: 20;
  }
}

.disable-tag {
  font-size: 20px;
  background-color: var(--grey-border);
  opacity: 0.5;
  color: var(--grey-font);
  position: absolute;
  top: 93px;
  left: 26px;
  z-index: 11;
  padding: 0 10px;
  border-radius: 6px;
  font-size: 1rem;
}
