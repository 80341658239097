.CertsRejectAction-header-wrap,
.CertsSidePanelCommentDrawer-rejectActionHeaderWrap {
  display: flex;
  align-items: center;
}

.CertsRejectPopover-commentsWrap {
  padding: 1rem;
}

.CertsRejectPopover-commentsTitle,
.CertsSidePanelCommentDrawer-rejectPopoverCommentsTitle {
  padding: 0 0.625rem;
  width: 100%;
}

.CertsRejectPopover-commentsDesc {
  margin-left: 1.875rem;
  padding: 0.2rem 0 0.5rem 0;
  color: var(--color-foreground-neutral-medium, #4a4c59);
}

.CertsRejectPopover-desc {
  color: var(--color-foreground-neutral-bold, #212328);
}

.CertsRejectPopover-commentsBoxWrap {
  margin: 1rem 0.8rem 0.5rem;
}

.CertsRejectPopover-commentsWrap .CertsActionPopover-controls {
  margin: 0 0.7rem;
}

.CertsRejectPopover-NoteWrapper {
  margin-left: 1.875rem;
  padding-bottom: 1rem;
  color: var(--color-foreground-neutral-medium, #4a4c59);
}

.CertsRejectPopover-NoteText {
  color: var(--color-critical-700, #cc1c10);
}

.CertsRejectPopover-note {
  padding: 0.5rem 0 0.3rem 0;
  margin-left: 0;
}

.CertsRejectActionPopover {
  width: min-content;
  min-width: 21rem;
  max-width: 60rem;
  padding: 1.5rem 1.3rem 0.5rem 1.5rem;
}

/* TODO: @Jacob, temp fix for CR-16494, beta regresions  */
@media (max-width: 640px) {
  .CertsActionPopover,
  .CertsRejectPopover-commentsWrap {
    min-width: unset;
    width: 70vw;
  }
}
/* TODO: END  */
