.DialogFullHeight{
    width: 100%;
    height: 100%;
    .mitigatingHead{
        margin-top: 35px;
        margin-bottom: 5px !important;
    }

    .mitigatingFooter{
        padding-right: 25px;
    }

    .contentMitigating{
        padding: 30px 25px 16px 25px;
    }
.allbuttonLayout{
    display:flex;
    margin-bottom:10px;
}

    .mitigatingDialog{
        padding: 23px 30px 51px 30px;
        background-color: var(--page-bg);
        .allButton{
            width: 35px;
            border-radius: 3px;
            border: solid 1px #dfe0eb;
            color: #272222;
            margin-top: 10px;
            height: 32px;
        }
        .recommendeButton{
            background-color: rgba(210, 216, 252, 0.5);
            border: none;
            margin-right: 7px;
            margin-top: 10px;
            height:32px;
        }
        h6{
            font-size: 13px;
            margin-bottom: 15px !important;
            font-weight: 500;
            color: black;
        }
    }

    .listMitigating{
        :global .divTableRow{
            &:hover{
                .selectBtn{
                    opacity: 1;
                }
            }
        }
}
.selectBtn{
    opacity:1;
}
.outlined{
    padding:4px 13px ;
}
.dialogTitle{
    padding-left: 9px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    color: black;
    .dialogSubTitle1{
        font-size: 12px;
        font-weight: normal;
        color: var(--grey-font);
    }
    .dialogSubTitle2{
        font-weight: 500;
    }
}
:global .MuiIconButton-root {
    top:13px !important;
}
}
.textFiledMargin{
    margin:0;
}



