.PopoverProgressBreakdown {
  position: absolute;
  bottom: 4rem;
  width: 25.5rem;
  height: 61vh;
  max-height: 65vh;
  border-radius: 0.25rem;
  background: var(--color-background-neutral-subtlest, #fff);
  box-shadow: 0 2px 16px 2px rgba(0, 0, 0, 0.08);
  padding: 0 0.5rem 0 1rem;
  display: flex;
  flex-direction: column;
}

.PopoverProgressBreakdown:after {
  content: '';
  width: 1.25rem;
  height: 1.25rem;
  transform: rotate(45deg);
  background: var(--color-background-neutral-subtlest, #fff);
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
}

.PopoverProgressBreakdown .PopoverProgressBreakdown-close {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.PopoverProgressBreakdown-title {
  color: var(--color-foreground-neutral-bold, #212328);
  justify-content: flex-start;
  display: flex;
}

/* Resonsive */
@media (max-width: 880px) {
  .PopoverProgressBreakdown {
    margin-left: 5rem;
  }
}
