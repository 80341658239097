.SpvRole {
  width: 100%;
  height: 100%;
}

.SpvRole-section {
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.SpvRole-securityRankings {
  cursor: pointer;
  pointer-events: none;
}

.SpvRole-additionalCustomProperties {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-200, 0.25rem);
  align-self: stretch;
}

.SpvRole-additionalCustomProperties--text {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.SpvRole-showMoreCustomProperties {
  font: var(--typography-body-2);
}
