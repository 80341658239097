.PaginationItem {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font: var(--typography-body-2);
  text-align: center;
  border-radius: var(--border-radius-circle, 99rem);
  border: 1px solid transparent;
  color: var(--color-foreground-neutral-bold, #212328);
  height: 2.5rem;
  width: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  transition: all var(--transition-fast-in-out);
  cursor: pointer;
}
.PaginationItem:hover {
  background: rgba(55, 81, 255, 0.08);
}
.PaginationItem:not(.PaginationItem--isDisabled)[tabindex]:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  outline-offset: 0.125rem;
}

/* isSelected */
.PaginationItem--isSelected {
  color: var(--color-foreground-primary-medium, #3751ff);
  border: 1px solid var(--color-border-primary-medium, #3751ff);
  background: var(--color-background-primary-subtle, #ebedff);
}
.PaginationItem--isSelected:hover {
  background: var(--color-background-primary-subtle, #ebedff);
}

/* isDisabled */
.PaginationItem--isDisabled {
  pointer-events: none;
}
.PaginationItem--isDisabled svg path {
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.PaginationItem--noTransition {
  transition: unset;
}
.PaginationItem--transition {
  transition: all var(--transition-fast-in-out);
}

/* isEllipses */
.PaginationItem--isEllipses {
  pointer-events: none;
  transition: unset;
}
