.BulkActionCommonSubTitle li {
  list-style: disc;
}

.BulkActionCommonBody {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  gap: 1.5rem;
}

.BulkActionCommonBody--rejectMessage {
  padding: 0;
}

.BulkActionCommonBody-checkBox {
  position: relative;
}

.BulkActionCommonBody-checkBoxWarning {
  color: var(--color-foreground-warning-medium);
  position: absolute;
  top: 0;
  left: -0.675rem;
}

.BulkActionCommonBody-checkBoxWarning.showAlert {
  color: var(--color-foreground-critical-medium, #cc1c10);
}

.BulkActionCommonBody-checkBox .Checkbox-container {
  width: 100%;
}

.BulkActionCommonFooter-footerButtonWrap {
  display: flex;
  gap: 0.5rem;
}

.BulkActionCommonFooter-footerButtonWrap .AlertBanner-container {
  position: fixed;
  width: calc(100% - 8rem);
}

.BulkActionCommonBody .Checkbox-container .Checkbox-errorWrapper {
  padding-top: 0.125rem;
  padding-left: 0.375rem;
}

.BulkActionCommonBody .Checkbox-container .Checkbox-errorWrapper .Icon {
  height: 1rem;
  width: 1rem;
}
