.body div.react-autosuggest__suggestions-container {
  width: 100%;
  border-top: none;
  background-color: #ffffff;
  top: 43px;
  position: absolute;
  z-index: 1111;
}
.body .modal-body div.react-autosuggest__suggestions-container {
  position: absolute;
  top: 22px;
}
div.react-autosuggest__container {
  width: 100%;
  position: relative;
}
input.react-autosuggest__input {
  height: 32px;
  font-size: 13px;
  border-radius: 3px;
  border: solid 1px var(--primary-main);
}
input.react-autosuggest__input,
input.react-autosuggest__input--focused {
  width: 100%;
  min-width: 100px;
  box-shadow: none;
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: solid 1px #ececec;
}
input.react-autosuggest__input--focused {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
ul.react-autosuggest__suggestions-list {
  list-style: none;
  max-height: 150px;
  overflow-y: hidden;
  width: 100%;
  border: solid 1px var(--primary-main);
  border-top: none;
  z-index: 1000;
  background: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin: 0;
}
ul.react-autosuggest__suggestions-list:hover {
  overflow-y: auto;
}
li.react-autosuggest__suggestion,
ul.react-autosuggest__suggestions-list
  li.react-autosuggest__suggestion--highlighted {
  background: #ffffff;
  padding: 5px;
  font-size: 11px;
}
li.react-autosuggest__suggestion:hover,
ul.react-autosuggest__suggestions-list
  li.react-autosuggest__suggestion--highlighted:hover {
  background-color: #e8ebff;
  cursor: pointer;
  color: var(--primary-main);
}
span.autosuggest-close {
  font-weight: bold;
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
}
div.autosuggest-maincontainer {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}
.capsul_select {
  align-items: flex-start;
  margin: 5px 0;
  overflow-y: auto;
  height: auto;
  max-height: 150px;
}
.capsul_select li {
  margin: 0 5px 5px 0;
  font-size: 12px;
}
.capsul_select li .ses-list .remove-icon {
  margin: 0 5px 0 10px;
}

ul.react-autosuggest__suggestions-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 1);
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 1);
  background-color: #ffffff;
}

ul.react-autosuggest__suggestions-list::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

ul.react-autosuggest__suggestions-list::-webkit-scrollbar-thumb {
  background-color: #9f9d9d;
  border-radius: 10px;
}
