@import './ModalSidePanel.css';

/* TODO: @Jacob, speak with a PAM dev to update so we can remove this: :not(.SidePanel--isResponsivenessDisabled) */

.SidePanel-wrapper {
  width: 100%;
}

.SidePanel {
  border-left: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  /* background: var(--color-background-neutral-subtlest, #fff); */
  height: 100%;
  width: 100%;
  z-index: var(--z-index-sidepanel);
}

.SidePanel-innerContent {
  position: absolute;
  bottom: 0;
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
  width: 100%;
  height: 100%;
  overflow: auto;
}

.SidePanel-header {
  display: flex;
  padding: 1rem 1rem 0 1rem;
  gap: 0.5rem;
  background: var(--color-background-neutral-subtlest, #fff);
}
.SidePanel-headerTextIconGroup {
  display: flex;
  gap: 0.75rem;
  width: 100%;
}

.SidePanel-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--color-background-neutral-subtlest, #fff);
  height: 100%;
  width: 100%;
  padding-top: 0.75rem;
}
.SidePanel-layout .TabGroup {
  width: 100%;
}
.SidePanel-layout .TabGroup .TabItem--0 {
  margin-left: 0.5rem;
}
.SidePanel-layout .TabContent {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.SidePanel-footer {
  display: flex;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
}

.SidePanel-header-icon,
.SidePanel-header-icon svg {
  width: 3rem;
  height: 3rem;
}

.SidePanel-headerText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
}

.SidePanel-headerTitle {
  align-self: stretch;
  color: var(--color-foreground-neutral-bold, #212328);
}

.SidePanel-headerDescription {
  color: var(--color-foreground-neutral-subtle, #717385);
}

@media (max-width: 833px) {
  /* TODO: Remove TEMP bool isResponsivenessDisabled, used to avoid PAM regressions */
  .SidePanel:not(.SidePanel--isResponsivenessDisabled) {
    flex-direction: unset;
    align-items: unset;
    border-left: unset;
    z-index: unset;
  }
  .SidePanel-innerContent:not(.SidePanel--isResponsivenessDisabled) {
    max-height: unset;
  }
  .SidePanel-layout:not(.SidePanel--isResponsivenessDisabled) {
    height: unset;
  }
}
