.status-msg {
  left: 18%;
  position: absolute;
  z-index: 9999999;
}

.status-msg .success-msg {
  border-radius: 3px;
  border: solid 1px #30b661;
  background-color: #e2f0ec;
  font-size: 12px;
  color: #30b661;
  padding: 8px 7px;
  position: fixed;
  min-width: 220px;
  bottom: 20px;
  z-index: 1000;
}

.status-msg .success-msg .link {
  margin-left: 10px;
}

.status-msg .success-msg .link:hover {
  color: #30b661;
}

.status-msg .error-msg {
  border-radius: 3px;
  border: solid 1px #f5c6cb;
  background-color: #f8d7da;
  font-size: 12px;
  color: #721c24;
  padding: 8px 7px;
  position: fixed;
  min-width: 220px;
  bottom: 20px;
  z-index: 1000;
}

.status-msg .error-msg .link {
  margin-left: 10px;
}

.status-msg .error-msg .link:hover {
  color: #721c24;
}
